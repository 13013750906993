import { createSelector } from '@ngrx/store';
import { SessionLessonChatInfo } from 'message-lib';
import { uniqueStudentName } from 'pipes-directives-lib';

import { SessionActivity, SessionActivityLog, SessionLesson, SessionLessonInfo, SessionLessonLogInfo, SessionLessonState, SessionQuestion } from '../../models';
import { ActivityEntity, ActivityEntityState, LessonEntity, LessonEntityState, QuestionEntity, QuestionEntityState } from '../models';
import {
  selectActivityEntities, selectActivityEntityState, selectLessonEntities, selectLessonEntityIds, selectLessonEntityState,
  selectQuestionEntities, selectQuestionEntityState, selectTutorState, TutorData
} from '../state';

interface EntityMap<TEntity> {
  [entityId: number]: TEntity;
}

interface EntityStateMap<TEntityState> {
  [entityGuid: string]: TEntityState;
}

// NOTE - this selector is used by log to contain just enough data to display
// If you use the full session Lesson selector, it repaints for no real reason

export const selectSessionLessonLogInfos = createSelector(selectLessonEntityIds, selectLessonEntities,
  selectLessonEntityState, selectActivityEntities, selectActivityEntityState,
  (ids, lessonEntities, lessonStates, activityEntities, activityStates) => {
    const allStudents = ids.map(id => lessonEntities[id]?.student);
    const mappedSessionLessonLogInfos = ids.map(id => {
      const entity = lessonEntities[id];
      let mappedSessionActivityLog: (SessionActivityLog | undefined)[] = [];
      if (entity) {
        const lessonState = lessonStates[entity.lessonGuid];
        if (lessonState) {
          mappedSessionActivityLog = lessonState.activityGuids.map(guid => {
            const activityState = activityStates[guid];

            let sessionActivity: SessionActivityLog | undefined;

            if (activityState) {
              const activityEntity = activityEntities[activityState.lessonActivityPlanId];
              if (activityEntity) {
                sessionActivity = {
                  activityGuid: guid,
                  name: activityEntity.name,
                  questions: activityState.questionGuids.map(q => ({ questionGuid: q }))
                };
              }
            }

            return sessionActivity;
          });
        }
      }

      const info: SessionLessonLogInfo = {
        lessonGuid: entity.lessonGuid,
        studentUniqueName: uniqueStudentName(entity.student, allStudents),
        activities: mappedSessionActivityLog.filter((activity): activity is SessionActivityLog => activity !== undefined)
      };
      return info;
    });

    return mappedSessionLessonLogInfos.filter((lesson): lesson is SessionLessonLogInfo => lesson !== undefined);
  });

// NOTE - this selector is used by chat to contain just enough data to display
// If you use the full session Lesson selector, it repaints for no real reason

export const selectSessionLessonChatInfos = createSelector(selectLessonEntityIds, selectLessonEntities, (ids, entities) => {
  const allStudents = ids.map(id => entities[id]?.student);
  const mapped = ids.map(id => {
    const entity = entities[id];

    let info: SessionLessonChatInfo | undefined;

    if (entity) {
      info = {
        lessonGuid: entity.lessonGuid,
        studentUniqueName: uniqueStudentName(entity.student, allStudents)
      };
    }

    return info;
  });

  return mapped.filter((lesson): lesson is SessionLessonChatInfo => lesson !== undefined);
});

const selectSessionLessonInfos = createSelector(selectLessonEntityIds, selectLessonEntities, (ids, entities) => {
  const allStudents = ids.map(id => entities[id]?.student);
  const mapped = ids.map(id => {
    const entity = entities[id];

    let info: SessionLessonInfo | undefined;

    if (entity) {
      info = {
        lessonId: entity.lessonId,
        lessonGuid: entity.lessonGuid,
        lessonPlanId: entity.lessonPlanId,
        previousLessonId: entity.previousLessonId,
        previousSessionId: entity.previousSessionId,
        previousLessonPlanId: entity.previousLessonPlanId,
        previousLessonGuid: entity.previousLessonGuid,
        previousLessonDateTime: entity.previousLessonDateTime,
        previousLessonTutor: entity.previousLessonTutor,
        previousText: entity.previousText,
        student: entity.student,
        studentUniqueName: uniqueStudentName(entity.student, allStudents),
        studentId: entity.studentId,
        centreId: entity.centreId,
        preferredCurrencyId: entity.preferredCurrencyId,
        preferredCurrencyCode: entity.preferredCurrencyCode,
        studentIanaTimeZoneName: entity.studentIanaTimeZoneName,
        dateOfBirth: entity.dateOfBirth,
        gradeId: entity.gradeId,
        regionId: entity.regionId,
        soundRegionId: entity.soundRegionId,
        subjectName: entity.subjectName,
        subjectShortCode: entity.subjectShortCode,
        levelTitle: entity.levelTitle,
        color: entity.color
      };
    }

    return info;
  });

  return mapped.filter((lesson): lesson is SessionLessonInfo => lesson !== undefined);
});

export const selectTimings = createSelector(selectTutorState, (state: TutorData) => state.lessons.timings);

const selectTeachingLessonEntityId = createSelector(selectTutorState, (state: TutorData) => state.lessons.teachId);
const selectObservingLessonEntityId = createSelector(selectTutorState, (state: TutorData) => state.lessons.observeId);

function mapQuestions(guids: readonly string[],
  questions: EntityMap<QuestionEntity>, questionState: EntityStateMap<QuestionEntityState>): SessionQuestion[] {

  // Get the entity and state for each question guid in the lesson
  const mapped = guids.map(guid => {
    const state = questionState[guid];

    let sessionQuestion: SessionQuestion | undefined;

    if (state) {
      const entity = questions[state.questionId];

      if (entity) {
        sessionQuestion = {
          questionId: entity.questionId,
          questionGuid: guid,
          contentGuid: entity.contentGuid,
          skillbuilderActivityId: entity.skillBuilderActivityId,
          duration: state.duration,
          visited: state.visited,
          skipped: state.skipped,
          correct: state.correct,
          hasWhiteboard: state.hasWhiteboard,
          aiChatCount: state.aiChatCount,
          attempts: state.attempts,
          currentAttempt: state.currentAttempt
        };
      }
    }

    return sessionQuestion;
  });

  return mapped.filter((question): question is SessionQuestion => question !== undefined);
}

function mapActivities(lessonGuid: string, guids: readonly string[],
  activities: EntityMap<ActivityEntity>, activityState: EntityStateMap<ActivityEntityState>,
  questions: EntityMap<QuestionEntity>, questionState: EntityStateMap<QuestionEntityState>): SessionActivity[] {

  // Get the entity and state for each activity guid in the lesson
  const mapped = guids.map(guid => {
    const state = activityState[guid];

    let sessionActivity: SessionActivity | undefined;

    if (state) {
      const entity = activities[state.lessonActivityPlanId];
      const activityQuestions = mapQuestions(state.questionGuids, questions, questionState);

      if (entity) {
        sessionActivity = {
          activityId: entity.activityId,
          counterId: entity.counterId,
          lessonActivityPlanId: state.lessonActivityPlanId,
          activityGuid: guid,
          lessonGuid: lessonGuid,
          name: entity.name,
          description: entity.description,
          isHiddenStudent: entity.isHiddenStudent,
          unit: entity.unit,
          unitId: entity.unitId,
          contentGuid: entity.contentGuid,
          assessmentFormName: entity.assessmentFormName,
          assessmentGradeId: entity.assessmentGradeId,
          assessmentResultTypeId: entity.assessmentResultTypeId,
          pdfFormFields: state.pdfFormFields,
          pdfFormCalculations: state.pdfFormCalculations,
          videos: state.videos,
          pdfFile: entity.pdfFile,
          pdfSolutionFile: entity.pdfSolutionFile,
          html: entity.html,
          soundFile: entity.soundFile,
          isJiraReportingBlocked: entity.isJiraReportingBlocked,
          internalNote: state.internalNote,
          questions: activityQuestions,
          manualScoring: {
            isSimpleScoring: entity.isSimpleScoring,
            correct: state.manualScoring.correct,
            total: state.manualScoring.total,
            inProgress: state.manualScoring.inProgress,
            simpleScore: state.manualScoring.simpleScore,
            noScoringRequired: state.manualScoring.noScoringRequired,
            scoringStatus: state.manualScoring.scoringStatus,
            timedActivityFirstAttempt: state.manualScoring.timedActivityFirstAttempt,
            timedActivityFirstAttemptStartTime: state.manualScoring.timedActivityFirstAttemptStartTime,
            timedActivitySecondAttempt: state.manualScoring.timedActivitySecondAttempt,
            timedActivitySecondAttemptStartTime: state.manualScoring.timedActivitySecondAttemptStartTime
          },
          completionTime: state.completionTime,
          percentage: state.percentage,
          age: state.age,
          ageResult: state.ageResult,
          hasSkillbuilders: entity.hasSkillbuilders,
          hasWhiteboard: state.hasWhiteboard,
          lessonActivityType: state.lessonActivityType,
          sessionActivityType: entity.sessionActivityType,
          warningTimeMinutes: entity.warningTimeMinutes,
          warningTimeTutorOnly: entity.warningTimeTutorOnly,
          maxAllowedTimeMinutes: entity.maxAllowedTimeMinutes,
          continuedFromPrevious: entity.continuedFromPrevious,
          files: state.files,
          completed: state.manualScoring.completed || activityQuestions.length > 0 && activityQuestions.every(question => question.correct)
        };
      }
    }

    return sessionActivity;
  });

  return mapped.filter((activity): activity is SessionActivity => activity !== undefined);
}

function mapLessonStates(ids: readonly number[],
  lessons: EntityMap<LessonEntity>, lessonState: EntityStateMap<LessonEntityState>,
  activities: EntityMap<ActivityEntity>, activityState: EntityStateMap<ActivityEntityState>,
  questions: EntityMap<QuestionEntity>, questionState: EntityStateMap<QuestionEntityState>): SessionLessonState[] {

  // Convert each ids into the associated lesson object
  const mapped = ids.map(id => {
    const entity = lessons[id];

    let sessionLessonState: SessionLessonState | undefined;

    if (entity) {
      const state = lessonState[entity.lessonGuid];

      if (state) {

        const newActivities = mapActivities(entity.lessonGuid, state.activityGuids, activities, activityState, questions, questionState);
        const newHomework = mapActivities(entity.lessonGuid, state.homeworkGuids, activities, activityState, questions, questionState);
        const newPreviousHomework = mapActivities(entity.previousLessonGuid, state.previousHomeworkGuids, activities, activityState,
          questions, questionState);

        const tutorActivityGuid = state.tutorActivityGuid;
        let currentActivity = newActivities.find(a => a.activityGuid === tutorActivityGuid);
        if (!currentActivity) {
          currentActivity = newHomework.find(a => a.activityGuid === tutorActivityGuid);
        }
        if (!currentActivity) {
          currentActivity = newPreviousHomework.find(a => a.activityGuid === tutorActivityGuid);
        }

        let currentQuestion: SessionQuestion | undefined;

        if (currentActivity) {
          const tutorQuestionGuid = state.tutorQuestionGuid;
          currentQuestion = currentActivity.questions.find(q => q.questionGuid === tutorQuestionGuid);
          if (!currentQuestion && currentActivity.questions.length > 0) {
            currentQuestion = currentActivity.questions[0];
          }
        }

        sessionLessonState = {
          lessonGuid: entity.lessonGuid,
          activityGuid: state.activityGuid,
          questionGuid: state.questionGuid,
          customActivityPageGuid: state.customActivityPageGuid,
          tutorLocked: state.tutorLocked,
          started: state.started,
          lobby: state.lobby,
          isOnline: state.isOnline,
          assessmentId: state.assessmentId,
          assessmentStatus: state.assessmentStatus,
          hasSkillbuilders: state.hasSkillbuilders,
          studentOnline: state.studentOnline,
          studentPhotoUrl: state.studentPhotoUrl,
          studentPhotoDefault: state.studentPhotoDefault,
          studentImageData: state.studentImageData,
          publishVideo: state.publishVideo,
          publishAudio: state.publishAudio,
          internalNote: state.internalNote,
          focusNote: state.focusNote,
          aiNote: state.aiNote,
          parentNote: state.parentNote,
          internalNoteRanking: state.internalNoteRanking,
          parentNoteRanking: state.parentNoteRanking,
          parentNoteApproved: state.parentNoteApproved,
          streamState: state.streamState,
          lessonStatus: state.lessonStatus,
          focus: state.focus,
          observe: state.observe,
          help: state.help,
          currentTutorQuestion: currentQuestion,
          currentTutorActivity: currentActivity,
          currentTutorCustomActivityPageGuid: state.tutorCustomActivityPageGuid,
          activities: newActivities,
          homework: newHomework,
          previousHomework: newPreviousHomework,
          enrolment: state.enrolment,
          deviceData: state.deviceData,
          isPinned: state.isPinned,
          dropInLessonCompleted: state.dropInLessonCompleted,
          visible: state.visible,
          lessonType: state.lessonType,
          displayOrder: state.displayOrder,
          isDIYCompatible: state.isDIYCompatible,
          isHomeworkOnly: state.isHomeworkOnly,
          studentSettings: state.studentSettings,
          installAvailable: state.installAvailable,
          message: state.message,
          messageReload: state.messageReload,
          messageError: state.messageError,
          finishLessonNote: state.finishLessonNote,
          finishLessonNoteRanking: state.finishLessonNoteRanking,
          startLessonNote: state.startLessonNote,
          startLessonNoteRanking: state.startLessonNoteRanking
        };
      }
    }

    return sessionLessonState;
  });

  return mapped.filter((lesson): lesson is SessionLessonState => lesson !== undefined);
}

export const selectSessionLessonStates = createSelector(selectLessonEntityIds, selectLessonEntities, selectLessonEntityState,
  selectActivityEntities, selectActivityEntityState, selectQuestionEntities, selectQuestionEntityState, mapLessonStates);

export const selectSomeStudentsOnline = createSelector(selectSessionLessonStates, states =>
  states.some(s => s.started && s.studentOnline && !s.lobby)
);

export const selectSessionLessons = createSelector(selectSessionLessonInfos, selectSessionLessonStates, (infos, states) => {
  const lessons = infos.map(info => {

    let lesson: SessionLesson | undefined;
    if (info) {
      const state = states.find(current => current.lessonGuid === info.lessonGuid);

      if (state) {
        lesson = {
          lessonId: info.lessonId,
          lessonGuid: info.lessonGuid,
          previousLessonId: info.previousLessonId,
          previousSessionId: info.previousSessionId,
          previousLessonPlanId: info.previousLessonPlanId,
          previousLessonGuid: info.previousLessonGuid,
          previousLessonDateTime: info.previousLessonDateTime,
          previousLessonTutor: info.previousLessonTutor,
          previousText: info.previousText,
          lessonPlanId: info.lessonPlanId,
          activityGuid: state.activityGuid,
          questionGuid: state.questionGuid,
          customActivityPageGuid: state.customActivityPageGuid,
          tutorLocked: state.tutorLocked,
          student: info.student,
          studentUniqueName: info.studentUniqueName,
          studentId: info.studentId,
          centreId: info.centreId,
          preferredCurrencyId: info.preferredCurrencyId,
          preferredCurrencyCode: info.preferredCurrencyCode,
          studentIanaTimeZoneName: info.studentIanaTimeZoneName,
          dateOfBirth: info.dateOfBirth,
          gradeId: info.gradeId,
          studentPhotoUrl: state.studentPhotoUrl,
          studentPhotoDefault: state.studentPhotoDefault,
          studentImageData: state.studentImageData,
          subjectName: info.subjectName,
          subjectShortCode: info.subjectShortCode,
          levelTitle: info.levelTitle,
          lessonStatus: state.lessonStatus,
          assessmentId: state.assessmentId,
          assessmentStatus: state.assessmentStatus,
          hasSkillbuilders: state.hasSkillbuilders,
          color: info.color,
          regionId: info.regionId,
          soundRegionId: info.soundRegionId,
          isOnline: state.isOnline,
          started: state.started,
          lobby: state.lobby,
          studentOnline: state.studentOnline,
          publishVideo: state.publishVideo,
          publishAudio: state.publishAudio,
          parentNote: state.parentNote,
          parentNoteApproved: state.parentNoteApproved,
          focusNote: state.focusNote,
          aiNote: state.aiNote,
          internalNote: state.internalNote,
          parentNoteRanking: state.parentNoteRanking,
          internalNoteRanking: state.internalNoteRanking,
          streamState: state.streamState,
          focus: state.focus,
          observe: state.observe,
          help: state.help,
          currentTutorCustomActivityPageGuid: state.currentTutorCustomActivityPageGuid,
          currentTutorQuestion: state.currentTutorQuestion,
          currentTutorActivity: state.currentTutorActivity,
          activities: state.activities,
          dropInLessonCompleted: state.dropInLessonCompleted,
          homework: state.homework,
          previousHomework: state.previousHomework,
          enrolment: state.enrolment,
          deviceData: state.deviceData,
          isPinned: state.isPinned,
          visible: state.visible,
          lessonType: state.lessonType,
          displayOrder: state.displayOrder,
          isDIYCompatible: state.isDIYCompatible,
          isHomeworkOnly: state.isHomeworkOnly,
          studentSettings: state.studentSettings,
          installAvailable: state.installAvailable,
          message: state.message,
          messageReload: state.messageReload,
          messageError: state.messageError,
          startLessonNote: state.startLessonNote,
          startLessonNoteRanking: state.startLessonNoteRanking,
          finishLessonNote: state.finishLessonNote,
          finishLessonNoteRanking: state.finishLessonNoteRanking
        };
      }
    }

    return lesson;
  });

  return lessons.filter((lesson): lesson is SessionLesson => lesson !== undefined);
});

export const selectTeachingLesson = createSelector(selectTeachingLessonEntityId, selectSessionLessons, (id, lessons) => {
  if (lessons) {
    return lessons.find(lesson => lesson && lesson.lessonId === id);
  }

  return undefined;
});

export const selectTeachingLessonLookingTab = createSelector(selectTeachingLesson, selectLessonEntityState, (teachLesson, entities) => {
  if (teachLesson) {
    const lesson = entities[teachLesson.lessonGuid];
    if (lesson) {
      return lesson.lookingTab;
    }
  }

  return undefined;
});

export const selectObservingLesson = createSelector(selectObservingLessonEntityId, selectSessionLessons, (id, lessons) => {
  if (lessons) {
    return lessons.find(lesson => lesson && lesson.lessonId === id);
  }

  return undefined;
});
