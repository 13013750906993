
import 'snapsvg-cjs';

import { ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import * as snapsvg from 'snapsvg';

declare let Snap: any;

export enum ClockType {
  Readonly = 1,
  Interactive = 2,
  Countdown = 3
}

enum HandClasses {
  Valid = 'kip-hour-hand-valid',
  Invalid = 'kip-hour-hand-invalid',
  Default = 'kip-hour-hand'
}

interface HandInfo {
  class: string;
  value: number;
  length: number;
  interval: number;
  enabled: boolean;
  hand?: snapsvg.Element;
  handle?: snapsvg.Element;
}

const genericClockHourHandId = 'generic-clock-hour-hand';
const genericClockMinuteHandId = 'generic-clock-minute-hand';

export class GenericClock {

  readonly #hours: HandInfo;
  readonly #minutes: HandInfo;
  readonly #center = 150;
  readonly #degrees = 180 / Math.PI;
  readonly #radians = Math.PI / 180;
  readonly #faceSize = this.#center - 2;
  readonly #handleSize = this.#faceSize * 0.2;
  readonly #clockType: ClockType;
  readonly #paper: snapsvg.Paper;
  readonly #change: Subject<void>;
  #hourHand?: snapsvg.Element;
  #countDownSound: HTMLAudioElement | undefined;
  #buzzerSound: HTMLAudioElement | undefined;

  constructor(element: ElementRef<SVGElement>,
    hours: { value?: number, interval?: number, enabled?: boolean, hidden?: boolean, valid?: boolean },
    minutes: { value?: number, interval?: number, enabled?: boolean, hidden?: boolean, valid?: boolean },
    clockType: ClockType) {

    this.#clockType = clockType;

    // Note that all positions are based on a size of 300 x 300
    this.#paper = Snap(element.nativeElement);

    this.#paper.clear();

    this.#drawFace();

    this.#hours = {
      class: clockType !== ClockType.Countdown && hours.valid !== undefined ? hours.valid ?
        HandClasses.Valid : HandClasses.Invalid : HandClasses.Default,
      value: hours.value ? hours.value * 5 : 0,
      length: this.#faceSize * 0.4,
      interval: hours.interval ? hours.interval * 5 : 5,
      enabled: !!hours.enabled && clockType === ClockType.Interactive
    };

    this.#minutes = {
      class: clockType !== ClockType.Countdown && hours.valid !== undefined ? minutes.valid ?
        HandClasses.Valid : HandClasses.Invalid : HandClasses.Default,
      value: minutes.value ?? 0,
      length: this.#faceSize * 0.7,
      interval: minutes.interval ?? 1,
      enabled: !!minutes.enabled && clockType === ClockType.Interactive
    };

    this.#change = new Subject<void>();

    // Add the hands - note the ordering so the hour hand is on top
    if (!minutes.hidden) {
      this.#addHand(this.#minutes, genericClockMinuteHandId);
    }

    if (!hours.hidden) {
      this.#addHand(this.#hours, genericClockHourHandId);
    }
  }

  setCountdownTimeAndPlaySounds(countDownTime: number) {
    if (countDownTime <= 120 && this.#minutes.hand) {
      this.#setHandAngle(countDownTime % 60 * (360 / 60), this.#minutes.hand);
    }

    if (!this.#countDownSound) {
      this.#countDownSound = this.#playSound('assets/activity/tick.mp3');
      this.#countDownSound.loop = true;
    }
    if (countDownTime >= 120 && !this.#buzzerSound) {
      if (this.#minutes.hand) {
        this.#setHandAngle(0, this.#minutes.hand);
      }
      if (this.#countDownSound) {
        this.#countDownSound.pause();
      }
      this.#buzzerSound = this.#playSound('assets/activity/buzzer.mp3');
    }

    if (countDownTime >= 125 && this.#buzzerSound) {
      this.#buzzerSound.pause();
    }
  }

  get clockHours() {
    return this.#hours;
  }

  get clockMinutes() {
    return this.#minutes;
  }

  get change() {
    return this.#change;
  }

  destroy() {
    if (this.#countDownSound) {
      this.#countDownSound.pause();
      this.#countDownSound = undefined;
    }

    if (this.#buzzerSound) {
      this.#buzzerSound.pause();
      this.#buzzerSound = undefined;
    }
    this.#paper.remove();
  }

  #playSound(url: string) {

    const audio = new Audio(url);
    audio.addEventListener('error', () => {
      // ignore
    });
    audio.addEventListener('pause', () => {
      // ignore
    });
    audio.addEventListener('ended', () => {
      // ignore
    });

    audio.play();

    return audio;
  }

  #addHand(info: HandInfo, id: string) {

    // Add the hand shape and the drag handle
    const top = this.#faceSize - info.length;
    const hand = this.#paper.line(this.#center, this.#center, this.#center, top);

    hand.attr({
      class: info.class,
      id
    });

    info.hand = hand;

    // Apply the hand angle for the default value
    let value = info.value * (360 / 60);

    if (id === genericClockHourHandId && info.value > 0) {
      const minutes = this.clockMinutes.value === 60 ? 0 : this.clockMinutes.value;
      const initialAngle = minutes / 60 * 30;
      const startHour = Math.floor(info.value / 5);
      value = startHour * 30 + initialAngle;
    }

    this.#setHandAngle(value, hand);

    // Make the hand draggable if it is enabled
    if (info.enabled) {
      const handle = this.#paper.circle(this.#center, top, this.#handleSize);

      handle.attr({
        class: 'kip-handle'
      });

      info.handle = handle;

      this.#resetHandlePosition(hand, handle);

      this.#hourHand = hand;
      // Configure the drag functionality
      handle.drag(
        (dx, dy) => {

          // When dragging clear validity

          hand.attr({ class: HandClasses.Default });
          info.class = HandClasses.Default;

          this.#moveHand(top, info.interval, dx, dy, hand, handle);
        },
        () => {
          this.#trackHandlePosition(handle);
        },
        () => {
          // Calculate the marker the hand is pointing at based on the angle
          info.value = this.#calculateHandMarker(hand);

          // Then reset the handle position based on the associated hand angle
          this.#resetHandlePosition(hand, handle);

          // Send notification of the values changing
          this.#change.next();
        });
    }
  }

  #setHandAngle(angle: number, hand: snapsvg.Element) {

    // Build the rotation matrix
    const matrix: snapsvg.Matrix = Snap.matrix();
    matrix.rotate(angle, this.#center, this.#center);

    // Apply the rotation transformation to the hand
    const transformation = matrix.toTransformString();

    hand.transform(transformation);
    hand.data('angle', angle);
  }

  #calculateHandMarker(hand: snapsvg.Element): number {
    const angle = hand.data('angle') as number;
    return Math.round(60 / (360 / angle));
  }

  #calculateLength(x1: number, y1: number, x2: number, y2: number): number {
    const a = x2 - x1;
    const b = y2 - y1;

    return Math.sqrt(a * a + b * b);
  }

  #calculateAngle(angle: number, interval: number, isHoursHand: boolean): number {

    // Find where the current angle is located with the steps
    const angleInterval = 360 / 60 * interval;
    const minutes = this.clockMinutes.value === 60 ? 0 : this.clockMinutes.value;
    const initialAngle = isHoursHand ? minutes / 60 * 30 : 0;

    for (let current = initialAngle; current < 360; current += angleInterval) {
      const start = current;
      const middle = start + angleInterval / 2;
      const end = start + angleInterval;

      // From the start of a marker to the middle will return the start
      if (angle >= start && angle <= middle) {
        return start;
      }

      // From the middle of a marker to the end will return the end
      if (angle > middle && angle <= end) {
        return end;
      }
    }

    return 0;
  }

  #trackHandlePosition(handle: snapsvg.Element) {
    const x = +handle.attr('cx');
    const y = +handle.attr('cy');

    handle.data('ox', x);
    handle.data('oy', y);
  }

  #resetHandlePosition(hand: snapsvg.Element, handle: snapsvg.Element) {

    // Get the bounds of the hand so we can get the x / y coordinates
    const box = hand.getBBox();
    const angle = hand.data('angle') as number;

    // The position we apply to the handle depends on the angle of the hand
    // This is because of where the SVG x / y positions are which change for the angle
    let x = box.x;
    let y = box.y;

    if (angle > 0 && angle < 180) {
      x = box.x2;
    }

    if (angle > 90 && angle < 270) {
      y = box.y2;
    }

    handle.attr({
      cx: x,
      cy: y
    });
  }

  #moveHand(top: number, interval: number, dx: number, dy: number, hand: snapsvg.Element, handle: snapsvg.Element) {

    // Get the current drag coordinates and position the handle
    const ox = handle.data('ox') as number;
    const oy = handle.data('oy') as number;
    const x = ox + dx;
    const y = oy + dy;

    handle.attr({
      cx: x,
      cy: y
    });

    // Get the length of the sides that form the drag 'triangle'
    //   |\
    //   | \       - Find the angle at * (clock center) based on the lengths of the triangle sides
    // c |  \ a    - The # marks the drag position of the mouse
    //   |___\
    //  *  b  #
    const a = this.#calculateLength(this.#center, top, x, y);
    const b = this.#calculateLength(this.#center, this.#center, x, y);
    const c = this.#calculateLength(this.#center, this.#center, this.#center, top);

    // Then apply the law of cosines to calculate the angle at the center point for the hand rotation
    const p1 = b * b + c * c - a * a;
    const p2 = 2 * b * c;

    // If the drag x is before the svg center point, an adjustment is needed to calculate an angle larger than 180
    let angle = Math.acos(p1 / p2) * this.#degrees;

    if (x < this.#center) {
      angle = 180 + (180 - angle);
    }

    // Snapping to the clock markers is also needed, so calculate based on the closest
    const prevAngle = Math.ceil(angle);
    angle = this.#calculateAngle(angle, interval, hand.attr('id') === genericClockHourHandId);

    if (hand.attr('id') === genericClockMinuteHandId && (prevAngle % 6 === 0 || angle >= 354)) {
      let hour = Math.floor(this.clockHours.value / 5 === 0 ? 12 : this.clockHours.value / 5);

      if (angle > 355) {
        hour = hour - 1;
      }

      // Calculate and set hoursHand angle
      this.#setHandAngle(hour * 30 + angle / 12, this.#hourHand!);
    }

    this.#setHandAngle(angle, hand);
  }

  #drawFace() {

    // Draw the static shapes
    const outer = this.#paper.circle(this.#center, this.#center, this.#faceSize);
    const center = this.#paper.circle(this.#center, this.#center, 6);

    outer.attr({
      class: 'kip-outer'
    });

    center.attr({
      class: 'kip-center'
    });

    // Add the markers
    for (let index = 1; index <= 60; index++) {
      const major = index % 5 === 0;
      const marker = this.#paper.line(this.#center, this.#center - this.#faceSize, this.#center, major ? 15 : 10);

      marker.attr({
        class: `kip-marker ${major ? 'kip-major' : 'kip-minor'}`
      });

      // Set up the rotation to position to marker
      const angle = 360 / 60 * (index + 45);
      const matrix: snapsvg.Matrix = Snap.matrix();

      matrix.rotate(angle, this.#center, this.#center);

      // Apply the transformation
      const transformation = matrix.toTransformString();
      marker.transform(transformation);
    }

    // Add the hour numbering
    let x;
    let y;
    let previous = -90;

    for (let index = 1; index <= 12; index++) {

      // Calculate the x and y for the number - a y offset is added to account for font size
      const radius = this.#faceSize - 26;
      const angle = previous + 360 / 12;

      x = this.#center + radius * Math.cos(angle * this.#radians);
      y = this.#center + radius * Math.sin(angle * this.#radians) + 7;

      previous = angle;

      // Add the number text and style it
      const clockFaceNumber = (this.#clockType === ClockType.Countdown ? 60 - 5 * index : index).toString();
      const text = this.#paper.text(x, y, clockFaceNumber);

      text.attr({
        class: 'kip-number'
      });
    }
  }
}
