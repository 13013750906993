import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import { CustomActivityQuestionIndexFormatter } from '../../../shared';
import { QuestionEntityState } from '../models';
import * as QuestionActions from './question.actions';

export interface QuestionState {
  readonly selectedIndex: number | undefined;
  readonly entityIds: number[];
  readonly entityState: {
    readonly [questionGuid: string]: QuestionEntityState;
  };
}

const initialState: QuestionState = {
  selectedIndex: undefined,
  entityIds: [],
  entityState: {}
};

function updateQuestionEntityState(
  questionGuid: string, state: QuestionState, update: (entityState: QuestionEntityState) => Partial<QuestionEntityState>): QuestionState {

  let questionState = state.entityState[questionGuid];

  if (questionState) {
    const updateState = update(questionState);

    if (updateState) {
      questionState = Object.assign({}, questionState, updateState);

      return {
        selectedIndex: state.selectedIndex,
        entityIds: state.entityIds,
        entityState: { ...state.entityState, [questionGuid]: questionState }
      };
    }
  }

  return state;
}

export const questionReducer = createReducer(initialState,
  on(QuestionActions.loadManyAction, (state, action) => {

    // Only add questions not already loaded
    let entityIds = state.entityIds;
    let entityState = state.entityState;

    for (const question of action.questions) {
      if (!entityIds.includes(question.questionId)) {
        entityIds = [...entityIds, question.questionId];
      }

      // Add the question state
      const questionState: QuestionEntityState = {
        questionIndex: question.index,
        questionId: question.questionId,
        visited: question.visited ?? false,
        skipped: question.skipped ?? false,
        duration: question.duration ?? 0,
        correct: question.correct ?? false,
        attempts: (question.attempts ?? []).length,
        hasWhiteboard: question.hasWhiteboard ?? false,
        score: question.score ?? 0,
        startTime: question.startTime,
        introduction: null,
        workedSolution: null,
        reportedAsError: false
      };

      entityState = { ...entityState, [question.questionGuid]: questionState };
    }

    return {
      selectedIndex: state.selectedIndex,
      entityIds: entityIds,
      entityState: entityState
    };
  }),
  on(QuestionActions.loadQuestionsAction, (state, action) => {
    let entityState = state.entityState;
    for (const question of action.questions) {
      let questionState = entityState[question.questionGuid];
      if (questionState) {
        questionState = Object.assign({}, questionState, { question: question.question, questionKatex: question.questionKatex });
        entityState = { ...entityState, [question.questionGuid]: questionState };
      }
    }

    return {
      selectedIndex: state.selectedIndex,
      entityIds: state.entityIds,
      entityState: entityState
    };
  }),
  on(QuestionActions.openAction, (state, action): QuestionState => {
    return {
      selectedIndex: action.questionIndex,
      entityIds: state.entityIds,
      entityState: state.entityState
    };
  }),
  on(QuestionActions.skipAction, (state, action): QuestionState => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      selectedIndex: undefined,
      skipped: true
    }));
  }),
  on(QuestionActions.closeAction, (state): QuestionState => {
    return {
      selectedIndex: undefined,
      entityIds: state.entityIds,
      entityState: state.entityState
    };
  }),
  on(QuestionActions.reportedAsErrorAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      reportedAsError: true
    }));
  }),
  on(QuestionActions.visitAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      visited: true
    }));
  }),
  on(QuestionActions.whiteboardDrawnAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      hasWhiteboard: true
    }));
  }),
  on(QuestionActions.answerAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, questionState => {
      let score = 0;

      // Calculate the score based on the result (whether correct and number of attempts)
      //  Correct + 1 attempt  = correct first time                  = 1 points
      //  Else                 = not correct or more than 1 attempts = 0 points
      const attempts = questionState.attempts + 1;

      if (action.correct && attempts <= 1 && !action.isAssessment) {
        score = action.kipPointsScaleFactor;
      }

      // double points for homework
      if (action.isHomework) {
        score = score * 2;
      }

      return {
        correct: action.correct,
        attempts: attempts,
        score: score
      };
    });
  }),
  on(QuestionActions.loadWorkedSolutionAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      workedSolution: action.workedSolution
    }));
  }),
  on(QuestionActions.loadIntroductionAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      introduction: action.introduction
    }));
  }),
  on(QuestionActions.startTimerAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      startTime: moment.utc().toDate()
    }));
  }),
  on(QuestionActions.endTimerAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, questionState => {
      const startTime = moment.utc(questionState.startTime);

      // If a valid start time is found, calculate the diff to now and set the time taken
      if (startTime.isValid()) {
        const now = moment.utc();
        const duration = now.diff(startTime, 'milliseconds');

        return {
          duration: questionState.duration + duration / 1000,
          startTime: undefined
        };
      }

      return {};
    });
  }),
  on(QuestionActions.openCustomActivityQuestionAction, (state, action) => {
    const questionIndex = CustomActivityQuestionIndexFormatter.getQuestionIndexFromPageGuid(action.whiteboardGuidKey.pageGuid);
    return {
      selectedIndex: questionIndex,
      entityIds: state.entityIds,
      entityState: state.entityState
    };
  }),
  on(QuestionActions.closeCustomActivityQuestionAction, (state, _action): QuestionState => {
    return {
      selectedIndex: undefined,
      entityIds: state.entityIds,
      entityState: state.entityState
    };
  })
);
