<div #dragContainer cdkDrag class="monty" [class.monty-hidden]="!aiTutorActive" [class.monty-visible]="aiTutorActive"
    (cdkDragMoved)="onDragMoved($event)">
    @if (!aiTutorActive) {
    <div class="kip-chat-box__log-overflow kip-ai-sidebar d-flex flex-column justify-content-end h-100">
        @if ($currentMessage) {
        <div class="d-flex flex-column justify-content-end speech-bubble px-2 pt-2 overflow-hidden">
            <kip-message-parser class="overflow-auto d-flex flex-column-reverse" [ai]="true"
                [message]="($currentMessage | async) ?? ''" />
            @if (!thinking())
            {
            <div class="input-group mb-1">
                <input #input class="form-control" placeholder="Ask me anything..." type="text"
                    (keyup.enter)="onSendMessage(input.value)" (keyup.shift.enter)="onSendMessage(input.value)">
                <button class="btn" placement="top" type="button"
                    [ngClass]="{'btn-outline-secondary': !isRecording(), 'btn-danger' : isRecording()}"
                    [ngbTooltip]="tooltipTemplateRecording" (click)="toggleRecording()">
                    <fa-icon [icon]="isRecording()?icons.microphone.on.regular: icons.microphone.off.regular" />
                </button>
                <ng-template #tooltipTemplateRecording>
                    {{ isRecording()?'Stop recording me': 'Start Recording me' }}
                </ng-template>
                <button #tooltip="ngbTooltip" class="btn btn-outline-secondary" placement="top" type="button"
                    [ngbTooltip]="tooltipTemplateAIActive" (click)="toggleAudio()">
                    <fa-icon [icon]="aiTutorVoiceActive?icons.audioTest:icons.audioTestOff" />
                </button>
                <ng-template #tooltipTemplateAIActive>
                    {{ !aiTutorVoiceActive?'Unmute Sound':'Mute Sound' }}
                </ng-template>
                <button class="btn btn-outline-secondary" ngbTooltip="Clear" placement="top" type="button"
                    (click)="clear()">
                    <fa-icon [icon]="icons.close.regular" />
                </button>
                <button class="btn btn-outline-secondary" ngbTooltip="Ask" placement="top" type="button"
                    (click)="onSendMessage(input.value)">
                    <fa-icon [icon]="icons.sendChat" />
                </button>
            </div>
            }
            @else {
            <kip-loader />
            }
        </div>
        }
        <div class="d-flex flex-column justify-content-end speech-bubble"></div>
        <div class="d-flex justify-content-between">
            @if (talking()) {
            <kip-monty-new-talking class="w-50" />
            }
            @else {
            <kip-monty-new-body class="w-50" />
            }
            <span class="mt-2 me-2" role="button" (click)="toggleHide()">
                <fa-icon [icon]="icons.eyes.eye" /> Hide
            </span>
        </div>
    </div>
    }
    @else {
    <div class="d-flex">
        <kip-monty-hidden style="width: 200px;" />
        <span class="hidden-margin" role="button" (click)="toggleHide()">
            <fa-icon [icon]="icons.eyes.eye" /> Show
        </span>
    </div>
    }
</div>