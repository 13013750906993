import { ChangeDetectionStrategy, Component, inject, input, OnDestroy } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';

import { SharedService } from '../../services';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-polly',
  templateUrl: './form-control-polly.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormControlPollyComponent extends BaseFormControlComponent implements OnDestroy {

  readonly #sharedService = inject(SharedService);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  readonly control = input.required<FormControl<string>>();
  readonly pollyValues = toSignal(this.#sharedService.getPolly(), { initialValue: [] });

  ngOnDestroy(): void {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  playAudio() {
    this.#subscriptions.push(
      this.#sharedService.usePolly(this.control().value, 'The quick brown fox jumps other the lazy dog').subscribe(response => {
        if (response.body) {
          const audio = new Audio();
          audio.src = window.URL.createObjectURL(response.body);
          audio.load();
          audio.play();
        }
      }));
  }
}
