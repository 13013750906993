import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { IconLibModule } from 'icon-lib';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { ServiceLibModule } from 'service-lib';

import { ErrorModalComponent } from './error-modal/error-modal.component';
import { PdfComponent } from './pdf/pdf.component';
import { PrintComponent } from './print/print.component';

@NgModule({
  declarations: [
    ErrorModalComponent,
    PdfComponent,
    PrintComponent
  ],
  imports: [
    IconLibModule,
    NgbModalModule,
    NgxExtendedPdfViewerModule,
    FormsModule,
    ServiceLibModule
  ],
  exports: [
    ErrorModalComponent,
    PdfComponent,
    PrintComponent
  ]
})
export class PdfLibModule { }
