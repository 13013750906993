import { createAction, props } from '@ngrx/store';
import { LessonActivityPlanFile } from 'ui-common-lib';

import { LessonActivity, SessionActivityType } from '../../../shared';
import { ScoredActivity } from '../../models';

export interface ActivityContext {
  readonly lessonGuid: string;
  readonly sessionActivityType: SessionActivityType;
  readonly activity: LessonActivity;
}

enum ActivityActionTypes {
  LoadMany = 'Student > Activity > Load Many',
  LoadQuestions = 'Student > Activity > Load Questions',
  FinishLoadQuestions = 'Student > Activity > Finish Load Questions',
  Open = 'Student > Activity > Open',
  Close = 'Student > Activity > Close',
  RequestOpen = 'Student > Activity > Request Open',
  RequestClose = 'Student > Activity > Request Close',
  Percentage = 'Student > Activity > Percentage',
  FirstAttempt = 'Student > Activity > First Attempt',
  SecondAttempt = 'Student > Activity > Second Attempt',
  Start = 'Student > Activity > Start',
  StartFirstAttempt = 'Student > Activity > Start First Attempt',
  StartSecondAttempt = 'Student > Activity > Start Second Attempt',
  Finish = 'Student > Activity > Finish',
  Scored = 'Student > Activity > Scored',
  UpdateFiles = 'Student > Activity > Update Files',
  RenameFile = 'Student > Activity > Rename File',
  RemoveFile = 'Student > Activity > Remove File',
  WhiteboardDrawn = 'Student > Activity > Whiteboard Drawn',
  UpdateTimesTableState = 'Student > Activity > Update Times Table State'
}

export const loadManyAction = createAction(
  ActivityActionTypes.LoadMany,
  props<{
    readonly activities: readonly ActivityContext[];
  }>()
);

export const finishLoadQuestionsAction = createAction(
  ActivityActionTypes.FinishLoadQuestions,
  props<{
    readonly activityGuid: string;
  }>()
);

export const loadQuestionsAction = createAction(
  ActivityActionTypes.LoadQuestions,
  props<{
    readonly lessonActivityPlanId: number;
    readonly regionId: number;
  }>()
);

export const openAction = createAction(
  ActivityActionTypes.Open,
  props<{
    readonly lessonActivityPlanId: number;
    readonly isHomework: boolean;
  }>()
);

export const closeAction = createAction(
  ActivityActionTypes.Close,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly completed: boolean;
    readonly unitId: number | null;
  }>()
);

export const updateTimesTableStateAction = createAction(
  ActivityActionTypes.UpdateTimesTableState,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
  }>()
);

export const percentageAction = createAction(
  ActivityActionTypes.Percentage,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly percentage: number;
    readonly ageResult: number | null;
    readonly age: number | null;
    readonly completed: boolean;
  }>()
);

export const startFirstAttempt = createAction(
  ActivityActionTypes.StartFirstAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly startDateTime: Date;
  }>()
);

export const startSecondAttempt = createAction(
  ActivityActionTypes.StartSecondAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly startDateTime: Date;
  }>()
);

export const firstAttemptAction = createAction(
  ActivityActionTypes.FirstAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly count: number;
  }>()
);

export const secondAttemptAction = createAction(
  ActivityActionTypes.SecondAttempt,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly count: number;
  }>()
);

export const requestOpenAction = createAction(
  ActivityActionTypes.RequestOpen,
  props<{
    readonly activityGuid: string;
    readonly questionGuid?: string;
    readonly pageGuid?: string;
  }>()
);

export const requestCloseAction = createAction(
  ActivityActionTypes.RequestClose,
  props<{
    readonly activityGuid: string;
  }>()
);

export const whiteboardDrawnAction = createAction(
  ActivityActionTypes.WhiteboardDrawn,
  props<{
    readonly activityGuid: string;
  }>()
);

export const startAction = createAction(
  ActivityActionTypes.Start,
  props<{
    readonly activityGuid: string;
    readonly startedOn: Date;
  }>()
);

export const finishAction = createAction(
  ActivityActionTypes.Finish,
  props<{
    readonly activityGuid: string;
    readonly completedOn: Date | undefined;
    readonly activityScore: number | undefined;
  }>()
);

export const updateFilesAction = createAction(
  ActivityActionTypes.UpdateFiles,
  props<{
    readonly activityGuid: string;
    readonly files: LessonActivityPlanFile[];
  }>()
);

export const renameFileAction = createAction(
  ActivityActionTypes.RenameFile,
  props<{
    readonly activityGuid: string;
    readonly fileId: number;
    readonly fileName: string;
  }>()
);

export const removeFileAction = createAction(
  ActivityActionTypes.RemoveFile,
  props<{
    readonly activityGuid: string;
    readonly fileId: number;
  }>()
);

export const scoredAction = createAction(
  ActivityActionTypes.Scored,
  props<{
    readonly lessonId: number;
    readonly lessonGuid: string;
    readonly scoredActivity: ScoredActivity;
  }>()
);
