import { createReducer, on } from '@ngrx/store';

import * as AiChatActions from './ai-chat.actions';

export interface AiChatState {
    readonly messages: readonly string[];
}

const initialState: AiChatState = {
    messages: []
};

export const aiChatReducer = createReducer(initialState,
  on(AiChatActions.addAction, (state, action) => {
      if (!state.messages.includes(action.message)) {
      return {
        messages: [...state.messages, action.message]
      };
    }
    return state;
  })
);
