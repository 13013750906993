import { createAction, props } from '@ngrx/store';

enum ChatActionTypes {
  Chat = 'Student > AI Chat > Chat',
  Add = 'Student > AI Chat > Add'
}

export interface AiChatQuestion {
  readonly questionGuid: string;
  readonly questionId: number;
  readonly questionText: string;
}

export const chatAction = createAction(
    ChatActionTypes.Chat,
    props<{
      readonly message: string;
      readonly chatGroup: string;
      readonly pdf: string | null;
      readonly adHoc: string | null;
      readonly lessonId: number | null;
      readonly tutorName: string | null;
      readonly lessonGuid: string;
      readonly lessonActivityPlanId: number | null;
      readonly question?: AiChatQuestion;
      readonly restrictAiSubject: boolean;
      readonly aiWithholdAnswer: boolean;
    }>()
  );

export const addAction = createAction(
  ChatActionTypes.Add,
  props<{
    readonly message: string;
    readonly chatGroup: string;
  }>()
);