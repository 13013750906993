@if (showWarning)
{
<div role="alert" [class]="'text-center p-2 ' + (showAlertWarning ? 'alert-danger' : 'alert-warning')">

    <fa-icon class="mr-2" [icon]="showAlertWarning ? icons.triangleExclamation.regular : icons.circleInfo.regular" />

    Due to scheduled maintenance

    @if (minutesUntilMaintenance && showAlertWarning)
    {
        <strong>in {{ minutesUntilMaintenance}} {{minutesUntilMaintenance === 1? 'minute': 'minutes' }}</strong>
    }
    
    the system will be unavailable

    @if (downTime && downTime.start && !showAlertWarning) {
        from <strong>{{ downTime.start | dateFromOffsetToTimezone : downTime.userIanaTimeZoneName }}</strong>
    }


    @if (downTime && downTime.finish) {
        until <strong>{{ downTime.finish | dateFromOffsetToTimezone : downTime.userIanaTimeZoneName }}</strong>.
    }

</div>
}