/* eslint  @typescript-eslint/naming-convention : 0 */

import { createSelector } from '@ngrx/store';
import {
  EventType, LessonLog, LessonLogActivityClosed,
  LessonLogActivityOpened, LessonLogActivityOpenRequested,
  LessonLogAuthor, Message, MessageSubType
} from 'message-lib';
import { currencySwapFunction } from 'questions-lib';

import { LessonActivity, SelectedLesson } from '../../models';
import { selectLessonActivities } from '../activity';
import { selectSelectedLesson } from '../lesson';
import { selectLogEntities, selectLogEntityIds } from '../state';

type MessageFormatter = {
  readonly [eventType in EventType]?: (lesson: SelectedLesson, activities: LessonActivity[] | undefined, log?: LessonLog) => string;
};

const tutorFormatters: MessageFormatter = {
  'SessionOpened': lesson => `${lesson.tutor.givenName} joined the lesson`,
  'TutorPublishFailure': lesson => `${lesson.tutor.givenName} is having video problems`,
  'LessonPlanUpdated': lesson => `${lesson.tutor.givenName} has updated the lesson plan`,
  // 'TutorPublishDenied': lesson => `${lesson.tutor.givenName} has denied access to video`,
  'SessionDisconnected': lesson => `${lesson.tutor.givenName} left the lesson`,
  'HelpAcknowledged': lesson => `${lesson.tutor.givenName} acknowledged your help request`,
  'LessonFocused': lesson => `${lesson.tutor.givenName} has started teaching you`,
  'LessonUnfocused': lesson => `${lesson.tutor.givenName} has stopped teaching you`,
  'ActivityOpenRequested': (lesson, activities, log: LessonLogActivityOpenRequested | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid && activities) {
      const activity = activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    return `${lesson.tutor.givenName} requested activity '${currencySwapFunction(activityName)}'`;
  }
};

const studentFormatters: MessageFormatter = {
  'LessonOpened': () => 'You joined the lesson',
  'StartedLookingTab': () => 'You came back to the lesson tab.',
  'StoppedLookingTab': () => 'You looking at another tab in your browser',
  'StartedInteracting': () => 'You started interacting in the lesson again',
  'StoppedInteracting': () => 'You stopped interacting in the lesson for 2 minutes or more',
  // 'LobbyOpened': () => `You joined the lesson lobby`,
  // 'LobbyClosed': () => `You left the lesson lobby`,
  // 'StudentPublishFailure': () => `You have video issues`,
  'StudentSubscribeFailure': () => 'We are having problems connecting to the tutor\'s video',
  'StudentPublishDenied': () => 'You have denied access to your video camera',
  'LessonDisconnected': () => 'You left the lesson',

  'ActivityOpened': (_lesson, activities, log: LessonLogActivityOpened | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid && activities) {
      const activity = activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    return `You opened activity '${currencySwapFunction(activityName)}'`;
  },
  'ActivityClosed': (_lesson, activities, log: LessonLogActivityClosed | undefined) => {
    let activityName = '(Unknown)';
    const activityGuid = log?.values?.activityGuid;
    if (activityGuid && activities) {
      const activity = activities.find(current => current.activityGuid === activityGuid);
      if (activity) {
        activityName = activity.name;
      }
    }
    return `You closed activity '${currencySwapFunction(activityName)}'`;
  },
  'HelpRequested': () => 'You requested help'
};

export const selectLessonMessages = createSelector(
  selectLogEntityIds, selectLogEntities, selectSelectedLesson, selectLessonActivities, (ids, entities, lesson, activities) => {

    // Map the logs for the lesson log ids
    let messages: (Message | undefined)[] = [];

    if (lesson) {
      const logIds = ids[lesson.lessonGuid];

      if (logIds) {
        messages = logIds.map(logId => {
          const entity = entities[logId];

          let message: Message | undefined;

          if (entity) {

            // Find the event type formatter and build the message object
            const formatters = entity.author === LessonLogAuthor.Student ? studentFormatters : tutorFormatters;
            const formatter = formatters[entity.eventType];

            if (formatter) {
              const text = formatter(lesson, activities, entity);

              if (text) {

                let sender = '';
                switch (entity.author) {
                  case LessonLogAuthor.AI:
                    sender = 'Monty AI';
                    break;
                  case LessonLogAuthor.Student:
                    sender = 'Student (me)';
                    break;
                  default:
                    sender = lesson.tutor.givenName;
                }

                message = {
                  id: entity.eventId,
                  type: entity.eventType,
                  subType: MessageSubType.System,
                  timestamp: entity.timestamp,
                  text: text,
                  warning: false,
                  lessonGuid: lesson.lessonGuid,
                  sender: sender
                };
              }
            }
          }

          return message;
        });
      }
    }

    return messages.filter((message): message is Message => message !== undefined);
  });
