import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { debounceTime, map, Observable, OperatorFunction } from 'rxjs';

import { determineOptional, FormControlDefinition } from '../../models';
import { SharedService } from '../../services';
import { BaseFormControlComponent } from '../form-control/base-form-control.component';

@Component({
  selector: 'kip-form-control-school',
  templateUrl: './form-control-school.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class FormControlSchoolComponent extends BaseFormControlComponent implements FormControlDefinition {

  readonly #sharedService = inject(SharedService);

  readonly #schools = toSignal(this.#sharedService.getSchools(), { initialValue: [] });

  readonly control = input.required<FormControl<number | string | null>>();
  readonly labelExtra = input('');
  readonly maxLength = input<number | null>(null);

  search: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term =>
        term === '' ? [] : this.#schools().filter(v => v.toLowerCase().startsWith(term.toLowerCase())).slice(0, 10)
      )
    );

  get optional() {
    return determineOptional(this.control());
  }

}
