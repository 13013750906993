@if (activity && !activity.isManual && question) {
<div class="kip-explore-computer-container h-100 mh-100 d-flex flex-column flex-fill">
  <div class="card flex-fill h-100 overflow-hidden">
    <div class="card-body d-flex flex-row p-0 h-100" [style.background-color]="backgroundColor">
      <kip-question-navigator [activeQuestionIndex]="questionData?.index" [items]="navigation"
        [whiteboardQuestionId]="whiteboardQuestionId" (navigate)="onNavigate($event)" />
      <div class="position-relative d-flex flex-column flex-fill">
        @if (!showWhiteboard) {
        <div class="kip-explore-content-margin d-flex flex-row flex-fill overflow-auto">
          @if (currentView === currentViews.Introduction) {
          <div class="m-auto" [innerHtml]="htmlIntroduction"></div>
          }
          @if (currentView === currentViews.WorkedSolution) {
          <div class="m-auto" [innerHtml]="htmlWorkedSolution"></div>
          }
          @if (currentView === currentViews.AdditionalData) {
          <div class="m-auto" [innerHtml]="additionalData"></div>
          }
          @if (currentView === currentViews.Question) {
          <kip-question class="flex-fill" [question]="question" [readonly]="true" [regionId]="regionId"
            [soundRegionId]="soundRegionId" [studentAnswers]="studentAnswers" />
          }
        </div>
        }
        @if (!showWhiteboard) {
        <div
          class="align-items-center d-flex position-absolute top-0 mx-auto justify-content-center w-100 pointer-events-none"
          style="z-index: 99;">
          <kip-solution-attempt-summary class="pointer-events-auto" [allStudentAnswers]="allStudentAnswers"
            [questionSummary]="questionSummary" [studentAnswers]="studentAnswers"
            (answerSelected)="displayStudentAnswer($event)" (displaySolution)="displayAnswer()" />
        </div>
        }
        <div class="kip-whiteboard-container" [style.display]="showWhiteboard?'':'none'">
          <kip-whiteboard [backgroundImage]="backgroundImage" [readonly]="true"
            [whiteboardGuidKey]="whiteboardGuidKey" />
        </div>
      </div>
    </div>
    <div
      class="pointer-events-none position-absolute bottom-0 w-100 d-flex justify-content-center align-items-center flex-wrap z-index-20">
      @if (skillBuilderText) {
      <button class="kip-button-group__radio btn text-capitalize mb-2 me-2 pointer-events-auto" type="button"
        (click)="addSkillbuilderToPlan()">
        <fa-icon class="me-1" [icon]="icons.skillBuilder" />
        {{ skillBuilderText }}
      </button>
      }
      @if (allowIntroduction || allowWorkedSolution || allowAdditionalData) {
      <div class="kip-button-group btn-group btn-group-toggle mb-2 pointer-events-auto me-2">
        <input class="btn-check text-capitalize" id="btn-question" name="radioCurrentView" type="radio"
          [value]="currentViews.Question" [(ngModel)]="currentViewString">
        <label class="kip-button-group__radio btn text-capitalize" for="btn-question">
          <fa-icon class="me-1" [icon]="icons.preview.regular" />
          Question
        </label>
        <input class="btn-check text-capitalize" id="btn-introduction" name="radioCurrentView" type="radio"
          [value]="currentViews.Introduction" [(ngModel)]="currentViewString">
        @if (allowIntroduction) {
        <label class="kip-button-group__radio btn text-capitalize" for="btn-introduction">
          <fa-icon class="me-1" [icon]="icons.introduction" />
          Introduction
        </label>
        }
        <input class="btn-check text-capitalize" id="btn-worked" name="radioCurrentView" type="radio"
          [value]="currentViews.WorkedSolution" [(ngModel)]="currentViewString">
        @if (allowWorkedSolution) {
        <label class="kip-button-group__radio btn text-capitalize" for="btn-worked">
          <fa-icon class="me-1" [icon]="icons.workedSolution" />
          Worked Solution
        </label>
        }
        <input class="btn-check text-capitalize" id="btn-additional" name="radioCurrentView" type="radio"
          [value]="currentViews.AdditionalData" [(ngModel)]="currentViewString">
        @if (allowAdditionalData) {
        <label class="kip-button-group__radio btn text-capitalize" for="btn-additional">
          <fa-icon class="me-1" [icon]="icons.brainBooster" />
          Additional Data
        </label>
        }
      </div>
      }
      @if (allowJira)
      {
      <button class="kip-button-group__radio btn text-capitalize mb-2 pointer-events-auto" type="button"
        (click)="jira.open()">
        <fa-icon class="me-1" [icon]="icons.reportJira.light" />
        Report
      </button>
      }
      <button class="kip-button-group__radio btn text-capitalize mb-2 pointer-events-auto" type="button"
        (click)="ai.open()">
        <fa-icon class="me-1" [icon]="icons.lightbulb" />
        AI
      </button>
    </div>
    <kip-explore-computer-ai #ai [activityName]="activity.name" [question]="questionText" [questionNumber]="(questionData?.index  ?? 0)+ 1" />
    <kip-jira-modal #jira requestText="Report Activity Content" style="display:none"
      [jiraStyleType]="jiraStyleTypeContent" [metaData]="jiraMetaData" [requestType]="jiraRequestType"
      [uId]="jiraUId" />
  </div>
</div>
}