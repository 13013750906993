/* eslint-disable sonarjs/no-duplicate-string, max-len */

import { IconDefinition, Icons } from 'icon-lib';

import { Breadcrumb } from '../models';
import { Feature } from './feature-flag.service';

export interface Route {
  path: string;
  pathOverride?: string;
  title: string;
  icon?: IconDefinition;
  feature: Feature;
  hidden?: boolean;
  legacy?: boolean;
  helpNodeName: string;
  children?: { [key: string]: Route };
}

export enum RouteParams {
  Id = 'id',
  AssessmentBookingId = 'assessmentBookingId',
  EnrolmentId = 'enrolmentId',
  TutorTimeSlotId = 'tutorTimeSlotId',
  LeadId = 'leadId',
  NoteId = 'noteId',
  ContactId = 'contactId',
  StudentId = 'studentId',
  SelectedTab = 'selectedTab',
  Calendar = 'calendar',
  AssessmentAvailabilityId = 'assessmentAvailabilityId',
  Datetime = 'datetime',
  IanaTimeZone = 'ianaTimeZone',
  CurrentStep = 'currentStep',
  EnrolmentSessionToBeRescheduleId = 'enrolmentSessionToBeRescheduleId',
  Uid = 'uid',
  BlockRedirect = 'blockRedirect'
}

export const changesRoute: Route = { path: 'change', title: 'Changes', icon: Icons.dashboard, feature: Feature.ChangeLog, helpNodeName: '039E9343-D8B3-4C77-B674-3C3343F334E9' };

export const dashboardRoute: Route = { path: 'dashboard', title: 'Dashboard', icon: Icons.dashboard, feature: Feature.Dashboard, helpNodeName: 'A0F7DC5E-9BF4-4036-9176-C738BB117B98' };
export const calendarRoute: Route = { path: 'calendar', title: 'Calendar', icon: Icons.calendar.main, feature: Feature.Calendar, helpNodeName: '7EFC5830-326F-41DA-83CE-B29C50CE999A' };
export const calendarNewRoute: Route = { path: 'calendar-new', title: 'Calendar (New)', icon: Icons.calendar.main, feature: Feature.NewCalendar, helpNodeName: '7EFC5830-326F-41DA-83CE-B29C50CE999A' };
export const connectionsRoute: Route = { path: 'connections', title: 'Connections', icon: Icons.online, feature: Feature.Connections, helpNodeName: 'BAE54AFF-AF41-4227-96D7-485EAB5B70D9' };
export const accountsRoute: Route = { path: 'accounts', title: 'Accounts', icon: Icons.accounts, feature: Feature.Accounts, helpNodeName: 'D1F4E74A-EF4D-4A9C-9E8F-660BF3D808B7' };

export const bulkEnrolStudentsRoute: Route = { path: 'enrolment/bulk/enrolments', title: 'Bulk Enrol Students', hidden: true, feature: Feature.Students, helpNodeName: '03E347A3-3302-4392-9C6F-B25AD59E8C03' };
export const bulkImportStudentsRoute: Route = { path: 'enrolment/bulk/students', title: 'Bulk Import Students', hidden: true, feature: Feature.Students, helpNodeName: '45E3249B-9B61-4E72-BEBD-6EDAE4564D5D' };
export const studentsDetailRoute: Route = { path: '', title: 'Student Detail', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEditRoute: Route = { path: '', title: 'Edit Student', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEditPasswordRoute: Route = { path: '', title: 'Reset Student Password', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentDetailRoute: Route = { path: '', title: 'Student Enrolment Detail', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentDetailUnenrolRoute: Route = { path: '', title: 'Student Enrolment Detail - Unenrol', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentDetailEditRoute: Route = { path: '', title: 'Student Enrolment Detail - Edit', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentDetailRescheduleRoute: Route = { path: '', title: 'Student Enrolment Detail - Reschedule', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentDetailRescheduleDetailRoute: Route = { path: '', title: 'Student Enrolment Detail - Reschedule Detail', hidden: true, feature: Feature.Students, helpNodeName: '' };

export const studentEnrolmentNewRoute: Route = { path: 'new', title: 'Student New Enrolment', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentAddRoute: Route = { path: 'add', title: 'Student Add Enrolment', hidden: true, feature: Feature.Students, helpNodeName: '' };
export const studentEnrolmentNewLiteRoute: Route = { path: 'new/lite', title: 'Student New Enrolment - Centre', hidden: true, feature: Feature.Students, helpNodeName: '' };

export const studentsRoute = {
  path: 'students', title: 'Students', icon: Icons.studentWithCap, feature: Feature.Students, helpNodeName: '7F2A1EBD-9EF9-4555-B5D8-A2D7CCC65BA1',
  children: {
    bulkEnrolStudents: bulkEnrolStudentsRoute,
    bulkImportStudents: bulkImportStudentsRoute,
    studentDetail: studentsDetailRoute,
    studentEdit: studentEditRoute,
    studentEditPassword: studentEditPasswordRoute,
    studentEnrolment: studentEnrolmentDetailRoute,
    studentEnrolmentUnenrol: studentEnrolmentDetailUnenrolRoute,
    studentEnrolmentEdit: studentEnrolmentDetailEditRoute,
    studentEnrolmentReschedule: studentEnrolmentDetailRescheduleRoute,
    studentEnrolmentRescheduleDetail: studentEnrolmentDetailRescheduleDetailRoute,
    studentEnrolmentNew: studentEnrolmentNewRoute,
    studentEnrolmentAdd: studentEnrolmentAddRoute,
    studentEnrolmentNewLite: studentEnrolmentNewLiteRoute
  }
};

export const leadsListRoute: Route = { path: 'leads', title: 'Leads', hidden: true, feature: Feature.Leads, helpNodeName: '5478E6A1-E8A8-463C-9DCF-649E75878FAA' };
export const leadAddRoute: Route = { path: 'add', title: 'Add Lead', hidden: true, feature: Feature.Leads, helpNodeName: '45AD458F-4DAD-4DD5-958E-EA9FD14DB862' };
export const leadDetailRoute: Route = { path: 'detail', title: 'Lead Detail', hidden: true, feature: Feature.Leads, helpNodeName: '5C0C2129-924B-45CF-B4C8-751C56676BC2' };
export const leadDetailEditRoute: Route = { path: 'detail-edit', title: 'Lead Detail Edit', hidden: true, feature: Feature.Leads, helpNodeName: '05079AC6-F0FA-4EC7-9877-052B765A8D68' };
export const leadDetailContactAddRoute: Route = { path: 'contact-add', title: 'Add Contact', hidden: true, feature: Feature.Leads, helpNodeName: '9F3882ED-92FA-4943-99B8-2B7AF1F5E1EB' };
export const leadDetailContactDetailRoute: Route = { path: 'contact-detail', title: 'Edit Contact', hidden: true, feature: Feature.Leads, helpNodeName: 'CDAF2383-D7F0-4DD4-A991-F00F4120FFCC' };
export const leadDetailNoteAddRoute: Route = { path: 'note-add', title: 'Add Note', hidden: true, feature: Feature.Leads, helpNodeName: '7922FC14-F415-43A3-AA7C-51C7A84D78C5' };
export const leadDetailNoteDetailRoute: Route = { path: 'note-detail', title: 'Edit Note', hidden: true, feature: Feature.Leads, helpNodeName: 'A556E69D-A0CD-414B-86A8-7D1BA288AF61' };
export const leadDetailEnrolmentRoute: Route = { path: 'enrolment', title: 'Lead Enrolment', hidden: true, feature: Feature.Leads, helpNodeName: '1CE5BA73-3B94-4B9D-AA13-0093535438B9' };

export const leadsRoute = {
  path: 'leads', title: 'Leads', icon: Icons.leadDetailsTab, feature: Feature.Leads, helpNodeName: 'D76E1A97-DB26-416F-909E-96796BE483BB',
  children: {
    leadsList: leadsListRoute,
    addLead: leadAddRoute,
    leadDetail: leadDetailRoute,
    leadDetailEdit: leadDetailEditRoute,
    leadDetailContactAdd: leadDetailContactAddRoute,
    leadDetailContactDetail: leadDetailContactDetailRoute,
    leadDetailNoteAdd: leadDetailNoteAddRoute,
    leadDetailNoteDetail: leadDetailNoteDetailRoute,
    leadDetailEnrolment: leadDetailEnrolmentRoute
  }
};
export const assessmentsRoute: Route = { path: 'assessments', title: 'Assessments', icon: Icons.assessment, feature: Feature.Assessments, helpNodeName: '71D441B2-EDF3-49B3-B14F-EA383597FD71' };

export const lessonReportRoute: Route = { path: 'lessons', title: 'Lessons', feature: Feature.LessonReport, helpNodeName: 'DE2366F8-0788-44AE-8855-79276A614FE5' };
export const assessmentReportRoute: Route = { path: 'assessments', title: 'Assessments', feature: Feature.AssessmentReport, helpNodeName: 'AF94AE70-B4DB-4549-8B8A-3ED96B910EAB' };
export const attendanceReportRoute: Route = { path: 'attendance', title: 'Attendance', feature: Feature.AttendanceReport, helpNodeName: '32BD1469-0EFE-433E-B557-FB876AE166D6' };
export const activityReportRoute: Route = { path: 'activities', title: 'Activities', feature: Feature.ActivitiesReport, helpNodeName: '4701C334-C224-4870-8BB1-0D9A21E39D44' };
export const rankingsReportRoute: Route = { path: 'rankings', title: 'Rankings', feature: Feature.LessonReport, helpNodeName: 'EA87D6C4-2422-4163-B777-4BDF35C0379B' };
export const problemUsersReportRoute: Route = { path: 'problem-users', title: 'Problem Users', feature: Feature.ProblemUsersReport, helpNodeName: 'EA97D6C4-2422-4163-B777-4BDF35C0379B' };
export const issuesReportRoute: Route = { path: 'issues', title: 'Errors and Issues', feature: Feature.IssuesReport, helpNodeName: 'BFE2F41A-4B2F-4A25-BEB9-8B0934E81B57', legacy: true };
export const jiraReportRoute: Route = { path: 'jira', title: 'Jira Tickets', feature: Feature.JiraReport, helpNodeName: 'C671A70A-B02F-42A5-9719-904C7432D19F' };
export const aiChatReportRoute: Route = { path: 'ai-chat', title: 'AI Chats', feature: Feature.AIChat, helpNodeName: 'C681A70A-B02F-42A5-9719-904C7432D19F' };
export const reportsRoute = {
  path: 'reports', title: 'Reports', icon: Icons.excel, feature: Feature.Reports, helpNodeName: 'EF2C7AD3-7C98-4A58-B0B6-27FEDA2BC706',
  children: {
    lessonReport: lessonReportRoute,
    assessmentReport: assessmentReportRoute,
    attendanceReport: attendanceReportRoute,
    activityReport: activityReportRoute,
    rankingsReport: rankingsReportRoute,
    problemUsersReport: problemUsersReportRoute,
    issuesReport: issuesReportRoute,
    jiraReport: jiraReportRoute,
    aiChatReport: aiChatReportRoute
  }
};

export const assessmentAvailabilityListRoute: Route = {
  path: '', title: 'Assessment Availabilities', feature: Feature.AssessmentAvailabilities,
  hidden: true, helpNodeName: 'DA61298D-A86C-4159-A844-65314F3D105E'
};
export const addAssessmentAvailabilityRoute: Route = { path: 'add', title: 'Add Assessment Availability', feature: Feature.AssessmentAvailabilities, hidden: true, helpNodeName: 'B2F42775-FD0A-4278-96B7-38987B75FEF8' };
export const editAssessmentAvailabilityRoute: Route = { path: 'edit', title: 'Edit Assessment Availability', feature: Feature.AssessmentAvailabilities, hidden: true, helpNodeName: '411775B8-204B-4403-8D6F-902DAD80BC42' };
export const displayAssessmentAvailabilityRoute: Route = { path: '', title: 'Edit Assessment Availability', feature: Feature.AssessmentAvailabilities, hidden: true, helpNodeName: '33E59AF1-BF7F-4D5F-A543-544895F27668' };
export const stopAssessmentAvailabilityRoute: Route = { path: 'stop', title: 'Stop Assessment Availability', feature: Feature.AssessmentAvailabilities, hidden: true, helpNodeName: 'E964FA4C-A594-46F1-BBF4-A7CCE4FD177C' };

export const assessmentAvailabilitiesRoute = {
  path: 'assessment-availabilities', title: 'Assessment Availabilities', feature: Feature.AssessmentAvailabilities, helpNodeName: '69B5639E-A1CC-40F9-92EE-99CDB6CD7C3F',
  children: {
    assessmentAvailabilityList: assessmentAvailabilityListRoute,
    addAssessmentAvailability: addAssessmentAvailabilityRoute,
    editAssessmentAvailability: editAssessmentAvailabilityRoute,
    displayAssessmentAvailability: displayAssessmentAvailabilityRoute,
    stopAssessmentAvailability: stopAssessmentAvailabilityRoute
  }
};

export const regionHolidaysRoute: Route = { path: 'region', title: 'Region Holidays', feature: Feature.ExclusionSets, helpNodeName: '4710533A-09F7-434B-B13E-6B2C81E1C158' };
export const centreHolidaysRoute: Route = { path: 'centres', title: 'Centre Holidays', feature: Feature.ExclusionSets, helpNodeName: '712E83DE-CD37-413D-A280-B2798073EADD' };

export const holidaysRoute = {
  path: 'holidays', title: 'Holidays', hidden: true, feature: Feature.ExclusionSets, helpNodeName: '4710533A-09F7-434B-B13E-6B2C81E1C158',
  children: {
    regionHolidays: regionHolidaysRoute,
    centreHolidays: centreHolidaysRoute
  }
};

export const contentRebuildRoute: Route = { path: 'content-tree', title: 'Content Rebuild', feature: Feature.Maintenance, helpNodeName: 'C294E854-EDC2-4E7E-96C8-D4A73A79957B' };
export const videoMaintenanceRoute: Route = { path: 'video-migrate', title: 'Migrate Videos', feature: Feature.Maintenance, helpNodeName: '3F025539-2FF8-4DC3-9BF0-FF0FD8F4CE53' };
export const maintenanceRoute = {
  path: 'maintenance', title: 'Maintenance', feature: Feature.Maintenance, helpNodeName: 'C294E854-EDC2-4E7E-96C8-D4A73A79957B',
  children:
  {
    contentRebuild: contentRebuildRoute,
    videoMaintenance: videoMaintenanceRoute
  }
};
export const bundleGradeRoute: Route = { path: 'grade', title: 'Bundle Grades', feature: Feature.Bundles, helpNodeName: 'BC347281-F401-42CE-A5DD-5DA61C75E00C' };
export const editBundleGradeRoute: Route = { path: 'edit', title: 'Edit Bundle Grade', feature: Feature.Bundles, hidden: true, helpNodeName: 'BC347281-F401-42CE-A5DD-5DA61C75E00C' };
export const addBundleGradeRoute: Route = { path: 'add', title: 'Add Bundle Grade', feature: Feature.Bundles, hidden: true, helpNodeName: 'BC347281-F401-42CE-A5DD-5DA61C75E00C' };

export const bundleStandardRoute: Route = { path: 'standard', title: 'Standard Bundles', feature: Feature.Bundles, helpNodeName: '371C5505-88E5-476F-953A-C97061947A3F' };
export const editBundleStandardRoute: Route = { path: 'edit', title: 'Edit Standard Bundle', feature: Feature.Bundles, hidden: true, helpNodeName: '371C5505-88E5-476F-953A-C97061947A3F' };
export const addBundleStandardRoute: Route = { path: 'add', title: 'Add Standard Bundle', feature: Feature.Bundles, hidden: true, helpNodeName: '371C5505-88E5-476F-953A-C97061947A3F' };

export const bundlePricingRoute: Route = { path: 'pricing', title: 'Pricing Bundles', feature: Feature.Bundles, helpNodeName: 'BC2F96B2-9A7B-40EF-8C82-D6E7BFB351E4' };
export const bundleSpecialRoute: Route = { path: 'special', title: 'Special Bundles', feature: Feature.Bundles, helpNodeName: 'D630F84D-73FE-417F-B09A-F01984263B06' };
export const editSpecialRoute: Route = { path: 'edit', title: 'Edit Special Bundle', feature: Feature.Bundles, hidden: true, helpNodeName: 'D630F84D-73FE-417F-B09A-F01984263B06' };
export const addSpecialRoute: Route = { path: 'add', title: 'Add Special Bundle', feature: Feature.Bundles, hidden: true, helpNodeName: 'D630F84D-73FE-417F-B09A-F01984263B06' };

export const bundlesRoute = {
  path: 'bundles', title: 'Bundles', feature: Feature.Bundles, hidden: true, helpNodeName: 'BC347281-F401-42CE-A5DD-5DA61C75E00C',
  children: {
    grades: bundleGradeRoute,
    editGrade: editBundleGradeRoute,
    addGrade: addBundleGradeRoute,
    standardBundles: bundleStandardRoute,
    editStandard: editBundleStandardRoute,
    addStandard: addBundleStandardRoute,
    pricing: bundlePricingRoute,
    specialBundles: bundleSpecialRoute,
    editSpecial: editSpecialRoute,
    addSpecial: addSpecialRoute
  }
};

export const centresListRoute: Route = { path: '', title: 'Centres', feature: Feature.Centres, hidden: true, helpNodeName: '33EDF6D2-07F3-429F-B971-0DE15A9D417E' };
export const addCentreRoute: Route = { path: 'add', title: 'Add Centre', feature: Feature.Centres, hidden: true, helpNodeName: 'FB93835B-06AD-4310-ADB9-A7F13C673CE8' };
export const defaultCentresRoute: Route = { path: 'default', title: 'Default Centres', feature: Feature.Centres, hidden: true, helpNodeName: '90E4B5AC-8A19-4453-BD18-910915084B6F' };
export const editCentreRoute: Route = { path: 'edit', title: 'Edit Centre', feature: Feature.Centres, hidden: true, helpNodeName: '78D89F6E-75BB-4AE6-A53D-8B677BBC63AA' };

export const centreRoute = {
  path: 'centres', title: 'Centres', feature: Feature.Centres, helpNodeName: 'F814E0EA-418D-4118-AF4A-02D8D7A46394',
  children: {
    centresList: centresListRoute,
    addCentre: addCentreRoute,
    defaultCentres: defaultCentresRoute,
    editCentre: editCentreRoute
  }
};

export const listChangeLogsRoute: Route = { path: '', title: 'Change Logs', feature: Feature.ChangeLog, hidden: true, helpNodeName: '22FFA018-16D5-4093-89AE-6DDC5484546F' };
export const addChangeLogRoute: Route = { path: 'add', title: 'Add Change Log', feature: Feature.ChangeLog, hidden: true, helpNodeName: '59073B10-DBE2-46BF-8737-3CF29649CED7' };
export const editChangeLogRoute: Route = { path: 'edit', title: 'Edit Change Log', feature: Feature.ChangeLog, hidden: true, helpNodeName: '744F4155-B5AD-439F-AFF0-0E79E3D42843' };

export const changeLogsRoute = {
  path: 'change-logs', title: 'Change Logs', feature: Feature.ChangeLog, helpNodeName: '40950A04-C014-4F83-8063-F01A9A197110',
  children: {
    changeLogsList: listChangeLogsRoute,
    addChangeLog: addChangeLogRoute,
    editChangeLog: editChangeLogRoute
  }
};

export const listOrganisationsRoute: Route = { path: '', title: 'Organisations', feature: Feature.Organisations, hidden: true, helpNodeName: 'EED58D4A-8728-4763-8153-CF3561E1EE81' };
export const addOrganisationRoute: Route = { path: 'add', title: 'Add Organisation', feature: Feature.Organisations, hidden: true, helpNodeName: 'C5C17F6A-C6EB-42A3-BC9D-596BCAEF3B02' };
export const defaultOrganisationsRoute: Route = { path: 'default', title: 'Organisation Defaults', feature: Feature.Organisations, hidden: true, helpNodeName: '5C0EC6A9-0A62-4343-9C2D-86EE56D05C50' };
export const editOrganisationRoute: Route = { path: 'edit', title: 'Edit Organisation', feature: Feature.Organisations, hidden: true, helpNodeName: '6F9787B1-B52B-4158-8DF4-F403EF6F6005' };
export const editOrganisationProviderRoute: Route = { path: 'provider', title: 'Edit Organisation Provider', feature: Feature.Organisations, hidden: true, helpNodeName: 'E4A15744-27B1-4C5D-8BB7-513FB707354A' };

export const organisationsRoute = {
  path: 'organisations', title: 'Organisations', feature: Feature.Organisations, helpNodeName: 'FF1A6C57-9974-4ECF-B118-6AF5D3BB1A85',
  children: {
    listOrganisations: listOrganisationsRoute,
    addOrganisation: addOrganisationRoute,
    defaultOrganisations: defaultOrganisationsRoute,
    editOrganisation: editOrganisationRoute,
    editOrganisationProvider: editOrganisationProviderRoute
  }
};

export const listOrganisationGoalsRoute: Route = { path: 'list', title: 'Goals', feature: Feature.OrganisationGoals, hidden: false, helpNodeName: '23932655-81B4-46CB-B1CE-9AE9F86F296C' };
export const addOrganisationGoalRoute: Route = { path: 'new', title: 'Add Goal', feature: Feature.OrganisationGoals, hidden: true, helpNodeName: '393BB8DE-DD49-45AB-88F7-C1BEBC703EF5' };
export const editOrganisationGoalRoute: Route = { path: 'edit', title: 'Edit Goals', feature: Feature.OrganisationGoals, hidden: true, helpNodeName: '0CE90BE7-9778-4832-8D64-F978E29266C4' };

export const organisationsGoalsRoute = {
  path: 'organisation-goals', title: 'Goals', hidden: true, feature: Feature.OrganisationGoals, helpNodeName: 'B9AE0D6B-F5B3-4763-89A3-18248C8109DC',
  children: {
    goalsList: listOrganisationGoalsRoute,
    editGoal: editOrganisationGoalRoute,
    addGoal: addOrganisationGoalRoute
  }
};

export const listOrganisationActivitiesRoute: Route = { path: 'list', title: 'Activities', feature: Feature.OrganisationActivities, hidden: false, helpNodeName: '301EB202-7AF5-4FAA-945D-71A8622BA13E' };
export const addOrganisationActivityRoute: Route = { path: 'new', title: 'Add Activity', feature: Feature.OrganisationActivities, hidden: true, helpNodeName: '6DF52009-2311-44BE-9C04-275BC192C257' };
export const editOrganisationActivityRoute: Route = { path: 'edit', title: 'Edit Activity', feature: Feature.OrganisationActivities, hidden: true, helpNodeName: '466295AC-31A3-4FB4-BC6C-A7B23BC58B7C' };

export const organisationActivitiesRoute = {
  path: 'organisation-activities', title: 'Activities', hidden: true, feature: Feature.OrganisationActivities, helpNodeName: '60139C78-16FF-41EE-A322-CA8789002033',
  children: {
    activitiesList: listOrganisationActivitiesRoute,
    editActivity: editOrganisationActivityRoute,
    addActivity: addOrganisationActivityRoute
  }
};

export const listSchoolUsersRoute: Route = { path: '', title: 'School Users', feature: Feature.SchoolMaintenance, hidden: true, helpNodeName: '688AF798-DDEC-480C-A516-BB519F0638A5' };
export const addSchoolUsersRoute: Route = { path: 'add', title: 'Add School User', feature: Feature.SchoolMaintenance, hidden: true, helpNodeName: 'ABFA3659-3B29-4ED1-A298-21C6D09F496E' };
export const editSchoolUserRoute: Route = { path: 'edit', title: 'Edit School User', feature: Feature.SchoolMaintenance, hidden: true, helpNodeName: '84DBBAEB-1D85-41D3-B2B3-B7EB203B608F' };
export const editSchoolUserPasswordRoute: Route = {
  path: 'password-reset', title: 'Edit School User Password', feature: Feature.SchoolMaintenance,
  hidden: true, helpNodeName: 'F5A5084D-51C1-490E-8923-5A00B64D94BB'
};

export const schoolUsersRoute = {
  path: 'school-users', title: 'School Users', feature: Feature.SchoolMaintenance, helpNodeName: '2FEDB761-0BF3-4F75-AF7B-BB6F4CF4FBD7',
  children: {
    schoolUsersList: listSchoolUsersRoute,
    addSchoolUsers: addSchoolUsersRoute,
    editSchoolUsers: editSchoolUserRoute,
    editSchoolUsersPassword: editSchoolUserPasswordRoute
  }
};

export const listEmployeesRoute: Route = { path: '', title: 'Employees', feature: Feature.Employees, helpNodeName: '2A97D2C4-1956-44DE-990D-30E2467E6C7D' };
export const addEmployeeRoute: Route = { path: 'add', title: 'Add Employee', feature: Feature.Employees, helpNodeName: 'B99FD4FC-02FB-4821-8B51-DD9EE968BA6F' };
export const detailEmployeeRoute: Route = { path: '', title: 'Employee Detail', feature: Feature.Employees, helpNodeName: 'E76168E8-0A37-422F-BFD3-7D9800F1B1F1' };
export const editEmployeeRoute: Route = { path: 'edit', title: 'Edit Employee', feature: Feature.Employees, helpNodeName: '3F6E545C-E99D-4644-B7CA-F34D151F5F46' };
export const addEmployeeSettingRoute: Route = { path: 'add-setting', title: 'Add Employee Settings', feature: Feature.Employees, helpNodeName: '0293B34C-D315-4A99-AC2E-72E351309A40' };
export const editEmployeeSettingRoute: Route = { path: 'edit-setting', title: 'Edit Employee Settings', feature: Feature.Employees, helpNodeName: '222B90F7-BFE8-415B-BF41-D6AB1B7A3466' };
export const editEmployeePasswordRoute: Route = { path: 'password-reset', title: 'Reset Employee Password', feature: Feature.Employees, helpNodeName: 'BBAE598C-AA16-4F0E-8F19-D5D1FADAFFB3' };

export const employeesRoute = {
  path: 'employees', title: 'Employees', feature: Feature.Employees, helpNodeName: '314731FC-43AD-48DD-B82A-E3F36DA25074',
  children: {
    employeesList: listEmployeesRoute,
    addEmployee: addEmployeeRoute,
    detailEmployee: detailEmployeeRoute,
    editEmployee: editEmployeeRoute,
    addEmployeeSetting: addEmployeeSettingRoute,
    editEmployeeSetting: editEmployeeSettingRoute,
    editEmployeePassword: editEmployeePasswordRoute
  }
};
export const accountMergeRoute: Route = { path: 'account-merge', title: 'Account Merge', feature: Feature.AccountMerge, helpNodeName: 'A40BFD69-49A1-4569-A0DD-AAC907183A61' };
export const quizRoute: Route = { path: 'quizzes', title: 'Quizzes', feature: Feature.Quizzes, helpNodeName: '008CDE1A-5E65-459B-953F-B39D3BC0F136' };

export const tutorsListRoute: Route = { path: '', title: 'Tutors', feature: Feature.Tutors, helpNodeName: 'F0623675-44A6-46DF-9569-694370809481' };
export const tutorsAddRoute: Route = { path: 'add', title: 'Add Tutor', feature: Feature.Tutors, helpNodeName: 'F0623675-44A6-46DF-9569-694370809481' };
export const tutorsEditRoute: Route = { path: 'edit', title: 'Edit Tutor', feature: Feature.Tutors, helpNodeName: 'F0623675-44A6-46DF-9569-694370809481' };
export const tutorsBulkRoute: Route = { path: 'bulk', title: 'Bulk Import Tutors', feature: Feature.Tutors, helpNodeName: 'F0623675-44A6-46DF-9569-694370809481' };

export const tutorsRoute = {
  path: 'tutors', title: 'Tutors', feature: Feature.Tutors, helpNodeName: 'F0623675-44A6-46DF-9569-694370809481',
  children: {
    tutorsList: tutorsListRoute,
    tutorsAdd: tutorsAddRoute,
    tutorsEdit: tutorsEditRoute,
    tutorsBulk: tutorsBulkRoute
  }
};

export const sessionSchedulesListRoute: Route = { path: '', title: 'Sessions', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };
export const addSessionSchedulesRoute: Route = { path: 'add', title: 'Add Session', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };
export const bulkSessionSchedulesListRoute: Route = { path: 'bulk', title: 'Bulk Import Session Schedules', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };
export const sessionScheduleDetailRoute: Route = { path: '', title: 'Session Detail', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };
export const editSessionSchedulesRoute: Route = { path: 'edit', title: 'Add Session Change', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };
export const scheduleChangeRoute: Route = { path: 'edit', title: 'Add Session Change', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };
export const studentImportRoute: Route = { path: 'edit', title: 'Enrol External Students', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE' };

export const sessionSchedulesRoute = {
  path: 'session-schedules', title: 'Sessions', feature: Feature.Sessions, helpNodeName: '4975F1CD-9F0C-4E7C-AC15-0CDC28A647AE',
  children: {
    sessionSchedulesList: sessionSchedulesListRoute,
    addSessionSchedules: addSessionSchedulesRoute,
    bulkSessionSchedules: bulkSessionSchedulesListRoute,
    sessionScheduleDetail: sessionScheduleDetailRoute,
    editSessionSchedule: editSessionSchedulesRoute,
    scheduleChange: scheduleChangeRoute,
    studentImport: studentImportRoute
  }
};
export const settingsRoute: Route = { path: 'settings', title: 'Settings', feature: Feature.Settings, helpNodeName: '9EA048E3-A1D0-41DA-B2B8-0D2C34C4F97B' };
export const settingsCalendarRoute: Route = { path: 'calendar', title: 'Calendar Settings', feature: Feature.Calendar, helpNodeName: 'C0F67272-C4B8-4C76-8595-9317CB5832AF' };

export const treesRoute: Route = { path: 'trees', title: 'Trees', feature: Feature.Trees, helpNodeName: '9EA048E3-A1D0-41DA-B2B8-0D2C34C4F97C' };

export const listAwardsRoute: Route = { path: '', title: 'Awards', feature: Feature.Awards, hidden: true, helpNodeName: 'B304C758-D413-420D-9646-CC982223587F' };
export const addAwardRoute: Route = { path: 'add', title: 'Add Award', feature: Feature.Awards, hidden: true, helpNodeName: '90B6E6CB-07CD-4FA9-B952-32EC966FD53A' };
export const editAwardRoute: Route = { path: 'edit', title: 'Edit Award', feature: Feature.Awards, hidden: true, helpNodeName: '4E2FD841-EC1C-46A0-BA45-2E3961B35143' };

export const awardsRoute = {
  path: 'awards', title: 'Awards', feature: Feature.Awards, helpNodeName: 'ADCB75EA-0E98-4E81-8BA4-EF3D625E471D',
  children: {
    awardsList: listAwardsRoute,
    addAward: addAwardRoute,
    editAward: editAwardRoute
  }
};
export const rewardsRoute: Route = { path: 'rewards', title: 'Rewards', feature: Feature.Rewards, helpNodeName: '5FD2D137-B7D1-41E1-9353-BCAF8472C44D' };
export const videosRoute: Route = { path: 'videos', title: 'Videos', feature: Feature.Videos, helpNodeName: '3F025539-2FF8-4DC3-9BF0-FF0FD8F4CE53' };
export const badWordsRoute: Route = { path: 'bad-words', title: 'Bad Words', feature: Feature.BadWords, helpNodeName: 'DC9B532F-D78B-4F9E-917E-C2D623444330' };
export const animationsRoute: Route = { path: 'animations', title: 'Animations', feature: Feature.Animations, helpNodeName: 'EE922E79-CA6E-4096-BFF2-E994CED65A34' };
export const avatarAssetsRoute: Route = { path: 'avatar-assets', title: 'Avatar Assets', feature: Feature.AvatarAssets, helpNodeName: '28F2DE84-216D-47DE-80BC-1DCFFEF06BF6' };

export const adminRoute = {
  path: 'admin', title: 'Administration', icon: Icons.admin, feature: Feature.Admin, helpNodeName: 'F0EBD0FF-D647-4DC4-B2C9-D46FE760E73A',
  children: {
    assessAvailabilities: assessmentAvailabilitiesRoute,
    maintenance: maintenanceRoute,
    bundles: bundlesRoute,
    centres: centreRoute,
    changeLogs: changeLogsRoute,
    organisations: organisationsRoute,
    organisationGoals: organisationsGoalsRoute,
    organisationActivities: organisationActivitiesRoute,
    schoolUsers: schoolUsersRoute,
    employees: employeesRoute,
    accountMerge: accountMergeRoute,
    quizzes: quizRoute,
    tutors: tutorsRoute,
    sessionSchedules: sessionSchedulesRoute,
    settings: settingsRoute,
    settingsCalendar: settingsCalendarRoute,
    awards: awardsRoute,
    rewards: rewardsRoute,
    videos: videosRoute,
    holidaysRoute: holidaysRoute,
    badWords: badWordsRoute,
    animations: animationsRoute,
    avatarAssets: avatarAssetsRoute,
    trees: treesRoute
  }
};

export const fmsRoutes = {
  dashboard: dashboardRoute,
  calendar: calendarRoute,
  calendarNew: calendarNewRoute,
  connections: connectionsRoute,
  accounts: accountsRoute,
  students: studentsRoute,
  leads: leadsRoute,
  assessments: assessmentsRoute,
  reports: reportsRoute,
  admin: adminRoute
};

export const fmsRoutesIterable: { [key: string]: Route } = fmsRoutes;

export function routerLink(pathAndId: (Route | number | string)[]): string {
  const baseArray: (number | string)[] = pathAndId.map(item => {
    if (typeof item === 'number' || typeof item === 'string') {
      return item;
    }
    return item.path;
  });

  return baseArray.join('/');
}

export function generateBreadcrumbs(upCount: number, routes: (Route | string)[]) {
  let upCountLocal = upCount;
  const breadcrumbs: Breadcrumb[] = [];
  for (const route of routes) {
    if (typeof route === 'string') {
      breadcrumbs.push({ path: '../'.repeat(upCountLocal), name: route });
    } else {
      breadcrumbs.push({ path: '../'.repeat(upCountLocal), name: route.title });
    }
    upCountLocal--;
  }

  return breadcrumbs;
}

