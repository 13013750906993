import { AnimationData } from './animation-data';

export function initializeAnimationData(animationData: AnimationData) {
  for (const emoji of animationData.emojis) {
    emoji.selected = false;
  }
  for (const gender of animationData.genders) {
    for (const group of gender.groups) {
      for (const file of group.files) {
        file.fileNameFull = `${animationData.baseUrl}/${group.urlPath}${file.fileName}.fbx`;
        file.selected = false;
      }
    }
  }

  return animationData;
}
