import { ChangeDetectionStrategy, Component, effect, inject, input, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';

@Component({
    selector: 'kip-student-ai-modal',
    templateUrl: './student-ai-modal.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAIModalComponent {

    readonly #modalService = inject(NgbModal);

    readonly icons = Icons;

    readonly studentId = input.required<number>();
    readonly aiFilter = input.required<string>();

    @ViewChild('content', { static: false }) modalContent: TemplateRef<any> | undefined;

    constructor() {
        effect(() => {
            const studentId = this.studentId();
            const aiFilter = this.aiFilter();

            if (studentId && aiFilter) {
                this.open();
            }
        });
    }

    /* eslint-disable kip/no-unused-public-members */

    open() {
        this.#modalService.open(this.modalContent, { size: 'xl', centered: true });
    }

    /* eslint-enable kip/no-unused-public-members */

    close() {
        this.#modalService.dismissAll();
    }
}