/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { EventType, MessagingService as MessagingServiceBase } from 'message-lib';
import { Observable, tap } from 'rxjs';

import { fromDropIn, fromLesson, StudentState } from '../store';

@Injectable({ providedIn: 'root' })
export class DropInMessagingService extends MessagingServiceBase {

  readonly #store = inject(Store<StudentState>);

  constructor() {
    super('Student', 'drop-in');
    this.init();
  }

  init() {
    this.profileService.getUserProfile().subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  override connect(): Observable<void> {
    return super.connect().pipe(
      tap(() => {
        this.receiveEvent('DropInSessionJoinRequestAccepted', this.#store,
          {
            action: fromDropIn.dropInLessonRequestAcceptedAction,
            converter: (args: [number]) =>
            ({
              lessonId: args[0]
            })
          });

        this.receiveEvent('DropInSessionJoinRequestDenied', this.#store,
          {
            action: fromDropIn.dropInLessonRequestDeclinedAction,
            converter: (args: [number, string]) =>
            ({
              sessionId: args[0],
              message: args[1]
            })
          });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/no-misused-promises
  async onReconnect() {
    // Dispatching this action rejoins the student to the group on the server.
    // It also causes a message to be pushed into the queue, which will in turn
    // flush any buffered messages from while we were disconnected.
    this.#store.dispatch(fromLesson.reconnectAction());
  }

  override onReceiveEventTrue() {
    this.#store.dispatch(fromLesson.updateSignalRConnectionStateAction({ connected: this.isConnectedAndReceivedEvent() }));
  }

  joinRequested(sessionId: number, tutorId: number, studentUserId: number, studentName: string): Observable<string> {
    return this.sendEventWrapper('DropInSessionJoinRequested', this.#store, {
      sessionId: sessionId, tutorId: tutorId, studentUserId: studentUserId, studentName: studentName, deviceData: JSON.stringify(this.deviceData)
    });
  }

  dropInLessonRequestCancelled(sessionId: number, tutorId: number, studentUserId: number, studentName: string): Observable<string> {
    return this.sendEventWrapper('DropInSessionRequestCancelled', this.#store, {
      sessionId: sessionId, tutorId: tutorId, studentUserId: studentUserId, studentName: studentName, deviceData: JSON.stringify(this.deviceData)
    });
  }

  private sendEventWrapper<TState>(name: EventType, store: Store<TState>, args: { [field: string]: any }) {
    this.#store.dispatch(fromLesson.updateSignalRConnectionStateAction({ connected: this.isConnectedAndReceivedEvent() }));
    return this.sendEvent(name, store, args);
  }
}
