import { Injectable } from '@angular/core';
import { DateStruct } from 'moment-extensions-lib';
import { Observable } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';
import { LessonType, PaymentWarningLevel } from 'ui-common-lib';

import { ActivityQuestions, Lesson, LessonSettings, LessonSummaryList } from '../models';

export interface NewLessonRequest {
  readonly enrolmentId: number;
  readonly tutorId: number;
  readonly centreId: number;
  readonly subjectId: number;
  readonly duration: number;
  readonly lessonPlanId: number;
  readonly paymentWarningLevel: PaymentWarningLevel | null;
  readonly sessionScheduleId: number;
  readonly lessonType: LessonType;
  readonly date: DateStruct;
  readonly time: string;
}

interface NewLessonResponse {
  readonly lessonId: number;
  readonly isOnline: boolean;
}

@Injectable({ providedIn: 'root' })
export class LessonService extends HttpWithAbsoluteService {

  getLessons(): Observable<LessonSummaryList> {
    return this.get<LessonSummaryList>('student/lessons');
  }

  recordSignalRError(lessonId: number): Observable<boolean> {
    return this.post<boolean>(`student/lessons/signalRError/${lessonId}`, null);
  }

  getLesson(lessonId: number, homeworkOnly: boolean): Observable<Lesson> {
    return this.get<Lesson>(`student/lessons/${lessonId}?homeworkOnly=${homeworkOnly}`);
  }

  getQuestions(lessonActivityPlanId: number, regionId: number): Observable<ActivityQuestions | null> {
    return this.get<ActivityQuestions | null>(`tutor/questions/activity/${lessonActivityPlanId}?regionId=${regionId}`);
  }

  getQuestionWorkedSolution(questionId: number, regionId: number): Observable<string | null> {
    return this.get<string | null>(`tutor/questions/${questionId}/worked-solution?regionid=${regionId}`);
  }

  getQuestionIntroduction(questionId: number, regionId: number): Observable<string | null> {
    return this.get<string | null>(`tutor/questions/${questionId}/introduction?regionid=${regionId}`);
  }

  getLessonOnline(lessonId: number): Observable<boolean> {
    return this.get<boolean>(`student/lessons/${lessonId}/is-online`);
  }

  updateEnrolmentLessonType(lessonId: number, lessonType: LessonType): Observable<boolean> {
    return this.post<boolean>(`student/lessons/update-enrolment-lesson-type/${lessonId}`, lessonType);
  }

  generateLesson(newLessonRequest: NewLessonRequest): Observable<NewLessonResponse> {
    return this.post<NewLessonResponse>('student/lessons', newLessonRequest);
  }

  generateAdHocLesson(enrolmentId: number): Observable<NewLessonResponse> {
    return this.post<NewLessonResponse>(`student/lessons/adhoc/${enrolmentId}`, null);
  }

  getTutorPhoto(tutorId: number) {
    return this.getBlob(`avatar/download-tutor-photo/${tutorId}`);
  }

  getStudentPhoto(studentId: number) {
    return this.getBlob(`avatar/download-student-photo/${studentId}`);
  }

  getAdhocImage(imageName: string) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/adhoc-image-url/${imageName}`, ErrorBlobPath.Image);
  }

  updateLessonSettings(lessonId: number, settings: LessonSettings) {
    return this.post<string>(`student/lessons/${lessonId}/settings`, settings);
  }
}
