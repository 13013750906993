import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { WhiteboardService } from 'whiteboard-lib';

import * as fromActivity from '../activity';
import * as fromQuestion from '../question';
import * as fromWhiteboard from '../whiteboard';

@Injectable()
export class WhiteboardEffects {

  readonly #actions$ = inject(Actions);
  readonly #whiteboardService = inject(WhiteboardService);

  whiteboardReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.receiveAction),
      switchMap(action => {
        this.#whiteboardService.applyMessage(
          {
            whiteboardGuidKey: action.whiteboardGuidKey,
            event: action.event
          });
        return [
          fromActivity.whiteboardDrawnAction({ activityGuid: action.whiteboardGuidKey.activityGuid }),
          fromQuestion.whiteboardDrawnAction({ questionGuid: action.whiteboardGuidKey.pageGuid })
        ];
      })
    );
  });

  whiteboardGridTypeReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.receiveGridTypeAction),
      switchMap(action => {
        this.#whiteboardService.applyGridType(
          {
            whiteboardGuidKey: action.whiteboardGuidKey,
            gridType: action.gridType
          });
        return EMPTY;
      })
    );
  }, { dispatch: false });

  whiteboardOpenReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.receiveOpenAction),
      switchMap(action => {
        this.#whiteboardService.open(
          {
            whiteboardGuidKey: action.whiteboardGuidKey
          });
        return EMPTY;
      })
    );
  }, { dispatch: false });

  whiteboardCloseReceive$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.receiveCloseAction),
      switchMap(action => {
        this.#whiteboardService.close(action.whiteboardGuidKey);
        return EMPTY;
      })
    );
  }, { dispatch: false });
}
