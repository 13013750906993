import { createReducer, on } from '@ngrx/store';
import { ComprehensionTest } from 'ui-common-lib';

import * as ComprehensionTestActions from './comprehension-test.actions';

export interface ComprehensionState {
  readonly comprehensionTests: readonly ComprehensionTest[];
}

const initialState: ComprehensionState = {
  comprehensionTests: []
};

export const comprehensionTestReducer = createReducer(initialState,
  on(ComprehensionTestActions.loadComprehensionTestsManyAction, (_state, action): ComprehensionState => {
    return {
      comprehensionTests: action.comprehensionTest
    };
  })
);

