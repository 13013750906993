import { SafeHtml } from '@angular/platform-browser';
import { createAction, props } from '@ngrx/store';
import { AnswerType, Question } from 'questions-lib';
import { WhiteboardGuidKey } from 'whiteboard-lib';

import { LessonQuestion } from '../../../shared';

enum QuestionActionTypes {
  LoadMany = 'Student > Question > Load Many',
  LoadQuestions = 'Student > Question > Load Questions',
  LoadWorkedSolution = 'Student > Question > Load Worked Solution',
  LoadIntroduction = 'Student > Question > Load Introduction',
  Open = 'Student > Question > Open',
  OpenCustomActivityQuestion = 'Student > Student > Question > Open Custom Activity',
  CloseCustomActivityQuestion = 'Student > Student > Question > Close Custom Activity',
  CompleteTimedComputerActivity = 'Student > Question > Timed Computer Activity Time Elapsed',
  Skip = 'Student > Question > Skip',
  Close = 'Student > Question > Close',
  Visit = 'Student > Question > Visit',
  Answer = 'Student > Question > Answer',
  Introduction = 'Student > Question > Introduction',
  WorkedSolution = 'Student > Question > Worked Solution',
  StartTimer = 'Student > Question > Timer > Start',
  EndTimer = 'Student > Question > Timer > End',
  WhiteboardDrawn = 'Student > Question > Whiteboard',
  UpdatedAnswer = 'Student > Question > Updated Answer',
  ReportedAsError = 'Student > Question > Reported As Error'
}

export const loadQuestionsAction = createAction(
  QuestionActionTypes.LoadQuestions,
  props<{
    readonly questions: readonly { questionGuid: string, question: Question, questionKatex: Question }[];
  }>()
);

export const reportedAsErrorAction = createAction(
  QuestionActionTypes.ReportedAsError,
  props<{
    readonly questionGuid: string;
  }>()
);

export const loadWorkedSolutionAction = createAction(
  QuestionActionTypes.LoadWorkedSolution,
  props<{
    readonly questionGuid: string;
    readonly workedSolution: SafeHtml | null;
  }>()
);

export const loadIntroductionAction = createAction(
  QuestionActionTypes.LoadIntroduction,
  props<{
    readonly questionGuid: string;
    readonly introduction: SafeHtml | null;
  }>()
);

export const loadManyAction = createAction(
  QuestionActionTypes.LoadMany,
  props<{
    readonly questions: readonly LessonQuestion[];
  }>()
);

export const openAction = createAction(
  QuestionActionTypes.Open,
  props<{
    readonly questionIndex: number;
  }>()
);

export const skipAction = createAction(
  QuestionActionTypes.Skip,
  props<{
    readonly lessonGuid: string;
    readonly activityGuid: string;
    readonly questionGuid: string;
  }>()
);

export const closeAction = createAction(
  QuestionActionTypes.Close,
  props<{
    readonly lessonGuid: string;
    readonly questionGuid: string;
  }>()
);

export const visitAction = createAction(
  QuestionActionTypes.Visit,
  props<{
    readonly questionGuid: string;
  }>()
);

export const introductionAction = createAction(
  QuestionActionTypes.Introduction,
  props<{
    readonly lessonGuid: string;
    readonly questionGuid: string;
    readonly questionId: number;
    readonly regionId: number;
  }>()
);

export const workedSolutionAction = createAction(
  QuestionActionTypes.WorkedSolution,
  props<{
    readonly lessonGuid: string;
    readonly questionGuid: string;
    readonly questionId: number;
    readonly regionId: number;
  }>()
);

export const answerAction = createAction(
  QuestionActionTypes.Answer,
  props<{
    readonly questionGuid: string;
    readonly answers: readonly AnswerType[];
    readonly correct: boolean;
    readonly kipPointsScaleFactor: number;
    readonly isHomework: boolean;
    readonly isAssessment: boolean;
  }>()
);

export const answerUpdatedAction = createAction(
  QuestionActionTypes.UpdatedAnswer,
  props<{
    readonly lessonGuid: string;
    readonly questionGuid: string;
    readonly answers: readonly AnswerType[];
    readonly correct: boolean;
  }>()
);

export const startTimerAction = createAction(
  QuestionActionTypes.StartTimer,
  props<{
    readonly questionGuid: string;
  }>()
);

export const whiteboardDrawnAction = createAction(
  QuestionActionTypes.WhiteboardDrawn,
  props<{
    readonly questionGuid: string;
  }>()
);

export const endTimerAction = createAction(
  QuestionActionTypes.EndTimer,
  props<{
    readonly questionGuid: string;
  }>()
);

export const openCustomActivityQuestionAction = createAction(
  QuestionActionTypes.OpenCustomActivityQuestion,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const closeCustomActivityQuestionAction = createAction(
  QuestionActionTypes.CloseCustomActivityQuestion,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const completeTimedComputerActivity = createAction(
  QuestionActionTypes.CompleteTimedComputerActivity,
  props<{
    readonly activityGuid: string;
  }>()
);
