import { createAction, props } from '@ngrx/store';

enum CentreActionTypes {
  SetContact = 'Student > Centre > Set Contact'
}

export const setContactCentreAction = createAction(
  CentreActionTypes.SetContact,
  props<{
    readonly id: number;
    readonly phone: string;
  }>()
);
