<div class="kip-tab kip-tab--is-edit">
  <form class="col-12 col-lg-12 col-xl-6" [formGroup]="settingsForm" (ngSubmit)="submit()">
    <h2 class="kip-tab__header">Edit Settings</h2>
    <kip-form-control-checkbox id="hideScores" label="Hide scores" [control]="hideScore" [disabled]="!canEdit" />
    <kip-form-control-checkbox id="hideTimes" label="Hide times" [control]="hideTimes" [disabled]="!canEdit" />
    <kip-form-control-checkbox id="playSounds" label="Play Sounds" [control]="playSounds" [disabled]="!canEdit" />
    <kip-form-control-checkbox id="openDyslexic" label="Open Dyslexic" [control]="openDyslexic" [disabled]="!canEdit" />
    <kip-form-control-checkbox id="useCustomKeyboard" label="Custom Keyboard" [control]="useCustomKeyboard"
      [disabled]="!canEdit" />
    <kip-form-control-checkbox id="qwertyKeyboard" label="QWERTY Keyboard" [control]="qwertyKeyboard"
      [disabled]="!canEdit" />
    <kip-form-control-overlays label="Overlays" [control]="overlays" (toggled)="onToggleOverlays()" />
    @if (overlays.value.enabled) {
    <kip-color-picker [color]="overlays.value.color" (changeColor)="onChangeOverlaysColor($event)" />
    }
    <kip-form-control-overlays label="Backgrounds" [control]="backgrounds" (toggled)="onToggleBackgrounds()" />
    @if (backgrounds.value.enabled) {
    <kip-color-picker [color]="backgrounds.value.color" [title]="'Select a background'"
      (changeColor)="onChangeBackgroundsColor($event)" />
    }
    <kip-form-control-spelling-speed id="spellingSpeed" label="Spelling Speed" [control]="questionSpeed"
      [disabled]="!canEdit" />
    <kip-form-control-theme id="theme" label="Theme" [control]="theme" [disabled]="!canEdit" />
    <kip-skin-tone-picker [disabled]="!canEdit" [skinToneId]="skinTone" />
    <kip-form-control-checkbox id="aiTutorVoiceActive" label="AI Voice Active" [control]="aiTutorVoiceActive" />
    <kip-form-control-polly id="aiTutorVoiceId" label="AI Voice" [control]="aiTutorVoiceId" />
    <kip-form-error [formGroup]="settingsForm" />
    @if (canEdit) {
    <button class="btn btn-primary" type="submit">Save</button>
    }
  </form>
</div>