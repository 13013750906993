<div class="card">
  <div class="card-body">
    <form [formGroup]="pickerForm" (ngSubmit)="submit()">

      @if (showSelection)
      {
      <div class="row">
        <div class="col-3">
          <kip-region-picker label="Country" name="country" [regionId]="regionId" />
        </div>
        <div class="col-3">
          <kip-grade-picker label="Grade" [gradeId]="gradeId" />
        </div>
        <div class="col-4">
          <kip-subject-picker-full label="Subject" name="subject" [subjectId]="subjectId" />
        </div>
        <div class="col-2">
          <button class="btn btn-primary mt-2" type="submit">Display</button>
        </div>
      </div>
      }

      <kip-confirm-dialog #dialog title="Lesson Plan" yesText="OK" [noEnabled]="false">
        <div>
          <div class="row mb-2">
            <div class="col-12">
              The target percentage is set at the tree level by the content team.<br /><br />
              Once that target is met for the last time they did the topic, it stops picking that topic in the lesson
              plan.<br /><br />
              Lesson plan would be generated with activities named :
            </div>
          </div>
          @for (lessonPlan of lessonPlans; track lessonPlan; let index=$index) {
          <div class="row">
            <div class="col-1">
              {{ index + 1}}.
            </div>
            <div class="col-11">
              {{ lessonPlan}}
            </div>
          </div>
          }
          <div class="row mt-2 mb-2">
            <div class="col-12">
              The number of questions per activity can be set in the settings screen in the FMS.<br /><br />
              For each activity it would pick for the lesson :
            </div>
          </div>
          @for (difficulty of difficulties; track difficulty) {
          <div class="row">
            <div class="col-4">
              {{ difficulty.name}}
            </div>
            <div class="col-8">
              {{ difficulty.numberQuestionsDefaultLesson}} questions
            </div>
          </div>
          }
        </div>
      </kip-confirm-dialog>

      @if (loaded) {
      @if (trees.length === 0) {
      <div>
        Sorry we cannot find any trees that match that criteria
      </div>
      }
      @if (trees.length > 0) {
      <div class="row">
        <div [class]="treeViewClass">
          <div class="kip-explore-menu overflow-hidden mt-3">
            @for (tree of trees; track tree) {
            <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
              [class.kip-activity--expanded]="tree.expanded" (click)="tree.expanded = !tree.expanded">
              <div class="flex-column text-start">
                @if (tree.html)
                {
                <ng-template #popContentTree>
                  <div [innerHTML]="tree.html | safe: 'html'"></div>
                </ng-template>
                <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                  [ngbPopover]="popContentTree" />
                }
                <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
                    [icon]="tree.expanded ? icons.folder.open : icons.calendar.expand" />
                  {{ tree.regionTag }} ({{ tree.name }}) :
                  Percentage Target {{ tree.percentageTarget }}%

                </span>
              </div>
              @if (showSelection)
              {
              <div class="flex-column text-end" kipStopPropagation (click)="displayStudents(tree)">
                {{ tree.students.length }} Students
              </div>
              <div class="flex-column text-end" kipStopPropagation (click)="generate(tree, dialog)">
                Generate
              </div>
              }
            </div>
            @if (tree.expanded) {
            <div class="ms-2">
              @for (stream of tree.streams; track stream) {
              <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
                [class.kip-activity--expanded]="stream.expanded" (click)="stream.expanded = !stream.expanded">
                <div class="flex-column text-start">
                  @if (stream.html)
                  {
                  <ng-template #popContentStream>
                    <div [innerHTML]="stream.html | safe: 'html'"></div>
                  </ng-template>
                  <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                    [ngbPopover]="popContentStream" />
                  }
                  <span class="me-2"><fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
                      [icon]="stream.expanded ? icons.folder.open : icons.calendar.expand" /> {{ stream.name }}</span>
                </div>
              </div>
              @if (stream.expanded) {
              <div class="ms-2">
                @for (topic of stream.topics; track topic) {
                <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
                  [class.kip-activity--expanded]="topic.expanded"
                  [style.background-color]="topic.percentage | topicColor : tree.percentageTarget"
                  (click)="topic.expanded = !topic.expanded; selectedTopicId = topic.id">
                  <div class="flex-column text-start">
                    @if (topic.html)
                    {
                    <ng-template #popContentTopic>
                      <div [innerHTML]="topic.html | safe: 'html'"></div>
                    </ng-template>
                    <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                      [ngbPopover]="popContentTopic" />
                    }
                    <span [class]="{'fw-bold': selectedTopicId === topic.id, 'me-2': true}"><fa-icon
                        class="me-1 text-secondary" size="sm" [fixedWidth]="true"
                        [icon]="topic.expanded ? icons.folder.open : icons.calendar.expand" />
                      {{ topic.name }} {{ topic.questions | topicQuestions}}
                    </span>
                  </div>
                  @if (showSelection)
                  {
                  <div class="flex-column text-end" kipStopPropagation ngbDropdown>
                    <span class="text-muted small me-2" ngbDropdownToggle ngbTooltip="Percentages"
                      [id]="'percentageResult'+ topic.id">
                      @if (topic.percentage)
                      {
                      {{ topic.percentage }} %
                      }
                      {{ topic.defaultActivityType }}
                    </span>
                    <div ngbDropdownMenu [attr.aria-labelledby]="'percentageResult'+ topic.id">
                      @for (percentageResult of percentageResults; track percentageResult) {
                      <button class="dropdown-item" ngbDropdownItem type="button"
                        [style.color]="percentageResult.percentage | topicColor : tree.percentageTarget"
                        (click)="updatePercentage(topic,percentageResult.percentage)">
                        {{ percentageResult.name}}
                      </button>
                      }
                    </div>
                  </div>
                  } @else {
                  <div class="flex-column text-end">
                    <span class="text-muted small me-2">
                      @if (topic.percentage)
                      {
                      {{ topic.percentage }} %
                      }
                      {{ topic.defaultActivityType }}
                    </span>
                  </div>
                  }
                </div>
                @if (topic.expanded) {
                <div class="ms-2">
                  @for (activity of topic.activities; track activity) {
                  <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
                    [class.kip-activity--expanded]="activity.expanded"
                    (click)="selectActivity(topic.id, activity, stream.name + '/' + topic.name + '/' + activity.name)">
                    <div class="flex-column text-start">
                      <span class="me-2">
                        @if (activity.html)
                        {
                        <ng-template #popContentActivity>
                          <div [innerHTML]="activity.html | safe: 'html'"></div>
                        </ng-template>
                        <fa-icon class="ms-2 me-2" triggers="mouseenter:mouseleave" [icon]="icons.comment"
                          [ngbPopover]="popContentActivity" />
                        }
                        <fa-icon class="me-1 text-secondary" size="sm" [fixedWidth]="true"
                          [icon]="activity.isManual ? icons.activityType.manual : icons.activityType.computer" />
                        {{ activity.name }}
                      </span>
                    </div>
                    <div class="flex-column text-end">
                      <span class="text-muted small me-2">
                        {{ activity.activityType }} {{ activity.activityDifficulty }}
                        {{activity.isJiraReportingBlocked?'Jira Blocked':'Jira Allowed' }} {{ activity.questions
                        }}</span>
                    </div>
                  </div>
                  }
                </div>
                }
                }
              </div>
              }
              }
            </div>
            }
            }
          </div>
        </div>
        <div [class]="questionViewClass">
          @if (treeActivityId)
          {
          <div class="card mt-3">
            <div class="card-body">
              <h5 class="card-title" (click)="closeActivity()">
                @if (!showSelection)
                {
                <fa-icon class="me-2" [icon]="icons.close.solid" />
                }
                {{ treeActivityTitle }}
              </h5>
              <kip-explore [activityData]="{treeActivityId: treeActivityId, questions: treeActivityQuestions}" />
            </div>
          </div>
          }
        </div>
        <div [class]="studentViewClass">
          <div class="kip-explore-menu overflow-hidden mt-3">
            <div class="kip-activity d-flex flex-row justify-content-between p-2">
              Students
            </div>
            @for (student of students; track students) {
            <div class="kip-activity cursor-pointer d-flex flex-row justify-content-between p-2"
              (click)="selectStudent(student.studentId)">
              {{ student | name }}
            </div>
            }
          </div>
        </div>
      </div>
      }
      }

    </form>
  </div>
</div>