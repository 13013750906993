import { QuestionParametersMultipleChoice, QuestionSummary } from '../../models';

export function convertMultipleChoice(question: QuestionSummary<QuestionParametersMultipleChoice>, genericInput: string,
  genericChoice: string, render: (input: string) => string, showAnswerOnly: boolean) {

  if (showAnswerOnly) {
    let answerText = '';
    for (const option of question.parameters.options) {
      if (question.answers?.find(c => c.values.includes(option.value))) {
        answerText += `<span class="kip-success-tutor-answer">${render(option.text ?? '')}</span>`;
      }
    }

    answerText = answerText.slice(0, answerText.length - 1);

    if (answerText.includes('<p>')) {
      return `${answerText.replace('<p>', '<p class="mb-0"><span class="kip-success-tutor-answer">')}</span>`;
    }

    return answerText;
  }

  const answer = genericInput.replace('&nbsp;', question.answers[0].values[0].toString());

  let optionsText = '';
  for (const option of question.parameters.options) {
    optionsText += option.value === question.answers[0].values[0] ? `<span class="kip-success-tutor-answer">${render(option.text ?? '')}</span>` : ` ${render(option.text ?? '')}`;
  }
  optionsText = genericChoice.replace('&nbsp;', optionsText.slice(2));

  if (question.text.includes('___')) {
    return question.text.replace('___', answer) + optionsText;
  }
  return question.text + optionsText + answer;
}
