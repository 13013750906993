import { inject, Injectable } from '@angular/core';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';
import { IGoogleAnalyticsServiceEvent } from '@hakimio/ngx-google-analytics/lib/interfaces/i-google-analytics-sevice';
import * as Sentry from '@sentry/angular';
import { SkinTone } from 'awards-lib';
import { SupportedDeviceService } from 'device-information-lib';
import moment from 'moment';
import { QuestionSpeed } from 'questions-lib';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'service-lib';

import {
  AnalyticsAction,
  CalendarModes, CentreFiltered, CentreProfile, DownTime, getUIVersion,
  Profile, StudentSettings, StudentSettingsAndIanaTimeZoneName,
  SubjectsAndProficiencies, Theme, toName, TutorSettingsAndIanaTimeZoneName, TutorUserName, UserProfile, VersionData
} from '../models';

export enum Role {
  CentreManager = 'centre-manager',
  Tutor = 'tutor',
  Parent = 'parent',
  Student = 'student',
  SuperUser = 'super-user',
  SupportAccountReader = 'support-account-reader',
  SupportAccountWriter = 'support-account-writer',
  SupportStudentReader = 'support-student-reader',
  SupportStudentWriter = 'support-student-writer',
  SupportLeadsReader = 'support-leads-reader',
  SupportLeadsWriter = 'support-leads-writer',
  SupportAssessmentsReader = 'support-assessments-reader',
  SupportAssessmentsWriter = 'support-assessments-writer',
  SupportAssessmentAvailabilityReader = 'support-admin-assessment-availability-reader',
  SupportAssessmentAvailabilityWriter = 'support-admin-assessment-availability-writer',
  SupportBundleReader = 'support-admin-bundle-reader',
  SupportBundleWriter = 'support-admin-bundle-writer',
  SupportCentreReader = 'support-admin-centre-reader',
  SupportCentreWriter = 'support-admin-centre-writer',
  SupportChangeLogReader = 'support-admin-change-log-reader',
  SupportChangeLogWriter = 'support-admin-change-log-writer',
  SupportOrganisationReader = 'support-admin-organisation-reader',
  SupportOrganisationWriter = 'support-admin-organisation-writer',
  SupportOrganisationGoalsReader = 'support-admin-organisation-goals-reader',
  SupportOrganisationGoalsWriter = 'support-admin-organisation-goals-writer',
  SupportOrganisationActivitiesReader = 'support-admin-organisation-activities-reader',
  SupportOrganisationActivitiesWriter = 'support-admin-organisation-activities-writer',
  SupportEmployeeReader = 'support-admin-employee-reader',
  SupportEmployeeWriter = 'support-admin-employee-writer',
  SupportTutorReader = 'support-admin-tutor-reader',
  SupportTutorWriter = 'support-admin-tutor-writer',
  SupportSessionReader = 'support-admin-session-reader',
  SupportSessionWriter = 'support-admin-session-writer',
  SupportAwardReader = 'support-admin-award-reader',
  SupportAwardWriter = 'support-admin-award-writer',
  SupportReport = 'support-report',
  SupportRewards = 'support-admin-rewards',
  SupportQuizzes = 'support-admin-quizzes',
  SupportAccountMergeReader = 'support-admin-account-merge-reader',
  SupportAccountMergeWriter = 'support-admin-account-merge-writer',
  SupportVideoReader = 'support-admin-video-reader',
  SupportVideoWriter = 'support-admin-video-writer',
  SchoolUser = 'school-user',
  SchoolTree = 'school-tree',

  // combination roles

  SupportAdmin = 'support-admin',
  Support = 'support'
}

export interface SwitchableUser {
  readonly id: number;
  readonly name: string;
}

export interface Switchable {
  readonly students: readonly SwitchableUser[];
  readonly tutors: readonly SwitchableUser[];
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService extends HttpService {

  readonly #supportedDeviceService = inject(SupportedDeviceService);
  readonly #profile: Profile | undefined;
  readonly #downTimeSubject = new BehaviorSubject<DownTime | undefined>(undefined);
  readonly #isInMaintenanceSubject = new BehaviorSubject<boolean>(false);
  readonly #minutesUntilMaintenanceSubject = new BehaviorSubject<number | undefined>(undefined);
  readonly #showMaintenanceGeneralWarning = new BehaviorSubject<boolean>(false);
  readonly #showMaintenanceLogoutWarning = new BehaviorSubject<boolean>(false);
  readonly #profileSubject = new AsyncSubject<Profile>();
  readonly #userProfileSubject = new BehaviorSubject<UserProfile | undefined>(undefined);
  readonly #gaService = inject(GoogleAnalyticsService);
  #currentUserProfile: UserProfile | undefined;
  #currentScope: Sentry.Scope | undefined;

  get showMaintenanceLogoutWarning() {
    return this.#showMaintenanceLogoutWarning.getValue();
  }

  get showMaintenanceGeneralWarning() {
    return this.#showMaintenanceGeneralWarning.getValue();
  }

  get minutesUntilMaintenance() {
    return this.#minutesUntilMaintenanceSubject.getValue();
  }

  get currentDownTime() {
    return this.#downTimeSubject.getValue();
  }

  get isInMaintenance() {
    return this.#isInMaintenanceSubject.getValue();
  }

  get currentProfile(): Profile | undefined {
    return this.#profile;
  }

  get currentUserProfile(): UserProfile | undefined {
    return this.#currentUserProfile;
  }

  recordDataAnalytics(action: AnalyticsAction, event?: IGoogleAnalyticsServiceEvent) {
    this.#gaService.event(action, event);
  }

  getUserProfile(): Observable<UserProfile> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile));
  }

  getVersionData(): Observable<VersionData> {
    return this.#profileSubject.asObservable().pipe(map(p => ({ uiVersion: p.uiVersion, apiVersion: p.apiVersion, hasVersionMismatch: p.hasVersionMismatch })));
  }

  trackUserProfile(): Observable<UserProfile | undefined> {
    return this.#userProfileSubject;
  }

  get getDownTime() {
    return this.#downTimeSubject.asObservable();
  }

  getIsSuperUser(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SuperUser)));
  }

  getIsCentreManager(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.CentreManager)));
  }

  getCanViewAccount(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(
      p => p.userProfile.roles.includes(Role.SupportAccountReader) ||
        p.userProfile.roles.includes(Role.SupportAccountWriter) ||
        p.userProfile.roles.includes(Role.Parent) ||
        p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateAccount(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(
      p => p.userProfile.roles.includes(Role.SupportAccountWriter) ||
        p.userProfile.roles.includes(Role.Parent) ||
        p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateTutor(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportTutorWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateCentre(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportCentreWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  aiModelId(): Observable<number> {
    return this.#profileSubject.asObservable().pipe(map(p => {
      return p.userProfile.roles.includes(Role.SuperUser) ? 3 : p.userProfile.aiModelId;
    }));
  }

  aiTutorEnabled(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => {
      return p.userCentres.some(uc => uc.aiTutorEnabled);
    }));
  }

  aiHelpIntervention(): Observable<number> {
    return this.#profileSubject.asObservable().pipe(map(p => {
      return p.userCentres.map(x => x.aiHelpIntervention).reduce((min, current) => current < min ? current : min);
    }));
  }

  restrictAiSubject(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => {
      return p.userCentres.some(uc => uc.restrictAiSubject);
    }));
  }

  aiWithholdAnswer(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => {
      return p.userCentres.some(uc => uc.aiWithholdAnswer);
    }));
  }

  getCanUpdateVideo(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportVideoWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateOrganisation(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportOrganisationWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateChangeLog(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportChangeLogWriter)));
  }

  getCanUpdateOrganisationGoals(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportOrganisationGoalsWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateOrganisationActivities(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportOrganisationActivitiesWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateAssessmentAvailability(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportAssessmentAvailabilityWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateSchoolUser(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateEmployee(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportEmployeeWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateAward(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportAwardWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateAccountMerge(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportAccountMergeWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateBundle(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportBundleWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanViewLead(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(
      p => p.userProfile.roles.includes(Role.SupportLeadsWriter) ||
        p.userProfile.roles.includes(Role.SupportLeadsReader) ||
        p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanViewStudent(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(
      p => p.userProfile.roles.includes(Role.SupportStudentWriter) ||
        p.userProfile.roles.includes(Role.SupportStudentReader) ||
        p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateStudent(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(
      p => p.userProfile.roles.includes(Role.SupportStudentWriter) ||
        p.userProfile.roles.includes(Role.Parent) || p.userProfile.roles.includes(Role.Tutor) ||
        p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateStudentButNotParent(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportStudentWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateStudentOrHasTutorRole(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportStudentWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser) || p.userProfile.roles.includes(Role.Tutor)));
  }

  getCanUpdateLeads(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportLeadsWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateSession(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportSessionWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getCanUpdateAssessments(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.SupportAssessmentsWriter) ||
      p.userProfile.roles.includes(Role.CentreManager) || p.userProfile.roles.includes(Role.SuperUser)));
  }

  getIsParent(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.roles.includes(Role.Parent)));
  }

  getDefaultRegionId(): Observable<number> {
    return this.#profileSubject.asObservable().pipe(map(p => p.defaultRegionId));
  }

  getDefaultSoundRegionId(): Observable<number> {
    return this.#profileSubject.asObservable().pipe(map(p => p.defaultSoundRegionId));
  }

  getDefaultIanaTimeZoneName(): Observable<string> {
    return this.#profileSubject.asObservable().pipe(map(p => p.defaultIanaTimeZoneName));
  }

  getIsLegacy(): Observable<boolean> {
    return this.#profileSubject.asObservable().pipe(map(p => p.isLegacy));
  }

  getCentres(): Observable<readonly CentreProfile[]> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userCentres));
  }

  getCalendarModes(): Observable<CalendarModes> {
    return this.#profileSubject.asObservable().pipe(map(p => p.userProfile.calendarModes));
  }

  getCentreFiltered(): Observable<CentreFiltered[]> {
    return this.get<CentreFiltered[]>('centres/filtered');
  }

  updateCentreFiltered(centreIds: number[]) {
    return this.post<string>('centres/update-filtered', centreIds);
  }

  updateTutorSettings(tutorSettings: TutorSettingsAndIanaTimeZoneName) {
    return this.post<string>('tutor-settings', tutorSettings);
  }

  resetTutorPassword(newPassword: string) {
    return this.post<string>('tutor-password', { password: newPassword });
  }

  getTutorUserName() {
    return this.get<TutorUserName>('tutor-username');
  }

  hideLoginCreation() {
    this.#currentUserProfile = Object.assign({}, this.#currentUserProfile, {
      showLoginCreation: false
    });
    this.#userProfileSubject.next(this.#currentUserProfile);
  }

  changeTheme(theme: Theme) {
    const studentSettings = Object.assign({}, this.#currentUserProfile?.settings as StudentSettings, { theme: theme });
    this.#currentUserProfile = Object.assign({}, this.#currentUserProfile, {
      settings: studentSettings
    });
    this.#userProfileSubject.next(this.#currentUserProfile);

    return this.post<string>('student-settings-theme', { theme: theme });
  }

  updateStudentSettings(studentSettings: StudentSettingsAndIanaTimeZoneName) {
    this.#currentUserProfile = Object.assign({}, this.#currentUserProfile, {
      settings: studentSettings
    });
    this.#userProfileSubject.next(this.#currentUserProfile);
    return this.post<string>('student-settings', studentSettings);
  }

  getSwitchable() {
    return this.get<Switchable>('switchable');
  }

  startActing(newUserId: number) {
    return this.post<string>(`start-acting/${newUserId}`, null);
  }

  stopActing() {
    return this.post<string>('stop-acting', null);
  }

  updateStudentQuestionSpeed(questionSpeed: QuestionSpeed) {
    return this.post<string>('student-settings-question-speed', { questionSpeed: questionSpeed });
  }

  updateStudentSkinTone(skinTone: SkinTone | undefined) {
    return this.post<string>('student-settings-skin-tone', { skinTone: skinTone });
  }

  updateStudentAIActive(aiActive: boolean) {
    return this.post<string>('student-settings-ai-active', { aiActive: aiActive });
  }

  updateStudentAIVoiceActive(aiActive: boolean) {
    return this.post<string>('student-settings-ai-voice-active', { aiActive: aiActive });
  }

  updateStudentKeyboard(keyboard: boolean) {
    return this.post<string>('student-settings-keyboard', { keyboard: keyboard });
  }

  updateTutorSkinTone(skinTone: SkinTone | undefined) {
    return this.post<string>('tutor-settings-skin-tone', { skinTone: skinTone });
  }

  updateUserName(givenName: string, familyName: string) {
    if (this.#currentUserProfile && (this.#currentUserProfile.name.givenName !== givenName || this.#currentUserProfile.name.familyName !== familyName)) {
      this.#currentUserProfile = Object.assign({}, this.#currentUserProfile, {
        name: { givenName: givenName, familyName: familyName }
      });
      this.#userProfileSubject.next(this.#currentUserProfile);
      if (this.#currentScope) {
        this.#currentScope.setUser({
          id: this.#currentUserProfile.userId.toString(),
          username: toName(this.#currentUserProfile.name)
        });
      }
    }
  }

  fetchDownTime() {
    return this.get<DownTime>('maintenance/downtime').pipe(
      map(value => {

        const fixedValue: DownTime = {
          ...value, ... {
            start: value.start ? { dateTimeOffset: value.start.dateTimeOffset.slice(0, 19), ianaTimeZone: value.start.ianaTimeZone } : null,
            finish: value.finish ? { dateTimeOffset: value.finish.dateTimeOffset.slice(0, 19), ianaTimeZone: value.finish.ianaTimeZone } : null
          }
        };

        let isInMaintenance = false;
        let minutesUntilMaintenance = undefined;
        let showGeneralWarning = false;
        let showLogoutWarning = false;
        const isSuperUserOrImpersonating = (this.#currentUserProfile?.roles.includes(Role.SuperUser) ?? false) || (this.#currentUserProfile?.actorId ?? 0) !== 0;
        if (fixedValue.start && fixedValue.finish) {
          const startUserTimeZone = moment.tz(moment.tz(fixedValue.start.dateTimeOffset, 'YYYY-MM-DDTHH:mm:ss', fixedValue.start.ianaTimeZone), fixedValue.userIanaTimeZoneName);
          const finishUserTimeZone = moment.tz(moment.tz(fixedValue.finish.dateTimeOffset, 'YYYY-MM-DDTHH:mm:ss', fixedValue.finish.ianaTimeZone), fixedValue.userIanaTimeZoneName);
          const currentTime = moment.tz(fixedValue.userIanaTimeZoneName).startOf('minute');

          minutesUntilMaintenance = startUserTimeZone.diff(currentTime, 'minutes');

          if (currentTime.isSameOrAfter(startUserTimeZone) && currentTime.isSameOrBefore(finishUserTimeZone) && !isSuperUserOrImpersonating) {
            isInMaintenance = true;
          }

          if (minutesUntilMaintenance && fixedValue && minutesUntilMaintenance > 0) {
            showGeneralWarning = minutesUntilMaintenance < fixedValue.downTimeGeneralWarningHoursBefore * 60 ||
              minutesUntilMaintenance < fixedValue.downTimeLogoutWarningMinutesBefore;
          }

          if (minutesUntilMaintenance && fixedValue && minutesUntilMaintenance > 0) {
            showLogoutWarning = minutesUntilMaintenance < fixedValue.downTimeLogoutWarningMinutesBefore;
          }
        }

        this.#showMaintenanceGeneralWarning.next(showGeneralWarning);
        this.#showMaintenanceLogoutWarning.next(showLogoutWarning);
        this.#minutesUntilMaintenanceSubject.next(minutesUntilMaintenance);
        this.#downTimeSubject.next(fixedValue);
        this.#isInMaintenanceSubject.next(isInMaintenance);
        return true;
      })
    );
  }

  fetchProfile(): Observable<boolean> {
    const uiVersion = getUIVersion();

    const deviceInfo = this.#supportedDeviceService.getCurrentDeviceInfo();
    const browser = deviceInfo.browser;
    const browserVersion = deviceInfo.browser_version;
    const browserUpToDate = !deviceInfo.outOfDate;

    return this.get<Profile>(`profile?uiVersion=${uiVersion}&browser=${browser}&browserVersion=${browserVersion}&browserUpToDate=${browserUpToDate}`)
      .pipe(
        map(profile => {
          this.#recordLogonAnalytics(profile.userProfile);
          const scope = Sentry.getCurrentScope();
          scope.setUser({
            id: profile.userProfile.userId.toString(),
            username: toName(profile.userProfile.name)
          });
          this.#currentScope = scope;
          this.#profileSubject.next(profile);
          this.#profileSubject.complete();
          this.#currentUserProfile = profile.userProfile;
          this.#userProfileSubject.next(this.#currentUserProfile);
          return true;
        })
      );
  }

  getSubjectsAndProficiencies(): Observable<SubjectsAndProficiencies> {
    return this.get<SubjectsAndProficiencies>('tutor-subjects-and-proficiencies');
  }

  #recordLogonAnalytics(userProfile: UserProfile) {
    this.#gaService.gtag('set', 'user_properties', {
      userId: userProfile.userId,
      userName: `${userProfile.name.givenName} ${userProfile.name.familyName}`,
      ianaTimeZoneName: userProfile.ianaTimeZoneName,
      roles: userProfile.roles.join(',')
    });
    this.#gaService.event(AnalyticsAction.UserLogon);
  }
}
