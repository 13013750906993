import { createReducer, on } from '@ngrx/store';
import { LessonChat, LessonLogAuthor } from 'message-lib';

import * as ChatActions from './chat.actions';

export interface ChatState {
  readonly entityIds: {
    [lessonGuid: string]: readonly string[];
  };
  readonly entities: {
    [eventId: string]: LessonChat;
  };
  readonly chatGroups: readonly string[];
  readonly recipientsTyping: string[];
}

const initialState: ChatState = {
  entityIds: {},
  entities: {},
  chatGroups: [],
  recipientsTyping: []
};

export const chatReducer = createReducer(initialState,
  on(ChatActions.loadManyAction, (state, action): ChatState => {
    let entityIds = state.entityIds;
    let entities = state.entities;
    const chatGroups = state.chatGroups;

    for (const chat of action.chatMessages) {
      const eventIds = entityIds[action.lessonGuid] || [];

      // Only add logs not already in the store
      entityIds = { ...entityIds, [action.lessonGuid]: [...eventIds, chat.eventId] };
      entities = { ...entities, [chat.eventId]: chat };
    }

    return {
      entityIds: entityIds,
      entities: entities,
      chatGroups: chatGroups,
      recipientsTyping: state.recipientsTyping
    };
  }),
  on(ChatActions.addAction, (state, action) => {
    const eventIds = state.entityIds[action.lessonGuid] || [];
    const chatIds = state.chatGroups || [];
    let recipientsTyping = state.recipientsTyping;

    if (action.chatMessages.author !== LessonLogAuthor.Student) {
      recipientsTyping = recipientsTyping.filter(x => x !== action.lessonGuid);
    }

    // Only add logs not already in the store
    if (!chatIds.includes(action.chatMessages.chatGroup)) {
      return {
        entityIds: { ...state.entityIds, [action.lessonGuid]: [...eventIds, action.chatMessages.eventId] },
        entities: { ...state.entities, [action.chatMessages.eventId]: action.chatMessages },
        chatGroups: [...state.chatGroups, action.chatMessages.chatGroup],
        recipientsTyping: recipientsTyping
      };
    }

    return state;
  }),
  on(ChatActions.updateTypingAction, (state, action) => {
    let recipientsTyping: string[] | undefined;

    if (action.isTyping && !state.recipientsTyping.includes(action.lessonGuid)) {
      recipientsTyping = [...state.recipientsTyping, action.lessonGuid];
    } else if (!action.isTyping && state.recipientsTyping.includes(action.lessonGuid)) {
      recipientsTyping = state.recipientsTyping.filter(x => x !== action.lessonGuid);
    }

    if (recipientsTyping) {
      return {
        entityIds: state.entityIds,
        entities: state.entities,
        chatGroups: state.chatGroups,
        recipientsTyping: recipientsTyping
      };
    }

    return state;
  })
);
