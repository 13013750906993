import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-new-talking',
  templateUrl: './monty-new-talking.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})

export class MontyNewTalkingComponent implements MontyGraphic, OnInit {
    readonly #changeDetectorRef = inject(ChangeDetectorRef);
    #direction = 1;
    currentStage = 1;

    ngOnInit(): void {
        setInterval(() => {
            if (this.#direction === 1) {
                if (this.currentStage < 4) {
                    this.currentStage += 1;
                } else {
                    this.#direction = 2;
                    this.currentStage -= 1;
                }
            } else {
                if (this.currentStage > 1) {
                    this.currentStage -= 1;
                } else {
                    this.#direction = 1;
                    this.currentStage += 1;
                }
            }

            this.#changeDetectorRef.detectChanges();
        }, 80);
    }

}
