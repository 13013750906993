/* eslint @typescript-eslint/no-useless-constructor : 0 */

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, map, Observable, Subscription, switchMap, tap } from 'rxjs';
import { HttpWithAbsoluteService } from 'service-lib';

import { fromCentre, fromState, StudentState } from '../store';
import { CentreState } from '../store/centre/centre.reducer';

@Injectable({
  providedIn: 'root'
})
export class CentreService extends HttpWithAbsoluteService {

  readonly #store = inject(Store<StudentState>);
  readonly #subscriptions: Subscription[] = [];
  readonly #centreSubject = new BehaviorSubject<CentreState | undefined>(undefined);

  constructor() {
    super();

    this.#subscriptions.push(
      this.#store.select(fromState.selectCentreState)
        .subscribe(centre => {
          this.#centreSubject.next(centre);
        })
    );
  }

  getCentre(id: number): Observable<CentreState | undefined> {
    if (this.#centreSubject.value && this.#centreSubject.value.id === id) {
      return this.#centreSubject.asObservable();
    }

    return this.get<CentreState>(`centre/contact/${id}`)
      .pipe(
        map(centre => ({ id: id, phone: centre.phone } as CentreState)),
        tap(centre => this.#store.dispatch(fromCentre.setContactCentreAction({ id: id, phone: centre.phone ?? '' }))),
        tap(centre => this.#centreSubject.next(centre)),
        switchMap(() => this.#centreSubject)
      );
  }
}
