import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'auth-lib';
import { Icons } from 'icon-lib';
import { interval, Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

@Component({
    selector: 'kip-maintenance-warning',
    templateUrl: './maintenance-warning.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class MaintenanceWarningComponent implements OnInit, OnDestroy {

    readonly #profileService = inject(ProfileService);
    readonly #authService = inject(AuthService);
    readonly #changeDetectorRef = inject(ChangeDetectorRef);
    
    #subscriptions: Subscription[] = [];
    
    readonly icons = Icons;

    get downTime() {
        return this.#profileService.currentDownTime;
    }

    get showWarning() {
        return this.#profileService.showMaintenanceGeneralWarning;
    }

    get showAlertWarning() {
        return this.#profileService.showMaintenanceLogoutWarning;
    }

    get minutesUntilMaintenance() {
        return this.#profileService.minutesUntilMaintenance;
    }

    ngOnInit() {
        this.#loadDowntime();
        this.#subscriptions.push(
            this.#profileService.getDownTime.subscribe(() => {
                this.#changeDetectorRef.markForCheck();
            }),
            interval(60_000).subscribe(() => this.#loadDowntime())
        );
    }

    ngOnDestroy() {
        for (const subscription of this.#subscriptions) {
            subscription.unsubscribe();
        }
        this.#subscriptions = [];
    }

    logout() {
        this.#authService.logout();
    }

    #loadDowntime() {
        this.#subscriptions.push(
            this.#profileService.fetchDownTime().subscribe(() => {
                this.#changeDetectorRef.markForCheck();
            }));
    }

}
