import { createAction, props } from '@ngrx/store';

enum DropInActionTypes {
  DropInSessionJoinRequest = 'Student > Drop In > Drop In Session Join Requested',
  DropInSessionJoinRequestAccepted = 'Student > Drop In > Drop In Session Request Accepted',
  DropInSessionJoinRequestCancelled = 'Student > Drop In > Drop In Session Request Cancelled',
  DropInSessionJoinRequestDeclined = 'Student > Drop In > Drop In Session Request Declined'
}

export const dropInSessionJoinRequestAction = createAction(
  DropInActionTypes.DropInSessionJoinRequest,
  props<{
    readonly sessionId: number;
    readonly tutorId: number;
    readonly studentUserId: number;
    readonly studentName: string;
  }>()
);

export const dropInLessonRequestAcceptedAction = createAction(
  DropInActionTypes.DropInSessionJoinRequestAccepted,
  props<{
    readonly lessonId: number;
  }>()
);

export const dropInLessonRequestDeclinedAction = createAction(
  DropInActionTypes.DropInSessionJoinRequestDeclined,
  props<{
    readonly sessionId: number;
    readonly message: string;
  }>()
);

export const dropInLessonRequestCancelledAction = createAction(
  DropInActionTypes.DropInSessionJoinRequestCancelled,
  props<{
    readonly studentUserId: number;
    readonly tutorId: number;
    readonly sessionId: number;
    readonly studentName: string;
  }>()
);
