import { createSelector } from '@ngrx/store';

import { SelectedLesson } from '../../models';
import { selectLessonEntityState, selectLessonState, selectSelectedLessonEntity } from '../state';
import { LessonState } from './lesson.reducer';

export const selectConnectedState = createSelector(selectLessonState, (state: LessonState) => state.connected);
export const selectLobbyState = createSelector(selectLessonState, (state: LessonState) => state.lobby);
export const selectLessonIdle = createSelector(selectLessonState, (state: LessonState) => state.idle);
export const selectAnimationState = createSelector(selectLessonState, (state: LessonState) => state.animationAction);
export const selectVideoEffectType = createSelector(selectLessonState, (state: LessonState) => state.videoEffectType);
export const selectDisableScreenShare = createSelector(selectLessonState, (state: LessonState) => state.disableScreenShare);
export const selectSendLessonUpdates = createSelector(selectLessonState, (state: LessonState) => state.sendUpdates);
export const selectIsLessonLoading = createSelector(selectLessonState, (state: LessonState) => state.isLoadingLesson);
export const selectClassInProgress = createSelector(selectLessonState, (state: LessonState) => state.classInProgress);

export const selectSelectedAssessmentResultType = createSelector(selectSelectedLessonEntity, selectLessonEntityState,
  (entity, entityState) => entity ? entityState[entity.lessonGuid].assessmentResultType : undefined);

export const selectInProgressEnrolment = createSelector(selectSelectedLessonEntity, selectLessonEntityState, (entity, entityState) => {
  if (entity) {
    const state = entityState[entity.lessonGuid];
    if (state) {
      return state.enrolment;
    }
  }
  return undefined;
});

export const selectSelectedLesson = createSelector(selectSelectedLessonEntity, selectLessonEntityState, (entity, entityState) => {
  let lesson: SelectedLesson | undefined;

  if (entity) {
    const state = entityState[entity.lessonGuid];

    if (state) {
      lesson = {
        lessonId: entity.lessonId,
        lessonGuid: entity.lessonGuid,
        sessionId: entity.sessionId,
        regionId: entity.regionId,
        soundRegionId: entity.soundRegionId,
        tutorId: entity.tutorId,
        studentId: entity.studentId,
        student: entity.student,
        tutor: entity.tutor,
        centreId: entity.centreId,
        centreName: entity.centreName,
        allowBrainBooster: entity.allowBrainBooster,
        subjectName: entity.subjectName,
        dateTime: entity.dateTime,
        duration: entity.duration,
        isAssessment: entity.isAssessment,
        isOnline: state.isOnline,
        isHomeworkOnly: state.isHomeworkOnly,
        tutorPhotoUrl: state.tutorPhotoUrl,
        studentPhotoUrl: state.studentPhotoUrl,
        tutorPhotoDefault: state.tutorPhotoDefault,
        studentPhotoDefault: state.studentPhotoDefault,
        tutorOnline: state.tutorOnline,
        publishVideo: state.publishVideo,
        publishAudio: state.publishAudio,
        subscribeAudio: state.subscribeAudio,
        subscribeScreenShare: state.subscribeScreenShare,
        publishState: state.publishState,
        isDIYCompatible: entity.isDIYCompatible,
        focus: state.focus,
        observe: state.observe,
        help: state.help,
        rendered: state.rendered,
        awardData: state.awardData,
        previousLessonGuid: entity.previousLessonGuid,
        lessonType: entity.lessonType,
        installApp: state.installApp,
        message: state.message,
        bundleSelected: state.bundleSelected,
        startLessonNote: state.startLessonNote,
        startLessonNoteRanking: state.startLessonNoteRanking,
        finishLessonNote: state.finishLessonNote,
        finishLessonNoteRanking: state.finishLessonNoteRanking,
        allowStudentFinishLessonNoteRanking: entity.allowStudentFinishLessonNoteRanking,
        allowStudentStartLessonNoteRanking: entity.allowStudentStartLessonNoteRanking,
        allowStudentFinishLessonNoteComment: entity.allowStudentFinishLessonNoteComment,
        allowStudentStartLessonNoteComment: entity.allowStudentStartLessonNoteComment,
        useEmojisForStudentRanking: entity.useEmojisForStudentRanking,
        studentStartLessonTitle: entity.studentStartLessonTitle,
        studentStartLessonText: entity.studentStartLessonText,
        studentFinishLessonText: entity.studentFinishLessonText,
        studentFinishLessonTitle: entity.studentFinishLessonTitle,
        newAvatar: entity.newAvatar,
        aiStudentHelpEnabled: entity.aiStudentHelpEnabled,
        aiStudentHelpSpeechEnabled: entity.aiStudentHelpSpeechEnabled,
        tutorIsAI: entity.tutorIsAI,
        aiTutorEnabled: entity.aiTutorEnabled,
        aiAssessmentTargetPercentage: entity.aiAssessmentTargetPercentage,
        treeSummaryIndex: state.treeSummaryIndex,
        treeSummaries: state.treeSummaries,
        aiAssessmentFinished: state.aiAssessmentFinished,
        aiNote: state.aiNote
      };
    }
  }

  return lesson;
});

export const selectSelectedLessonIsDropInLessonCompleted = createSelector(selectSelectedLessonEntity, selectLessonEntityState,
  (entity, entityState) => entity ? entityState[entity.lessonGuid].isDropInLessonCompleted : undefined);
