import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import { EMPTY, Observable, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

import {
  AssessmentStatus,
  gotoLessonEnrolment,
  gotoLessonResults,
  gotoLobby,
  LessonActivityType,
  LessonQuestion,
  PointService,
  RouterService,
  SessionActivityType
} from '../../../shared';
import { SubjectGrades, TreeSummary } from '../../models';
import { LessonService, MessagingService } from '../../services';
import { fromState } from '..';
import * as fromActivity from '../activity';
import * as fromChat from '../chat';
import * as fromComprehensionTest from '../comprehension-test';
import * as fromLesson from '../lesson';
import * as fromLog from '../log';
import * as fromQuestion from '../question';
import * as fromSoundOption from '../sound-option';
import { StudentState } from '../state';

type Writable<T> = {
  -readonly [P in keyof T]: T[P];
};

@Injectable()
export class LessonEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<StudentState>);
  readonly #lessonService = inject(LessonService);
  readonly #pointService = inject(PointService);
  readonly #profileService = inject(ProfileService);
  readonly #authService = inject(AuthService);
  readonly #messagingService = inject(MessagingService);

  readonly routing = inject(RouterService);

  tutorPhotoLoad$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.tutorPhotoLoadAction),
      switchMap(action => this.#lessonService.getTutorPhoto(action.tutorId)
        .pipe(
          switchMap(response =>
            [fromLesson.tutorPhotoUpdateAction(
              {
                lessonGuid: action.lessonGuid,
                tutorPhotoUrl: response.body ? window.URL.createObjectURL(response.body) : '',
                tutorPhotoDefault: response.headers.get('image-default') === 'true'
              })]
          )
        )
      )
    );
  });

  studentPhotoLoad$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.studentPhotoLoadAction),
      switchMap(action => this.#lessonService.getStudentPhoto(action.studentId)
        .pipe(
          switchMap(response =>
            [fromLesson.studentPhotoUpdateAction(
              {
                lessonGuid: action.lessonGuid,
                studentPhotoUrl: response.body ? window.URL.createObjectURL(response.body) : '',
                studentPhotoDefault: response.headers.get('image-default') === 'true'
              })]
          )
        )
      )
    );
  });

  studentDetailsUpdated$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.studentDetailsUpdatedAction),
      tap(action => {
        this.#profileService.updateUserName(action.givenName, action.familyName);
      }));
  },
    { dispatch: false }
  );

  dropInLessonRequestAccepted$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.dropInLessonRequestAcceptedAction),
      tap(action => {
        this.routing.router.navigate([gotoLobby(action.lessonId)]);
      }));
  },
    { dispatch: false });

  load$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.loadAction),
      tap(action => {
        const userName = action.lesson.student;
        this.#profileService.updateUserName(userName.givenName, userName.familyName);
      }));
  },
    { dispatch: false }
  );

  open$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.openAction),
      switchMap(action =>
        of(fromLesson.getAction({ lessonId: action.lessonId, isHomework: action.isHomework, lessonActivityPlanId: action.lessonActivityPlanId }))
      )
    );
  });

  awardGiven$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.awardGivenAction),

      switchMap(action => {
        // Build the actions to load the lesson data
        const actions: Action[] = [
          fromLesson.refreshAwardDataAction({ lessonGuid: action.lessonGuid, lessonId: action.lessonId })
        ];

        return actions;
      })
    );
  });

  refreshAwardData$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.refreshAwardDataAction),
      switchMap(action => this.#pointService
        .getLessonPoints(action.lessonId)
        .pipe(
          switchMap(awardData => {
            // Build the actions to load the lesson data
            const actions: Action[] = [
              fromLesson.updateAwardDataAction({ lessonGuid: action.lessonGuid, awardData: awardData })
            ];

            return actions;
          })
        )
      )
    );
  });

  get$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.getAction),
      switchMap(action => this.#lessonService
        .getLesson(action.lessonId, action.isHomework)
        .pipe(
          switchMap(lesson => {

            const activities: fromActivity.ActivityContext[] = [];
            const questions: LessonQuestion[] = [];
            if (lesson.activities) {
              for (const activity of lesson.activities) {
                activities.push({
                  lessonGuid: lesson.lessonGuid,
                  sessionActivityType: SessionActivityType.Activity,
                  activity: activity
                });
              }
            }
            if (lesson.homework) {
              for (const activity of lesson.homework) {
                activities.push({
                  lessonGuid: lesson.lessonGuid,
                  sessionActivityType: SessionActivityType.Activity,
                  activity: activity
                });
              }
            }
            if (lesson.previousHomework) {
              for (const activity of lesson.previousHomework) {
                activities.push({
                  lessonGuid: lesson.previousLessonGuid,
                  sessionActivityType: SessionActivityType.Activity,
                  activity: activity
                });
              }
            }

            for (const activity of activities) {
              switch (activity.activity.lessonActivityType) {
                case LessonActivityType.Computer:
                  if (activity.activity.computer) {
                    questions.push(...activity.activity.computer.questions);
                  }
                  break;
                case LessonActivityType.TimedComputer:
                  if (activity.activity.timedComputer) {
                    questions.push(...activity.activity.timedComputer.questions);
                  }
                  break;
                default:
                  break;
              }
            }

            // Turn off online for homework
            if (action.isHomework) {
              lesson.isHomeworkOnly = true;
              lesson.isOnline = false;
            } else {
              lesson.isHomeworkOnly = false;
            }

            if (lesson.assessmentStatus === AssessmentStatus.Reviewing) {
              this.routing.router.navigate([gotoLessonResults(lesson.lessonId)]);
            }

            if (lesson.assessmentStatus === AssessmentStatus.Enrolled || lesson.assessmentStatus === AssessmentStatus.Enrolling) {
              this.routing.router.navigate([gotoLessonEnrolment(lesson.lessonId)]);
            }

            // Build the actions to load the lesson data
            const actions: Action[] = [
              fromSoundOption.loadStudentSoundsAction({ regionId: lesson.regionId }),
              fromLesson.loadAction({ lesson: lesson }),
              fromLesson.refreshAwardDataAction({ lessonId: lesson.lessonId, lessonGuid: lesson.lessonGuid }),
              fromActivity.loadManyAction({ activities: activities }),
              fromQuestion.loadManyAction({ questions: questions }),
              fromLesson.tutorPhotoLoadAction({ lessonGuid: lesson.lessonGuid, tutorId: lesson.tutorId }),
              fromLesson.studentPhotoLoadAction({ lessonGuid: lesson.lessonGuid, studentId: lesson.studentId }),
              fromLog.loadManyAction({ lessonGuid: lesson.lessonGuid, logs: lesson.logs ?? [] }),
              fromChat.loadManyAction({ lessonGuid: lesson.lessonGuid, chatMessages: lesson.chat ?? [] }),
              fromComprehensionTest.loadComprehensionTestsAction()
            ];

            if (action.lessonActivityPlanId) {
              actions.push(fromActivity.loadQuestionsAction({ lessonActivityPlanId: action.lessonActivityPlanId, regionId: lesson.regionId }));
            }

            if (activities.some(s => s.activity.assessmentFormName)) {
              actions.push(fromComprehensionTest.loadComprehensionTestsAction());
            }

            if (lesson.isAssessment && activities.some(s => s.activity.treeTopicId)) {
              const activity = activities.find(s => s.activity.treeTopicId && !s.activity.completedOn);
              if (activity) {
                actions.push(fromActivity.requestOpenAction({ activityGuid: activity.activity.activityGuid }),
                  fromLesson.refreshTreeSummaryAction({ activityGuid: activity.activity.activityGuid }));
              }
              else {
                actions.push(fromLesson.aiAssessmentFinished({ lessonGuid: lesson.lessonGuid }));
              }
            }

            return actions;
          })
        )
      )
    );
  });

  refreshTreeSummary$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.refreshTreeSummaryAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSelectedLesson),
        this.#store.select(fromActivity.selectLessonActivities),
        this.#store.select(fromState.selectQuestionEntityState)
      ),
      switchMap(([action, selectedLesson, selectedActivities, selectedQuestionState]) => {

        const actions: Action[] = [];

        let treeSummaryIndex = 0;

        if (selectedLesson && selectedActivities) {
          const treeSummaries: Writable<TreeSummary>[] = selectedLesson.treeSummaries.map(obj => ({
            ...obj
          }));

          for (const treeSummary of treeSummaries) {
            treeSummary.activitiesTotal = 0;
            treeSummary.activitiesCompleted = 0;
            treeSummary.activitiesMetTarget = 0;
            treeSummary.questionsTotal = 0;
            treeSummary.questionsCompleted = 0;
            treeSummary.questionsCorrect = 0;
          }

          for (const selectedActivity of selectedActivities) {
            const treeTopicId = selectedActivity.treeTopicId;
            if (treeTopicId) {
              const treeSummary = treeSummaries.find(s => s.treeTopicIds.includes(treeTopicId));
              if (selectedActivity.activityGuid === action.activityGuid && treeSummary) {
                treeSummaryIndex = treeSummaries.indexOf(treeSummary);
              }
              if (treeSummary) {
                treeSummary.activitiesTotal++;
                if (selectedActivity.completedOn) {
                  treeSummary.activitiesCompleted++;
                  if (selectedActivity.percentage && selectedActivity.percentage >= treeSummary.percentageTarget) {
                    treeSummary.activitiesMetTarget++;
                  }
                }

                if (selectedActivity.computer) {
                  for (const question of selectedActivity.computer.questions) {
                    treeSummary.questionsTotal++;
                    const questionEntityState = selectedQuestionState[question.questionGuid];
                    const firstAttempt = questionEntityState.attempts === 1;
                    const assessment = selectedLesson.isAssessment || selectedActivity.isAssessmentActivity;
                    const correct = questionEntityState.correct;
                    const skipped = questionEntityState.skipped;
                    if (correct || skipped || firstAttempt && assessment) {
                      treeSummary.questionsCompleted++;
                      if (correct && firstAttempt) {
                        treeSummary.questionsCorrect++;
                      }
                    }
                  }
                }
              }
            }
          }

          const gradesAchieved = treeSummaries.filter(s => s.questionsCompleted === s.questionsTotal && 100 * s.questionsCorrect / s.questionsTotal >= selectedLesson.aiAssessmentTargetPercentage)
            .map(s => ({ subjectId: s.subjectId, gradeId: s.gradeId }));

          if (gradesAchieved.length > 0) {
            const subjectGrades = gradesAchieved.reduce<SubjectGrades[]>((subjects, { subjectId, gradeId }) => {
              const existingSubject = subjects.find(item => item.subjectId === subjectId);
              if (existingSubject) {
                existingSubject.gradeIds.push(gradeId);
              } else {
                subjects.push({ subjectId, gradeIds: [gradeId] });
              }
              return subjects;
            }, []);
            actions.push(fromLesson.updateGradesAchievedAction({ lessonGuid: selectedLesson.lessonGuid, subjects: subjectGrades }));
          }

          actions.push(fromLesson.updateTreeSummaryAction({ lessonGuid: selectedLesson.lessonGuid, treeSummaryIndex: treeSummaryIndex, treesSummaries: treeSummaries }));

          // check if any trees are completed and did not achieve percentage or if everything is done

          if (treeSummaries.length > 0) {
            const hasFailure = treeSummaries.some(s => s.questionsCompleted === s.questionsTotal && 100 * s.questionsCorrect / s.questionsTotal < selectedLesson.aiAssessmentTargetPercentage);
            const finished = !treeSummaries.some(s => s.questionsCompleted !== s.questionsTotal);

            if (hasFailure || finished) {
              actions.push(
                fromActivity.requestCloseAction({ activityGuid: action.activityGuid }),
                fromLesson.aiAssessmentFinished({ lessonGuid: selectedLesson.lessonGuid }));
            }
          }
        }

        return actions;
      })
    );
  });

  disconnectLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.disconnectLessonAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSelectedLesson),
        this.#store.select(fromLesson.selectLobbyState),
        this.#store.select(fromQuestion.selectSelectedQuestion),
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(fromQuestion.selectSelectedQuestionIndex)
      ),
      switchMap(([action, selectedLesson, lobbyState, selectedQuestion, selectedActivity, questionIndex]) => {

        const actions: Action[] = [];

        if (selectedLesson) {
          if (selectedQuestion?.questionGuid) {
            actions.push(fromQuestion.closeAction({
              questionGuid: selectedQuestion.questionGuid,
              lessonGuid: selectedLesson.lessonGuid
            }));
          }

          if ((selectedActivity?.lessonActivityType === LessonActivityType.Manual || selectedActivity?.lessonActivityType === LessonActivityType.AdHoc) && questionIndex) {
            actions.push(fromQuestion.closeCustomActivityQuestionAction({
              lessonGuid: selectedLesson.lessonGuid
            }));
          }

          if (selectedActivity?.activityGuid) {
            actions.push(fromActivity.closeAction({
              activityGuid: selectedActivity.activityGuid,
              lessonGuid: selectedLesson.lessonGuid,
              unitId: selectedActivity.unitId,
              completed: selectedActivity.completed
            }));
          }

          if (selectedLesson.focus?.focused) {
            actions.push(fromLesson.stopTeachingAction({ lessonGuid: selectedLesson.lessonGuid }));
          }

          if (selectedLesson.observe?.observed) {
            actions.push(fromLesson.stopObservingAction({ lessonGuid: selectedLesson.lessonGuid }));
          }

          if (lobbyState) {
            actions.push(fromLesson.closeLobbyAction({ lessonId: selectedLesson.lessonId }));
          }

          actions.push(fromLesson.closeAction({ lessonGuid: selectedLesson.lessonGuid }));
        }

        if (action.logoutUser) {
          actions.push(fromLesson.studentLogoutAction({
            showLogoutMessage: action.showLogoutMessage,
            logoutMessage: action.logoutMessage
          }));
        }

        return actions;
      })
    );
  });

  logout$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.studentLogoutAction),
      tap(action => {
        this.#authService.logout();
        if (action.showLogoutMessage && action.logoutMessage) {
          this.#authService.logoutMessage = action.logoutMessage;
        }
      })
    );
  },
    {
      dispatch: false
    });

  disconnectSession$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.closeLobbyAction, fromLesson.disconnectSessionAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([_action, lesson]) => {

        // If the lesson is focused, then it needs to be set to unfocused
        if (lesson?.focus?.focused) {
          return of(
            fromLesson.stopTeachingAction({ lessonGuid: lesson.lessonGuid })
          );
        }

        /* TODO : Figure out how this should work
 
        if (lesson.observe && lesson.observe.observed) {
          return of(
            new fromLesson.StopObservingAction(lesson.lessonGuid)
          );
        }
        */

        return EMPTY;
      })
    );
  });

  update$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updateAction),
      withLatestFrom(
        this.#store.select(fromActivity.selectLessonActivities),
        this.#store.select(fromActivity.selectLessonHomework)
      ),
      switchMap(([action, lessonActivities, lessonHomework]) => {
        const inStoreActivities = [...lessonActivities ?? [], ...lessonHomework ?? []];
        const activities: fromActivity.ActivityContext[] = [];
        const questions: LessonQuestion[] = [];
        if (action.lesson.activities) {
          for (const activity of action.lesson.activities) {
            activities.push({
              lessonGuid: action.lesson.lessonGuid,
              sessionActivityType: SessionActivityType.Activity,
              activity: activity
            });
          }
        }
        if (action.lesson.homework) {
          for (const activity of action.lesson.homework) {
            activities.push({
              lessonGuid: action.lesson.lessonGuid,
              sessionActivityType: SessionActivityType.Activity,
              activity: activity
            });
          }
        }
        if (action.lesson.previousHomework) {
          for (const activity of action.lesson.previousHomework) {
            activities.push({
              lessonGuid: action.lesson.previousLessonGuid,
              sessionActivityType: SessionActivityType.Activity,
              activity: activity
            });
          }
        }

        for (const activity of activities) {
          if (!inStoreActivities.some(a => a.activityGuid === activity.activity.activityGuid)) {
            switch (activity.activity.lessonActivityType) {
              case LessonActivityType.Computer:
                if (activity.activity.computer) {
                  questions.push(...activity.activity.computer.questions);
                }
                break;
              case LessonActivityType.TimedComputer:
                if (activity.activity.timedComputer) {
                  questions.push(...activity.activity.timedComputer.questions);
                }
                break;
              default:
                break;
            }
          }
        }
        // insert the activities and questions in store.
        const actions: Action[] = [
          fromActivity.loadManyAction({ activities: activities }),
          fromQuestion.loadManyAction({ questions: questions })
        ];

        if (activities.some(s => s.activity.assessmentFormName)) {
          actions.push(fromComprehensionTest.loadComprehensionTestsAction());
        }

        return actions;
      })
    );
  });

  streamConnected$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.streamConnectedAction),
      switchMap(action => this.#messagingService.streamConnected(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });
}
