import { createReducer, on } from '@ngrx/store';

import * as fromDropIn from './drop-in.actions';

interface DropInRequest {
  readonly requestedSessionId: number | undefined;
  readonly declinedMessage: string | undefined;
}

export interface DropInState {
  readonly requestActive: boolean;
  readonly dropInRequest: DropInRequest | undefined;
}

const initialState: DropInState = {
  requestActive: false,
  dropInRequest: undefined
};

export const dropInReducer = createReducer(initialState,
  on(fromDropIn.dropInSessionJoinRequestAction, (_state, action): DropInState => {
    const dropInState: DropInState = {
      requestActive: true,
      dropInRequest: {
        requestedSessionId: action.sessionId,
        declinedMessage: undefined
      }
    };

    return dropInState;
  }),
  on(fromDropIn.dropInLessonRequestAcceptedAction, (_state, _action): DropInState => {
    const dropInState: DropInState = {
      requestActive: false,
      dropInRequest: undefined
    };

    return dropInState;
  }),
  on(fromDropIn.dropInLessonRequestDeclinedAction, (state, action): DropInState => {
    const request = state.dropInRequest;

    if (request) {

      const dropInState: DropInState = {
        requestActive: false,
        dropInRequest: {
          requestedSessionId: request.requestedSessionId,
          declinedMessage: action.message
        }
      };

      return dropInState;
    }

    return state;
  }),
  on(fromDropIn.dropInLessonRequestCancelledAction, (_state, _action): DropInState => {
    const dropInState: DropInState = {
      requestActive: false,
      dropInRequest: undefined
    };

    return dropInState;
  })
);
