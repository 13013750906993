import { Location } from '@angular/common';
import { HttpStatusCode } from '@angular/common/http';
import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, inject,
  OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { NavigationError, NavigationStart, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService, ConfigId } from 'auth-lib';
import { SupportedDeviceInfo, SupportedDeviceService } from 'device-information-lib';
import { PwaService } from 'pwa-lib';
import { Observable, Subscription } from 'rxjs';
import { ProfileService, StudentSettings, Theme, UserProfile } from 'ui-common-lib';

import { environment } from '../environments';
import {
  CommonRoutes, ConfigService, DeviceOrientationDirective, Orientation, RouterService, StudentRoutes, TutorRoutes
} from './shared';
import { fromOverlaysBackgroundColor, OverlaysBackgroundColorState, selectOverlaysBackgroundColorState } from './store';

@Component({
  selector: 'kip-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  readonly #location = inject(Location);
  readonly #authService = inject(AuthService);
  readonly #profileService = inject(ProfileService);
  readonly #router = inject(Router);
  readonly #routerService = inject(RouterService);
  readonly #pwaService = inject(PwaService);
  readonly #supportedDeviceService = inject(SupportedDeviceService);
  readonly #store = inject(Store<OverlaysBackgroundColorState>);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #configService = inject(ConfigService);

  readonly #subscription = new Subscription();
  #userProfile: UserProfile | undefined;
  #isAuthenticated = false;
  #viewHeight = 0;
  #subscriptions: Subscription[] = [];
  #isAnonymous = false;

  isStudent = false;
  isTutor = false;
  isLoaded = false;
  currentDevice: SupportedDeviceInfo;
  orientation!: string;
  logoutMessage: string | null = null;
  overlaySelector?: Observable<OverlaysBackgroundColorState>;
  readonly whiteLabel = environment.whiteLabel?.toLowerCase();
  readonly classLinkEnabled = environment.classLinkEnabled;
  readonly cleverEnabled = environment.cleverEnabled;

  theme = Theme.Default;
  authError = false;

  get isAuthenticatedOrRouteAnonymouslyAccessible() {
    return this.#authService.authDisabled || this.#isAuthenticated || this.#isAnonymous;
  }

  get isInMaintenance() {
    return this.#profileService.isInMaintenance;
  }

  @ViewChild(DeviceOrientationDirective) deviceOrientation!: DeviceOrientationDirective;

  constructor() {

    // Subscribe to the router events so diagnostics can be logged
    this.#subscriptions.push(
      this.#routerService.router.events.subscribe(event => {

        this.isStudent = this.#routerService.router.url.startsWith(`/${StudentRoutes.Student}`) || this.#routerService.router.url.startsWith(`/${CommonRoutes.CreditCard}`);
        this.isTutor = this.#routerService.router.url.startsWith(`/${TutorRoutes.Tutor}`);

        // Log the start and error events - should be enough for diagnosis
        if (event instanceof NavigationStart || event instanceof NavigationError) {
          const routerLog: { type: string, url: string, error?: any, profile?: { userId: number, roles: readonly string[] } } = {
            type: 'router',
            url: event.url
          };

          if (event instanceof NavigationError) {
            const err: NavigationError = event;
            /*eslint-disable @typescript-eslint/no-unsafe-assignment */
            routerLog.error = err.error;
          }

          if (this.#userProfile) {
            routerLog.profile = {
              userId: this.#userProfile.userId,
              roles: this.#userProfile.roles
            };
          }
        }
      }));

    // Set information about current device and browser
    this.currentDevice = this.#supportedDeviceService.getCurrentDeviceInfo();
  }

  ngOnInit() {
    this.logoutMessage = this.#authService.logoutMessage;
    this.#authService.clearLogoutMessage();
    this.overlaySelector = this.#store.select(selectOverlaysBackgroundColorState);
    const sub = this.#profileService.trackUserProfile().subscribe(data => {
      if (data?.settings?.overlays || data?.settings?.backgrounds) {
        this.#store.dispatch(fromOverlaysBackgroundColor.setColorAction({
          backgrounds: data?.settings?.backgrounds,
          overlays: data?.settings?.overlays
        }));
        sub.unsubscribe();
      }
    });
    this.#subscription.add(sub);

    if (!this.currentDevice.supported) {
      this.isLoaded = true;
      return;
    }

    const route = this.#sanitizeRoute(this.#location.path());

    switch (route) {
      case this.#sanitizeRoute(CommonRoutes.Upload):
        this.#disableAuthGetConfig();
        return;
      case this.#sanitizeRoute(CommonRoutes.UploadActivityFiles):
        this.#disableAuthGetConfig();
        return;
      case this.#sanitizeRoute(CommonRoutes.CreateActivity):
        this.#disableAuthGetConfig();
        return;
      case this.#sanitizeRoute(CommonRoutes.CreditCard):
        this.#disableAuthGetConfig();
        return;
      case this.#sanitizeRoute(CommonRoutes.ClasslinkInstantLoginRedirect):
        this.#setAnonymousAndLoaded();
        return;
      case this.#sanitizeRoute(CommonRoutes.CleverInstantLoginRedirect):
        this.#setAnonymousAndLoaded();
        return;
      default:
        break;
    }

    this.#changeDetectorRef.markForCheck();

    this.#subscriptions.push(
      this.#authService.checkAuth().subscribe(
        {
          next: isAuthenticated => {
            const authorizedChanged = this.isAuthenticatedOrRouteAnonymouslyAccessible !== isAuthenticated;
            this.#isAuthenticated = isAuthenticated;

            this.isLoaded = true;

            this.#changeDetectorRef.markForCheck();

            if (!this.isAuthenticatedOrRouteAnonymouslyAccessible) {
              return;
            }

            if (authorizedChanged) {

              this.#subscriptions.push(
                this.#profileService.trackUserProfile().subscribe(profile => {

                  if (profile) {
                    const studentSettings = profile.settings as StudentSettings;
                    if (studentSettings.theme) {
                      this.theme = studentSettings.theme;
                    }
                    this.#changeDetectorRef.markForCheck();
                  }
                }),

                // Only redirect if authorization status changed.
                // This stops an issue where users were being thrown to
                // dashboard on silent renew.

                this.#profileService.fetchProfile().subscribe({
                  next: () => {
                    this.#subscriptions.push(
                      this.#profileService.getDownTime.subscribe(() => {
                        this.#changeDetectorRef.markForCheck();
                      }),
                      this.#profileService
                        .getUserProfile()
                        .subscribe(profile => {
                          this.#userProfile = profile;
                          // Otherwise use the profile to determine the url
                          this.#routerService.navigateByPath(profile);
                          this.#changeDetectorRef.markForCheck();
                        }));
                  },
                  error: (error: { status: number }) => {
                    if (error.status === HttpStatusCode.Forbidden) {
                      this.#router.navigate([CommonRoutes.Forbidden]);
                    }
                    this.#changeDetectorRef.markForCheck();
                  }
                }));
            }
            this.#changeDetectorRef.markForCheck();
          },
          error: () => {
            this.authError = true;
            this.#changeDetectorRef.markForCheck();
          }
        })
    );

    this.setViewHeight();
  }

  ngAfterViewInit() {
    if (this.deviceOrientation) {

      const updateOrientation = (o: Orientation) => {
        this.orientation = o;
        this.#changeDetectorRef.markForCheck();
      };

      if (this.deviceOrientation.orientation !== undefined) {
        const orientation = this.deviceOrientation.orientation;
        setTimeout(() => updateOrientation(orientation), 0);
      }

      this.#subscription.add(this.deviceOrientation.orientationChange
        .subscribe({
          next: (o: Orientation) => updateOrientation(o)
        }));
    }
  }

  ngOnDestroy() {
    this.#subscription.unsubscribe();
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler() {
    // This is being used to prevent router navigation events from being triggered
    // on a browser refresh or url change
    this.#routerService.setWindowUnloading();
  }

  @HostListener('window:resize')
  setViewHeight() {
    this.#viewHeight = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${this.#viewHeight}px`);
  }

  login() {
    this.authError = false;
    this.#authService.login(ConfigId.Default);
  }

  classLinkLogin() {
    this.authError = false;
    this.#authService.login(ConfigId.ClassLink);
  }

  cleverLogin() {
    this.authError = false;
    this.#authService.login(ConfigId.Clever);
  }

  #disableAuthGetConfig() {
    this.#authService.authDisabled = true;
    this.#pwaService.disablePrompt = true;
    this.#subscriptions.push(
      this.#configService.config$.subscribe(() => {
        this.isLoaded = true;
        this.#changeDetectorRef.markForCheck();
      }));
  }

  #setAnonymousAndLoaded() {
    this.#isAnonymous = true;
    this.isLoaded = true;
  }

  #sanitizeRoute(route: string): string {
    let sanitizedRoute = route || '';

    // Remove any leading /
    sanitizedRoute = sanitizedRoute.startsWith('/')
      ? sanitizedRoute.slice(1)
      : sanitizedRoute;

    // Also exclude any query string values
    const index = sanitizedRoute.indexOf('?');

    sanitizedRoute = index > -1
      ? sanitizedRoute.slice(0, Math.max(0, index))
      : sanitizedRoute;

    return sanitizedRoute.toLowerCase();
  }
}
