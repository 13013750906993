/* eslint @typescript-eslint/no-dynamic-delete : 0 */

import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';

import { LessonQuestion, LessonQuestionAttempt } from '../../../shared';
import { QuestionEntity, QuestionEntityState } from '../models';
import * as QuestionActions from './question.actions';

export interface QuestionState {
  readonly entityIds: number[];
  readonly entities: {
    readonly [questionId: number]: QuestionEntity;
  };
  readonly entityState: {
    [questionGuid: string]: QuestionEntityState;
  };
  readonly entityStateGuids: {
    [lessonGuid: string]: string[];
  };
}

interface EntityContext {
  entity: QuestionEntity;
  entityState: QuestionEntityState;
}

const initialState: QuestionState = {
  entityIds: [],
  entities: {},
  entityState: {},
  entityStateGuids: {}
};

function calculateDuration(startTime: Date | undefined): number | undefined {
  if (startTime) {
    const start = moment.utc(startTime);

    // If a valid start time is found, calculate the diff to now and set the time taken
    if (start.isValid()) {
      const now = moment.utc();
      return now.diff(start, 'seconds');
    }
  }

  return undefined;
}

function getQuestionEntityContext(question: LessonQuestion): EntityContext {

  // Create the base entity object
  const entity: QuestionEntity = {
    questionId: question.questionId,
    contentGuid: question.contentGuid,
    skillBuilderActivityId: question.skillbuilderActivityId
  };

  let startTime = question.startTime;
  let duration = question.duration ?? 0;

  // Calculate the duration if a start time is provided
  // This is so the timers count from now and not from when the start time kicked in

  if (startTime && duration <= 0) {
    const offset = calculateDuration(startTime) ?? 0;

    duration += offset;
    startTime = moment.utc().toDate();
  }

  const entityState: QuestionEntityState = {
    questionId: question.questionId,
    duration: duration ?? 0,
    visited: question.visited ?? false,
    skipped: question.skipped ?? false,
    correct: question.correct ?? false,
    aiChatCount: question.aiChatCount,
    attempts: question.attempts ?? [],
    currentAttempt: undefined,
    hasWhiteboard: question.hasWhiteboard ?? false,
    startTime: startTime
  };

  return {
    entity: entity,
    entityState: entityState
  };
}

function updateQuestionEntityState(
  questionGuid: string, state: QuestionState, update: (entityState: QuestionEntityState) => Partial<QuestionEntityState>): QuestionState {

  let questionState = state.entityState[questionGuid];

  if (questionState) {
    const updateState = update(questionState);

    if (updateState) {
      questionState = Object.assign({}, questionState, updateState);

      return {
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [questionGuid]: questionState },
        entityStateGuids: state.entityStateGuids
      };
    }
  }

  return state;
}

export const questionReducer = createReducer(initialState,
  on(QuestionActions.loadManyAction, (state, action) => {
    let entityIds = state.entityIds;
    let entities = state.entities;
    let entityState = state.entityState;
    let entityStateGuids = state.entityStateGuids;

    for (const context of action.contexts) {
      const questionGuids = entityStateGuids[context.lessonGuid] || [];
      const entityContext = getQuestionEntityContext(context.question);

      // Only add the question if it is not already in the store
      if (!entityIds.includes(context.question.questionId)) {
        entityIds = [...entityIds, context.question.questionId];
        entities = { ...entities, [context.question.questionId]: entityContext.entity };
      }

      // Add the question state
      entityState = { ...entityState, [context.question.questionGuid]: entityContext.entityState };

      // The question guids are also tracked for convenience during updates
      entityStateGuids = { ...entityStateGuids, [context.lessonGuid]: [...questionGuids, context.question.questionGuid] };
    }

    return {
      entityIds: entityIds,
      entities: entities,
      entityState: entityState,
      entityStateGuids: entityStateGuids
    };
  }),
  on(QuestionActions.updateAction, (state, action) => {

    // Update the entity state
    let entityIds = state.entityIds;
    let entities = state.entities;
    let entityState = state.entityState;

    for (const question of action.questions) {
      const entityContext = getQuestionEntityContext(question);

      // If the question is not in the store, add it
      if (!entityIds.includes(question.questionId)) {
        entityIds = [...entityIds, question.questionId];
        entities = { ...entities, [question.questionId]: entityContext.entity };
      }

      // Update the question state
      let questionState = entityState[question.questionGuid];
      questionState = Object.assign({}, questionState, entityContext.entityState);

      entityState = { ...entityState, [question.questionGuid]: questionState };
    }

    // Remove the state for any questions no longer part of the lesson
    const stateGuids = state.entityStateGuids[action.lessonGuid] || [];
    const questionGuids = action.questions.map(question => question.questionGuid);

    for (const stateGuid of stateGuids) {
      if (!questionGuids.includes(stateGuid)) {
        delete entityState[stateGuid];
      }
    }

    return {
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: entityState,
      entityStateGuids: { ...state.entityStateGuids, [action.lessonGuid]: questionGuids }
    };
  }),
  on(QuestionActions.openAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      visited: true
    }));
  }),
  on(QuestionActions.whiteboardDrawnAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      hasWhiteboard: true
    }));
  }),
  on(QuestionActions.answerAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, questionState => {
      const attempt: LessonQuestionAttempt = {
        answers: action.answers
      };

      return {
        currentAttempt: undefined,
        correct: action.correct,
        attempts: [...questionState.attempts, attempt]
      };
    });
  }),
  on(QuestionActions.introductionAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      introductionViewTime: moment.utc().toDate()
    }));
  }),
  on(QuestionActions.workedSolutionAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      workedSolutionViewTime: moment.utc().toDate()
    }));
  }),
  on(QuestionActions.startTimerAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({
      startTime: moment.utc().toDate()
    }));
  }),
  on(QuestionActions.endTimerAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, questionState => {
      const duration = calculateDuration(questionState.startTime);

      if (duration) {
        return {
          duration: questionState.duration + duration,
          startTime: undefined
        };
      }

      return {};
    });
  }),
  on(QuestionActions.skipAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({ currentAttempt: undefined, skipped: true }));
  }),
  on(QuestionActions.closeAction, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, () => ({ currentAttempt: undefined }));
  }),
  on(QuestionActions.answerUpdatedAction, (state, action) =>
    updateQuestionEntityState(action.questionGuid, state, () => {
      const attempt: LessonQuestionAttempt = {
        answers: action.answers,
        correct: action.correct
      };

      return {
        currentAttempt: attempt
      };
    })
  ),
  on(QuestionActions.aiChat, (state, action) => {
    return updateQuestionEntityState(action.questionGuid, state, questionState => {
      return {
        aiChatCount: questionState.aiChatCount + 1
      };
    });
  }));

