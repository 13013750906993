/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BundleSelected, LessonEnrolmentSessionSchedule, LessonEnrolmentStage } from 'enrolment-lib';
import { EventType, LessonChat, LessonLog, MessagingService as MessagingServiceBase } from 'message-lib';
import { DateStruct } from 'moment-extensions-lib';
import { AnswerType } from 'questions-lib';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BillingIntervalType, LessonActivityPlanFile, LessonStatus, LessonType, Subject, VideoEffectType } from 'ui-common-lib';
import { WhiteboardEvent, WhiteboardGridType } from 'whiteboard-lib';

import { DIYUpdateResult, Lesson } from '../../shared';
import { ScoredActivity, SubjectGrades } from '../models';
import { fromActivity, fromAiChat, fromChat, fromLesson, fromLog, fromWhiteboard, StudentState } from '../store';
import { AiChatQuestion } from '../store/ai-chat';

@Injectable({
  providedIn: 'root'
})
export class MessagingService extends MessagingServiceBase {

  readonly #store = inject(Store<StudentState>);

  readonly #keyLimits = new Map<string, number>();

  constructor() {
    super('Student', 'lesson');
    this.init();
  }

  init() {
    this.profileService.getUserProfile().subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  override connect(): Observable<void> {

    // Ensure the message handlers are registered
    return super.connect().pipe(
      tap(() => {
        this.receiveEvent('SessionOpened', this.#store,
          {
            action: fromLesson.openSessionAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('SessionClosed', this.#store,
          {
            action: fromLesson.closeSessionAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('SessionDisconnected', this.#store,
          {
            action: fromLesson.disconnectSessionAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('JoinLessonCompleted', this.#store,
          {
            action: fromLesson.joinLessonCompletedAction,
            converter: (args: [string, string]) =>
            ({
              lessonGuid: args[0],
              connectionId: args[1]
            })
          });

        this.receiveEvent('ForceReload', this.#store,
          {
            action: fromLesson.forceReloadAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('ForceReloadVideo', this.#store,
          {
            action: fromLesson.forceReloadVideoAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('UpdateLessonDoItYourself', this.#store,
          {
            action: fromLesson.updateLessonDoItYourselfAction,
            converter: (args: [string, boolean, LessonType, LessonStatus, DIYUpdateResult]) =>
            ({
              lessonGuid: args[0],
              isOnline: args[1],
              lessonType: args[2],
              lessonStatus: args[3],
              updateResult: args[4]
            })
          });

        this.receiveEvent('ActivityFilesAdded', this.#store, {
          action: fromActivity.updateFilesAction,
          converter: (args: [string, string, LessonActivityPlanFile[]]) =>
          ({
            activityGuid: args[0],
            files: args[2]
          })
        });

        this.receiveEvent('PullFromLobby', this.#store,
          {
            action: fromLesson.pullFromLobbyAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('ResetPassword', this.#store,
          {
            action: fromLesson.resetPassword,
            converter: (args: [string, boolean]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('OnlineToggled', this.#store,
          {
            action: fromLesson.toggleOnlineAction,
            converter: (args: [string, boolean]) =>
            ({
              lessonGuid: args[0],
              isOnline: args[1]
            })
          });
        this.receiveEvent('VideoToggled', this.#store,
          {
            action: fromLesson.toggleVideoAction,
            converter: (args: [string, boolean]) =>
            ({
              lessonGuid: args[0],
              publish: args[1]
            })
          });
        this.receiveEvent('AudioToggled', this.#store,
          {
            action: fromLesson.toggleAudioAction,
            converter: (args: [string, boolean]) =>
            ({
              lessonGuid: args[0],
              publish: args[1]
            })
          });

        this.receiveEvent('StartTeaching', this.#store, {
          action: fromLesson.startTeachingAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });
        this.receiveEvent('StopTeaching', this.#store, {
          action: fromLesson.stopTeachingAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('StartTeachingClass', this.#store, {
          action: fromLesson.startTeachingClassAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('StopTeachingClass', this.#store, {
          action: fromLesson.stopTeachingClassAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEventSimple('TutorPublishAllowed', this.#store, fromLesson.tutorPublishAllowedAction);
        this.receiveEventSimple('TutorPublishDenied', this.#store, fromLesson.tutorPublishDeniedAction);
        this.receiveEvent('TutorPublishFailure', this.#store,
          {
            action: fromLesson.tutorPublishFailureAction,
            converter: (args: [string, string]) =>
            ({
              name: args[0],
              message: args[1]
            })
          });

        this.receiveEvent('StudentScreenSharePublishAllowed', this.#store,
          {
            action: fromLesson.screenSharePublishAllowedAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('StudentScreenSharePublishDenied', this.#store,
          {
            action: fromLesson.screenSharePublishDeniedAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('StudentScreenSharePublishFailure', this.#store,
          {
            action: fromLesson.screenSharePublishFailureAction,
            converter: (args: [string, string, string]) =>
            ({
              lessonGuid: args[0],
              name: args[1],
              message: args[2]
            })
          });
        this.receiveEvent('StudentScreenShareStreamDestroyed', this.#store,
          {
            action: fromLesson.screenShareStreamDestroyedAction,
            converter: (args: [string, string]) =>
            ({
              lessonGuid: args[0],
              reason: args[1]
            })
          });

        this.receiveEvent('StudentPublishAllowed', this.#store,
          {
            action: fromLesson.publishAllowedAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('StudentPublishDenied', this.#store,
          {
            action: fromLesson.publishDeniedAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('StudentPublishFailure', this.#store,
          {
            action: fromLesson.publishFailureAction,
            converter: (args: [string, string, string]) =>
            ({
              lessonGuid: args[0],
              name: args[1],
              message: args[2]
            })
          });
        this.receiveEvent('StudentSubscribeFailure', this.#store,
          {
            action: fromLesson.subscribeFailureAction,
            converter: (args: [string, string, string]) =>
            ({
              lessonGuid: args[0],
              name: args[1],
              message: args[2]
            })
          });
        this.receiveEvent('StudentStreamDestroyed', this.#store,
          {
            action: fromLesson.streamDestroyedAction,
            converter: (args: [string, string]) =>
            ({
              lessonGuid: args[0],
              reason: args[1]
            })
          });
        this.receiveEvent('StudentDetailsUpdated', this.#store,
          {
            action: fromLesson.studentDetailsUpdatedAction,
            converter: (args: [string, string, number | null]) =>
            ({
              givenName: args[0],
              familyName: args[1],
              regionId: args[2]
            })
          });

        this.receiveEvent('StartObserving', this.#store,
          {
            action: fromLesson.startObservingAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('StopObserving', this.#store,
          {

            action: fromLesson.stopObservingAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('VideoEffectRequested', this.#store,
          {
            action: fromLesson.videoEffectRequestedAction,
            converter: (args: [VideoEffectType, string]) =>
            ({
              videoEffectType: args[0],
              lessonGuid: args[1]
            })
          });
        this.receiveEvent('ScreenShareSettingUpdated', this.#store,
          {
            action: fromLesson.screenShareSettingUpdatedAction,
            converter: (args: [boolean, string]) =>
            ({
              disableScreenShare: args[0],
              lessonGuid: args[1]
            })
          });

        this.receiveEvent('AwardGiven', this.#store,
          {
            action: fromLesson.awardGivenAction,
            converter: (args: [number, string]) =>
            ({
              lessonId: args[0],
              lessonGuid: args[1]
            })
          });
        this.receiveEvent('HelpAcknowledged', this.#store,
          {
            action: fromLesson.acknowledgeHelpAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('HelpCleared', this.#store,
          {
            action: fromLesson.clearHelpAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });
        this.receiveEvent('LessonPlanUpdated', this.#store,
          {
            action: fromLesson.updateAction,
            converter: (args: [Lesson]) => ({
              lesson: args[0]
            })
          });
        this.receiveEvent('ActivityOpenRequested', this.#store, {
          action: fromActivity.requestOpenAction,
          converter: (args: [string, string, string]) => ({
            activityGuid: args[0],
            questionGuid: args[1],
            pageGuid: args[2]
          })
        });

        this.receiveEvent('ActivityCloseRequested', this.#store, {
          action: fromActivity.requestCloseAction,
          converter: (args: [string]) => ({
            activityGuid: args[0]
          })
        });

        this.receiveEvent('AssessmentResultsOpenRequested', this.#store,
          {
            action: fromLesson.openAssessmentResultsAction,
            converter: (args: [string, number, BundleSelected]) =>
            ({
              lessonGuid: args[0],
              lessonId: args[1],
              bundleSelected: args[2]
            })
          });
        this.receiveEvent('AssessmentResultTypeOpenRequested', this.#store,
          {
            action: fromLesson.openAssessmentResultTypeAction,
            converter: (args: [string, number]) =>
            ({
              lessonGuid: args[0],
              resultType: args[1]
            })
          });
        this.receiveEvent('AssessmentHomeOpenRequested', this.#store,
          {

            action: fromLesson.openAssessmentHomeAction,
            converter: (args: [string, number]) =>
            ({
              lessonGuid: args[0],
              lessonId: args[1]
            })
          });
        this.receiveEvent('AssessmentExitRequested', this.#store,
          {
            action: fromLesson.exitAssessmentAction,
            converter: (args: [string]) =>
            ({
              lessonGuid: args[0]
            })
          });

        this.receiveEvent('LessonEnrolmentOpenRequested', this.#store,
          {
            action: fromLesson.openLessonEnrolmentAction,
            converter: (args: [string, number]) =>
            ({
              lessonGuid: args[0],
              lessonId: args[1]
            })
          });
        this.receiveEvent('LessonEnrolmentProgressUpdated', this.#store,
          {
            action: fromLesson.updateEnrolmentProgressAction,
            converter: (args: [string, LessonEnrolmentStage]) =>
            ({
              lessonGuid: args[0],
              enrolmentStage: args[1]
            })
          });
        this.receiveEvent('LessonEnrolmentSubjectsUpdated', this.#store,
          {
            action: fromLesson.updateEnrolmentSubjectsAction,
            converter: (args: [string, Subject[]]) =>
            ({
              lessonGuid: args[0],
              subjects: args[1]
            })
          });
        this.receiveEvent('LessonEnrolmentSessionsUpdated', this.#store, {
          action: fromLesson.updateEnrolmentSessionsAction,
          converter: (args: [string, LessonEnrolmentSessionSchedule[]]) =>
          ({
            lessonGuid: args[0],
            sessions: args[1]
          })
        });
        this.receiveEvent('LessonEnrolmentBundleUpdated', this.#store,
          {
            action: fromLesson.updateEnrolmentBundleAction,
            converter: (args: [string, number, BillingIntervalType, boolean]) =>
            ({
              lessonGuid: args[0],
              bundleOfferId: args[1],
              billingInterval: args[2],
              customerRepresentsOrganisation: args[3]
            })
          });
        this.receiveEvent('LessonEnrolmentBundleTypeUpdated', this.#store,
          {
            action: fromLesson.updateEnrolmentBundleTypeAction,
            converter: (args: [string, number]) =>
            ({
              lessonGuid: args[0],
              bundleOfferTypeId: args[1]
            })
          });
        this.receiveEvent('LessonEnrolmentBundleCurrencyUpdated', this.#store, {
          action: fromLesson.updateEnrolmentBundleCurrencyAction,
          converter: (args: [string, string]) => ({
            lessonGuid: args[0],
            bundleCurrency: args[1]
          })
        });
        this.receiveEvent('LessonEnrolmentBundleSelected', this.#store, {
          action: fromLesson.updateEnrolmentBundleAction,
          converter: (args: [string, number, BillingIntervalType, boolean]) => ({
            lessonGuid: args[0],
            bundleOfferId: args[1],
            billingInterval: args[2],
            customerRepresentsOrganisation: args[3]
          })
        });

        this.receiveEvent('LessonEnrolmentPaymentOptionsUpdated', this.#store,
          {
            action: fromLesson.updateEnrolmentPaymentOptionsAction,
            converter: (args: [string, number, DateStruct, number]) => ({
              lessonGuid: args[0],
              debitDay: args[1],
              startDate: args[2],
              upfrontPaymentOption: args[3]
            })
          });
        this.receiveEvent('LessonEnrolmentPaymentEntrySelected', this.#store, {
          action: fromLesson.updateEnrolmentPaymentEntryAction,
          converter: (args: [string, boolean]) => ({
            lessonGuid: args[0],
            assessorEnteringPayment: args[1]
          })
        });
        this.receiveEvent('LessonEnrolmentAccountCreated', this.#store, {
          action:
            fromLesson.updateEnrolmentAccountCreatedAction,
          converter: (args: [string]) => ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('InstallApp', this.#store, {
          action:
            fromLesson.installAppAction,
          converter: (args: [string]) => ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('WhiteboardEvent', this.#store, {
          action: fromWhiteboard.receiveAction,
          converter: (args: [string, string, string, WhiteboardEvent]) =>
          ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] },
            event: args[3]
          })
        });
        this.receiveEvent('WhiteboardGridType', this.#store, {
          action: fromWhiteboard.receiveGridTypeAction,
          converter: (args: [string, string, string, WhiteboardGridType]) => ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] },
            gridType: args[3]
          })
        });
        this.receiveEvent('WhiteboardOpenRequested', this.#store, {
          action: fromWhiteboard.receiveOpenAction,
          converter: (args: [string, string, string]) =>
          ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] }
          })
        });
        this.receiveEvent('WhiteboardCloseRequested', this.#store, {
          action: fromWhiteboard.receiveCloseAction,
          converter: (args: [string, string, string]) => ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] }
          })
        });
        this.receiveEvent('Log', this.#store, {
          action: fromLog.addAction,
          converter: (args: [string, LessonLog]) => ({
            lessonGuid: args[0],
            log: args[1]
          })
        });
        this.receiveEvent('AiChat', this.#store, {
          action: fromAiChat.addAction,
          converter: (args: [string, string]) =>
          ({
            message: args[1],
            chatGroup: args[0]
          })
        });
        this.receiveEvent('Chat', this.#store, {
          action: fromChat.addAction,
          converter: (args: [string, LessonChat]) =>
          ({
            lessonGuid: args[0],
            chatMessages: args[1]
          })
        });
        this.receiveEvent('ChatUpdateTyping', this.#store, {
          action: fromChat.updateTypingAction,
          converter: (args: [string, boolean]) =>
          ({
            lessonGuid: args[0],
            isTyping: args[1]
          })
        });
        this.receiveEvent('ActivityScored', this.#store, {
          action: fromActivity.scoredAction,
          converter: (args: [number, string, ScoredActivity]) => ({
            lessonId: args[0],
            lessonGuid: args[1],
            scoredActivity: args[2]
          })
        });
        this.receiveEvent('DropInLessonCompleted', this.#store, {
          action: fromLesson.dropInLessonCompletedAction,
          converter: (args: [string]) => ({
            lessonGuid: args[0]
          })
        });
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/require-await, @typescript-eslint/no-misused-promises
  async onReconnect() {
    // Dispatching this action rejoins the student to the group on the server.
    // It also causes a message to be pushed into the queue, which will in turn
    // flush any buffered messages from while we were disconnected.
    this.#store.dispatch(fromLesson.reconnectAction());
  }

  startedLookingTab(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StartedLookingTab', this.#store, { lessonGuid: lessonGuid });
  }

  stoppedLookingTab(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StoppedLookingTab', this.#store, { lessonGuid: lessonGuid });
  }

  startedInteracting(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StartedInteracting', this.#store, { lessonGuid: lessonGuid });
  }

  stoppedInteracting(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StoppedInteracting', this.#store, { lessonGuid: lessonGuid });
  }

  autoLoggedOutOnIdle(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StudentAutoLoggedOutOnIdle', this.#store, { lessonGuid: lessonGuid });
  }

  gradesAchieved(lessonGuid: string, subjects: SubjectGrades[]): Observable<string> {
    return this.sendEventWrapper('GradesAchieved', this.#store, { lessonGuid: lessonGuid, subjects: subjects });
  }

  lobbyOpened(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('LobbyOpened', this.#store, { lessonGuid: lessonGuid });
  }

  lobbyClosed(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('LobbyClosed', this.#store, { lessonGuid: lessonGuid });
  }

  lessonOpened(lessonGuid: string, isHomeworkOnly: boolean): Observable<string> {
    return this.sendEventWrapper('LessonOpened', this.#store, { lessonGuid: lessonGuid, deviceData: JSON.stringify(this.deviceData), isHomeworkOnly: isHomeworkOnly });
  }

  updateFinishLessonNote(lessonGuid: string, note: string, noteRanking: number | null): Observable<string> {
    return this.sendEventWrapper('UpdateFinishLessonNote', this.#store, { lessonGuid: lessonGuid, note: note, noteRanking: noteRanking });
  }

  updateStartLessonNote(lessonGuid: string, note: string, noteRanking: number | null): Observable<string> {
    return this.sendEventWrapper('UpdateStartLessonNote', this.#store, { lessonGuid: lessonGuid, note: note, noteRanking: noteRanking });
  }

  lessonClosed(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('LessonClosed', this.#store, { lessonGuid: lessonGuid });
  }

  lessonFinished(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('LessonFinished', this.#store, { lessonGuid: lessonGuid });
  }

  chat(lessonGuid: string, message: string, chatGroup: string, warning: boolean): Observable<string> {
    return this.sendEventWrapper('StudentChat', this.#store, { lessonGuid: lessonGuid, message: message, chatGroup: chatGroup, warning: warning });
  }

  aiChat(message: string, chatGroup: string, lessonGuid: string, lessonId: number | null, tutorName: string | null, lessonActivityPlanId: number | null, pdf: string | null,
    adHoc: string | null, question?: AiChatQuestion, restrictAiSubject = false, aiWithholdAnswer = false): Observable<string> {
    return this.sendEventWrapper('StudentAiChat', this.#store, {
      message: message, chatGroup: chatGroup, question: question, pdf: pdf, adHoc: adHoc,
      lessonId: lessonId, lessonActivityPlanId: lessonActivityPlanId, lessonGuid: lessonGuid,
      restrictAiSubject: restrictAiSubject, aiWithholdAnswer: aiWithholdAnswer, tutorName: tutorName
    });
  }

  chatTyping(lessonGuid: string, isTyping: boolean): Observable<string> {
    return this.sendEventWrapper('StudentChatUpdateTyping', this.#store, { lessonGuid: lessonGuid, isTyping: isTyping });
  }

  installAvailable(lessonGuid: string, available: boolean): Observable<string> {
    return this.sendEventWrapper('InstallAvailable', this.#store, { lessonGuid: lessonGuid, available: available });
  }

  helpRequested(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('HelpRequested', this.#store, { lessonGuid: lessonGuid });
  }

  activityOpened(lessonGuid: string, activityGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('ActivityOpened', this.#store, { lessonGuid: lessonGuid, activityGuid: activityGuid, previousLessonGuid: previousLessonGuid });
  }

  activityClosed(lessonGuid: string, activityGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('ActivityClosed', this.#store, { lessonGuid: lessonGuid, activityGuid: activityGuid, previousLessonGuid: previousLessonGuid });
  }

  activityPercentage(lessonGuid: string, activityGuid: string, percentage: number,
    ageResult: number | null, age: number | null): Observable<string> {
    return this.sendEventWrapper('ActivityPercentage', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, percentage: percentage, ageResult: ageResult, age: age });
  }

  activityStartFirstAttempt(lessonGuid: string, activityGuid: string, commenceDateTime: Date): Observable<string> {
    return this.sendEventWrapper('ActivityStartFirstAttempt', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, startDateTime: commenceDateTime });
  }

  activityStartSecondAttempt(lessonGuid: string, activityGuid: string, commenceDateTime: Date): Observable<string> {
    return this.sendEventWrapper('ActivityStartSecondAttempt', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, startDateTime: commenceDateTime });
  }

  activityFirstAttempt(lessonGuid: string, activityGuid: string, correct: number): Observable<string> {
    return this.sendEventWrapper('ActivityFirstAttempt', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, correct: correct });
  }

  activitySecondAttempt(lessonGuid: string, activityGuid: string, correct: number): Observable<string> {
    return this.sendEventWrapper('ActivitySecondAttempt', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, correct: correct });
  }

  activityStarted(lessonGuid: string, activityGuid: string, startedOn: Date): Observable<string> {
    return this.sendEventWrapper('ActivityStarted', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, startedOn: startedOn });
  }

  activityCompleted(lessonGuid: string, activityGuid: string, completedOn: Date | undefined): Observable<string> {
    return this.sendEventWrapper('ActivityCompleted', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, completedOn: completedOn });
  }

  activityTimeAlerted(lessonGuid: string, activityGuid: string, isWarning: boolean,
    helpRequested: boolean, minutesRemaining?: number): Observable<string> {
    return this.sendEventWrapper('ActivityTimeAlerted', this.#store, {
      lessonGuid: lessonGuid, activityGuid: activityGuid,
      isWarning: isWarning, helpRequested: helpRequested, minutesRemaining: minutesRemaining
    });
  }

  questionOpened(lessonGuid: string, questionGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('QuestionOpened', this.#store, { lessonGuid: lessonGuid, questionGuid: questionGuid, previousLessonGuid: previousLessonGuid });
  }

  questionSkipped(lessonGuid: string, activityGuid: string, questionGuid: string): Observable<string> {
    return this.sendEventWrapper('QuestionSkipped', this.#store, { lessonGuid: lessonGuid, activityGuid: activityGuid, questionGuid: questionGuid });
  }

  questionClosed(lessonGuid: string, questionGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('QuestionClosed', this.#store, { lessonGuid: lessonGuid, questionGuid: questionGuid, previousLessonGuid: previousLessonGuid });
  }

  questionIntroductionViewed(lessonGuid: string, questionGuid: string): Observable<string> {
    return this.sendEventWrapper('QuestionIntroductionViewed', this.#store, { lessonGuid: lessonGuid, questionGuid: questionGuid });
  }

  questionWorkedSolutionViewed(lessonGuid: string, questionGuid: string): Observable<string> {
    return this.sendEventWrapper('QuestionWorkedSolutionViewed', this.#store, { lessonGuid: lessonGuid, questionGuid: questionGuid });
  }

  questionAnswered(lessonGuid: string, questionGuid: string,
    answers: readonly AnswerType[], correct: boolean, score: number, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('QuestionAnswered', this.#store,
      { lessonGuid: lessonGuid, questionGuid: questionGuid, answers: answers, correct: correct, score: score, previousLessonGuid: previousLessonGuid });
  }

  questionUpdated(lessonGuid: string, questionGuid: string, answers: readonly AnswerType[], correct: boolean): Observable<string> {
    return this.sendEventWrapper('QuestionUpdated', this.#store,
      { lessonGuid: lessonGuid, questionGuid: questionGuid, answers: answers, correct: correct });
  }

  whiteboardGridType(lessonGuid: string, activityGuid: string, pageGuid: string, gridType: WhiteboardGridType, broadcastLessonGuid: string): Observable<string> {
    return this.sendWhiteboardGridType('student', lessonGuid, activityGuid, pageGuid, gridType, broadcastLessonGuid);
  }

  whiteboardEvent(lessonGuid: string, activityGuid: string, pageGuid: string, event: WhiteboardEvent, broadcastLessonGuid: string): Observable<string> {
    return this.sendWhiteboardEvent('student', this.#store, lessonGuid, activityGuid, pageGuid, event, broadcastLessonGuid);
  }

  whiteboardOpen(lessonGuid: string, activityGuid: string, pageGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('WhiteboardOpened', this.#store, {
      lessonGuid: lessonGuid,
      activityGuid: activityGuid,
      pageGuid: pageGuid,
      previousLessonGuid: previousLessonGuid
    });
  }

  whiteboardClose(lessonGuid: string, activityGuid: string, pageGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('WhiteboardClosed', this.#store,
      {
        lessonGuid: lessonGuid,
        activityGuid: activityGuid,
        pageGuid: pageGuid,
        previousLessonGuid: previousLessonGuid
      });
  }

  addDIY(lessonId: number, isHomework: boolean): Observable<string> {
    return this.sendEventWrapper('AddDIYActivitiesStudent', this.#store,
      { lessonId: lessonId, isHomework: isHomework });
  }

  newUnitRequired(activityGuid: string, unitId: number, lessonId: number): Observable<string> {
    return this.sendEventWrapper('NewUnitRequired', this.#store, { activityGuid: activityGuid, unitId: unitId, lessonId: lessonId });
  }

  updateLessonEnrolmentProgress(lessonGuid: string, stage: LessonEnrolmentStage) {
    return this.sendEventWrapper('LessonEnrolmentProgressUpdated', this.#store, { lessonGuid: lessonGuid, stage: stage });
  }

  updateLessonEnrolmentAccountCreated(lessonGuid: string) {
    return this.sendEventWrapper('LessonEnrolmentAccountCreated', this.#store, { lessonGuid: lessonGuid });
  }

  selectLessonEnrolmentBundle(lessonGuid: string, bundleOfferId: number | null, billingInterval: BillingIntervalType | null) {
    return this.sendEventWrapper('LessonEnrolmentBundleSelected', this.#store, {
      lessonGuid: lessonGuid, bundleOfferId: bundleOfferId,
      billingInterval: billingInterval
    });
  }

  updateLessonEnrolmentBundle(lessonGuid: string, bundleOfferId: number, billingInterval: BillingIntervalType) {
    return this.sendEventWrapper('LessonEnrolmentBundleUpdated', this.#store, {
      lessonGuid: lessonGuid, bundleOfferId: bundleOfferId,
      billingInterval: billingInterval
    });
  }

  removeActivityFile(lessonGuid: string, activityGuid: string, fileId: number) {
    return this.sendEventWrapper('ActivityFileRemoved', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, fileId: fileId });
  }

  renameActivityFile(lessonGuid: string, activityGuid: string, fileId: number, fileName: string) {
    return this.sendEventWrapper('ActivityFileRenamed', this.#store,
      { lessonGuid: lessonGuid, activityGuid: activityGuid, fileId: fileId, fileName: fileName });
  }

  // these tokbox messages can sometimes go nuts, so blocking them after 10 messages in a lesson

  screenShareStreamDestroyed(lessonGuid: string, reason: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentScreenShareStreamDestroyed', this.#store, lessonGuid, { lessonGuid: lessonGuid, reason: reason });
  }

  streamDestroyed(lessonGuid: string, reason: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentStreamDestroyed', this.#store, lessonGuid, { lessonGuid: lessonGuid, reason: reason });
  }

  subscribeFailure(lessonGuid: string, name: string, message: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentSubscribeFailure', this.#store, lessonGuid, { lessonGuid: lessonGuid, name: name, message: message });
  }

  screenSharePublishAllowed(lessonGuid: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentScreenSharePublishAllowed', this.#store, lessonGuid, { lessonGuid: lessonGuid });
  }

  screenSharePublishDenied(lessonGuid: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentScreenSharePublishDenied', this.#store, lessonGuid, { lessonGuid: lessonGuid });
  }

  screenSharePublishFailure(lessonGuid: string, name: string, message: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentScreenSharePublishFailure', this.#store, lessonGuid, { lessonGuid: lessonGuid, name: name, message: message });
  }

  publishFailure(lessonGuid: string, name: string, message: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentPublishFailure', this.#store, lessonGuid, { lessonGuid: lessonGuid, name: name, message: message });
  }

  publishAllowed(lessonGuid: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentPublishAllowed', this.#store, lessonGuid, { lessonGuid: lessonGuid });
  }

  publishDenied(lessonGuid: string): Observable<string> {
    return this.sendEventWrapperLimited('StudentPublishDenied', this.#store, lessonGuid, { lessonGuid: lessonGuid });
  }

  streamConnected(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StudentStreamConnected', this.#store, { lessonGuid: lessonGuid });
  }

  customActivityQuestionOpened(lessonGuid: string, activityGuid: string, pageGuid: string): Observable<string> {
    return this.sendEventWrapper('CustomActivityQuestionOpened', this.#store, { lessonGuid: lessonGuid, activityGuid: activityGuid, pageGuid: pageGuid });
  }

  customActivityQuestionClosed(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('CustomActivityQuestionClosed', this.#store, { lessonGuid: lessonGuid });
  }

  override onReceiveEventTrue() {
    this.#store.dispatch(fromLesson.updateSignalRConnectionStateAction({ connected: this.isConnectedAndReceivedEvent() }));
  }

  private sendEventWrapperLimited<TState>(name: EventType, store: Store<TState>, key: string, args: { [field: string]: any }) {
    const limitKey = `${name}-${key}`;
    const maxValue = 10;
    const value = this.#keyLimits.get(limitKey);

    if (value !== undefined) {
      if (value < maxValue) {
        this.#keyLimits.set(limitKey, value + 1);

        if (value === maxValue - 1) {
          console.log(`Messages blocked for ${name}`);
        }

        return this.sendEventWrapper(name, store, args);
      }
    } else {
      this.#keyLimits.set(limitKey, 1);
      return this.sendEventWrapper(name, store, args);
    }

    const promise = Promise.resolve('');

    return from(promise);
  }

  private sendEventWrapper<TState>(name: EventType, store: Store<TState>, args: { [field: string]: any }) {
    this.#store.dispatch(fromLesson.updateSignalRConnectionStateAction({ connected: this.isConnectedAndReceivedEvent() }));
    return this.sendEvent(name, store, args);
  }
}
