import { activityReducer } from './activity/activity.reducer';
import { aiChatReducer } from './ai-chat/ai-chat.reducer';
import { assistantReducer } from './assistant/assistant.reducer';
import { chatReducer } from './chat/chat.reducer';
import { comprehensionTestReducer } from './comprehension-test/comprehension-test.reducer';
import { deviceReducer } from './device/device.reducer';
import { dropInReducer } from './drop-in/drop-in.reducer';
import { ActivityEffects } from './effects/activity.effects';
import { ChatEffects } from './effects/chat.effects';
import { ComprehensionTestEffects } from './effects/comprehension-test.effects';
import { DeviceEffects } from './effects/device.effects';
import { DropInEffects } from './effects/drop-in.effects';
import { LessonEffects } from './effects/lesson.effects';
import { MessagingEffects } from './effects/messaging.effects';
import { QuestionEffects } from './effects/question.effects';
import { RouterEffects } from './effects/router.effects';
import { SoundOptionEffects } from './effects/sound-option.effects';
import { WhiteboardEffects } from './effects/whiteboard.effects';
import { lessonReducer } from './lesson/lesson.reducer';
import { logReducer } from './log/log.reducer';
import { questionReducer } from './question/question.reducer';
import { soundOptionReducer } from './sound-option/sound-option.reducer';
export * as fromActivity from './activity';
export * as fromAiChat from './ai-chat';
export * as fromAssistant from './assistant';
export * as fromCentre from './centre';
export * as fromChat from './chat';
export * as fromDevices from './device';
export * as fromDropIn from './drop-in';
export * as fromLesson from './lesson';
export * as fromLog from './log';
export * as fromQuestion from './question';
export * as fromState from './state';
export { StudentState } from './state';
export * as fromWhiteboard from './whiteboard';

export const effects = [
  RouterEffects,
  MessagingEffects,
  LessonEffects,
  QuestionEffects,
  WhiteboardEffects,
  ActivityEffects,
  ChatEffects,
  DeviceEffects,
  ComprehensionTestEffects,
  DropInEffects,
  SoundOptionEffects
];

export const reducers = {
  lessons: lessonReducer,
  activities: activityReducer,
  questions: questionReducer,
  logs: logReducer,
  chat: chatReducer,
  aiChat: aiChatReducer,
  assistant: assistantReducer,
  devices: deviceReducer,
  assessment: comprehensionTestReducer,
  dropIn: dropInReducer,
  soundOption: soundOptionReducer
};
