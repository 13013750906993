import { inject, Injectable, OnDestroy } from '@angular/core';
import { CustomActivityService } from 'activity-lib';
import * as pdfjs from 'pdfjs-dist';
import { PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist/types/src/display/api';
import { Subscription } from 'rxjs';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';
import { WhiteboardGuidKey } from 'whiteboard-lib';

export enum PageType { Pdf = 0, Blank = 1, Additional = 2 }

export interface PdfPage {
  readonly pageNumber: number;
  readonly pageGuid: string;
  readonly hasData: boolean;
  readonly pageType: PageType;
}

export interface PdfImage {
  readonly dataUrl: string;
  readonly page: number;
  readonly pages: readonly PdfPage[];
  readonly whiteboardGuidKey: WhiteboardGuidKey;
  readonly url: string;
}

@Injectable({ providedIn: 'root' })
export class PdfImageService extends HttpWithAbsoluteService implements OnDestroy {

  readonly #customActivityService = inject(CustomActivityService);
  #pdfProxy: PDFDocumentProxy | undefined;
  #pageProxy: PDFPageProxy | undefined;
  #url = '';
  #pages = 0;
  #subscriptions: Subscription[] = [];

  loadPdf(pdfFile: string, activityGuid: string, additionalPageGuids: readonly string[], url: string, pageNumber: number, pageGuids: readonly string[] | null | undefined = []): Promise<PdfImage> {
    const pageGuidsFixed = pageGuids ?? [];
    const promise: Promise<PdfImage> = new Promise<PdfImage>(resolve => {
      if (url && pageNumber) {
        resolve(this.#view(activityGuid, url, pageGuidsFixed, additionalPageGuids, pageNumber));
      } else {
        this.#subscriptions.push(
          this.getUrlAndDownloadWithErrorBlob(`assets/activity-pdf-url/${pdfFile}`, ErrorBlobPath.Pdf, pdfFile === null)
            .subscribe(blob => resolve(this.#view(activityGuid,
              blob.body ? URL.createObjectURL(blob.body) : '', pageGuidsFixed, additionalPageGuids, pageNumber))));
      }
    });

    return promise;
  }

  ngOnDestroy() {
    this.#destroy();
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  #loadUploadedFile(fileGuid: string) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/download-uploaded-content-url/${fileGuid}`, ErrorBlobPath.Image);
  }

  #view(whiteboardActivityGuid: string, url: string, pageGuids: readonly string[], additionalPageGuids: readonly string[], pageNumber: number): Promise<PdfImage> {
    const promise: Promise<PdfImage> = new Promise<PdfImage>(resolve => {
      if (this.#url !== url) {
        this.#url = url;
        pdfjs.GlobalWorkerOptions.workerSrc = `assets/pdf.worker.min.mjs?guid=${this.buildGuid}`;
        pdfjs.getDocument(url).promise.then((pdfProxy: PDFDocumentProxy) => {
          this.#pdfProxy = pdfProxy;
          this.#pages = pdfProxy.numPages;
          this.#navigateByPage(whiteboardActivityGuid, pageGuids, additionalPageGuids, pageNumber).then(data => {
            resolve(data);
          });
        });
      } else {
        this.#navigateByPage(whiteboardActivityGuid, pageGuids, additionalPageGuids, pageNumber).then(data => {
          resolve(data);
        });
      }
    });

    return promise;
  }

  #navigateByPage(whiteboardActivityGuid: string, pageGuids: readonly string[], additionalPageGuids: readonly string[], pageNumber: number): Promise<PdfImage> {

    const promise: Promise<PdfImage> = new Promise<PdfImage>(resolve => {
      let pagesArray: PdfPage[] = Array.from<number>({ length: this.#pages + 1 }).fill(0).map((_value, index) => {
        const whiteboardPageGuid = this.#customActivityService.getCustomActivityPageGuid(whiteboardActivityGuid, index + 1);

        const pdfPage: PdfPage = {
          pageNumber: index + 1,
          pageGuid: whiteboardPageGuid,
          hasData: pageGuids.includes(whiteboardPageGuid),
          pageType: index >= this.#pages ? PageType.Blank : PageType.Pdf
        };

        return pdfPage;
      });

      // Add any additional adhoc hoc items that have been uploaded to a manual activity
      pagesArray = [...pagesArray, ...additionalPageGuids.map((pageGuid, index) => {

        const customPageNumber = pagesArray.length + index + 1;
        const customPageGuid = this.#customActivityService.getCustomActivityPageGuid(pageGuid, customPageNumber);

        const pdfPage: PdfPage = {
          pageNumber: customPageNumber,
          pageGuid: customPageGuid,
          hasData: false,
          pageType: PageType.Additional
        };

        return pdfPage;
      })];

      const pdfImage: PdfImage = {
        page: pageNumber,
        dataUrl: '',
        url: this.#url,
        pages: pagesArray,
        whiteboardGuidKey: {
          activityGuid: whiteboardActivityGuid,
          pageGuid: this.#customActivityService.getCustomActivityPageGuid(whiteboardActivityGuid, pageNumber)
        }
      };

      const additionalPagesIndex = this.#pdfProxy ? pageNumber - this.#pdfProxy.numPages - 2 : -1;

      if (this.#pdfProxy && pageNumber > 0 && pageNumber <= this.#pdfProxy.numPages) {
        this.#pdfProxy.getPage(pageNumber).then((pageProxy: PDFPageProxy) => {
          this.#pageProxy = pageProxy;

          // The higher the scale, the better quality image

          const viewport = this.#pageProxy.getViewport({ scale: 3 });
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          if (context) {
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            this.#pageProxy.render({ canvasContext: context, viewport: viewport }).promise.then(() => {
              resolve({
                page: pageNumber,
                dataUrl: canvas.toDataURL(),
                url: this.#url,
                pages: pagesArray,
                whiteboardGuidKey: {
                  activityGuid: whiteboardActivityGuid,
                  pageGuid: this.#customActivityService.getCustomActivityPageGuid(whiteboardActivityGuid, pageNumber)
                }
              });
            });
          }
        });
      } else if (this.#pdfProxy && additionalPagesIndex >= 0 && additionalPageGuids.length > additionalPagesIndex) {
        const whiteboardPageGuid = additionalPageGuids[additionalPagesIndex];
        this.#subscriptions.push(
          this.#loadUploadedFile(whiteboardPageGuid).subscribe(blob => {
            resolve({
              page: pageNumber,
              dataUrl: blob.body ? URL.createObjectURL(blob.body) : '',
              url: this.#url,
              pages: pagesArray,
              whiteboardGuidKey: {
                activityGuid: whiteboardActivityGuid,
                pageGuid: this.#customActivityService.getCustomActivityPageGuid(whiteboardPageGuid, pageNumber)
              }
            });
          }
          ));
      } else {
        resolve(pdfImage);
      }
    });

    return promise;
  }

  #destroy() {
    if (this.#pageProxy) {
      this.#pageProxy = undefined;
    }

    if (this.#pdfProxy) {
      this.#pdfProxy.destroy();
      this.#pdfProxy = undefined;
    }
  }
}
