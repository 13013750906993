import { inject, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import * as moment from 'moment';
import { convertAgeToNumber, determineAgeComprehensionTest } from 'pipes-directives-lib';
import { currencySwapFunction } from 'questions-lib';
import { EMPTY, Observable, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import { AgeResult, ComprehensionTest } from 'ui-common-lib';

import { LessonActivityType } from '../../../shared';
import { LessonActivity, LessonQuestion, SelectedActivity, SelectedLesson } from '../../models';
import { LessonService, MessagingService } from '../../services';
import * as fromActivity from '../activity';
import * as fromLesson from '../lesson';
import * as fromQuestion from '../question';
import * as fromState from '../state';
import { StudentState } from '../state';

@Injectable()
export class QuestionEffects {

  readonly #domSanitizer = inject(DomSanitizer);
  readonly #lessonService = inject(LessonService);
  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<StudentState>);
  readonly #messagingService = inject(MessagingService);

  readonly maxAttemptsToBeCorrect = 1;

  workedSolution$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.workedSolutionAction),
      switchMap(action => this.#lessonService
        .getQuestionWorkedSolution(action.questionId, action.regionId)
        .pipe(
          switchMap(workedSolution => {
            const workedSolutionSanitized =
              workedSolution ? this.#domSanitizer.bypassSecurityTrustHtml(currencySwapFunction(workedSolution, action.regionId)) : null;

            const actions: Action[] = [
              fromQuestion.loadWorkedSolutionAction({
                questionGuid: action.questionGuid,
                workedSolution: workedSolutionSanitized
              })];

            return actions;

          })
        )
      )
    );
  });

  introduction$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.introductionAction),
      switchMap(action => this.#lessonService
        .getQuestionIntroduction(action.questionId, action.regionId)
        .pipe(
          switchMap(introduction => {
            const introductionSanitized =
              introduction ? this.#domSanitizer.bypassSecurityTrustHtml(currencySwapFunction(introduction, action.regionId)) : null;

            const actions: Action[] = [
              fromQuestion.loadIntroductionAction({
                questionGuid: action.questionGuid,
                introduction: introductionSanitized
              })];

            return actions;

          })
        )
      )
    );
  });

  open$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.openAction, fromQuestion.loadManyAction),
      concatLatestFrom(
        () => this.#store.select(fromQuestion.selectSelectedQuestion)
      ),
      switchMap(([_action, question]) => {
        const actions: Action[] = [];

        if (question) {

          // Add the start timer action to track the time taken
          actions.push(
            fromQuestion.startTimerAction({ questionGuid: question.questionGuid })
          );

          // If the question hasn't been visited, update the state to visited
          if (!question.visited) {
            actions.push(
              fromQuestion.visitAction({ questionGuid: question.questionGuid })
            );
          }

          return actions;
        }

        return EMPTY;
      })
    );
  });

  answer$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.answerAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSelectedLesson),
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(fromActivity.selectLessonActivities),
        this.#store.select(fromQuestion.selectActivityQuestions),
        this.#store.select(fromState.selectComprehensionTests)
      ),
      switchMap(([action, lesson, activity, lessonActivities, questions, comprehensionTests]) => {
        return this.#getAnswerOrSkipActions(lesson, activity, lessonActivities ?? [], questions, comprehensionTests, action.correct);
      })
    );
  });

  skip$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.skipAction),
      withLatestFrom(
        this.#store.select(fromLesson.selectSelectedLesson),
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(fromActivity.selectLessonActivities),
        this.#store.select(fromQuestion.selectActivityQuestions),
        this.#store.select(fromState.selectComprehensionTests)
      ),
      switchMap(([_action, lesson, activity, lessonActivities, questions, comprehensionTests]) => {
        return this.#getAnswerOrSkipActions(lesson, activity, lessonActivities ?? [], questions, comprehensionTests, false);
      })
    );
  });

  close$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.closeAction),
      switchMap(action => of(
        fromQuestion.endTimerAction({ questionGuid: action.questionGuid })
      ))
    );
  });

  openCustomActivityQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.openCustomActivityQuestionAction),
      switchMap(action => this.#messagingService.customActivityQuestionOpened(action.lessonGuid, action.whiteboardGuidKey.activityGuid, action.whiteboardGuidKey.pageGuid)
        .pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  closeCustomActivityQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.closeCustomActivityQuestionAction),
      switchMap(action => this.#messagingService.customActivityQuestionClosed(action.lessonGuid)
        .pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  setCompleteOnElapsedTimedActivity$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.completeTimedComputerActivity),
      withLatestFrom(
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(fromQuestion.selectActivityQuestions)
      ),
      switchMap(([action, activity, questions]) => {
        if (activity && questions && action.activityGuid === activity.activityGuid) {
          return this.#setTimedActivityAttempts(activity, questions, true);
        }
        return EMPTY;
      })
    );
  });

  #getAnswerOrSkipActions(lesson: SelectedLesson | undefined, activity: SelectedActivity | undefined, activities: LessonActivity[], questions: LessonQuestion[],
    comprehensionTests: readonly ComprehensionTest[], correct: boolean): Action[] {
    const actions: Action[] = [];

    if (activity && questions && lesson) {

      // Calculate the number of attempted questions vs those that are correct to get the percentage
      const stats = questions.reduce((context, question) => {
        if (question.correct) {
          context.correct++;
        }
        if (question.skipped) {
          context.skipped++;
        }
        if (this.#answeredCorrectlyOnFirstAttempt(question)) {
          context.correctFirstTime++;
        }
        // Any question irrespective of correct or not
        // if answered should be considered for percentage calculation.
        if (question.attempts || question.skipped) {
          context.attempted++;
        }
        return context;
      }, { correctFirstTime: 0, correct: 0, attempted: 0, skipped: 0 });

      // Trigger the activity percentage action
      const percentage = Math.floor(stats.correctFirstTime / stats.attempted * 100);
      const completed = stats.correct + stats.skipped === questions.length;
      const allAttemptedAssessment = stats.attempted === questions.length && (lesson.isAssessment || activity.isAssessmentActivity);

      let ageResult: AgeResult | null = null;
      let age: number | null = null;

      if (activity.assessmentFormName) {
        const testResult = determineAgeComprehensionTest(stats.correct, activity.assessmentFormName, comprehensionTests);
        if (testResult) {
          ageResult = testResult.result;
          if (testResult.age) {
            age = convertAgeToNumber(testResult.age);
          }
        }
      }

      actions.push(
        fromActivity.percentageAction(
          {
            lessonGuid: activity.lessonGuid,
            activityGuid: activity.activityGuid,
            percentage: percentage,
            ageResult: ageResult,
            age: age,
            completed: completed
          })
      );

      // send the activity completed event if all questions are answered correctly
      if ((completed || allAttemptedAssessment) && activity.lessonActivityType !== LessonActivityType.TimedComputer) {
        // Update score for kip points
        let score = 0;
        for (const question of questions) {
          score += question.score;
        }

        const now = moment.utc().toDate();
        actions.push(
          fromActivity.finishAction({
            activityGuid: activity.activityGuid,
            completedOn: now,
            activityScore: score
          })
        );

        const nextActivity = activities.find(s => !s.completedOn && s.treeTopicId && s.activityGuid !== activity.activityGuid);
        if (nextActivity) {
          actions.push(fromActivity.requestOpenAction({ activityGuid: nextActivity.activityGuid }));
        }
      }

      actions.push(fromLesson.refreshTreeSummaryAction({ activityGuid: activity.activityGuid }));

      if (correct && activity.lessonActivityType === LessonActivityType.TimedComputer) {
        const timedComputerActivityActions = this.#setTimedActivityAttempts(activity, questions);
        actions.push(...timedComputerActivityActions);
      }

      // notify AI how the student went - have it last thing
      if (completed) {
        actions.push(fromLesson.updateAINoteAction({
          lessonGuid: activity.lessonGuid,
          note: `I just completed ${activity.name} and got ${percentage}% correct`
        }));
      }

    }

    return actions;
  }

  #setTimedActivityAttempts(activity: SelectedActivity,
    questions: LessonQuestion[],
    setCompletionOnly = false): Action[] {
    const actions = [];

    const secondAttemptStartTime = moment(activity.timedComputer?.secondAttemptStartTime);
    const secondAttemptDiff = moment().diff(secondAttemptStartTime, 'seconds');
    const isFirstAttempt = !activity.timedComputer?.secondAttemptStartTime;

    // if  only the first round has been completed, set the firstAttempt to
    // the amount of correct questions, otherwise the second round score
    // can be calculated by counting the total correct and negating the
    // count from the first round.
    let firstAttempt = !isFirstAttempt ? activity.timedComputer?.firstAttemptResult ?? 0 : 0;
    let secondAttempt = !isFirstAttempt ? 0 - firstAttempt : 0;

    for (const question of questions) {
      if (question.correct && question.attempts === 1) {
        if (isFirstAttempt) {
          firstAttempt++;
        } else {
          secondAttempt++;
        }
      }
    }

    if (isFirstAttempt) {
      if (!setCompletionOnly) {
        actions.push(fromActivity.firstAttemptAction(
          {
            lessonGuid: activity.lessonGuid,
            activityGuid: activity.activityGuid,
            count: firstAttempt
          }));
      }
    } else if (!isFirstAttempt) {
      if (!setCompletionOnly) {
        actions.push(fromActivity.secondAttemptAction(
          {
            lessonGuid: activity.lessonGuid,
            activityGuid: activity.activityGuid,
            count: secondAttempt
          }));
      }

      if (secondAttemptDiff > 60) {
        actions.push(fromActivity.finishAction({
          activityGuid: activity.activityGuid,
          completedOn: secondAttemptStartTime.add(60, 'seconds').toDate(),
          activityScore: Math.max(firstAttempt, secondAttempt)
        }));
      }
    }

    return actions;
  }

  #answeredCorrectlyOnFirstAttempt(question: LessonQuestion): boolean {
    return question.correct && question.attempts === this.maxAttemptsToBeCorrect;
  }
}
