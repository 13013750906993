import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnDestroy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormBuilderTypeSafe, FormGroupTypeSafe } from 'forms-lib';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ConfirmDialogComponent } from 'ui-common-lib';

import { ActivityDifficulty, Picker, StudentTreeResult, StudentUserName, Tree, TreeActivity, TreeTopic } from '../models';
import { TreePickerService } from '../services';

@Component({
    selector: 'kip-tree-picker',
    templateUrl: './tree-picker.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class TreePickerComponent implements OnDestroy {

  readonly #fb = inject(FormBuilderTypeSafe);
  readonly #treePickerService = inject(TreePickerService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #lessonPlans: string[] = [];
  #studentTreeResults: StudentTreeResult[] = [];
  #topicMap: Map<number, TreeTopic> | null = null;
  #treeTopicId: number | undefined;
  #treeActivityTitle = '';
  #treeActivityId: number | undefined;

  #treeSubjectIds: number[] = [];
  #pickerValue: Picker | undefined;

  #subscriptions: Subscription[] = [];

  readonly percentageResults: { name: string, percentage: number | null }[] = [
    { name: 'Clear', percentage: null },
    { name: '20%', percentage: 20 },
    { name: '40%', percentage: 40 },
    { name: '60%', percentage: 60 },
    { name: '80%', percentage: 80 },
    { name: '100%', percentage: 100 }
  ];

  get regionId() {
    return this.pickerForm.getSafe(s => s.regionId);
  }

  get subjectId() {
    return this.pickerForm.getSafe(s => s.subjectId);
  }

  get gradeId() {
    return this.pickerForm.getSafe(s => s.gradeId);
  }

  get lessonPlans() {
    return this.#lessonPlans;
  }

  get treeActivityId() {
    return this.#treeActivityId;
  }

  get treeActivityTitle() {
    return this.#treeActivityTitle;
  }

  get treeActivityQuestions() {
    if (this.#treeTopicId && this.#topicMap) {
      const topic = this.#topicMap.get(this.#treeTopicId);
      if (topic) {
        return topic.questions;
      }
    }

    return null;
  }

  readonly icons = Icons;

  pickerForm: FormGroupTypeSafe<Picker>;
  trees: readonly Tree[] = [];
  difficulties: readonly ActivityDifficulty[] = [];
  loaded = false;

  defaultPlanningLessonActivities = 6;

  students: readonly StudentUserName[] = [];

  selectedTopicId: number | null = null;

  get treeViewClass() {
    return `kip-explore ${!this.showSelection ? this.treeActivityId ? 'd-none' : 'col-12' : this.students.length === 0 ? 'col-6' : 'col-5'}`;
  }

  get questionViewClass() {
    return !this.showSelection ? this.treeActivityId ? 'col-12' : 'd-none' : this.students.length === 0 ? 'col-6' : 'col-5';
  }

  get studentViewClass() {
    return !this.showSelection || this.students.length === 0 ? 'd-none' : 'col-2';
  }

  @Input() showSelection = true;

  @Input() treeId: number | null | undefined;

  @Input() set picker(value: Picker | undefined) {
    if (this.#pickerValue !== value) {
      this.#pickerValue = value;
      if (value) {
        this.#loadValues(value);
      }
    }
  }

  get picker() {
    return this.#pickerValue;
  }

  @Input() set treeSubjectIds(value: number[]) {
    if (this.#treeSubjectIds !== value) {
      this.#treeSubjectIds = value;
      if (value) {
        this.loaded = false;
        this.#subscriptions.push(this.#treePickerService.getTreesByIds(value).subscribe(trees => {
          this.trees = trees;
          this.loaded = true;
          this.#changeDetectorRef.markForCheck();
        }));
      }
    }
  }

  get treeSubjectIds() {
    return this.#treeSubjectIds;
  }

  constructor() {

    /*eslint-disable @typescript-eslint/unbound-method */

    this.pickerForm = this.#fb.group<Picker>({
      regionId: new FormControl<number | null>(null, Validators.required),
      gradeId: new FormControl<number | null>(null, Validators.required),
      subjectId: new FormControl<number | null>(null, Validators.required)
    });

    /*eslint-enable @typescript-eslint/unbound-method */
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  generate(tree: Tree, dialog: ConfirmDialogComponent) {
    this.#lessonPlans = this.#generatePlans(tree, this.defaultPlanningLessonActivities);
    dialog.open('lg');
  }

  submit() {
    if (this.pickerForm.valid) {
      this.loaded = false;
      this.#loadValues(this.pickerForm.value);
    }
  }

  updatePercentage(treeTopic: TreeTopic, percentage: number | null) {
    treeTopic.percentage = percentage;
  }

  closeActivity() {
    this.#treeTopicId = undefined;
    this.#treeActivityId = undefined;
    this.#treeActivityTitle = '';
  }

  selectActivity(treeTopicId: number, activity: TreeActivity, treeActivityTitle: string) {
    activity.expanded = !activity.expanded;
    this.#treeTopicId = treeTopicId;
    this.#treeActivityId = activity.id;
    this.#treeActivityTitle = treeActivityTitle;
  }

  selectStudent(studentId: number) {
    this.#subscriptions.push(this.#treePickerService.getStudentTreeResults(this.trees.map(s => s.treeId), studentId).subscribe(studentTreeResults => {
      this.#studentTreeResults = studentTreeResults;
      this.#resetTrees();
      const topicMap = this.#generateMap();
      this.#topicMap = topicMap;
      for (const studentTreeResult of this.#studentTreeResults) {
        for (const studentTreeTopicResult of studentTreeResult.topicResults) {
          const topic = topicMap.get(studentTreeTopicResult.treeTopicId);
          if (topic) {
            if (topic.percentage === null) {
              topic.percentage = studentTreeTopicResult.percentage;
            }

            if (topic.questions === undefined) {
              topic.questions = new Map<number, boolean>();
            }

            const questions = topic.questions;
            if (questions) {
              for (const correct of studentTreeTopicResult.correctQuestions) {
                if (!questions.has(correct)) {
                  questions.set(correct, true);
                }
              }

              for (const incorrect of studentTreeTopicResult.incorrectQuestions) {
                if (!questions.has(incorrect)) {
                  questions.set(incorrect, false);
                }
              }
            }
          }
        }
      }

      this.#changeDetectorRef.markForCheck();
    }));
  }

  displayStudents(tree: Tree) {
    this.students = this.students.length === 0 ? tree.students : [];
  }

  #generateMap() {
    const topicMap = new Map<number, TreeTopic>();
    for (const tree of this.trees) {
      for (const streams of tree.streams) {
        for (const topic of streams.topics) {
          topicMap.set(topic.id, topic);
        }
      }
    }

    return topicMap;
  }

  #resetTrees() {
    for (const tree of this.trees) {
      for (const streams of tree.streams) {
        for (const topic of streams.topics) {
          topic.percentage = null;
          topic.questions = undefined;
        }
      }
    }
  }

  #generatePlans(tree: Tree, count: number) {
    const topics: string[] = [];
    let exitLoop = false;
    for (const stream of tree.streams) {
      for (const topic of stream.topics) {
        if (topic.percentage === null || topic.percentage === undefined || topic.percentage < tree.percentageTarget) {
          topics.push(`${stream.name}/${topic.name}`);
          if (topics.length >= count) {
            exitLoop = true;
          }
          if (exitLoop) {
            break;
          }
        }
      }
      if (exitLoop) {
        break;
      }
    }

    return topics;
  }

  #loadValues(picker: Picker) {
    this.loaded = false;
    this.#subscriptions.push(this.#treePickerService.getTrees(picker).subscribe(data => {
      this.defaultPlanningLessonActivities = data.defaultPlanningLessonActivities;
      this.trees = data.trees;
      this.difficulties = data.difficulties;
      this.loaded = true;
      this.#changeDetectorRef.markForCheck();
    }));
  }

}
