import { CurrencySwap } from './currency-swap';

export const currencySwapCodes: CurrencySwap = {
  primaryCurrencyWordSingularUpper: '_primaryCurrencyWordSingularUpper',
  primaryCurrencyWordSingularLower: '_primaryCurrencyWordSingularLower',
  primaryCurrencyWordPluralUpper: '_primaryCurrencyWordPluralUpper',
  primaryCurrencyWordPluralLower: '_primaryCurrencyWordPluralLower',
  primaryCurrencySymbol: '_primaryCurrencySymbol',
  secondaryCurrencyWordSingularUpper: '_secondaryCurrencyWordSingularUpper',
  secondaryCurrencyWordSingularLower: '_secondaryCurrencyWordSingularLower',
  secondaryCurrencyWordPluralUpper: '_secondaryCurrencyWordPluralUpper',
  secondaryCurrencyWordPluralLower: '_secondaryCurrencyWordPluralLower',
  secondaryCurrencySymbol: '_secondaryCurrencySymbol',
  pointOrComma: '_pointOrComma',
  commaOrSemicolon: '_commaOrSemicolon',
  highest: '_highest',
  lowest: '_lowest',
  notes: '_notes',
  fullStop: '_fullStop',
  taxName: '_taxName',
  coin001: '_coin001',
  coin005: '_coin005',
  coin010: '_coin010',
  coin020: '_coin020',
  coin025: '_coin025',
  coin050: '_coin050',
  coin100: '_coin100',
  coin200: '_coin200',
  note005: '_note005',
  note010: '_note010',
  note020: '_note020',
  note050: '_note050',
  note100: '_note100',
  oCoin001: '_oCoin001',
  oCoin005: '_oCoin005',
  oCoin010: '_oCoin010',
  oCoin020: '_oCoin020',
  oCoin025: '_oCoin025',
  oCoin050: '_oCoin050',
  oCoin100: '_oCoin100',
  oCoin200: '_oCoin200',
  oNote005: '_oNote005',
  oNote010: '_oNote010',
  oNote020: '_oNote020',
  oNote050: '_oNote050',
  oNote100: '_oNote100'
};
