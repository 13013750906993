import { createAction, props } from '@ngrx/store';
import { WhiteboardEvent, WhiteboardGridType, WhiteboardGuidKey } from 'whiteboard-lib';

enum WhiteboardActionTypes {
  Send = 'Student > Whiteboard > Send',
  Receive = 'Student > Whiteboard > Receive',
  ReceiveOpen = 'Student > Whiteboard > Receive Open',
  ReceiveClose = 'Student > Whiteboard > Receive Close',
  Open = 'Student > Whiteboard > Open',
  Close = 'Student > Whiteboard > Close',
  SendGridType = 'Student > Whiteboard > Send Grid Type',
  ReceiveGridType = 'Student > Whiteboard > Receive Grid Type'
}

export const sendGridTypeAction = createAction(
  WhiteboardActionTypes.SendGridType,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly gridType: WhiteboardGridType;
    readonly broadcastLessonGuid: string;
  }>()
);

export const receiveGridTypeAction = createAction(
  WhiteboardActionTypes.ReceiveGridType,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly gridType: WhiteboardGridType;
  }>()
);

export const receiveOpenAction = createAction(
  WhiteboardActionTypes.ReceiveOpen,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const receiveCloseAction = createAction(
  WhiteboardActionTypes.ReceiveClose,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const openAction = createAction(
  WhiteboardActionTypes.Open,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const closeAction = createAction(
  WhiteboardActionTypes.Close,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
  }>()
);

export const sendAction = createAction(
  WhiteboardActionTypes.Send,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly event: WhiteboardEvent;
    readonly broadcastLessonGuid: string;
  }>()
);

export const receiveAction = createAction(
  WhiteboardActionTypes.Receive,
  props<{
    readonly lessonGuid: string;
    readonly whiteboardGuidKey: WhiteboardGuidKey;
    readonly event: WhiteboardEvent;
  }>()
);
