<ng-template #content>
    <div class="modal-header px-0 py-1 d-flex align-items-center justify-content-between">
        <h4 class="modal-title h3 p-1 ms-3" id="modal-basic-title">
            <fa-icon class="me-1" size="1x" [icon]="icons.lightbulb" />
            AI Interactions
        </h4>
        <button aria-label="Close" class="btn btn-link btn--icon me-1" type="button" (click)="close()">
            <fa-icon [icon]="icons.close.regular" />
        </button>
    </div>
    <div class="modal-body">
        <kip-student-ai-chat [aiFilter]="aiFilter()" [studentId]="studentId()" />
    </div>
</ng-template>