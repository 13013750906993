import { Environment } from './environment-interface';

export const environment: Environment = {
  production: true,
  slowRequestTime: 3000,
  auth: {
    renewSeconds: 30
  },
  minutesEarlyStartDashboard: 10,
  showIcons: false,
  showSentry: false,
  buildGuid: '3.10.111',
  dropInEnabled: false,
  classLinkEnabled: false,
  cleverEnabled: false,
  visibleDropInSessionCount: 20,
  franchiseePortal: 'https://kipmcgrath.atlassian.net/servicedesk/customer/portal/21',
  googleTag: 'G-C560E381PG'
};
