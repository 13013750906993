/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BundleSelected, LessonEnrolmentSessionSchedule, LessonEnrolmentStage } from 'enrolment-lib';
import { EventType, LessonChat, LessonLog, LessonLogAuthor, MessagingService as MessagingServiceBase, Sound } from 'message-lib';
import { DateStruct } from 'moment-extensions-lib';
import { FormDataType } from 'ngx-extended-pdf-viewer';
import { AnswerType } from 'questions-lib';
import { from, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BillingIntervalType, DayOfWeek, LessonActivityPlanFile, LessonStatus, LessonType, Subject, TutorSettings, VideoEffectType } from 'ui-common-lib';
import { WhiteboardEvent, WhiteboardGridType, WhiteboardGuidKey } from 'whiteboard-lib';

import { DIYUpdateResult } from '../../shared';
import { EventsRequest, Lesson, LessonPlan, ReadingSummary, UpdatedLesson } from '../models';
import { fromActivity, fromChat, fromLesson, fromLog, fromQuestion, fromSession, fromWhiteboard, TutorState } from '../store';

@Injectable({ providedIn: 'root' })
export class MessagingService extends MessagingServiceBase {

  readonly #store = inject(Store<TutorState>);
  readonly #keyLimits = new Map<string, number>();

  constructor() {
    super('Tutor', 'lesson');
    this.init();
  }

  get tutorSettings() {
    return this.userProfile?.settings as TutorSettings | undefined;
  }

  init() {
    this.profileService.trackUserProfile().subscribe(userProfile => {
      this.userProfile = userProfile;
    });
  }

  override connect(): Observable<void> {

    // Ensure the message handlers are registered
    return super.connect().pipe(
      tap(() => {
        // This event is just to confirm the connection is working and received an event.
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        this.receive('SessionOpenSuccess', () => { });

        this.receiveEvent('LessonOpened', this.#store, {
          action: fromLesson.openAction,
          converter: (args: [Lesson, boolean]) =>
          ({
            sessionLesson: args[0],
            isHomeworkOnly: args[1]
          })
        });

        this.receiveEvent('LessonAttendCompleted', this.#store, {
          action: fromLesson.attendLessonCompletedAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('LessonDeferCompleted', this.#store, {
          action: fromLesson.deferLessonCompletedAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('JoinLessonCompleted', this.#store, {
          action: fromLesson.joinLessonCompletedAction,
          converter: (args: [string, string]) =>
          ({
            lessonGuid: args[0],
            connectionId: args[1]
          })
        });

        this.receiveEvent('UpdateStartLessonNote', this.#store, {
          action: fromLesson.updateStartLessonNoteAction,
          converter: (args: [string, string, number | null]) =>
          ({
            lessonGuid: args[0],
            note: args[1],
            noteRanking: args[2]
          })
        });

        this.receiveEvent('UpdateFinishLessonNote', this.#store, {
          action: fromLesson.updateFinishLessonNoteAction,
          converter: (args: [string, string, number | null]) =>
          ({
            lessonGuid: args[0],
            note: args[1],
            noteRanking: args[2]
          })
        });

        this.receiveEvent('LessonClosed', this.#store, {
          action: fromLesson.closeAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('StartedInteracting', this.#store, {
          action: fromLesson.startedInteractingAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentStartedInteracting,
              play: () =>
                this.tutorSettings?.playSoundOnStudentStartedInteracting ?? false
            }
          ]
        });

        this.receiveEvent('StoppedInteracting', this.#store, {
          action: fromLesson.stoppedInteractingAction,
          converter: (args: string[]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentStoppedInteracting,
              play: () =>
                this.tutorSettings?.playSoundOnStudentStoppedInteracting ?? false
            }
          ]
        });

        this.receiveEvent('StartedLookingTab', this.#store, {
          action: fromLesson.startedLookingTabAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentStartedLookingTab,
              play: () =>
                this.tutorSettings?.playSoundOnStudentStartedLookingTab ?? false
            }
          ]
        });

        this.receiveEvent('StoppedLookingTab', this.#store, {
          action: fromLesson.stoppedLookingTabAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentStoppedLookingTab,
              play: () =>
                this.tutorSettings?.playSoundOnStudentStoppedLookingTab ?? false
            }
          ]
        });

        this.receiveEvent('LobbyOpened', this.#store, {
          action: fromLesson.openLobbyAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentEnter,
              play: () =>
                this.tutorSettings?.playSoundOnStudentEnter ?? false
            }
          ]
        });

        this.receiveEvent('LobbyClosed', this.#store, {
          action: fromLesson.closeLobbyAction,
          converter: (args: [string]) => ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentLeave,
              play: () =>
                this.tutorSettings?.playSoundOnStudentLeave ?? false
            }
          ]
        });

        this.receiveEvent('LessonDisconnected', this.#store, {
          action: fromLesson.disconnectAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentLeave,
              play: () =>
                this.tutorSettings?.playSoundOnStudentLeave ?? false
            }
          ]
        });

        this.receiveEvent('StudentPublishDenied', this.#store, {
          action: fromLesson.studentPublishDeniedAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentVideoDenied,
              play: () =>
                this.tutorSettings?.playSoundOnStudentVideoDenied ?? false
            }
          ]
        });

        this.receiveEvent('StudentPublishFailure', this.#store, {
          action: fromLesson.studentPublishFailureAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.StudentVideoFailure,
              play: () =>
                this.tutorSettings?.playSoundOnStudentVideoFailure ?? false
            }
          ]
        });

        this.receiveEvent('TutorPublishAllowed', this.#store, {
          action: fromSession.publishAllowedAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('TutorPublishDenied', this.#store, {
          action: fromSession.publishDeniedAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('BroadcastingPublishDenied', this.#store, {
          action: fromSession.broadcastingPublishDeniedAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('StartBroadcastingObservers', this.#store, {
          action: fromSession.startBroadcastingObserversAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('StopBroadcastingObservers', this.#store, {
          action: fromSession.stopBroadcastingObserversAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('BroadcastingPublishFailure', this.#store, {
          action: fromSession.broadcastingPublishFailureAction,
          converter: (args: [number, string, string]) =>
          ({
            sessionId: args[0],
            name: args[1],
            message: args[2]
          })
        });

        this.receiveEvent('InstallAvailable', this.#store, {
          action: fromLesson.installAvailableAction,
          converter: (args: [string, boolean]) =>
          ({
            lessonGuid: args[0],
            available: args[1]
          })
        });

        this.receiveEvent('TutorPublishFailure', this.#store, {
          action: fromSession.publishFailureAction,
          converter: (args: [number, string, string]) =>
          ({
            sessionId: args[0],
            name: args[1],
            message: args[2]
          })
        });

        this.receiveEvent('TutorSubscribeFailure', this.#store, {
          action: fromLesson.subscribeFailureAction,
          converter: (args: [string, string, string]) =>
          ({
            lessonGuid: args[0],
            name: args[1],
            message: args[2]
          })
        });

        this.receiveEvent('TutorStreamDestroyed', this.#store, {
          action: fromSession.streamDestroyedAction,
          converter: (args: [number, string]) =>
          ({
            sessionId: args[0],
            reason: args[1]
          })
        });

        this.receiveEvent('TutorScreenSharePublishAllowed', this.#store, {
          action: fromSession.screenSharePublishAllowedAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('TutorScreenSharePublishDenied', this.#store, {
          action: fromSession.screenSharePublishDeniedAction,
          converter: (args: [number]) =>
          ({
            sessionId: args[0]
          })
        });

        this.receiveEvent('TutorScreenSharePublishFailure', this.#store, {
          action: fromSession.screenSharePublishFailureAction,
          converter: (args: [number, string, string]) =>
          ({
            sessionId: args[0],
            name: args[1],
            message: args[2]
          })
        });

        this.receiveEvent('TutorScreenShareStreamDestroyed', this.#store, {
          action: fromSession.screenShareStreamDestroyedAction,
          converter: (args: [number, string]) =>
          ({
            sessionId: args[0],
            reason: args[1]
          })
        });

        this.receiveEvent('HelpRequested', this.#store, {
          action: fromLesson.requestHelpAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          }),
          sounds: [
            {
              sound: Sound.Help,
              play: () =>
                this.tutorSettings?.playSoundOnRequestHelp ?? false
            }
          ]
        });

        this.receiveEvent('ActivityFilesAdded', this.#store, {
          action: fromActivity.activityFilesAddedAction,
          converter: (args: [string, string, LessonActivityPlanFile[]]) =>
          ({
            activityGuid: args[0],
            lessonGuid: args[1],
            files: args[2]
          })
        });

        this.receiveEvent('ActivityFileRemoved', this.#store, {
          action: fromActivity.activityFileRemovedAction,
          converter: (args: [string, string, number]) =>
          ({
            activityGuid: args[0],
            lessonGuid: args[1],
            fileId: args[2]
          })
        });

        this.receiveEvent('ActivityFileRenamed', this.#store, {
          action: fromActivity.activityFileRenamedAction,
          converter: (args: [string, string, number, string]) =>
          ({
            activityGuid: args[0],
            lessonGuid: args[1],
            fileId: args[2],
            fileName: args[3]
          })
        });

        this.receiveEvent('ActivityCompleted', this.#store, {
          action: fromActivity.activityCompletedAction,
          converter: (args: [string, string, Date | undefined, number]) =>
          ({
            activityGuid: args[0],
            lessonGuid: args[1],
            completedOn: args[2],
            completionTime: args[3]
          })
        });

        this.receiveEvent('ActivityFirstAttempt', this.#store, {
          action: fromActivity.timedActivityFirstAttempt,
          converter: (args: [string, string, number]) =>
          ({
            lessonGuid: args[0],
            activityGuid: args[1],
            correct: args[2]
          })
        });

        this.receiveEvent('ActivityStartFirstAttempt', this.#store, {
          action: fromActivity.timedActivityStartFirstAttempt,
          converter: (args: [string, string, Date]) =>
          ({
            lessonGuid: args[0],
            activityGuid: args[1],
            startDateTime: args[2]
          })
        });

        this.receiveEvent('ActivitySecondAttempt', this.#store, {
          action: fromActivity.timedActivitySecondAttempt,
          converter: (args: [string, string, number]) =>
          ({
            lessonGuid: args[0],
            activityGuid: args[1],
            correct: args[2]
          })
        });

        this.receiveEvent('ActivityStartSecondAttempt', this.#store, {
          action: fromActivity.timedActivityStartSecondAttempt,
          converter: (args: [string, string, Date]) =>
          ({
            lessonGuid: args[0],
            activityGuid: args[1],
            startDateTime: args[2]
          })
        });

        this.receiveEvent('ActivityOpened', this.#store, {
          action: fromLesson.openActivityAction,
          converter: (args: [string, string]) =>
          ({
            activityGuid: args[0],
            lessonGuid: args[1]
          })
        });

        this.receiveEvent('ActivityClosed', this.#store, {
          action: fromLesson.closeActivityAction,
          converter: (args: [string, string]) =>
          ({
            activityGuid: args[0],
            lessonGuid: args[1]
          }),
          sounds: [
            {
              sound: Sound.StudentCompletedActivity,
              play: () =>
                this.tutorSettings?.playSoundOnStudentCompletedActivity ?? false
            }
          ]
        },
          {
            action: fromActivity.updateCompletionTimeAction,
            converter: (args: [string, string, number]) =>
            ({
              activityGuid: args[0],
              lessonGuid: args[1],
              completionTime: args[2]
            })
          });

        this.receiveEvent('ActivityVideoArchived', this.#store, {
          action: fromActivity.videoArchivedAction,
          converter: (args: [string, string]) =>
          ({
            activityGuid: args[0],
            videoGuid: args[1]
          })
        });

        this.receiveEvent('ActivityPercentage', this.#store, {
          action: fromActivity.percentageAction,
          converter: (args: [string, number, number | null, number | null]) =>
          ({
            activityGuid: args[0],
            percentage: args[1],
            ageResult: args[2],
            age: args[3]
          })
        });

        this.receiveEvent('QuestionSkipped', this.#store, {
          action: fromQuestion.skipAction,
          converter: (args: [string, number, string, boolean]) =>
          ({
            questionGuid: args[0],
            lessonId: args[1],
            activityGuid: args[2],
            skillBuilderAdded: args[3]
          })
        });

        this.receiveEvent('ActivityTimeAlerted', this.#store, {
          action: fromActivity.timeAlertedAction,
          converter: (args: [string, string, boolean, boolean, number | undefined]) =>
          ({
            lessonGuid: args[0],
            activityGuid: args[1],
            isWarning: args[2],
            helpRequested: args[3],
            minutesRemaining: args[4]
          }),
          sounds: [{
            sound: Sound.StudentMaxTimeReached,
            play: action => !action.isWarning && (this.tutorSettings?.playSoundOnStudentMaxTimeReached ?? false)
          }, {
            sound: Sound.StudentWarningTimeReached,
            play: action => action.isWarning && (this.tutorSettings?.playSoundOnStudentWarningTimeReached ?? false)
          }]
        });

        this.receiveEvent('QuestionOpened', this.#store, {
          action: fromLesson.openQuestionAction,
          converter: (args: [string, string]) => ({
            questionGuid: args[0],
            lessonGuid: args[1]
          })
        }, {
          action: fromQuestion.openAction,
          converter: (args: [string]) =>
          ({
            questionGuid: args[0]
          })
        });

        this.receiveEvent('QuestionSkipped', this.#store,
          {
            action: fromQuestion.skipAction,
            converter: (args: [string]) =>
            ({
              questionGuid: args[0]
            })
          });

        this.receiveEvent('QuestionClosed', this.#store, {
          action: fromLesson.closeQuestionAction,
          converter: (args: [string, string]) =>
          ({
            questionGuid: args[0],
            lessonGuid: args[1]
          })
        },
          {
            action: fromQuestion.closeAction,
            converter: (args: [string]) =>
            ({
              questionGuid: args[0]
            })
          });

        this.receiveEvent('QuestionAnswered', this.#store, {
          action: fromQuestion.answerAction,
          converter: (args: [string, AnswerType[], boolean, number]) =>
          ({
            questionGuid: args[0],
            answers: args[1],
            correct: args[2],
            score: args[3]
          })
        });

        this.receiveEvent('QuestionUpdated', this.#store, {
          action: fromQuestion.answerUpdatedAction,
          converter: (args: [string, AnswerType[], boolean]) =>
          ({
            questionGuid: args[0],
            answers: args[1],
            correct: args[2]
          })
        });

        this.receiveEvent('QuestionIntroductionViewed', this.#store, {
          action: fromQuestion.introductionAction,
          converter: (args: [string, string]) =>
          ({
            lessonGuid: args[0],
            questionGuid: args[1]
          })
        });

        this.receiveEvent('QuestionWorkedSolutionViewed', this.#store, {
          action: fromQuestion.workedSolutionAction,
          converter: (args: [string, string]) =>
          ({
            lessonGuid: args[0],
            questionGuid: args[1]
          })
        });

        this.receiveEvent('WhiteboardEvent', this.#store, {
          action: fromWhiteboard.receiveAction,
          converter: (args: [string, string, string, WhiteboardEvent]) =>
          ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] },
            event: args[3]
          })
        });

        this.receiveEvent('WhiteboardOpened', this.#store, {
          action: fromWhiteboard.studentOpenAction,
          converter: (args: [string, string, string]) =>
          ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] }
          })
        });

        this.receiveEvent('ResetPassword', this.#store, {
          action: fromLesson.resetPasswordCompleteAction,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('WhiteboardClosed', this.#store, {
          action: fromWhiteboard.studentCloseAction,
          converter: (args: [string, string, string]) =>
          ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] }
          })
        });

        this.receiveEvent('WhiteboardGridType', this.#store, {
          action: fromWhiteboard.receiveGridTypeAction,
          converter: (args: [string, string, string, WhiteboardGridType]) =>
          ({
            lessonGuid: args[0],
            whiteboardGuidKey: { activityGuid: args[1], pageGuid: args[2] },
            gridType: args[3]
          })
        });

        this.receiveEvent('Log', this.#store, {
          action: fromLog.addAction,
          converter: (args: [string, LessonLog]) =>
          ({
            lessonGuid: args[0],
            log: args[1]
          })
        });

        this.receiveEvent('Chat', this.#store, {
          action: fromChat.addAction,
          converter: (args: [string, LessonChat]) =>
          ({
            lessonGuid: args[0],
            chatMessages: args[1]
          }),
          sounds: [
            {
              sound: Sound.MessageTutor,
              play: action => (this.tutorSettings?.playSoundOnChat && action.chatMessages.author === LessonLogAuthor.Student) ?? false
            }
          ]
        });

        this.receiveEvent('ChatUpdateTyping', this.#store, {
          action: fromChat.updateTypingAction,
          converter: (args: [string, boolean]) =>
          ({
            lessonGuid: args[0],
            isTyping: args[1]
          })
        });

        this.receiveEvent('LessonEnrolmentProgressUpdated', this.#store, {
          action: fromLesson.updateEnrolmentProgressAction,
          converter: (args: [string, LessonEnrolmentStage]) => ({
            lessonGuid: args[0],
            enrolmentStage: args[1]
          })
        });

        this.receiveEvent('LessonEnrolmentSubjectsUpdated', this.#store, {
          action: fromLesson.updateEnrolmentSubjectsAction,
          converter: (args: [string, Subject[]]) =>
          ({
            lessonGuid: args[0],
            subjects: args[1]
          })
        });

        this.receiveEvent('LessonEnrolmentSessionsUpdated', this.#store, {
          action: fromLesson.updateEnrolmentSessionsAction,
          converter: (args: [string, LessonEnrolmentSessionSchedule[]]) => ({
            lessonGuid: args[0],
            sessions: args[1]
          })
        });

        this.receiveEvent('UpdateLessonDoItYourself', this.#store, {
          action: fromLesson.updateLessonDoItYourselfAction,
          converter: (args: [string, boolean, LessonType, LessonStatus, DIYUpdateResult, boolean, number]) => ({
            lessonGuid: args[0],
            isOnline: args[1],
            lessonType: args[2],
            lessonStatus: args[3],
            updateResult: args[4],
            updateFromParent: args[5],
            lessonId: args[6]
          })
        });

        this.receiveEvent('LessonEnrolmentBundleUpdated', this.#store, {
          action: fromLesson.updateEnrolmentBundleAction,
          converter: (args: [string, number, BillingIntervalType]) => ({
            lessonGuid: args[0],
            bundleOfferId: args[1],
            billingInterval: args[2]
          })
        });

        this.receiveEvent('LessonEnrolmentBundleTypeUpdated', this.#store, {
          action: fromLesson.updateEnrolmentBundleTypeAction,
          converter: (args: [string, number]) => ({
            lessonGuid: args[0],
            bundleOfferTypeId: args[1]
          })
        });

        this.receiveEvent('LessonEnrolmentBundleCurrencyUpdated', this.#store, {
          action: fromLesson.updateEnrolmentBundleCurrencyAction,
          converter: (args: [string, string]) => ({
            lessonGuid: args[0],
            bundleCurrency: args[1]
          })
        });

        this.receiveEvent('LessonEnrolmentBundleSelected', this.#store, {
          action: fromLesson.updateEnrolmentBundleAction,
          converter: (args: [string, number, BillingIntervalType]) => ({
            lessonGuid: args[0],
            bundleOfferId: args[1],
            billingInterval: args[2]
          })
        });

        this.receiveEvent('LessonEnrolmentCustomerRepresentsUpdated', this.#store, {
          action: fromLesson.updateEnrolmentCustomerRepresentsAction,
          converter: (args: [string, boolean]) => ({
            lessonGuid: args[0],
            customerRepresentsOrganisation: args[1]
          })
        });

        this.receiveEvent('LessonEnrolmentPaymentOptionsUpdated', this.#store, {
          action: fromLesson.updateEnrolmentPaymentOptionsAction,
          converter: (args: [string, number, DateStruct, number]) => ({
            lessonGuid: args[0],
            debitDay: args[1],
            startDate: args[2],
            upfrontPaymentOption: args[3]
          })
        });

        this.receiveEvent('LessonEnrolmentPaymentEntrySelected', this.#store,
          {
            action: fromLesson.updateEnrolmentPaymentEntryAction,
            converter: (args: [string, boolean]) => ({
              lessonGuid: args[0],
              assessorEnteringPayment: args[1]
            })
          });

        this.receiveEvent('LessonEnrolmentAccountCreated', this.#store, {
          action: fromLesson.updateEnrolmentAccountCreatedAction,
          converter: (args: [string]) => ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('LessonPlanUpdated', this.#store, {
          action: fromLesson.updatePlanAction,
          converter: (args: [UpdatedLesson]) =>
          ({
            lesson: args[0]
          })
        });

        this.receiveEvent('SkillBuilderAdded', this.#store, {
          action: fromLesson.addSkillBuilderAction,
          converter: (args: [number, LessonPlan]) =>
          ({
            lessonPlanId: args[0],
            lessonPlan: args[1]
          })
        });

        this.receiveEvent('StudentStreamConnected', this.#store, {
          action: fromLesson.studentStreamConnected,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('DropInLessonCompleted', this.#store, {
          action: fromLesson.dropInLessonCompletedAction,
          converter: (args: [string, LessonPlan]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('CustomActivityQuestionOpened', this.#store, {
          action: fromLesson.openCustomActivityQuestion,
          converter: (args: [string, string, string]) =>
          ({
            lessonGuid: args[0],
            activityGuid: args[1],
            pageGuid: args[2]
          })
        });

        this.receiveEvent('CustomActivityQuestionClosed', this.#store, {
          action: fromLesson.closeCustomActivityQuestion,
          converter: (args: [string]) =>
          ({
            lessonGuid: args[0]
          })
        });

        this.receiveEvent('AiChat', this.#store, {
          action: fromQuestion.aiChat,
          converter: (args: [string]) =>
          ({
            questionGuid: args[0]
          })
        });

      })
    );
  }

  onReconnect() {
    this.#store.dispatch(fromSession.reconnectAction());
  }

  skillbuilderRequested(lessonId: number, skillbuilderActivityId: number | undefined, eventsRequest: EventsRequest): Observable<string> {
    return this.sendEventWrapper('SkillBuilderRequestedTutor', this.#store,
      { lessonId: lessonId, skillbuilderActivityId: skillbuilderActivityId, eventsRequest: eventsRequest });
  }

  skillbuildersRequested(lessonId: number, skillbuilderActivityIds: number[] | undefined, eventsRequest: EventsRequest): Observable<string> {
    return this.sendEventWrapper('SkillBuildersRequestedTutor', this.#store,
      { lessonId: lessonId, skillbuilderActivityIds: skillbuilderActivityIds, eventsRequest: eventsRequest });
  }

  sessionOpened(sessionId: number): Observable<string> {
    return this.sendEventWrapper('SessionOpened', this.#store, { sessionId: sessionId, deviceData: JSON.stringify(this.deviceData) });
  }

  sessionClosed(sessionId: number): Observable<string> {
    return this.sendEventWrapper('SessionClosed', this.#store, { sessionId: sessionId });
  }

  dropInSessionLessonOpened(sessionId: number, lessonId: number): Observable<string> {
    return this.sendEventWrapper('DropInLessonAccepted', this.#store, { sessionId: sessionId, lessonId: lessonId });
  }

  chat(lessonGuid: string, message: string, chatGroup: string, warning: boolean): Observable<string> {
    return this.sendEventWrapper('TutorChat', this.#store, { lessonGuid: lessonGuid, message: message, chatGroup: chatGroup, warning: warning });
  }

  chatTyping(lessonGuid: string, isTyping: boolean): Observable<string> {
    return this.sendEventWrapper('TutorChatUpdateTyping', this.#store, { lessonGuid: lessonGuid, isTyping: isTyping });
  }

  resetPassword(lessonGuid: string, password: string | null): Observable<string> {
    return this.sendEventWrapper('ResetPassword', this.#store, { lessonGuid: lessonGuid, password: password });
  }

  systemChat(lessonGuid: string, message: string, chatGroup: string): Observable<string> {
    return this.sendEventWrapper('TutorSystemChat', this.#store, { lessonGuid: lessonGuid, message: message, chatGroup: chatGroup });
  }

  captionsError(sessionId: number, statusCode: number): Observable<string> {
    return this.sendEventWrapper('CaptionsError', this.#store, { sessionId: sessionId, statusCode });
  }

  autoLoggedOutOnIdle(sessionId: number): Observable<string> {
    return this.sendEventWrapper('TutorAutoLoggedOutOnIdle', this.#store, { sessionId: sessionId });
  }

  updateBundleOfferTypeId(lessonGuid: string, bundleOfferTypeId: number): Observable<string> {
    return this.sendEventWrapper('LessonEnrolmentBundleTypeUpdated', this.#store,
      { lessonGuid: lessonGuid, bundleOfferTypeId: bundleOfferTypeId });
  }

  updateBundleCurrency(lessonGuid: string, bundleCurrency: string): Observable<string> {
    return this.sendEventWrapper('LessonEnrolmentBundleCurrencyUpdated', this.#store,
      { lessonGuid: lessonGuid, bundleCurrency: bundleCurrency });
  }

  forceReload(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('ForceReload', this.#store, { lessonGuid: lessonGuid });
  }

  forceReloadVideo(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('ForceReloadVideo', this.#store, { lessonGuid: lessonGuid });
  }

  addDIY(lessonId: number, isHomework: boolean, eventsRequest: EventsRequest): Observable<string> {
    return this.sendEventWrapper('AddDIYActivitiesTutor', this.#store,
      { lessonId: lessonId, isHomework: isHomework, eventsRequest: eventsRequest });
  }

  onlineToggled(lessonGuid: string, isOnline: boolean): Observable<string> {
    return this.sendEventWrapper('OnlineToggled', this.#store, { lessonGuid: lessonGuid, isOnline: isOnline });
  }

  pullFromLobby(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('PullFromLobby', this.#store, { lessonGuid: lessonGuid });
  }

  videoToggled(lessonGuid: string, publish: boolean): Observable<string> {
    return this.sendEventWrapper('VideoToggled', this.#store, { lessonGuid: lessonGuid, publish: publish });
  }

  audioToggled(lessonGuid: string, publish: boolean): Observable<string> {
    return this.sendEventWrapper('AudioToggled', this.#store, { lessonGuid: lessonGuid, publish: publish });
  }

  startTeaching(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StartTeaching', this.#store, { lessonGuid: lessonGuid });
  }

  stopTeaching(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StopTeaching', this.#store, { lessonGuid: lessonGuid });
  }

  startTeachingClass(sessionId: number): Observable<string> {
    return this.sendEventWrapper('StartTeachingClass', this.#store, { sessionId: sessionId });
  }

  stopTeachingClass(sessionId: number): Observable<string> {
    return this.sendEventWrapper('StopTeachingClass', this.#store, { sessionId: sessionId });
  }

  broadcastingPublishDenied(sessionId: number): Observable<string> {
    return this.sendEventWrapper('BroadcastingPublishDenied', this.#store, { sessionId: sessionId });
  }

  broadcastingPublishFailure(sessionId: number, name: string, message: string): Observable<string> {
    return this.sendEventWrapper('BroadcastingPublishFailure', this.#store, { sessionId: sessionId, name: name, message: message });
  }

  startBroadcastingObservers(sessionId: number, streamId: string): Observable<string> {
    return this.sendEventWrapper('StartBroadcastingObservers', this.#store, { sessionId: sessionId, streamId: streamId });
  }

  stopBroadcastingObservers(sessionId: number): Observable<string> {
    return this.sendEventWrapper('StopBroadcastingObservers', this.#store, { sessionId: sessionId });
  }

  installApp(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('InstallApp', this.#store, { lessonGuid: lessonGuid });
  }

  startObserving(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StartObserving', this.#store, { lessonGuid: lessonGuid });
  }

  stopObserving(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('StopObserving', this.#store, { lessonGuid: lessonGuid });
  }

  studentDetailsUpdated(lessonGuid: string, givenName: string, familyName: string, regionId: number | null): Observable<string> {
    return this.sendEventWrapper('StudentDetailsUpdated', this.#store,
      { lessonGuid: lessonGuid, givenName: givenName, familyName: familyName, regionId: regionId });
  }

  helpAcknowledged(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('HelpAcknowledged', this.#store, { lessonGuid: lessonGuid });
  }

  helpCleared(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('HelpCleared', this.#store, { lessonGuid: lessonGuid });
  }

  requestOpenWhiteboard(lessonGuid: string, activityGuid: string, questionGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('WhiteboardOpenRequested', this.#store, {
      lessonGuid: lessonGuid,
      activityGuid: activityGuid,
      questionGuid: questionGuid,
      previousLessonGuid: previousLessonGuid
    });
  }

  requestCloseWhiteboard(lessonGuid: string, activityGuid: string, questionGuid: string, previousLessonGuid: string | undefined): Observable<string> {
    return this.sendEventWrapper('WhiteboardCloseRequested', this.#store, {
      lessonGuid: lessonGuid,
      activityGuid: activityGuid,
      questionGuid: questionGuid,
      previousLessonGuid: previousLessonGuid
    });
  }

  updateFormFields(activityGuid: string, pdfFormFields: FormDataType, pdfFormCalculations: ReadingSummary): Observable<string> {
    return this.sendEventWrapper('UpdateFormFields', this.#store, {
      activityGuid: activityGuid,
      pdfFormFields: pdfFormFields,
      pdfFormCalculations: pdfFormCalculations
    });
  }

  requestOpenActivity(lessonGuid: string, activityGuid: string, questionGuid?: string, previousLessonGuid?: string, pageGuid?: string): Observable<string> {
    return this.sendEventWrapper('ActivityOpenRequested', this.#store, {
      lessonGuid: lessonGuid,
      activityGuid: activityGuid,
      questionGuid: questionGuid,
      previousLessonGuid: previousLessonGuid,
      pageGuid: pageGuid
    });
  }

  requestCloseActivity(lessonGuid: string, activityGuid: string, previousLessonGuid?: string): Observable<string> {
    return this.sendEventWrapper('ActivityCloseRequested', this.#store, { lessonGuid: lessonGuid, activityGuid: activityGuid, previousLessonGuid: previousLessonGuid });
  }

  requestOpenAssessmentResults(lessonGuid: string, bundleSelected: BundleSelected) {
    return this.sendEventWrapper('AssessmentResultsOpenRequested', this.#store, { lessonGuid: lessonGuid, bundleSelected: bundleSelected });
  }

  requestOpenAssessmentResultType(lessonGuid: string, resultType: number) {
    return this.sendEventWrapper('AssessmentResultTypeOpenRequested', this.#store, { lessonGuid: lessonGuid, resultType: resultType });
  }

  requestOpenAssessmentHome(lessonGuid: string) {
    return this.sendEventWrapper('AssessmentHomeOpenRequested', this.#store, { lessonGuid: lessonGuid });
  }

  requestExitAssessment(lessonGuid: string) {
    return this.sendEventWrapper('AssessmentExitRequested', this.#store, { lessonGuid: lessonGuid });
  }

  whiteboardGridType(lessonGuid: string, whiteboardGuidKey: WhiteboardGuidKey, gridType: WhiteboardGridType, broadcastLessonGuid: string): Observable<string> {
    return this.sendWhiteboardGridType('tutor', lessonGuid, whiteboardGuidKey.activityGuid, whiteboardGuidKey.pageGuid, gridType, broadcastLessonGuid);
  }

  whiteboardEvent(lessonGuid: string, whiteboardGuidKey: WhiteboardGuidKey, event: WhiteboardEvent, broadcastLessonGuid: string): Observable<string> {
    return this.sendWhiteboardEvent('tutor', this.#store, lessonGuid, whiteboardGuidKey.activityGuid, whiteboardGuidKey.pageGuid, event, broadcastLessonGuid);
  }

  requestVideoEffect(lessonGuid: string, videoEffectType: VideoEffectType): Observable<string> {
    return this.sendEventWrapper('VideoEffectRequested', this.#store, { lessonGuid: lessonGuid, videoEffectType: videoEffectType });
  }

  updateScreenShareSetting(lessonGuid: string, disableScreenShare: boolean): Observable<string> {
    return this.sendEventWrapper('ScreenShareSettingUpdated', this.#store,
      { lessonGuid: lessonGuid, disableScreenShare: disableScreenShare });
  }

  requestOpenLessonEnrolment(lessonGuid: string) {
    return this.sendEventWrapper('LessonEnrolmentOpenRequested', this.#store, { lessonGuid: lessonGuid });
  }

  updateLessonEnrolmentProgress(lessonGuid: string, stage: LessonEnrolmentStage) {
    return this.sendEventWrapper('LessonEnrolmentProgressUpdated', this.#store, { lessonGuid: lessonGuid, stage: stage });
  }

  updateLessonEnrolmentPaymentOptions(lessonGuid: string, startDate: DateStruct, debitDay: DayOfWeek, upfrontPayment: number) {
    return this.sendEventWrapper('LessonEnrolmentPaymentOptionsUpdated', this.#store,
      { lessonGuid: lessonGuid, startDate: startDate, debitDay: debitDay, upfrontPaymentOption: upfrontPayment });
  }

  updateLessonEnrolmentSubjects(lessonGuid: string, subjects: Subject[]) {
    return this.sendEventWrapper('LessonEnrolmentSubjectsUpdated', this.#store, { lessonGuid: lessonGuid, subjects: subjects });
  }

  updateLessonEnrolmentSessions(lessonGuid: string, sessions: LessonEnrolmentSessionSchedule[]) {
    return this.sendEventWrapper('LessonEnrolmentSessionsUpdated', this.#store, { lessonGuid: lessonGuid, sessions: sessions });
  }

  updateLessonEnrolmentBundle(lessonGuid: string, bundleId: number, billingInterval: BillingIntervalType) {
    return this.sendEventWrapper('LessonEnrolmentBundleUpdated', this.#store, {
      lessonGuid: lessonGuid, bundleId: bundleId,
      billingInterval: billingInterval
    });
  }

  updateLessonEnrolmentPaymentEntry(lessonGuid: string, assessorEnteringPayment: boolean) {
    return this.sendEventWrapper('LessonEnrolmentPaymentEntrySelected', this.#store, {
      lessonGuid: lessonGuid,
      assessorEnteringPayment: assessorEnteringPayment
    });
  }

  updateLessonEnrolmentCustomerRepresents(lessonGuid: string, customerRepresentsOrganisation: boolean) {
    return this.sendEventWrapper('LessonEnrolmentCustomerRepresentsUpdated', this.#store, {
      lessonGuid: lessonGuid,
      customerRepresentsOrganisation: customerRepresentsOrganisation
    });
  }

  attendLesson(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('LessonAttend', this.#store, { lessonGuid: lessonGuid });
  }

  deferLesson(lessonGuid: string): Observable<string> {
    return this.sendEventWrapper('LessonDefer', this.#store, { lessonGuid: lessonGuid });
  }

  lessonDoItYourselfToggle(lessonGuid: string, doItYourself: boolean): Observable<string> {
    return this.sendEventWrapper('LessonDoItYourselfToggle', this.#store, { lessonGuid: lessonGuid, doItYourself: doItYourself });
  }

  override onReceiveEventTrue() {
    this.#store.dispatch(fromSession.updateSignalRConnectionStateAction({ connected: this.isConnectedAndReceivedEvent() }));
  }

  // these tokbox messages can sometimes go nuts, so blocking them after 10 messages in a lesson/session

  screenSharePublishFailure(sessionId: number, name: string, message: string): Observable<string> {
    return this.sendEventWrapperLimited('TutorScreenSharePublishFailure', this.#store, sessionId, { sessionId: sessionId, name: name, message: message });
  }

  screenShareStreamDestroyed(sessionId: number, reason: string): Observable<string> {
    return this.sendEventWrapperLimited('TutorScreenShareStreamDestroyed', this.#store, sessionId, { sessionId: sessionId, reason: reason });
  }

  publishFailure(sessionId: number, name: string, message: string): Observable<string> {
    return this.sendEventWrapperLimited('TutorPublishFailure', this.#store, sessionId, { sessionId: sessionId, name: name, message: message });
  }

  streamDestroyed(sessionId: number, reason: string): Observable<string> {
    return this.sendEventWrapperLimited('TutorStreamDestroyed', this.#store, sessionId, { sessionId: sessionId, reason: reason });
  }

  subscribeFailure(lessonGuid: string, name: string, message: string): Observable<string> {
    return this.sendEventWrapperLimited('TutorSubscribeFailure', this.#store, lessonGuid, { lessonGuid: lessonGuid, name: name, message: message });
  }

  screenSharePublishDenied(sessionId: number): Observable<string> {
    return this.sendEventWrapperLimited('TutorScreenSharePublishDenied', this.#store, sessionId, { sessionId: sessionId });
  }

  screenSharePublishAllowed(sessionId: number): Observable<string> {
    return this.sendEventWrapperLimited('TutorScreenSharePublishAllowed', this.#store, sessionId, { sessionId: sessionId });
  }

  publishDenied(sessionId: number): Observable<string> {
    return this.sendEventWrapperLimited('TutorPublishDenied', this.#store, sessionId, { sessionId: sessionId });
  }

  publishAllowed(sessionId: number): Observable<string> {
    return this.sendEventWrapperLimited('TutorPublishAllowed', this.#store, sessionId, { sessionId: sessionId });
  }

  completeDropInLesson(lessonId: number): Observable<string> {
    return this.sendEventWrapper('CompleteDropInLesson', this.#store, { lessonId: lessonId });
  }

  private sendEventWrapperLimited<TState>(name: EventType, store: Store<TState>, key: number | string, args: { [field: string]: any }) {
    const limitKey = `${name}-${key}`;
    const maxValue = 10;
    const value = this.#keyLimits.get(limitKey);

    if (value !== undefined) {
      if (value < maxValue) {
        this.#keyLimits.set(limitKey, value + 1);

        if (value === maxValue - 1) {
          console.log(`Messages blocked for ${name}`);
        }

        return this.sendEventWrapper(name, store, args);
      }
    } else {
      this.#keyLimits.set(limitKey, 1);
      return this.sendEventWrapper(name, store, args);
    }

    const promise = Promise.resolve('');

    return from(promise);
  }

  private sendEventWrapper<TState>(name: EventType, store: Store<TState>, args: { [field: string]: any }) {
    this.#store.dispatch(fromSession.updateSignalRConnectionStateAction({
      connected: this.isConnectedAndReceivedEvent()
    }));
    return this.sendEvent(name, store, args);
  }
}
