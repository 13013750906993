/* eslint  @typescript-eslint/naming-convention : 0 */

import { createSelector } from '@ngrx/store';
import { EventType, LessonChat, LessonLogAuthor, Message, MessageSubType } from 'message-lib';

import { LessonActivity, SelectedLesson } from '../../models';
import { selectLessonActivities } from '../activity';
import { selectSelectedLesson } from '../lesson';
import { selectChatEntities, selectChatEntityIds, selectChatState } from '../state';
import { ChatState } from './chat.reducer';

type MessageFormatter = {
  readonly [eventType in EventType]?: (lesson: SelectedLesson, activities: LessonActivity[] | undefined, chat: LessonChat) => string;
};

const tutorFormatters: MessageFormatter = {
  'Chat': (_lesson, _activities, chat) => chat.values.message,
  'SystemChat': (_lesson, _activities, chat) => chat.values.message
};

const studentFormatters: MessageFormatter = {
  'Chat': (_lesson, _activities, chat) => chat.values.message,
  'SystemChat': (_lesson, _activities, chat) => chat.values.message
};

export const selectLessonMessages = createSelector(
  selectChatEntityIds, selectChatEntities, selectSelectedLesson, selectLessonActivities, (ids, entities, lesson, activities) => {
    // Map the logs for the lesson log ids
    let messages: (Message | undefined)[] = [];

    if (lesson) {
      const entityIds = ids[lesson.lessonGuid];
      let chatGroups: string[] = [];

      if (entityIds) {
        messages = entityIds.map(entityId => {
          const entity = entities[entityId];

          if (entity && // Multiples of the same log will be defined for the tutor - one for each lesson
            // We therefore need to filter them by event group to show a single instance
            !chatGroups.includes(entity.chatGroup)) {

            // Find the event type formatter and build the message object
            const formatters = entity.author === LessonLogAuthor.Student ? studentFormatters : tutorFormatters;
            const formatter = formatters[entity.eventType];

            if (formatter) {
              const text = formatter(lesson, activities, entity);

              if (text) {
                // Track the log group for filtering
                chatGroups = [...chatGroups, entity.chatGroup];

                let sender = '';
                switch (entity.author) {
                  case LessonLogAuthor.AI:
                    sender = 'Monty AI';
                    break;
                  case LessonLogAuthor.Student:
                    sender = 'Student (me)';
                    break;
                  default:
                    sender = lesson.tutor.givenName;
                }

                return {
                  id: entity.eventId,
                  type: entity.eventType,
                  subType: entity.author === LessonLogAuthor.Student ? MessageSubType.ChatOutgoing : MessageSubType.ChatIncoming,
                  timestamp: entity.timestamp,
                  text: text,
                  warning: entity.values.warning,
                  lessonGuid: lesson.lessonGuid,
                  sender: sender
                };
              }
            }
          }

          return undefined;
        });
      }
    }

    return messages.filter((message): message is Message => message !== undefined);
  });

export const selectRecipientsTyping = createSelector(selectChatState, (state: ChatState) => state.recipientsTyping);
