import { createAction, props } from '@ngrx/store';
import { BundleSelected, LessonEnrolment, LessonEnrolmentSessionSchedule, LessonEnrolmentStage } from 'enrolment-lib';
import { DateStruct } from 'moment-extensions-lib';
import { BillingIntervalType, LessonStatus, LessonType, Subject, VideoEffectType } from 'ui-common-lib';

import { AssessmentStatus, AwardData, DIYUpdateResult, IdleStatus } from '../../../shared';
import { Lesson, SubjectGrades, TreeSummary } from '../../models';

enum LessonActionTypes {
  AcknowledgeHelp = 'Student > Lesson > Help > Acknowledge',
  AddDIY = 'Student > Lesson > Add DIY',
  AiAssessmentFinished = 'Student > Lesson > AI Assessment Finished',
  AiChat = 'Student > Lesson > AI Chat',
  AvatarHide = 'Student > Avatar > Hide',
  AvatarShow = 'Student > Avatar > Show',
  AwardGiven = 'Student > Lesson > Award Given',
  CancelInstallApp = 'Student > Cancel Install App',
  Chat = 'Student > Lesson > Chat',
  ClearHelp = 'Student > Lesson > Help > Clear',
  ClearMessage = 'Student > Lesson > Clear Message',
  Close = 'Student > Lesson > Close',
  CloseLobby = 'Student > Lesson > Close Lobby',
  CloseSession = 'Student > Lesson > Session > Close',
  DisconnectLesson = 'Student > Lesson > Disconnect',
  DisconnectSession = 'Student > Lesson > Session > Disconnect',
  DropInLessonRequestAcceptedAction = 'Student > Lesson > Drop In Lesson Request Accepted',
  DropInLessonRequestDeniedAction = 'Student > Lesson > Drop In Lesson Request Denied',
  DropInLessonCompleted = 'Student > Lesson > Drop In Lesson Completed',
  ExitAssessment = 'Student > Lesson > Exit Assessment',
  Finish = 'Student > Lesson > Finish',
  ForceReload = 'Student > Lesson > Force Reload',
  ForceReloadVideo = 'Student > Lesson > Force Reload Video',
  Get = 'Student > Lesson > Get',
  InstallApp = 'Student  > Install App',
  InstallAvailable = 'Student > Install Available',
  JoinLessonCompleted = 'Student > Join Lesson Completed',
  GetLoading = 'Student > Lesson > Get Loading',
  Load = 'Student > Lesson > Load',
  LoadEnrolment = 'Student > Lesson > Load Enrolment',
  Open = 'Student > Lesson > Open',
  OpenAssessmentHome = 'Student > Lesson > Open Assessment Home',
  OpenAssessmentResults = 'Student > Lesson > Open Assessment Results',
  OpenAssessmentResultType = 'Student > Lesson > Open Assessment Result Type',
  OpenLessonEnrolment = 'Student > Lesson > Open Lesson Enrolment',
  OpenLobby = 'Student > Lesson > Open Lobby',
  OpenSession = 'Student > Lesson > Session > Open',
  PublishAllowed = 'Student > Lesson > Publish Allowed',
  PublishDenied = 'Student > Lesson > Publish Denied',
  PublishFailure = 'Student > Lesson > Publish Failure',
  PullFromLobby = 'Student > Lesson > Pull From Lobby ',
  Reconnect = 'Student > Lesson > Session > Reconnect',
  RefreshAwardData = 'Student > Lesson > Refresh Award Data',
  RefreshTreeSummary = 'Student > Lesson > Refresh Tree Summary',
  Rendered = 'Student > Lesson > Rendered',
  RequestHelp = 'Student > Lesson > Help > Request',
  ResetPassword = 'Student > Lesson > Reset Password',
  ScreenSharePublishAllowed = 'Student > Lesson > Screen Share Publish Allowed',
  ScreenSharePublishDenied = 'Student > Lesson > Screen Share Publish Denied',
  ScreenSharePublishFailure = 'Student > Lesson > Screen Share Publish Failure',
  ScreenShareSettingsUpdate = 'Student > Lesson > Screen Share Setting Update',
  ScreenShareStreamDestroyed = 'Student > Lesson > Screen Share Stream Destroyed',
  UpdateIdleStatus = 'Student > Lesson > Update Idle Status',
  StartedLookingTab = 'Student > Lesson > Started Looking Tab',
  StartObserving = 'Student > Lesson > Start Observing',
  StartTeaching = 'Student > Lesson > Start Teaching',
  StartTeachingClass = 'Student > Lesson > Start Teaching Class',
  StopObserving = 'Student > Lesson > Stop Observing',
  StoppedLookingTab = 'Student > Lesson > Stopped Looking Tab',
  StopTeaching = 'Student > Lesson > Stop Teaching',
  StopTeachingClass = 'Student > Lesson > Stop Teaching Class',
  StreamConnected = 'Student > Lesson > Stream Connected',
  StreamDestroyed = 'Student > Lesson > Stream Destroyed',
  StudentDetailsUpdated = 'Student > Lesson > Student Details Updated',
  StudentLogout = 'Student > Lesson > Student Logout',
  StudentPhotoLoad = 'Student > Lesson > Student Photo Load',
  StudentPhotoUpdate = 'Student > Lesson > Student Photo Update',
  SubscribeFailure = 'Student > Lesson > Subscribe Failure',
  ToggleAudio = 'Student > Lesson > Toggle Audio',
  ToggleOnline = 'Student > Lesson > Toggle Online',
  ToggleVideo = 'Student > Lesson > Toggle Video',
  TutorPhotoLoad = 'Student > Lesson > Tutor Photo Load',
  TutorPhotoUpdate = 'Student > Lesson > Tutor Photo Update',
  TutorPublishAllowed = 'Student > Lesson > Tutor Publish Allowed',
  TutorPublishDenied = 'Student > Lesson > Tutor Publish Denied',
  TutorPublishFailure = 'Student > Lesson > Tutor Publish Failure',
  UpdateAnimationStateQuestion = 'Student > Lesson > Update Animation State Question',
  UpdateAnimationStateComplete = 'Student > Lesson > Update Animation State Complete',
  Update = 'Student > Lesson > Update',
  UpdateAINote = 'Student > Lesson > Update AI Note',
  UpdateAwardData = 'Student > Lesson > Update Award Data',
  UpdateTreesSummary = 'Student > Lesson > Update Trees Summary',
  UpdateLessonDoItYourself = 'Student > Lesson > Update Lesson Do It Yourself',
  UpdateAssessmentStatus = 'Student > Lesson > Update Assessment Status',
  UpdateEnrolmentAccountCreated = 'Student > Lesson > Update Account Created',
  UpdateEnrolmentBundle = 'Student > Lesson > Update Enrolment Bundle',
  UpdateEnrolmentBundleCurrency = 'Student > Lesson > Update Enrolment Bundle Currency',
  UpdateEnrolmentBundleType = 'Student > Lesson > Update Enrolment Bundle Type',
  UpdateEnrolmentPaymentEntry = 'Student > Lesson > Update Payment Entry',
  UpdateEnrolmentPaymentOptions = 'Student > Lesson > Update Payment Options',
  UpdateEnrolmentProgress = 'Student > Lesson > Update Enrolment Progress',
  UpdateEnrolmentSessions = 'Student > Lesson > Update Enrolment Sessions',
  UpdateEnrolmentSubjects = 'Student > Lesson > Update Enrolment Subjects',
  UpdateGradesAchieved = 'Student > Lesson > Update Grades Achieved',
  UpdateFinishLessonNote = 'Student > Lesson > Update Finish LessonNote',
  UpdateLessonSettings = 'Student > Lesson > Update Lesson Settings',
  UpdateSignalRConnectionState = 'Student > Lesson > SignalR > Connection',
  UpdateStartLessonNote = 'Student > Lesson > Update Start Lesson Note',
  VideoEffectRequested = 'Student > Lesson > Video Effect Requested'
}

export const updateAINoteAction = createAction(
  LessonActionTypes.UpdateAINote,
  props<{
    readonly lessonGuid: string;
    readonly note: string;
  }>()
);

export const aiAssessmentFinished = createAction(
  LessonActionTypes.AiAssessmentFinished,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const avatarShowAction = createAction(
  LessonActionTypes.AvatarShow
);

export const avatarHideAction = createAction(
  LessonActionTypes.AvatarHide
);

export const updateAnimationStateQuestionAction = createAction(
  LessonActionTypes.UpdateAnimationStateQuestion,
  props<{
    correct: boolean;
  }>()
);

export const updateAnimationStateCompleteAction = createAction(
  LessonActionTypes.UpdateAnimationStateComplete,
  props<{
    percentage: number | null | undefined;
    isAssessment: boolean | undefined;
  }>()
);

export const updateStartLessonNoteAction = createAction(
  LessonActionTypes.UpdateStartLessonNote,
  props<{
    readonly lessonGuid: string;
    readonly note: string;
    readonly noteRanking: number | null;
  }>()
);

export const updateFinishLessonNoteAction = createAction(
  LessonActionTypes.UpdateFinishLessonNote,
  props<{
    readonly lessonGuid: string;
    readonly note: string;
    readonly noteRanking: number | null;
  }>()
);

export const clearMessageAction = createAction(
  LessonActionTypes.ClearMessage,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const addDIYAction = createAction(
  LessonActionTypes.AddDIY,
  props<{
    readonly lessonId: number;
    readonly isHomework: boolean;
  }>()
);

export const cancelInstallAppAction = createAction(
  LessonActionTypes.CancelInstallApp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const installAppAction = createAction(
  LessonActionTypes.InstallApp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const installAvailableAction = createAction(
  LessonActionTypes.InstallAvailable,
  props<{
    readonly lessonGuid: string;
    readonly available: boolean;
  }>()
);

export const updateLessonDoItYourselfAction = createAction(
  LessonActionTypes.UpdateLessonDoItYourself,
  props<{
    readonly lessonGuid: string;
    readonly isOnline: boolean;
    readonly lessonType: LessonType;
    readonly lessonStatus: LessonStatus;
    readonly updateResult: DIYUpdateResult;
  }>()
);

export const refreshAwardDataAction = createAction(
  LessonActionTypes.RefreshAwardData,
  props<{
    readonly lessonId: number;
    readonly lessonGuid: string;
  }>()
);

export const refreshTreeSummaryAction = createAction(
  LessonActionTypes.RefreshTreeSummary,
  props<{
    activityGuid: string;
  }>()
);

export const updateGradesAchievedAction = createAction(
  LessonActionTypes.UpdateGradesAchieved,
  props<{
    readonly lessonGuid: string;
    readonly subjects: SubjectGrades[];
  }>()
);

export const updateTreeSummaryAction = createAction(
  LessonActionTypes.UpdateTreesSummary,
  props<{
    readonly lessonGuid: string;
    readonly treeSummaryIndex: number;
    readonly treesSummaries: TreeSummary[];
  }>()
);

export const updateAwardDataAction = createAction(
  LessonActionTypes.UpdateAwardData,
  props<{
    readonly lessonGuid: string;
    readonly awardData: AwardData;
  }>()
);

export const getAction = createAction(
  LessonActionTypes.Get,
  props<{
    readonly lessonId: number;
    readonly isHomework: boolean;
    readonly lessonActivityPlanId?: number;
  }>()
);

export const loadAction = createAction(
  LessonActionTypes.Load,
  props<{
    readonly lesson: Lesson;
  }>()
);

export const renderedAction = createAction(
  LessonActionTypes.Rendered,
  props<{
    readonly lessonGuid: string;
    readonly rendered: Date;
  }>()
);

export const loadEnrolmentAction = createAction(
  LessonActionTypes.LoadEnrolment,
  props<{
    readonly lessonGuid: string;
    readonly lessonEnrolment: LessonEnrolment;
  }>()
);

export const updateEnrolmentProgressAction = createAction(
  LessonActionTypes.UpdateEnrolmentProgress,
  props<{
    readonly lessonGuid: string;
    readonly enrolmentStage: LessonEnrolmentStage;
  }>()
);

export const updateEnrolmentSubjectsAction = createAction(
  LessonActionTypes.UpdateEnrolmentSubjects,
  props<{
    readonly lessonGuid: string;
    readonly subjects: Subject[];
  }>()
);

export const updateEnrolmentSessionsAction = createAction(
  LessonActionTypes.UpdateEnrolmentSessions,
  props<{
    readonly lessonGuid: string;
    readonly sessions: LessonEnrolmentSessionSchedule[];
  }>()
);

export const updateEnrolmentPaymentOptionsAction = createAction(
  LessonActionTypes.UpdateEnrolmentPaymentOptions,
  props<{
    readonly lessonGuid: string;
    readonly debitDay: number;
    readonly startDate: DateStruct;
    readonly upfrontPaymentOption: number;
  }>()
);

export const updateEnrolmentPaymentEntryAction = createAction(
  LessonActionTypes.UpdateEnrolmentPaymentEntry,
  props<{
    readonly lessonGuid: string;
    readonly assessorEnteringPayment: boolean;
  }>()
);

export const updateEnrolmentBundleTypeAction = createAction(
  LessonActionTypes.UpdateEnrolmentBundleType,
  props<{
    readonly lessonGuid: string;
    readonly bundleOfferTypeId: number;
  }>()
);

export const updateEnrolmentBundleCurrencyAction = createAction(
  LessonActionTypes.UpdateEnrolmentBundleCurrency,
  props<{
    readonly lessonGuid: string;
    readonly bundleCurrency: string;
  }>()
);

export const updateEnrolmentBundleAction = createAction(
  LessonActionTypes.UpdateEnrolmentBundle,
  props<{
    readonly lessonGuid: string;
    readonly bundleOfferId: number;
    readonly billingInterval: BillingIntervalType;
    readonly customerRepresentsOrganisation: boolean;
  }>()
);

export const updateEnrolmentAccountCreatedAction = createAction(
  LessonActionTypes.UpdateEnrolmentAccountCreated,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const updateAssessmentStatusAction = createAction(
  LessonActionTypes.UpdateAssessmentStatus,
  props<{
    readonly lessonGuid: string;
    readonly assessmentStatus: AssessmentStatus;
  }>()
);

export const startedLookingTabAction = createAction(
  LessonActionTypes.StartedLookingTab,
  props<{
    readonly lessonId: number;
  }>()
);

export const stoppedLookingTabAction = createAction(
  LessonActionTypes.StoppedLookingTab,
  props<{
    readonly lessonId: number;
  }>()
);

export const updateIdleStatusAction = createAction(
  LessonActionTypes.UpdateIdleStatus,
  props<{
    readonly lessonId: number;
    readonly idleStatus: IdleStatus;
  }>()
);

export const openLobbyAction = createAction(
  LessonActionTypes.OpenLobby,
  props<{
    readonly lessonId: number;
  }>()
);

export const closeLobbyAction = createAction(
  LessonActionTypes.CloseLobby,
  props<{
    readonly lessonId: number;
  }>()
);

export const tutorPhotoLoadAction = createAction(
  LessonActionTypes.TutorPhotoLoad,
  props<{
    readonly lessonGuid: string;
    readonly tutorId: number;
  }>()
);

export const tutorPhotoUpdateAction = createAction(
  LessonActionTypes.TutorPhotoUpdate,
  props<{
    readonly lessonGuid: string;
    readonly tutorPhotoUrl: string;
    readonly tutorPhotoDefault: boolean;
  }>()
);

export const studentPhotoLoadAction = createAction(
  LessonActionTypes.StudentPhotoLoad,
  props<{
    readonly lessonGuid: string;
    readonly studentId: number;
  }>()
);

export const studentPhotoUpdateAction = createAction(
  LessonActionTypes.StudentPhotoUpdate,
  props<{
    readonly lessonGuid: string;
    readonly studentPhotoUrl: string;
    readonly studentPhotoDefault: boolean;
  }>()
);

export const openAction = createAction(
  LessonActionTypes.Open,
  props<{
    readonly lessonId: number;
    readonly isHomework: boolean;
    readonly lessonActivityPlanId?: number;
  }>()
);

export const joinLessonCompletedAction = createAction(
  LessonActionTypes.JoinLessonCompleted,
  props<{
    readonly lessonGuid: string;
    readonly connectionId: string;
  }>()
);

export const studentLogoutAction = createAction(LessonActionTypes.StudentLogout,
  props<{
    readonly showLogoutMessage: boolean | false;
    readonly logoutMessage: string | undefined;
  }>());

export const closeAction = createAction(
  LessonActionTypes.Close,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const finishAction = createAction(
  LessonActionTypes.Finish,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const updateAction = createAction(
  LessonActionTypes.Update,
  props<{
    readonly lesson: Lesson;
  }>()
);

export const chatAction = createAction(
  LessonActionTypes.Chat,
  props<{
    readonly lessonGuid: string;
    readonly message: string;
    readonly chatGroup: string;
    readonly warning: boolean;
  }>()
);

export const screenShareStreamDestroyedAction = createAction(
  LessonActionTypes.ScreenShareStreamDestroyed,
  props<{
    readonly lessonGuid: string;
    readonly reason: string;
  }>()
);

export const streamDestroyedAction = createAction(
  LessonActionTypes.StreamDestroyed,
  props<{
    readonly lessonGuid: string;
    readonly reason: string;
  }>()
);

export const subscribeFailureAction = createAction(
  LessonActionTypes.SubscribeFailure,
  props<{
    readonly lessonGuid: string;
    readonly name: string;
    readonly message: string;
  }>()
);

export const screenSharePublishAllowedAction = createAction(
  LessonActionTypes.ScreenSharePublishAllowed,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const screenSharePublishDeniedAction = createAction(
  LessonActionTypes.ScreenSharePublishDenied,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const screenSharePublishFailureAction = createAction(
  LessonActionTypes.ScreenSharePublishFailure,
  props<{
    readonly lessonGuid: string;
    readonly name: string;
    readonly message: string;
  }>()
);

export const publishAllowedAction = createAction(
  LessonActionTypes.PublishAllowed,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const publishDeniedAction = createAction(
  LessonActionTypes.PublishDenied,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const publishFailureAction = createAction(
  LessonActionTypes.PublishFailure,
  props<{
    readonly lessonGuid: string;
    readonly name: string;
    readonly message: string;
  }>()
);

export const tutorPublishAllowedAction = createAction(LessonActionTypes.TutorPublishAllowed);

export const tutorPublishDeniedAction = createAction(LessonActionTypes.TutorPublishDenied);

export const tutorPublishFailureAction = createAction(
  LessonActionTypes.TutorPublishFailure,
  props<{
    readonly name: string;
    readonly message: string;
  }>()
);

export const toggleOnlineAction = createAction(
  LessonActionTypes.ToggleOnline,
  props<{
    readonly lessonGuid: string;
    readonly isOnline: boolean;
  }>()
);

export const pullFromLobbyAction = createAction(
  LessonActionTypes.PullFromLobby,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const forceReloadAction = createAction(
  LessonActionTypes.ForceReload,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const forceReloadVideoAction = createAction(
  LessonActionTypes.ForceReloadVideo,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const toggleVideoAction = createAction(
  LessonActionTypes.ToggleVideo,
  props<{
    readonly lessonGuid: string;
    readonly publish: boolean;
  }>()
);

export const toggleAudioAction = createAction(
  LessonActionTypes.ToggleAudio,
  props<{
    readonly lessonGuid: string;
    readonly publish: boolean;
  }>()
);

export const startTeachingAction = createAction(
  LessonActionTypes.StartTeaching,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const stopTeachingAction = createAction(
  LessonActionTypes.StopTeaching,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const startTeachingClassAction = createAction(
  LessonActionTypes.StartTeachingClass,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const stopTeachingClassAction = createAction(
  LessonActionTypes.StopTeachingClass,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const startObservingAction = createAction(
  LessonActionTypes.StartObserving,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const stopObservingAction = createAction(
  LessonActionTypes.StopObserving,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const resetPassword = createAction(
  LessonActionTypes.ResetPassword,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const requestHelpAction = createAction(
  LessonActionTypes.RequestHelp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const acknowledgeHelpAction = createAction(
  LessonActionTypes.AcknowledgeHelp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const clearHelpAction = createAction(
  LessonActionTypes.ClearHelp,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const openSessionAction = createAction(
  LessonActionTypes.OpenSession,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const closeSessionAction = createAction(
  LessonActionTypes.CloseSession,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const disconnectLessonAction = createAction(LessonActionTypes.DisconnectLesson,
  props<{
    readonly logoutUser: boolean;
    readonly showLogoutMessage: boolean | false;
    readonly logoutMessage: string | undefined;
  }>());

export const disconnectSessionAction = createAction(
  LessonActionTypes.DisconnectSession,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const reconnectAction = createAction(LessonActionTypes.Reconnect);

export const updateSignalRConnectionStateAction = createAction(
  LessonActionTypes.UpdateSignalRConnectionState,
  props<{
    readonly connected: boolean;
  }>()
);

export const awardGivenAction = createAction(
  LessonActionTypes.AwardGiven,
  props<{
    readonly lessonId: number;
    readonly lessonGuid: string;
  }>()
);

export const videoEffectRequestedAction = createAction(
  LessonActionTypes.VideoEffectRequested,
  props<{
    readonly videoEffectType: VideoEffectType;
    readonly lessonGuid: string;
  }>()
);

export const screenShareSettingUpdatedAction = createAction(
  LessonActionTypes.ScreenShareSettingsUpdate,
  props<{
    readonly disableScreenShare: boolean;
    readonly lessonGuid: string;
  }>()
);

export const openAssessmentResultsAction = createAction(
  LessonActionTypes.OpenAssessmentResults,
  props<{
    readonly lessonGuid: string;
    readonly lessonId: number;
    readonly bundleSelected: BundleSelected;
  }>()
);

export const openAssessmentResultTypeAction = createAction(
  LessonActionTypes.OpenAssessmentResultType,
  props<{
    readonly lessonGuid: string;
    readonly resultType: number;
  }>()
);

export const openAssessmentHomeAction = createAction(
  LessonActionTypes.OpenAssessmentHome,
  props<{
    readonly lessonGuid: string;
    readonly lessonId: number;
  }>()
);

export const exitAssessmentAction = createAction(
  LessonActionTypes.ExitAssessment,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const openLessonEnrolmentAction = createAction(
  LessonActionTypes.OpenLessonEnrolment,
  props<{
    readonly lessonGuid: string;
    readonly lessonId: number;
  }>()
);

export const studentDetailsUpdatedAction = createAction(
  LessonActionTypes.StudentDetailsUpdated,
  props<{
    readonly givenName: string;
    readonly familyName: string;
    readonly regionId: number | null;
  }>()
);

export const getLoadingAction = createAction(
  LessonActionTypes.GetLoading,
  props<{
    readonly isLoading: boolean;
  }>()
);

export const streamConnectedAction = createAction(
  LessonActionTypes.StreamConnected,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const dropInLessonCompletedAction = createAction(
  LessonActionTypes.DropInLessonCompleted,
  props<{
    readonly lessonGuid: string;
  }>()
);

export const dropInLessonRequestAcceptedAction = createAction(
  LessonActionTypes.DropInLessonRequestAcceptedAction,
  props<{
    readonly lessonId: number;
  }>()
);
