import { inject, Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { SelectedLesson } from '../models';
import { fromLesson, StudentState } from '../store';

@Injectable({ providedIn: 'root' })
export class LookingTabService implements OnDestroy {

  readonly #store = inject(Store<StudentState>);

  #lesson: SelectedLesson | undefined;
  #lessonSubscription: Subscription | undefined;

  constructor() {
    document.addEventListener('visibilitychange', () => {
      if (this.#lesson) {
        if (document.visibilityState === 'visible') {
          this.#store.dispatch(
            fromLesson.startedLookingTabAction({ lessonId: this.#lesson.lessonId })
          );
        } else {
          this.#store.dispatch(
            fromLesson.stoppedLookingTabAction({ lessonId: this.#lesson.lessonId })
          );
        }
      }
    });

    this.#lessonSubscription = this.#store
      .select(fromLesson.selectSelectedLesson)
      .subscribe(lesson => {
        this.#lesson = lesson;
      });
  }

  ngOnDestroy() {
    if (this.#lessonSubscription) {
      this.#lessonSubscription.unsubscribe();
      this.#lessonSubscription = undefined;
    }
  }
}
