import { createAction, props } from '@ngrx/store';
import { ComprehensionTest } from 'ui-common-lib';

enum ComprehensionTestActionTypes {
  Load = 'Student > Comprehension Tests > Load',
  LoadMany = 'Student > Comprehension Tests > Load Many'
}

export const loadComprehensionTestsAction = createAction(ComprehensionTestActionTypes.Load);

export const loadComprehensionTestsManyAction = createAction(
  ComprehensionTestActionTypes.LoadMany,
  props<{
    readonly comprehensionTest: readonly ComprehensionTest[];
  }>()
);
