import { ChangeDetectionStrategy, Component, computed, inject, input, resource, signal } from '@angular/core';
import { Icons } from 'icon-lib';
import { firstValueFrom } from 'rxjs';

import { AIRole } from '../../../models';
import { StudentService } from '../../../services';

@Component({
    selector: 'kip-student-ai-chat',
    templateUrl: './student-ai-chat.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class StudentAIChatComponent {

    readonly #studentService = inject(StudentService);

    readonly aiRole = AIRole;
    readonly icons = Icons;
    readonly studentId = input.required<number>();
    readonly showSystemPrompt = signal(false);
    readonly aiFilter = input('');

    readonly aiDatesResource = resource({
        request: () => ({ id: this.studentId(), systemPrompt: this.showSystemPrompt(), filter : this.aiFilter() }),
        loader: async param => await firstValueFrom(this.#studentService.getAIDateSummaries(param.request.id, param.request.systemPrompt, param.request.filter))
    });
    readonly studentSummary = computed(() => this.aiDatesResource.value());
    readonly aiDates = computed(() => this.aiDatesResource.value()?.dates ?? []);
}