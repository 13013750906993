/* eslint  @typescript-eslint/naming-convention : 0 */

import { CdkDragMove } from '@angular/cdk/drag-drop';
import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, effect, ElementRef, inject, input, OnDestroy, OnInit,
  output, signal, viewChild
} from '@angular/core';
import { Icons } from 'icon-lib';
import RecordRTC, { StereoAudioRecorder } from 'recordrtc';
import { concat, delay, ignoreElements, interval, map, Observable, of, Subscription, take, tap } from 'rxjs';
import { ProfileService, StudentSettings, UserProfile } from 'ui-common-lib';

import { AITutorTexts } from '../../models';
import { SoundService } from '../../services';

interface TypeParams {
  message: string;
  speed: number;
}

@Component({
  selector: 'kip-monty-ai-messages',
  templateUrl: './monty-ai-messages.component.html',
  styleUrl: './monty-ai-messages.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class MontyAiMessagesComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #soundService = inject(SoundService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);

  #userProfile: UserProfile | undefined;
  #audio = new Audio();
  #aiTutorTexts: AITutorTexts | undefined;
  #mediaRecorder: RecordRTC | undefined;
  #isRecording = signal(false);
  #isPlaying = signal(false);
  #talking = signal(false);
  #thinking = signal(false);
  #loaded = false;
  #subscriptions: Subscription[] = [];
  #chatGroupId: string | undefined;
  #messages: string[] = [];
  #currentMessage = '';
  #dragStyle: string | null | undefined;

  readonly icons = Icons;
  readonly talking = this.#talking.asReadonly();
  readonly thinking = this.#thinking.asReadonly();
  readonly isRecording = this.#isRecording.asReadonly();
  readonly isPlaying = this.#isPlaying.asReadonly();

  readonly messages = input.required<string[]>();
  readonly chatGroupId = input.required<string>();

  get aiTutorActive() {
    if (this.#userProfile) {
      const studentSettings = this.#userProfile.settings as StudentSettings;
      return studentSettings.aiTutorActive;
    }

    return false;
  }

  get aiTutorVoiceActive() {
    if (this.#userProfile) {
      const studentSettings = this.#userProfile.settings as StudentSettings;
      return studentSettings.aiTutorVoiceActive;
    }

    return false;
  }

  get aiTutorVoiceId() {
    if (this.#userProfile) {
      const studentSettings = this.#userProfile.settings as StudentSettings;
      return studentSettings.aiTutorVoiceId;
    }

    return '';
  }

  $currentMessage: Observable<string> | undefined;

  readonly input = viewChild<ElementRef<HTMLInputElement>>('input');
  readonly montyContainer = viewChild<ElementRef<HTMLElement>>('dragContainer');
  readonly sendMessage = output<string>();
  readonly aiTutorTextsLoaded = output<AITutorTexts>();

  constructor() {
    effect(() => {
      this.#sayMessages(this.messages());
    });

    effect(() => {
      this.#clearOnChange(this.chatGroupId());
    });
  }

  ngOnInit(): void {

    // Listen for play, pause, and ended events
    this.#audio.addEventListener('playing', () => {
      this.#isPlaying.set(true);
    });
    this.#audio.addEventListener('pause', () => {
      this.#isPlaying.set(false);
    });
    this.#audio.addEventListener('ended', () => {
      this.#isPlaying.set(false);
    });

    this.#subscriptions.push(
      this.#profileService.trackUserProfile().subscribe(profile => {
        this.#userProfile = profile;
        this.#changeDetectorRef.detectChanges();
      }),
      this.#soundService.getAITutorTexts().subscribe(value => {
        this.#aiTutorTexts = value;
        this.aiTutorTextsLoaded.emit(value);
        this.#say(value.aiTutorIntroduction);
      })

    );

    if (this.montyContainer()?.nativeElement) {
      this.#calculateMontyContainer(this.montyContainer()!.nativeElement);
    }
  }

  ngOnDestroy(): void {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  toggleAudio() {
    this.#subscriptions.push(
      this.#profileService.updateStudentAIVoiceActive(!this.aiTutorVoiceActive).subscribe(() => {
        this.#subscriptions.push(
          this.#profileService.fetchProfile().subscribe(() => {
            this.#changeDetectorRef.markForCheck();
          }));
      }));

    if (this.#isPlaying()) {
      this.#audio.pause();
    }
  }

  onSendMessage(message: string) {
    this.#loaded = true;
    this.#thinking.set(true);
    this.sendMessage.emit(message);
    const textInput = this.input();
    if (textInput) {
      textInput.nativeElement.value = '';
    }
  }

  toggleHide() {
    const montyContainer = this.montyContainer();
    if (montyContainer) {
      if (!this.aiTutorActive) {
        montyContainer.nativeElement.setAttribute('style', this.#dragStyle ?? '');
      } else {
        this.#dragStyle = montyContainer.nativeElement.getAttribute('style');
        montyContainer.nativeElement.removeAttribute('style');
      }
    }
    this.#subscriptions.push(
      this.#profileService.updateStudentAIActive(!this.aiTutorActive).subscribe(() => {
        this.#subscriptions.push(
          this.#profileService.fetchProfile().subscribe(() => {
            this.#changeDetectorRef.markForCheck();
          }));
      }));
  }

  toggleRecording() {
    if (!this.#isRecording()) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then(
        result => {
          this.#initializeRecorder(result);
          this.#isRecording.set(true);
        },
        () => {
          this.#say(this.#aiTutorTexts?.aiTutorNoMicrophoneAccess ?? '');
        });
    } else {
      this.#stopRecorder();
    }
  }

  onDragMoved(event: CdkDragMove) {
    const element = event.source.element.nativeElement;

    this.#calculateMontyContainer(element);
  }

  clear() {
    this.#talking.set(false);
    this.#clear();
  }

  #clearOnChange(chatGroupId: string) {
    if (this.#chatGroupId !== chatGroupId && this.#chatGroupId) {
      this.#clear();
      this.#messages = [];
    }
    this.#chatGroupId = chatGroupId;
  }

  #sayMessages(messages: string[]) {
    if (messages.length > 0 && messages.length > this.#messages.length) {
      if (this.#loaded) {
        this.#say(messages[messages.length - 1]);
      }
      this.#messages = messages;
    }
    this.#thinking.set(false);
    this.#changeDetectorRef.detectChanges();
  }

  #calculateMontyContainer(element: HTMLElement) {
    const windowHeight = window.innerHeight;
    const elementRect = element.getBoundingClientRect();
    const distanceFromBottom = elementRect.bottom - windowHeight;
    const minMaxHeight = 350;
    const topPadding = 50;
    const maxHeight = Math.max(windowHeight + distanceFromBottom - topPadding, minMaxHeight);

    element.style.maxHeight = `${maxHeight}px`;
  }

  #stopRecorder() {
    const mediaRecorder = this.#mediaRecorder;
    if (mediaRecorder) {
      this.#thinking.set(true);
      mediaRecorder.stopRecording(() => {
        this.#subscriptions.push(this.#soundService.convertAudioToText(mediaRecorder.getBlob(), '.wav').subscribe(value => {
          mediaRecorder.reset();

          if (value.text) {
            this.onSendMessage(value.text);
          } else {
            this.#thinking.set(false);
            this.#say(this.#aiTutorTexts?.aiTutorNoSpeechDetected ?? '');
          }

          this.#isRecording.set(false);
        }));
      });
    }
  }

  #initializeRecorder(result: MediaStream) {

    /* eslint-disable spellcheck/spell-checker */

    // using a custom media recorder as there are differences between audio formats supported 
    // depending on which browser is used
    // this also produces an audio format that Azure Speech supports without any conversion
    if (!this.#mediaRecorder) {
      this.#mediaRecorder = new RecordRTC(result, {
        type: 'audio',
        mimeType: 'audio/wav', // Ensures WAV format
        recorderType: StereoAudioRecorder,
        desiredSampRate: 16_000, // Set to 16kHz for Azure Speech SDK
        numberOfAudioChannels: 1
      });

    }
    /* eslint-enable spellcheck/spell-checker */

    this.#mediaRecorder.startRecording();
  }

  #say(message: string) {
    if (this.#currentMessage === message) {
      return;
    }

    this.#currentMessage = message;

    this.#talking.set(true);
    this.$currentMessage = this.#typeEffect(message);

    // don't vocalize the clear message
    if (this.aiTutorVoiceActive && message !== this.#aiTutorTexts?.aiTutorClear) {
      this.#subscriptions.push(
        this.#soundService.usePolly(this.aiTutorVoiceId, message).subscribe(response => {
          if (response.body) {
            this.#audio.src = window.URL.createObjectURL(response.body);
            this.#audio.load();
            this.#audio.play();
          }
        }));
    }

  }

  #type({ message, speed }: TypeParams) {
    return interval(speed).pipe(
      map(x => message.slice(0, Math.max(0, x + 1))),
      tap(x => this.#talking.set(x.length !== message.length)),
      take(message.length)
    );
  }

  #typeEffect(message: string) {
    return concat(
      this.#type({ message, speed: 20 }),
      of('').pipe(delay(1200), ignoreElements())
    );
  }

  #clear() {
    if (!this.#talking()) {
      this.#audio?.pause();
      this.#say(this.#aiTutorTexts?.aiTutorClear ?? '');
    }
  }
}
