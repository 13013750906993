import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AwardsLibModule } from 'awards-lib';
import { EmojiLibModule } from 'emoji-lib';
import { IconLibModule } from 'icon-lib';
import { PipesDirectivesLibModule } from 'pipes-directives-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { MessageParserComponent } from './message-parser/message-parser.component';
import { ChatMessagesComponent, LogMessagesComponent, MontyAiMessagesComponent } from './messages';
import { EventTypeToSeverityPipe } from './pipes';
import { SessionCaptionListComponent } from './session-caption-list/session-caption-list.component';
import { SessionWarningsComponent } from './session-warnings/session-warnings.component';

@NgModule({
  declarations: [
    ChatMessagesComponent,
    EventTypeToSeverityPipe,
    LogMessagesComponent,
    MessageParserComponent,
    MontyAiMessagesComponent,
    SessionCaptionListComponent,
    SessionWarningsComponent
  ],
  imports: [
    AwardsLibModule,
    CommonModule,
    EmojiLibModule,
    FormsModule,
    IconLibModule,
    NgbDropdownModule,
    NgbNavModule,
    NgbTooltipModule,
    PipesDirectivesLibModule,
    UiCommonLibModule
  ],
  exports: [
    ChatMessagesComponent,
    EventTypeToSeverityPipe,
    LogMessagesComponent,
    MessageParserComponent,
    MontyAiMessagesComponent,
    SessionCaptionListComponent,
    SessionWarningsComponent
  ]
})
export class MessageLibModule { }
