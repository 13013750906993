import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-new-talking-four',
  templateUrl: './monty-new-talking-four.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})

export class MontyNewTalkingFourComponent implements MontyGraphic {

}
