import { SimpleScore } from 'pipes-directives-lib';

import { ActivityHistory } from './history';

export enum AssociationType {
  Initial = 1,
  Subsequent = 2,
  Repeat = 3,
  Manual = 4,
  Linked = 5,
  SkillBuilder = 6,
  AdHoc = 7,
  SubsequentCheck = 8,
  Decision = 9,
  SubsequentAccelerated = 10,
  InProgress = 11,
  Unit = 12,
  SubsequentError = 13,
  Diy = 14
}

export interface LessonPlanActivity {
  readonly activityGuid?: string;
  readonly activityId: number;
  readonly associationType: AssociationType;
  readonly description: string;
  readonly history?: ActivityHistory;
  readonly isHomework?: boolean;
  readonly isManual: boolean;
  readonly lessonActivityPlanId?: number;
  readonly name: string;
  readonly nextActivityId?: number;
  readonly pdfFile?: string;
  readonly adHocPdfFile?: string;
  readonly previousActivityGuid?: string;
  readonly previousActivityId?: number;
  readonly previousLessonActivityPlanId?: number;
  readonly previousLessonGuid?: string;
  readonly previousPdfFile?: string;
  readonly previousWhiteboardPages?: readonly string[];
  readonly videos?: readonly string[];
  readonly files?: readonly string[];
  readonly whiteboardPages?: readonly string[];
  readonly linkedActivityIsHomework?: boolean;
  readonly aiUsed?: boolean;
  aiGradeAndSubject?: string | null;
  aiAttempted?: number | null;
  aiCorrect?: number | null;
  aiPercentage?: number | null;
  aiTotal?: number | null;
  age?: number | null;
  ageResult?: number | null;
  skipped?: number;
  attempted?: number;
  completedOn?: Date;
  completionTime?: number;
  correct?: number;
  inProgress?: boolean;
  noScoringRequired?: boolean;
  internalNote: string;
  markdown?: string;
  html?: string;
  originalActivityId?: number;
  percentage?: number | null;
  selected?: boolean;
  startedOn?: Date;
  total?: number;
  isSimpleScoring?: boolean;
  simpleScore?: SimpleScore;
  firstAttemptCorrectQuestions?: number;
  secondAttemptCorrectQuestions?: number;
  hidden?: boolean;
  continueFromPrevious?: boolean;
}
