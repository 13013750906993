import { Injectable, OnDestroy } from '@angular/core';
import { Observable, Observer, Subject, Subscription, timer } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TimerService implements OnDestroy {

  #timerStarted = false;
  #timer: Observable<number> | undefined;
  #timerSubscription: Subject<number> | undefined = new Subject();
  #subscriptions: Subscription[] = [];

  start(startDue: Date | number, intervalDuration: number) {
    if (!this.#timer || !this.#timerStarted) {
      this.#timerStarted = true;
      this.#timer = timer(startDue, intervalDuration);

      // Holding its own timer subscription as every
      // subscriber to the timer, starts it's own timer.
      // This subscriber will keep consistency by allowing
      // all descendent subscribers (subscribers of #timerSubscription)
      // to trigger from the same timer.
      this.#timer?.subscribe(count => {
        if (this.#timerSubscription) {
          this.#timerSubscription.next(count);
        }
      });
    }
  }

  subscribe(observer: Partial<Observer<number>> | ((value: number) => void)) {
    if (this.#timerSubscription) {
      const subscription = this.#timerSubscription.subscribe(observer);
      this.#subscriptions.push(subscription);

      // returning individual subscriptions to allow the subscribing component
      // to handle the disposal of the subscription
      return subscription;
    }
    return undefined;
  }

  stop() {
    if (this.#timer) {
      this.#timer = undefined;
      this.#timerSubscription?.unsubscribe();
    }
  }

  ngOnDestroy() {
    if (this.#subscriptions) {
      for (const subscription of this.#subscriptions) {
        if (subscription) {
          subscription.unsubscribe();
        }
      }
      this.#subscriptions = [];
    }

    if (this.#timerSubscription) {
      this.#timerSubscription.unsubscribe();
      this.#timerSubscription = undefined;
    }
  }

}
