/* eslint  @typescript-eslint/naming-convention : 0 */

import { createSelector } from '@ngrx/store';

import { selectAiChatMessages } from '../state';

export const selectAiMessages = createSelector(
    selectAiChatMessages, messages => {
    return messages.filter(message => message !== undefined);
});
