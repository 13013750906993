
import { createReducer, on } from '@ngrx/store';
import * as moment from 'moment';
import { ScoringStatus } from 'pipes-directives-lib';

import { LessonActivityType, LessonQuestion } from '../../../shared';
import { ActivityEntity, ActivityEntityState, TimesTableModalState } from '../models';
import * as ActivityActions from './activity.actions';

function getTimeSinceCurrent(date: Date) {
  const momentDate = moment(date);
  return moment().diff(momentDate, 'seconds');
}

function isWithinTimedActivityTimeLimit(attemptStartTime: Date | undefined, maxAllowedTimeInMinutes: number) {
  if (attemptStartTime) {
    const diff = getTimeSinceCurrent(attemptStartTime);
    return diff >= 0 && diff < 60 * maxAllowedTimeInMinutes;
  }
  return false;
}

export interface ActivityState {
  readonly selectedLessonActivityPlanId: number | undefined;
  readonly requestedLessonActivityPlanId: number | undefined;
  readonly requestedQuestionGuid: string | undefined;
  readonly requestedPageGuid: string | undefined;
  readonly isHomework: boolean;
  readonly entityIds: readonly number[];
  readonly entities: {
    readonly [lessonActivityPlanId: number]: ActivityEntity;
  };
  readonly entityState: {
    readonly [activityGuid: string]: ActivityEntityState;
  };
}

const initialState: ActivityState = {
  selectedLessonActivityPlanId: undefined,
  requestedLessonActivityPlanId: undefined,
  requestedQuestionGuid: undefined,
  requestedPageGuid: undefined,
  isHomework: false,
  entityIds: [],
  entities: {},
  entityState: {}
};

function updateActivityState(activityGuid: string, state: ActivityState,
  update: (entityState: ActivityEntityState) => Partial<ActivityEntityState>) {
  let activityState = state.entityState[activityGuid];

  if (activityState) {
    const updateState = update(activityState);
    activityState = Object.assign({}, activityState, updateState);

    return {
      selectedLessonActivityPlanId: state.selectedLessonActivityPlanId,
      requestedLessonActivityPlanId: state.requestedLessonActivityPlanId,
      requestedQuestionGuid: state.requestedQuestionGuid,
      requestedPageGuid: state.requestedPageGuid,
      isHomework: state.isHomework,
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: { ...state.entityState, [activityGuid]: activityState }
    };
  }

  return state;
}

export const activityReducer = createReducer(initialState,
  on(ActivityActions.loadManyAction, (state, action) => {

    // Only add activities not already loaded
    let entityIds = state.entityIds;
    let entities = state.entities;
    let entityState = state.entityState;

    let counterId = 1;

    for (const activity of action.activities) {
      if (!entityIds.includes(activity.activity.lessonActivityPlanId)) {

        let maxTimeAllowedMinutes = 0;

        if (activity.activity.lessonActivityType === LessonActivityType.Computer && activity.activity.computer?.maxAllowedTimeMinutes) {
          maxTimeAllowedMinutes = activity.activity.computer.maxAllowedTimeMinutes;
        }

        if (activity.activity.lessonActivityType === LessonActivityType.Manual && activity.activity.manual?.maxAllowedTimeMinutes) {
          maxTimeAllowedMinutes = activity.activity.manual.maxAllowedTimeMinutes;
        }

        if (activity.activity.lessonActivityType === LessonActivityType.TimedComputer) {
          maxTimeAllowedMinutes = 1;
        }

        const entity: ActivityEntity = {
          counterId: counterId,
          lessonActivityPlanId: activity.activity.lessonActivityPlanId,
          activityId: activity.activity.activityId,
          name: activity.activity.name,
          description: activity.activity.description ?? '',
          isHiddenStudent: activity.activity.isHiddenStudent ?? false,
          unit: activity.activity.unit ?? '',
          unitId: activity.activity.unitId ?? 0,
          computer: activity.activity.computer ?? null,
          manual: activity.activity.manual ?? null,
          html: activity.activity.html ?? null,
          timedComputer: activity.activity.timedComputer ?? null,
          adHoc: activity.activity.adHoc ?? null,
          lessonActivityType: activity.activity.lessonActivityType,
          kipPointsScaleFactor: activity.activity.kipPointsScaleFactor ?? 1,
          assessmentFormName: activity.activity.assessmentFormName ?? null,
          sessionActivityType: activity.sessionActivityType,
          assessmentResultTypeId: activity.activity.assessmentResultTypeId ?? null,
          isAssessmentActivity: activity.activity.isAssessmentActivity ?? false,
          continuedFromPrevious: activity.activity.continuedFromPrevious ?? false,
          treeTopicId: activity.activity.treeTopicId ?? null,
          maxTimeAllowedMinutes: maxTimeAllowedMinutes
        };

        entityIds = [...entityIds, activity.activity.lessonActivityPlanId];
        entities = { ...entities, [activity.activity.lessonActivityPlanId]: entity };
      }

      if (!activity.activity.isHiddenStudent) {
        counterId++;
      }

      let questions: readonly LessonQuestion[] = [];
      if (activity.activity.lessonActivityType === LessonActivityType.Computer && activity.activity.computer) {
        questions = activity.activity.computer.questions;
      }

      let isCompleted = false;

      if (activity.activity.lessonActivityType === LessonActivityType.TimedComputer && activity.activity.timedComputer) {
        questions = activity.activity.timedComputer.questions;
        isCompleted = !!activity.activity.completedOn;
      }
      else {
        isCompleted = questions.length > 0 && questions.every(question => question.correct || question.skipped);
      }

      // Add the activity state
      const activityState: ActivityEntityState = {
        lessonActivityPlanId: activity.activity.lessonActivityPlanId,
        questionGuids: questions.map(question => question.questionGuid),
        percentage: activity.activity.percentage ?? null,
        firstAttemptResult: activity.activity.timedComputer?.firstAttemptResult,
        firstAttemptStartTime: activity.activity.timedComputer?.firstAttemptStartTime,
        secondAttemptResult: activity.activity.timedComputer?.secondAttemptResult,
        secondAttemptStartTime: activity.activity.timedComputer?.secondAttemptStartTime,
        score: questions.reduce((total, question) => total + (question.score ?? 0), 0),
        completed: isCompleted,
        completionTime: activity.activity.completionTime ?? 0,
        openTime: undefined,
        startedOn: activity.activity.startedOn,
        completedOn: activity.activity.completedOn,
        hasWhiteboard: activity.activity.hasWhiteboard ?? false,
        files: activity.activity.files ?? [],
        firstAttemptActive: undefined,
        secondAttemptActive: undefined,
        activitySeconds: undefined,
        percentageTime: undefined,
        remainingSeconds: undefined,
        remainingColor: undefined,
        timeLimitElapsed: undefined,
        questionsLoaded: false,
        timesTableModalState: TimesTableModalState.None
      };

      entityState = { ...entityState, [activity.activity.activityGuid]: activityState };
    }

    return {
      selectedLessonActivityPlanId: state.selectedLessonActivityPlanId,
      requestedLessonActivityPlanId: state.requestedLessonActivityPlanId,
      requestedQuestionGuid: state.requestedQuestionGuid,
      requestedPageGuid: state.requestedPageGuid,
      isHomework: state.isHomework,
      entityIds: entityIds,
      entities: entities,
      entityState: entityState
    };
  }),
  on(ActivityActions.openAction, (state, action) => {
    let activityEntityState: ActivityEntityState | undefined;
    let newEntityState = state.entityState;

    for (const activityGuid in state.entityState) {
      if (state.entityState[activityGuid].lessonActivityPlanId === action.lessonActivityPlanId) {
        activityEntityState = state.entityState[activityGuid];

        activityEntityState = Object.assign({}, activityEntityState, {
          openTime: moment.utc().toDate()
        });
        newEntityState = { ...newEntityState, [activityGuid]: activityEntityState };
        break;
      }
    }

    return {
      selectedLessonActivityPlanId: action.lessonActivityPlanId,
      requestedLessonActivityPlanId: action.lessonActivityPlanId === state.requestedLessonActivityPlanId ? undefined :
        state.requestedLessonActivityPlanId,
      requestedQuestionGuid: state.requestedQuestionGuid,
      requestedPageGuid: state.requestedPageGuid,
      isHomework: action.isHomework,
      entityIds: state.entityIds,
      entities: state.entities,
      entityState: newEntityState
    };
  }),
  on(ActivityActions.closeAction, (state, action) => {
    let activityState = state.entityState[action.activityGuid];

    const startTime = moment.utc(activityState.openTime);

    if (startTime.isValid()) {
      const now = moment.utc();
      const duration = now.diff(startTime, 'seconds');

      activityState = Object.assign({}, activityState, {
        completionTime: activityState.completionTime + duration,
        openTime: undefined
      });
    }

    if (activityState) {
      return {
        selectedLessonActivityPlanId: undefined,
        requestedLessonActivityPlanId: activityState.lessonActivityPlanId === state.requestedLessonActivityPlanId ? undefined :
          state.requestedLessonActivityPlanId,
        requestedQuestionGuid: state.requestedQuestionGuid,
        requestedPageGuid: state.requestedPageGuid,
        isHomework: state.isHomework,
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: { ...state.entityState, [action.activityGuid]: activityState }
      };
    }

    return state;
  }),
  on(ActivityActions.requestOpenAction, (state, action): ActivityState => {
    const activityState = state.entityState[action.activityGuid];
    if (activityState) {
      return {
        selectedLessonActivityPlanId: state.selectedLessonActivityPlanId,
        requestedLessonActivityPlanId: activityState.lessonActivityPlanId,
        requestedQuestionGuid: action.questionGuid,
        requestedPageGuid: action.pageGuid,
        isHomework: state.isHomework,
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: state.entityState
      };
    }

    return state;
  }),
  on(ActivityActions.requestCloseAction, (state, action): ActivityState => {
    const activityState = state.entityState[action.activityGuid];

    if (activityState) {
      return {
        selectedLessonActivityPlanId: state.selectedLessonActivityPlanId,
        requestedLessonActivityPlanId: activityState.lessonActivityPlanId,
        requestedQuestionGuid: undefined,
        requestedPageGuid: undefined,
        isHomework: state.isHomework,
        entityIds: state.entityIds,
        entities: state.entities,
        entityState: state.entityState
      };
    }

    return state;
  }),
  on(ActivityActions.finishLoadQuestionsAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      questionsLoaded: true
    }));
  }),
  on(ActivityActions.startFirstAttempt, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      firstAttemptStartTime: action.startDateTime
    }));
  }),
  on(ActivityActions.startSecondAttempt, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      secondAttemptStartTime: action.startDateTime
    }));
  }),
  on(ActivityActions.firstAttemptAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      firstAttemptResult: action.count
    }));
  }),
  on(ActivityActions.secondAttemptAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      secondAttemptResult: action.count
    }));
  }),
  on(ActivityActions.updateTimesTableStateAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, activityState => {
      let activitySeconds: number | undefined = 0;
      let timesTableModalState: TimesTableModalState = TimesTableModalState.None;
      const activityEntity = state.entities[activityState.lessonActivityPlanId];
      const timedActivityMaxAllowedTimeMinutes = activityEntity.maxTimeAllowedMinutes;
      const timedActivityFirstAttemptStartTime = activityState.firstAttemptStartTime;
      const timedActivitySecondAttemptStartTime = activityState.secondAttemptStartTime;

      if (!timedActivityFirstAttemptStartTime) {
        timesTableModalState = TimesTableModalState.OpenStartGame;
      }
      // determine time from first attempt
      if (timedActivityFirstAttemptStartTime && isWithinTimedActivityTimeLimit(timedActivityFirstAttemptStartTime, timedActivityMaxAllowedTimeMinutes)) {
        activitySeconds = getTimeSinceCurrent(timedActivityFirstAttemptStartTime);
      }
      else if (!timedActivitySecondAttemptStartTime && timedActivityFirstAttemptStartTime) {
        timesTableModalState = TimesTableModalState.OpenFinishFirstAttempt;
      }
      // determine time from second attempt
      else if (timedActivitySecondAttemptStartTime && isWithinTimedActivityTimeLimit(timedActivitySecondAttemptStartTime, timedActivityMaxAllowedTimeMinutes)) {
        activitySeconds = getTimeSinceCurrent(timedActivitySecondAttemptStartTime);
      }
      // both attempts have been completed
      else if (timedActivityFirstAttemptStartTime && !isWithinTimedActivityTimeLimit(timedActivityFirstAttemptStartTime, timedActivityMaxAllowedTimeMinutes)
        && timedActivitySecondAttemptStartTime && !isWithinTimedActivityTimeLimit(timedActivitySecondAttemptStartTime, timedActivityMaxAllowedTimeMinutes)) {
        activitySeconds = undefined;
        timesTableModalState = TimesTableModalState.OpenFinishGame;
      }

      // determining color and remaining time
      let remainingColor: string | undefined;

      const percentageTime = activitySeconds !== undefined ? Math.max(0, Math.round(100 - activitySeconds / (timedActivityMaxAllowedTimeMinutes * 60) * 100)) : undefined;

      if (percentageTime === undefined) {
        remainingColor = undefined;
      }
      else if (percentageTime <= 0) {
        remainingColor = '';
      }
      else if (percentageTime <= 25) {
        remainingColor = 'danger';
      }
      else if (percentageTime <= 50) {
        remainingColor = 'warning';
      }
      else {
        remainingColor = 'success';
      }

      const timedActivityFirstAttemptActive = isWithinTimedActivityTimeLimit(timedActivityFirstAttemptStartTime, timedActivityMaxAllowedTimeMinutes);
      const timedActivitySecondAttemptActive = isWithinTimedActivityTimeLimit(timedActivitySecondAttemptStartTime, timedActivityMaxAllowedTimeMinutes);
      const timedActivityCompleted = !!timedActivityFirstAttemptStartTime && !!timedActivitySecondAttemptStartTime &&
        !timedActivityFirstAttemptActive && !timedActivitySecondAttemptActive;

      return {
        timesTableModalState: timesTableModalState,
        activitySeconds: activitySeconds,
        percentageTime: percentageTime,
        remainingColor: remainingColor,
        remainingSeconds: activitySeconds !== undefined ? Math.max(0, 60 * timedActivityMaxAllowedTimeMinutes - activitySeconds) : undefined,
        timeLimitElapsed: timedActivityMaxAllowedTimeMinutes && activitySeconds && timedActivityMaxAllowedTimeMinutes * 60 < activitySeconds,
        firstAttemptActive: timedActivityFirstAttemptActive,
        secondAttemptActive: timedActivitySecondAttemptActive,
        timedActivityCompleted: timedActivityCompleted,
        completed: timedActivityCompleted,
        completedOn: timedActivityCompleted ? new Date() : undefined,
        completionTime: timedActivityCompleted ? timedActivityMaxAllowedTimeMinutes * 60 : undefined
      };
    });
  }),
  on(ActivityActions.percentageAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      percentage: action.percentage,
      completed: action.completed
    }));
  }),
  on(ActivityActions.whiteboardDrawnAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, () => ({
      hasWhiteboard: true
    }));
  }),
  on(ActivityActions.startAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, activityState => {
      // check if activity is opened & its not been started on yet
      // then only set the started on, else ignore.
      if (!activityState.startedOn) {
        return {
          startedOn: action.startedOn
        };
      }

      return {};
    });
  }),
  on(ActivityActions.finishAction, (state, action) => {
    let activityState: ActivityEntityState = state.entityState[action.activityGuid];
    if (activityState) {

      let entity = state.entities[activityState.lessonActivityPlanId];

      if (entity?.manual) {
        let manual = entity.manual;

        manual = Object.assign({}, manual, {
          noScoringRequired: false,
          inProgress: false
        });

        entity = Object.assign({}, entity, {
          manual: manual
        });
      }

      activityState = Object.assign({}, activityState, {
        completed: entity.lessonActivityType === LessonActivityType.TimedComputer ? !!action.completedOn : activityState.completed,
        completedOn: action.completedOn,
        percentage: entity?.manual ? null : activityState.percentage,
        score: action.activityScore ?? activityState.score
      });

      return {
        selectedLessonActivityPlanId: state.selectedLessonActivityPlanId,
        requestedLessonActivityPlanId: state.requestedLessonActivityPlanId,
        requestedQuestionGuid: state.requestedQuestionGuid,
        requestedPageGuid: state.requestedPageGuid,
        isHomework: state.isHomework,
        entityIds: state.entityIds,
        entities: { ...state.entities, [activityState.lessonActivityPlanId]: entity },
        entityState: { ...state.entityState, [action.activityGuid]: activityState }
      };
    }

    return state;
  }),
  on(ActivityActions.scoredAction, (state, action) => {
    let activityState = state.entityState[action.scoredActivity.activityGuid];
    if (activityState) {
      activityState = Object.assign({}, activityState, {
        completedOn: action.scoredActivity.completedOn,
        percentage: action.scoredActivity.percentage
      });

      let entity = state.entities[activityState.lessonActivityPlanId];

      if (entity) {
        let manual = entity.manual;

        manual = Object.assign({}, manual, {
          correct: action.scoredActivity.correct,
          noScoringRequired: action.scoredActivity.scoringStatus === ScoringStatus.NoScoringRequired,
          inProgress: action.scoredActivity.scoringStatus === ScoringStatus.InProgress
        });

        entity = Object.assign({}, entity, {
          manual: manual
        });

        return {
          selectedLessonActivityPlanId: state.selectedLessonActivityPlanId,
          requestedLessonActivityPlanId: state.requestedLessonActivityPlanId,
          requestedQuestionGuid: state.requestedQuestionGuid,
          requestedPageGuid: state.requestedPageGuid,
          isHomework: state.isHomework,
          entityIds: state.entityIds,
          entities: { ...state.entities, [activityState.lessonActivityPlanId]: entity },
          entityState: { ...state.entityState, [action.scoredActivity.activityGuid]: activityState }
        };
      }
    }

    return state;
  }),
  on(ActivityActions.updateFilesAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, activityState => {
      const existingFiles = [...activityState.files];

      for (const file of action.files) {
        const index = existingFiles.findIndex(existingFile => existingFile.fileName === file.fileName);
        if (index !== -1) {
          existingFiles[index] = file;
        } else {
          existingFiles.push(file);
        }
      }

      return {
        files: existingFiles
      };

    });
  }),
  on(ActivityActions.removeFileAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, activityState => {
      const existingFiles = [...activityState.files];
      const index = existingFiles.findIndex(existingFile => existingFile.id === action.fileId);

      if (index !== -1) {
        existingFiles.splice(index, 1);
        return {
          files: existingFiles
        };
      }

      return {};
    });
  }),
  on(ActivityActions.renameFileAction, (state, action) => {
    return updateActivityState(action.activityGuid, state, activityState => {
      const existingFiles = [...activityState.files];
      const index = existingFiles.findIndex(existingFile => existingFile.id === action.fileId);

      if (index !== -1) {
        existingFiles[index] = Object.assign({}, existingFiles[index], {
          fileName: action.fileName
        });

        return {
          files: existingFiles
        };
      }

      return {};
    });
  }));
