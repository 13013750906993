@if (treeId) {
@if (label) {
<label [class]="labelClass??''" [for]="name">{{ label }}@if (description) {
  <span class="ms-2 small text-uppercase">{{ description
    }}</span>
  }
  @if (treeName) {
  <span class="ms-2 small text-uppercase">
    @if (treeName.subjects.length > 0)
    {
    {{ treeName.subjects.length === 1 ? 'Subject:':'Subjects:'}}
    @for (subject of treeName.subjects; track subject)
    {
    {{ subject.name}}
    }
    }
    @if (treeName.grades.length > 0) {
    {{ treeName.grades.length === 1 ? 'Grade:':'Grades:'}}
    @for (grade of treeName.grades; track grade)
    {
    {{ grade.name}}
    }
    }
  </span>
  }

</label>
}
<select class="form-control" [formControl]="treeId" [id]="name">
  @for (treeName of treeNames; track treeName) {
  <option [ngValue]="treeName.treeId">
    {{ treeName.regionTag }} {{ treeName.treeId > 0 ?'(' + treeName.name + ')': ''}}
  </option>
  }
</select>
}