import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbNavModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconLibModule } from 'icon-lib';
import { UiCommonLibModule } from 'ui-common-lib';

import { AnimationsAssignComponent } from './animations-assign/animations-assign.component';
import { AvatarAssetsListComponent } from './assets-list/assets-list.component';
import { ReadyPlayerMeCreatorComponent } from './creator/ready-player-me-creator.component';
import { TalkingHeadDemoComponent } from './talking-head-demo/talking-head-demo.component';
import { AnimationPipe } from './talking-head-view/animation.pipe';
import { TalkingHeadViewComponent } from './talking-head-view/talking-head-view.component';

@NgModule({
  declarations: [
    AnimationPipe,
    AnimationsAssignComponent,
    AvatarAssetsListComponent,
    ReadyPlayerMeCreatorComponent,
    TalkingHeadDemoComponent,
    TalkingHeadViewComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconLibModule,
    NgbNavModule,
    NgbTooltipModule,
    ReactiveFormsModule,
    UiCommonLibModule
  ],
  exports: [
    AnimationsAssignComponent,
    AvatarAssetsListComponent,
    ReadyPlayerMeCreatorComponent,
    TalkingHeadDemoComponent,
    TalkingHeadViewComponent
  ]
})
export class ReadyPlayerMeLibModule { }
