@if (isEmoji) {
<kip-emoji-message [message]="message" [warning]="warning" />
}
@if (isAward) {
<kip-awards-message [message]="message" />
}
@if (isStandard) {
@if (ai)
{
<span [innerHTML]="message | safe : 'html'" [ngClass]="{'text-danger': warning}"></span>
}
@else {
<span [innerHTML]="(message | linkify) | safe : 'html'" [ngClass]="{'text-danger': warning}"></span>
}
}