
import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { StudentSoundService } from 'ui-common-lib';

import * as fromSoundOption from '../sound-option';

@Injectable()
export class SoundOptionEffects {

  readonly #actions$ = inject(Actions);
  readonly #studentSoundService = inject(StudentSoundService);

  loadStudentSounds$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromSoundOption.loadStudentSoundsAction),
      switchMap(action => this.#studentSoundService
        .getSoundOptionGroups(action.regionId)
        .pipe(
          switchMap(data => {

            const actions: Action[] = [
              fromSoundOption.updateStudentSoundsAction({ soundOptionGroups: data })
            ];

            return actions;
          })
        )
      )
    );
  });
}
