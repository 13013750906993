/* eslint-disable  @typescript-eslint/naming-convention, rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { debounceTime, Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import * as fromChat from '../chat';
import { StudentState } from '../state';

class TypingStatus {
  #isTyping = false;
  readonly #debounce: Subject<void>;

  constructor(readonly cancelAction: () => void) {
    this.#debounce = new Subject<void>();
    this.#debounce.pipe(debounceTime(5000)).subscribe(() => {
      if (this.#isTyping) {
        this.#isTyping = false;
        cancelAction();
      }
    });
  }

  set isTyping(value: boolean) {
    this.#isTyping = value;
    if (value) {
      this.#debounce.next();
    }
  }

  get isTyping() {
    return this.#isTyping;
  }
}

@Injectable()
export class ChatEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<StudentState>);

  #typingStatuses: { [lessonGuid: string]: TypingStatus } = {};

  typingUpdated$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromChat.updateTypingAction),
      tap(action => {
        let typingStatus = this.#typingStatuses[action.lessonGuid];
        if (!typingStatus) {
          typingStatus = new TypingStatus(() => {
            this.#store.dispatch(fromChat.updateTypingAction({ lessonGuid: action.lessonGuid, isTyping: false }));
          });
          this.#typingStatuses[action.lessonGuid] = typingStatus;
        }
        typingStatus.isTyping = action.isTyping;
      }));
  },
    { dispatch: false }
  );
}
