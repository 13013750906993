import { Injectable } from '@angular/core';
import { Question } from 'questions-lib';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ErrorBlobPath, HttpWithAbsoluteService } from 'service-lib';

import { AccessKeysAndCurriculum, AISolution, ContentNode, CurriculumNode, ExploreActivity, StudentQuestionResult } from '../models';

@Injectable({ providedIn: 'root' })
export class ExploreService extends HttpWithAbsoluteService {

  getActivityAISolution(pdf: string, questionNumber: string) {
    return this.post<AISolution>('tutor/activities/ai-solution', { pdf: pdf, questionNumber: questionNumber });
  }

  getQuestionAISolution(questionText: string) {
    return this.post<AISolution>('tutor/questions/ai-solution', { questionText: questionText });
  }

  getAdhocImage(imageName: string) {
    return this.getUrlAndDownloadWithErrorBlob(`assets/adhoc-image-url/${imageName}`, ErrorBlobPath.Image);
  }

  getAccessKeys(): Observable<AccessKeysAndCurriculum> {
    return this.get<AccessKeysAndCurriculum>('tutor/contents/access-keys');
  }

  getActivities(regionId: number): Observable<ContentNode> {
    return this.get<ContentNode>(`tutor/contents/${regionId}/tree`);
  }

  getAssessmentActivities(regionId: number): Observable<ContentNode> {
    return this.get<ContentNode>(`tutor/contents/${regionId}/assessments`);
  }

  getAdditionalActivities(): Observable<ContentNode[]> {
    return this.get<ContentNode[]>('tutor/contents/additional');
  }

  getCurriculumTree(regionId: number): Observable<CurriculumNode[]> {
    return this.get<CurriculumNode[]>(`tutor/contents/${regionId}/regional`);
  }

  getResults(lessonActivityPlanId: number): Observable<StudentQuestionResult[]> {
    return this.get<StudentQuestionResult[]>(`tutor/questions/results/${lessonActivityPlanId}`);
  }

  getQuestionWorkedSolution(questionId: number, regionId: number): Observable<string | null> {
    return this.get<string | null>(`tutor/questions/${questionId}/worked-solution?regionid=${regionId}`);
  }

  getQuestionIntroduction(questionId: number, regionId: number): Observable<string | null> {
    return this.get<string | null>(`tutor/questions/${questionId}/introduction?regionid=${regionId}`);
  }

  getQuestion(questionId: number, regionId: number): Observable<{ html: Question, katex: Question }> {

    // Ensure the question JSON is expanded for the Question type
    return this.get<Question>(`tutor/questions/${questionId}?regionId=${regionId}`).pipe(
      map(question => {
        return {
          html: Object.assign({
            questionId: question.questionId,
            hasIntroduction: question.hasIntroduction,
            hasWorkedSolution: question.hasWorkedSolution,
            contentGuid: question.contentGuid
          }, (question as any).data),
          katex: Object.assign({
            questionId: question.questionId,
            hasIntroduction: question.hasIntroduction,
            hasWorkedSolution: question.hasWorkedSolution,
            contentGuid: question.contentGuid
          }, (question as any).dataKatex)
        };
      })
    );
  }

  getExploreNewActivityById(treeActivityId: number): Observable<ExploreActivity> {
    return this.get<ExploreActivity>(`tutor/activities/explore-new/${treeActivityId}`);
  }

  getExploreActivityById(activityId: number): Observable<ExploreActivity> {
    return this.get<ExploreActivity>(`tutor/activities/explore/${activityId}`);
  }
}
