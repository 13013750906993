/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION, RouterNavigationAction } from '@ngrx/router-store';
import { Action, Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import { Observable } from 'rxjs';
import { distinctUntilChanged, switchMap, take, withLatestFrom } from 'rxjs/operators';

import { MergedRoute } from '../../../route-extensions/merged-route';
import { LessonActivityType, RouterService, StudentRouteParams, urlIsHomework } from '../../../shared';
import { SelectedActivity, SelectedLesson, SelectedQuestion } from '../../models';
import { MessagingService } from '../../services';
import * as fromActivity from '../activity';
import * as fromLesson from '../lesson';
import * as fromQuestion from '../question';
import { selectSelectedQuestionEntityIndex, StudentState } from '../state';

interface RouteValues {
  [key: string]: any;
  [StudentRouteParams.LessonId]?: number;
  isHomework?: string;
  [StudentRouteParams.LessonActivityPlanId]?: number;
  [StudentRouteParams.QuestionIndex]?: number;
}

@Injectable()
export class RouterEffects {

  readonly #actions$ = inject(Actions);
  readonly #authService = inject(AuthService);
  readonly #store = inject(Store<StudentState>);
  readonly #routerService = inject(RouterService);
  readonly #messagingService = inject(MessagingService);

  private isAuthenticated = false;

  route$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-arguments
      ofType<RouterNavigationAction<MergedRoute>>(ROUTER_NAVIGATION),
      // we were getting this message spammed some time, so putting this in to prevent it from doing so
      distinctUntilChanged((previous, current) => previous.payload.routerState.url === current.payload.routerState.url),
      withLatestFrom(
        this.#store.select(fromLesson.selectSelectedLesson),
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(selectSelectedQuestionEntityIndex),
        this.#store.select(fromQuestion.selectSelectedQuestion)
      ),
      switchMap(([action, lesson, activity, questionIndex, question]) =>
        // Router event is being hit on a browser refresh and running the action
        // which is causing a new activity opened event to be triggered
        this.#routerService.isWindowUnloading()
          .pipe(
            switchMap(routingState => {
              const actions: Action[] = [];
              if (routingState.isWindowUnloading) {
                return actions;
              }

              if (action.payload.routerState !== undefined && this.isAuthenticated) {

                const lessonGuid = activity?.lessonGuid;

                // Resolve the entity ids from the route parameters
                const values: RouteValues = {};
                this.#resolveValues(values, action.payload.routerState);
                // Add the correct actions for the determined values
                const homework = urlIsHomework(action.payload.routerState.url);

                const connect = this.#resolveLessonActions(lesson, values, homework, actions);

                this.#resolveActivityActions(activity, values, homework, actions);

                // boolean to true if changing between two activities without a question index change
                // (eg. activity1 - question1 -> activity2 - question1). This will force to close the already
                // open question and send the open question event.
                const isChangingActivity = activity && activity.lessonActivityPlanId !== values.lessonActivityPlanId;

                this.#resolveQuestionActions(questionIndex, question, values, actions, isChangingActivity, lessonGuid, activity?.lessonActivityType);

                // The lesson action resolve will tell us if the messaging needs to be connected
                // A disconnect is performed elsewhere to ensure close actions are fulfilled beforehand
                if (connect) {
                  return this.#messagingService.connect().pipe(
                    take(1),
                    switchMap(() => actions)
                  );
                }
              }

              return actions;
            })
          )
      )
    );
  });

  constructor() {
    this.#authService.isLoggedIn.subscribe(isLoggedIn => this.isAuthenticated = isLoggedIn);
  }

  #resolveValues(values: RouteValues, route: MergedRoute) {

    // Map the route params to the context
    const params = Object.keys(route.params);

    for (const param of params) {
      const value = +route.params[param];

      if (!Number.isNaN(value)) {
        values[param] = value;
      }
    }
  }

  #resolveLessonActions(lesson: SelectedLesson | undefined, values: RouteValues, isHomework: boolean, actions: Action[]): boolean {
    let connect = false;

    // Check if the values has a lessonId defined
    if (values.lessonId && // If found, check whether a lesson needs to be opened
      (!lesson || lesson.lessonId !== values.lessonId)) {
        actions.push(
          fromLesson.openAction({ lessonId: values.lessonId, isHomework: isHomework, lessonActivityPlanId: values.lessonActivityPlanId })
        );

        // Also track that the messaging needs to be connected
        connect = true;
      } 

    return connect;
  }

  #resolveActivityActions(activity: SelectedActivity | undefined, values: RouteValues,
    isHomework: boolean, actions: Action[]) {

    // Check if the values has an activityId defined

    if (values.lessonActivityPlanId) {

      // Ensure the previously opened activity is closed
      // This is mainly for when a tutor requests an activity for the student
      if (activity && activity.lessonActivityPlanId !== values.lessonActivityPlanId) {
        actions.push(
          fromActivity.closeAction(
            {
              lessonGuid: activity.lessonGuid,
              activityGuid: activity.activityGuid,
              completed: activity.completed,
              unitId: activity.unitId
            })
        );
      }

      // If found, check whether an activity needs to be opened
      if (!activity || activity.lessonActivityPlanId !== values.lessonActivityPlanId) {
        actions.push(
          fromActivity.openAction(
            {
              lessonActivityPlanId: values.lessonActivityPlanId,
              isHomework: isHomework
            })
        );
      }

    } else if (activity) {

      // Otherwise if no activity id on the route, but one is selected, close it
      actions.push(
        fromActivity.closeAction(
          {
            lessonGuid: activity.lessonGuid,
            activityGuid: activity.activityGuid,
            completed: activity.completed,
            unitId: activity.unitId
          })
      );
    }
  }

  #resolveQuestionActions(questionIndex: number | undefined, question: SelectedQuestion | undefined, values: RouteValues, actions: Action[],
    isChangingActivity: boolean | undefined, lessonGuid: string | undefined, lessonActivityType: LessonActivityType | undefined) {
    // Check if the values has a questionIndex defined
    if (values.questionIndex) {

      // If found, check whether a question needs to be opened
      if (!questionIndex || questionIndex !== values.questionIndex || isChangingActivity) {

        // Ensure the previously opened question is closed
        if (questionIndex && question) {
          // Uses the lessonGuid as it's pulled from the activity which will have the correct
          // lesson guid when closing a previous lessons homework
          actions.push(
            fromQuestion.closeAction({ lessonGuid: lessonGuid ?? question.lessonGuid, questionGuid: question.questionGuid })
          );
        }
        else if (questionIndex && (lessonActivityType === LessonActivityType.Manual || lessonActivityType === LessonActivityType.AdHoc) && lessonGuid) {
          actions.push(
            fromQuestion.closeCustomActivityQuestionAction({ lessonGuid: lessonGuid })
          );
        }

        actions.push(
          fromQuestion.openAction({ questionIndex: values.questionIndex })
        );
      }

    }
    // Otherwise if no question id on the route, but one is selected for computer activities, close it
    else if (question && (lessonActivityType === LessonActivityType.Computer || lessonActivityType === LessonActivityType.TimedComputer)) {
      // Uses the lessonGuid as it's pulled from the activity which will have the correct
      // lesson guid when closing a previous lessons homework
      actions.push(
        fromQuestion.closeAction({ lessonGuid: lessonGuid ?? question.lessonGuid, questionGuid: question.questionGuid })
      );
    }
    // Otherwise if no question id on the route, but one is selected for custom activities, close it
    else if (lessonGuid && questionIndex && lessonActivityType !== LessonActivityType.Computer && lessonActivityType !== LessonActivityType.TimedComputer) {
      actions.push(
        fromQuestion.closeCustomActivityQuestionAction({ lessonGuid: lessonGuid })
      );
    }
  }
}
