import { createAction, props } from '@ngrx/store';
import { Monty } from 'ui-common-lib';

import { AssistantMessage, AssistantMessageOption } from '../../models';

enum AssistantActionTypes {
  ClearMessages = 'Student > Assistant > Clear Messages',
  AddMessage = 'Student > Assistant > Add Message',
  ChangeMonty = 'Student > Assistant > Change Monty',
  ShowLoading = 'Student > Assistant > Show Loading',
  HideLoading = 'Student > Assistant > Hide Loading',
  SelectOption = 'Student > Assistant > Select Option',
  Reset = 'Student > Assistant > Reset'
}

export const clearMessagesAction = createAction(AssistantActionTypes.ClearMessages);

export const addMessageAction = createAction(
  AssistantActionTypes.AddMessage,
  props<{
    readonly message: AssistantMessage;
  }>()
);

export const changeMontyAction = createAction(
  AssistantActionTypes.ChangeMonty,
  props<{
    readonly monty: Monty;
  }>()
);

export const showLoadingAction = createAction(AssistantActionTypes.ShowLoading);

export const hideLoadingAction = createAction(AssistantActionTypes.HideLoading);

export const selectOptionAction = createAction(
  AssistantActionTypes.SelectOption,
  props<{
    readonly message: AssistantMessage;
    readonly option: AssistantMessageOption;
  }>()
);

export const resetAssistantAction = createAction(AssistantActionTypes.Reset);

