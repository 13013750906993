<div [class]="'w-100 h-100 flex-column flex-fill ' + (pdfAvailable ?'d-flex':'d-none')">
  <audio #audio controls [style.display]="soundAvailable?'':'none'"></audio>
  <div class="w-100 h-100">
    <ngx-extended-pdf-viewer [customToolbar]="additionalButtons" [enablePrint]="enablePrint"
      [filenameForDownload]="activityName + '.pdf'" [height]="'100%'" [logLevel]="5" [replaceBrowserPrint]="enablePrint"
      [showBorders]="false" [src]="pdfSrc" [useInlineScripts]="false" [(formData)]="formData"
      (formDataChange)="formDataChange.emit($event)" (pageChange)="onPageChange($event)"
      (pagesLoaded)="onPagesLoaded($event)" />
    <ng-template #additionalButtons>
      <div id="toolbarViewerRight">
        <pdf-paging-area />
        @if (enablePrint)
        {
        <pdf-print />
        }
        <pdf-download />
        <button class="toolbarButton new-tab" id="jira" title="Report Issue" type="button"
          [style.display]="allowJira?'block':'none'">
          <fa-icon [icon]="icons.reportJira.light" (click)="displayJira.emit()" />
        </button>
        @if (allowAI)
        {
        <button class="toolbarButton new-tab" id="displayAISolution" title="Display AI Solution" type="button"
          [style.display]="allowAI?'block':'none'">
          <fa-icon [icon]="icons.lightbulb" (click)="displayAISolution.emit(questionNumber)" />
        </button>
        <input class="toolbarField" title="Question to solve" type="text" [(ngModel)]="questionNumber" />
        }
        <button class="toolbarButton new-tab" id="toggle" title="Toggle Answers" type="button"
          [style.display]="allowToggleAnswers?'block':'none'">
          <fa-icon [icon]="icons.answers" (click)="toggleAnswers.emit()" />
        </button>
      </div>
      <pdf-zoom-toolbar />
    </ng-template>
  </div>
</div>
<div [class]="'w-100 h-100 flex-column flex-fill ' + (!pdfAvailable ?'d-flex':'d-none')">
  <div class="w-100 h-100 card">
    <div class="card-body">
      <h5 class="card-title"> {{ activityName }}</h5>
      <p class="card-text" [innerHTML]="activityHtml"></p>
    </div>
  </div>
</div>
<kip-error-modal #errorModal [buttonText]="buttonText" [message]="message" [title]="title" />