<div class="w-100">
    @switch(currentStage) {
        @case (1) {
            <kip-monty-new-talking-one />
        }
        @case (2) {
            <kip-monty-new-talking-two />
        }
        @case (3) {
            <kip-monty-new-talking-three />
        }
        @case (4) {
            <kip-monty-new-talking-four />
        }
    }
</div>