import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'auth-lib';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';

@Component({
  selector: 'kip-in-maintenance',
  templateUrl: './in-maintenance.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class IsInMaintenanceComponent implements OnInit, OnDestroy {

  readonly #profileService = inject(ProfileService);
  readonly #authService = inject(AuthService);

  #subscriptions: Subscription[] = [];

  readonly icons = Icons;

  get downTime() {
    return this.#profileService.currentDownTime;
  }

  ngOnInit() {
    this.#subscriptions.push(this.#profileService.fetchDownTime().subscribe(() => {
      // do nothing
    }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  logout() {
    this.#authService.logout();
  }

}
