import { createAction, props } from '@ngrx/store';
import { SoundOptionGroup } from 'ui-common-lib';

enum SoundOptionActionTypes {
  Load = 'Student > Sound Option > Load',
  Update = 'Student > Sound Option > Update'
}

export const loadStudentSoundsAction = createAction(
  SoundOptionActionTypes.Load,
  props<{
    readonly regionId: number;
  }>()
);

export const updateStudentSoundsAction = createAction(
  SoundOptionActionTypes.Update,
  props<{
    readonly soundOptionGroups: SoundOptionGroup[];
  }>()
);

