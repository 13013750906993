import { ChangeDetectionStrategy, Component, computed, inject, input, OnDestroy, OnInit, output, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { SharedService } from '../../services';

@Component({
  selector: 'kip-centre-picker',
  templateUrl: './centre-picker.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class CentrePickerComponent implements OnInit, OnDestroy {

  readonly #sharedService = inject(SharedService);

  readonly #centres = toSignal(this.#sharedService.getCentres(), { initialValue: [] });
  readonly #centreIdSignal = signal(0);
  #subscriptions: Subscription[] = [];

  readonly centreId = input.required<FormControl<number | null>>();
  readonly organisationIdFilter = input<number | undefined>(undefined);
  readonly label = input('');
  readonly name = input.required<string>();
  readonly labelClass = input<string | undefined>(undefined);
  readonly showDefaultAITutor = input(false);

  readonly defaultTutorAITutorText = computed(() => {
    const centres = this.#centres();
    const centreId = this.#centreIdSignal();
    const showDefaultAITutor = this.showDefaultAITutor();

    const centre = centres.find(s => s.id === centreId);

    if (centre && showDefaultAITutor) {
      return centre.defaultAITutorId ? 'Has default AI Tutor' : 'Does not have default AI Tutor';
    }

    return undefined;
  });

  readonly centres = computed(() => {
    const centres = this.#centres();
    const organisationIdFilter = this.organisationIdFilter();

    return organisationIdFilter ? [...centres.filter(c => c.organisationId === organisationIdFilter)] : [...centres];
  });

  readonly centreChange = output<number>();

  ngOnInit() {
    this.#subscriptions.push(
      this.centreId().valueChanges.subscribe(value => {
        this.#centreIdSignal.set(value ?? 0);
      }));
  }

  ngOnDestroy() {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

}
