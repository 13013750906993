import { createSelector } from '@ngrx/store';

import { LessonQuestion, SelectedQuestion } from '../../models';
import { QuestionEntityState } from '../models';
import { selectQuestionEntityState, selectSelectedActivityQuestionGuids, selectSelectedQuestionEntityIndex } from '../state';

export const selectSelectedQuestionIndex = createSelector(selectSelectedQuestionEntityIndex, id => id);

export const selectActivityQuestions = createSelector(
  selectQuestionEntityState, selectSelectedActivityQuestionGuids, (entityState, context) => {
    let questions: (LessonQuestion | undefined)[] = [];

    if (context) {
      questions = context.questionGuids.map(guid => {
        const state: QuestionEntityState = entityState[guid];

        if (state && state.question && state.questionKatex) {
          const question: LessonQuestion = {
            questionId: state.questionId,
            questionIndex: state.questionIndex,
            questionGuid: guid,
            lessonGuid: context.lessonGuid,
            question: state.question,
            questionKatex: state.questionKatex,
            visited: state.visited,
            duration: state.duration,
            correct: state.correct,
            skipped: state.skipped,
            hasWhiteboard: state.hasWhiteboard,
            attempts: state.attempts,
            score: state.score,
            introduction: state.introduction,
            workedSolution: state.workedSolution,
            reportedAsError: state.reportedAsError
          };

          return question;
        }

        return undefined;
      });
    }

    return questions.filter((question): question is LessonQuestion => question !== undefined);
  });

export const selectSelectedQuestion = createSelector(selectSelectedQuestionEntityIndex, selectActivityQuestions, (index, questions) => {
  if (questions) {
    let question: SelectedQuestion | undefined;

    if (index !== undefined) {

      const lessonQuestion = questions.find(q => q.questionIndex === index);

      if (lessonQuestion) {
        question = {
          questionId: lessonQuestion.questionId,
          question: lessonQuestion.question,
          questionKatex: lessonQuestion.questionKatex,
          questionIndex: lessonQuestion.questionIndex,
          questionGuid: lessonQuestion.questionGuid,
          lessonGuid: lessonQuestion.lessonGuid,
          visited: lessonQuestion.visited,
          skipped: lessonQuestion.skipped,
          duration: lessonQuestion.duration,
          attempts: lessonQuestion.attempts,
          correct: lessonQuestion.correct,
          skipAvailable: lessonQuestion.attempts >= 3,
          score: lessonQuestion.score,
          introduction: lessonQuestion.introduction,
          workedSolution: lessonQuestion.workedSolution,
          reportedAsError: lessonQuestion.reportedAsError
        };
      }
    }

    return question;
  }

  return undefined;
});
