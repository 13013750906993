<div class="p-4">
    <div class="custom-control custom-checkbox kip-choice mb-3">
        <div class="form-check">
            <input class="form-check-input" id="showSystemPrompt" type="checkbox" [ngModel]="showSystemPrompt()"
                (ngModelChange)="showSystemPrompt.set($event)">
            <label class="custom-control-label form-check-label form-check-label--append" for="showSystemPrompt">
                Show System Prompt
            </label>
        </div>
    </div>
    @if (studentSummary(); as studentSummary)
    {
    <div class="row">
        <div class="col-3">
            {{ studentSummary.fullName }}
        </div>
        <div class="col-3">
            {{ studentSummary.grade }}
        </div>
        <div class="col-3">
            {{ studentSummary.dateOfBirth | date }}
        </div>
        <div class="col-3">
            @if (studentSummary.aiTutorVoiceId)
            {
            AI Voice: {{ studentSummary.aiTutorVoiceId }}
            }
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <kip-message-parser [ai]="true" [message]="studentSummary.studentSummary" />
        </div>
    </div>
    }
    @for (aiDate of aiDates(); track aiDate)
    {
    <div class="row" (click)="aiDate.expanded = !aiDate.expanded">
        <div class="col-3">
            <fa-icon [icon]="aiDate.expanded?icons.chevrons.up: icons.chevrons.down" />
            {{ aiDate.date | day}}
        </div>
        <div class="col-9">
            {{ aiDate.interactions }}
        </div>
    </div>
    @if (aiDate.expanded)
    {
    @for (aiChat of aiDate.chats; track aiChat)
    {
    <div class="ms-4">
        <div class="row fw-bold" (click)="aiChat.expanded = !aiChat.expanded">
            <div class="col-2">
                <fa-icon [icon]="aiChat.expanded?icons.chevrons.up: icons.chevrons.down" />
                {{ aiChat.source }}
            </div>
            <div class="col-2">
                @if (aiChat.lesson)
                {
                Subject: {{ aiChat.lesson.subject }}
                }
            </div>
            <div class="col-2">
                @if (aiChat.lesson)
                {
                Tutor: {{ aiChat.lesson.tutor }}
                }
            </div>
            <div class="col-4">
                @if (aiChat.activity)
                {
                <fa-icon [icon]="aiChat.activity.isManual? icons.activityType.manual : icons.activityType.computer" />
                {{ aiChat.activity.name }}
                {{ aiChat.activity.isHomework ? '(Homework)': '' }}
                }
            </div>
            <div class="col-2">
                @if (aiChat.question)
                {
                Q{{ aiChat.question.sequence + 1 }}
                } @else {
                @if (aiChat.activity && !aiChat.activity.isManual) {
                Not Supported
                }
                }
            </div>
        </div>
        @if (aiChat.expanded)
        {
        <div class="ms-4">
            @for (message of aiChat.messages; track message)
            {
            <div class="row">
                <div class="col-1">
                    <fa-icon
                        [icon]="message.role === aiRole.System ? icons.lightbulb : message.role === aiRole.Assistant ? icons.brainBooster: icons.studentDetailsTab" />
                </div>
                <div class="col-11" [ngClass]="{'fst-italic': message.role === aiRole.Assistant}">
                    <kip-message-parser [ai]="true" [message]="message.message" />
                </div>
            </div>
            }
        </div>
        }
    </div>
    }
    }
    }
</div>