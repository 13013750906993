import { createReducer, on } from '@ngrx/store';
import { SoundOptionGroup } from 'ui-common-lib';

import * as SoundOptionActions from './sound-option.actions';

export interface SoundOptionState {
  readonly soundOptionGroups: readonly SoundOptionGroup[];
}

const initialState: SoundOptionState = {
  soundOptionGroups: []
};

export const soundOptionReducer = createReducer(initialState,
  on(SoundOptionActions.updateStudentSoundsAction, (_state, action): SoundOptionState => {
    return { soundOptionGroups: action.soundOptionGroups };
  })
);
