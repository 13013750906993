/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { AuthService } from 'auth-lib';
import { OpentokService } from 'open-tok-lib';
import { EMPTY, Observable, tap, throttleTime } from 'rxjs';
import { finalize, switchMap, withLatestFrom } from 'rxjs/operators';
import { LessonType, Monty } from 'ui-common-lib';
import { WhiteboardEventType } from 'whiteboard-lib';

import {
  gotoLesson,
  gotoLessonEnrolment,
  gotoLessonResults,
  IdleStatus,
  RouterService
} from '../../../shared';
import { AssistantMessage, AssistantMessageOption } from '../../models';
import { MessagingService } from '../../services';
import { fromAiChat } from '..';
import * as fromActivity from '../activity';
import * as fromAssistant from '../assistant';
import * as fromLesson from '../lesson';
import * as fromQuestion from '../question';
import { StudentState } from '../state';
import * as fromWhiteboard from '../whiteboard';

@Injectable()
export class MessagingEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<StudentState>);
  readonly #routing = inject(RouterService);
  readonly #authService = inject(AuthService);
  readonly #messagingService = inject(MessagingService);
  readonly #opentokService = inject(OpentokService);

  startedLookingTab$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.startedLookingTabAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.startedLookingTab(lesson.lessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  stoppedLookingTab$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.stoppedLookingTabAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.stoppedLookingTab(lesson.lessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  updateIdleStatus: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updateIdleStatusAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson) {
          return action.idleStatus === IdleStatus.Active || action.idleStatus === IdleStatus.ActiveLessonOver ?
            this.#messagingService.startedInteracting(lesson.lessonGuid).pipe(
              switchMap(() => EMPTY)
            ) : this.#messagingService.stoppedInteracting(lesson.lessonGuid).pipe(
              switchMap(() => EMPTY)
            );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  updateGradesAchieved$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updateGradesAchievedAction),
      switchMap(action => {
        return this.#messagingService.gradesAchieved(action.lessonGuid, action.subjects).pipe(
          switchMap(() => EMPTY)
        );
      })
    );
  }, { dispatch: false });

  openLobby$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.openLobbyAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.lobbyOpened(lesson.lessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  installAvailable$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.installAvailableAction),
      switchMap(action => this.#messagingService.installAvailable(action.lessonGuid, action.available).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  pullFromLobby$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.pullFromLobbyAction),
      concatLatestFrom(() => this.#store.select(fromLesson.selectSelectedLesson)),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          this.#store.dispatch(
            fromLesson.closeLobbyAction({ lessonId: lesson.lessonId })
          );

          this.#routing.router.navigate([gotoLesson(lesson.lessonId)]);
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  forceReload$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.forceReloadAction),
      switchMap(() => {
        window.location.reload();
        return EMPTY;
      })
    );
  }, { dispatch: false });

  forceReloadVideo$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.forceReloadVideoAction),
      switchMap(() => {
        this.#opentokService.refreshPublisher();
        return EMPTY;
      })
    );
  }, { dispatch: false });

  closeLobby$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.closeLobbyAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.lobbyClosed(lesson.lessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  openLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.loadAction, fromLesson.reconnectAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([_action, lesson]) => {
        if (lesson) {
          return this.#messagingService.lessonOpened(lesson.lessonGuid, lesson.isHomeworkOnly).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  updateStartLessonNote$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updateStartLessonNoteAction),
      switchMap(action => this.#messagingService.updateStartLessonNote(action.lessonGuid, action.note, action.noteRanking).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  updateFinishLessonNote$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.updateFinishLessonNoteAction),
      switchMap(action => this.#messagingService.updateFinishLessonNote(action.lessonGuid, action.note, action.noteRanking).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  closeLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.closeAction),
      switchMap(action => this.#messagingService.lessonClosed(action.lessonGuid).pipe(
        switchMap(() => EMPTY),
        finalize(() => this.#messagingService.disconnect())
      ))
    );
  }, { dispatch: false });

  finishLesson$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.finishAction),
      switchMap(action => this.#messagingService.lessonFinished(action.lessonGuid).pipe(
        switchMap(() => EMPTY),
        finalize(() => this.#messagingService.disconnect())
      ))
    );
  }, { dispatch: false });

  requestHelp$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.requestHelpAction),
      switchMap(action => this.#messagingService.helpRequested(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  openActivity$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.openAction, fromActivity.loadManyAction),
      throttleTime(500),
      concatLatestFrom(() => [
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(fromLesson.selectSelectedLesson)
      ]
      ),
      switchMap(([_action, activity, lesson]) => {
        if (activity && lesson) {
          const lessonGuid = lesson.lessonGuid;
          let homeworkLessonGuid: string | undefined;

          if (activity.isHomework) {
            homeworkLessonGuid = activity.lessonGuid;
          }

          return this.#messagingService.activityOpened(lessonGuid, activity.activityGuid, homeworkLessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  closeActivity$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.closeAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, action.lessonGuid);

          this.#messagingService.activityClosed(lesson.lessonGuid, action.activityGuid, previousLessonGuid).pipe(
            switchMap(() => EMPTY)
            , finalize(() => {
              if (action.unitId && action.completed) {
                this.#messagingService.newUnitRequired(action.activityGuid, action.unitId, lesson.lessonId);
              }
            })
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  activityPercentage$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.percentageAction),
      switchMap(action => this.#messagingService.activityPercentage(action.lessonGuid, action.activityGuid,
        action.percentage, action.ageResult, action.age).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  activityStartFirstAttempt$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.startFirstAttempt),
      switchMap(action => this.#messagingService.activityStartFirstAttempt(action.lessonGuid, action.activityGuid, action.startDateTime).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  activityStartSecondAttempt$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.startSecondAttempt),
      switchMap(action => this.#messagingService.activityStartSecondAttempt(action.lessonGuid, action.activityGuid, action.startDateTime).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  activityFirstAttempt$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.firstAttemptAction),
      switchMap(action => this.#messagingService.activityFirstAttempt(action.lessonGuid, action.activityGuid, action.count).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  activitySecondAttempt$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.secondAttemptAction),
      switchMap(action => this.#messagingService.activitySecondAttempt(action.lessonGuid, action.activityGuid, action.count).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  activityStarted$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.startAction),
      concatLatestFrom(
        () => this.#store.select(fromActivity.selectSelectedActivity)
      ),
      switchMap(([action, activity]) => {
        if (activity) {
          return this.#messagingService.activityStarted(activity.lessonGuid, action.activityGuid, action.startedOn)
            .pipe(
              switchMap(() => EMPTY)
            );
        }
        return EMPTY;
      }
      ));
  }, { dispatch: false });

  activityFinished$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.finishAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson) {
          return this.#messagingService.activityCompleted(lesson.lessonGuid, action.activityGuid, action.completedOn)
            .pipe(
              switchMap(() => EMPTY)
            );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  activityFileRemoved$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.removeFileAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson) {
          return this.#messagingService.removeActivityFile(lesson.lessonGuid, action.activityGuid, action.fileId)
            .pipe(
              switchMap(() => EMPTY)
            );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  activityFileRenamed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.renameFileAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson) {
          return this.#messagingService.renameActivityFile(lesson.lessonGuid, action.activityGuid, action.fileId, action.fileName)
            .pipe(
              switchMap(() => EMPTY)
            );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  openQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.openAction, fromQuestion.loadManyAction),
      concatLatestFrom(() => [
        this.#store.select(fromQuestion.selectSelectedQuestion),
        this.#store.select(fromActivity.selectSelectedActivity)
      ]
      ),
      switchMap(([_action, question, activity]) => {
        if (question && activity) {

          const lessonGuid = question.lessonGuid;
          let homeworkLessonGuid: string | undefined;

          if (activity.isHomework) {
            homeworkLessonGuid = activity.lessonGuid;
          }

          return this.#messagingService.questionOpened(lessonGuid, question.questionGuid, homeworkLessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  skipQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.skipAction),
      switchMap(action =>

        this.#messagingService.questionSkipped(action.lessonGuid, action.activityGuid, action.questionGuid).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  closeQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.closeAction),
      concatLatestFrom(() => this.#store.select(fromLesson.selectSelectedLesson)),
      switchMap(([action, lesson]) => {
        if (lesson) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, action.lessonGuid);

          return this.#messagingService.questionClosed(lesson.lessonGuid, action.questionGuid, previousLessonGuid).pipe(
            switchMap(() => EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  openAssessmentResults$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.openAssessmentResultsAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson && action.lessonId === lesson.lessonId) {
          this.#routing.router.navigate([gotoLesson(lesson.lessonId)], { skipLocationChange: true }).then(() =>
            this.#routing.router.navigate([gotoLessonResults(lesson.lessonId)]));
        }
        return EMPTY;
      })
    );
  }, { dispatch: false });

  openAssessmentHome$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.openAssessmentHomeAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson && action.lessonId === lesson.lessonId) {
          this.#routing.router.navigate([gotoLesson(lesson.lessonId)]);
        }
        return EMPTY;
      })
    );
  }, { dispatch: false });

  exitAssessment$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.exitAssessmentAction),
      tap(() => {
        this.#authService.logout();
      })
    );
  }, { dispatch: false });

  openLessonEnrolment$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.openLessonEnrolmentAction),
      concatLatestFrom(
        () => this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, lesson]) => {
        if (lesson && action.lessonId === lesson.lessonId) {
          this.#routing.router.navigate([gotoLesson(lesson.lessonId)], { skipLocationChange: true }).then(() =>
            this.#routing.router.navigate([gotoLessonEnrolment(lesson.lessonId)]));
        }
        return EMPTY;
      })
    );
  }, { dispatch: false });

  viewIntroduction$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.introductionAction),
      switchMap(action => this.#messagingService.questionIntroductionViewed(action.lessonGuid, action.questionGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  viewWorkedSolution$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.workedSolutionAction),
      switchMap(action => this.#messagingService.questionWorkedSolutionViewed(action.lessonGuid, action.questionGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  updatedQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.answerUpdatedAction),
      tap(action => {
        this.#messagingService.questionUpdated(action.lessonGuid, action.questionGuid, action.answers, action.correct);
      }));
  }, { dispatch: false });

  answerQuestion$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromQuestion.answerAction),
      withLatestFrom(
        this.#store.select(fromQuestion.selectSelectedQuestion),
        this.#store.select(fromLesson.selectSelectedLesson),
        this.#store.select(fromQuestion.selectActivityQuestions),
        this.#store.select(fromActivity.selectSelectedActivity)
      ),
      switchMap(([action, question, lesson, questions, activity]) => {
        if (question && lesson && activity) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, activity.lessonGuid);

          return (
            this.#messagingService
              .questionAnswered(lesson.lessonGuid, question.questionGuid, action.answers, action.correct, question.score, previousLessonGuid)
              .pipe(
                switchMap(() => EMPTY),
                finalize(() => {
                  const attemptedQuestion = questions.find(q => q.questionGuid === question.questionGuid);
                  if (attemptedQuestion && attemptedQuestion.attempts > 2 && !attemptedQuestion.correct && !lesson.isHomeworkOnly && !lesson.tutorIsAI && lesson.lessonType !== LessonType.HomeMakeUp) {
                    const options: AssistantMessageOption[] = [{ text: 'Yes' }, { text: 'Try again' }];
                    const message = new AssistantMessage(`Hi ${lesson.student.givenName}, do you need help?`, options, '', true);
                    message.result.subscribe(value => {
                      if (value.text === 'Yes') {
                        this.#store.dispatch(fromLesson.requestHelpAction({ lessonGuid: lesson.lessonGuid }));
                        this.#store.dispatch(fromAssistant.addMessageAction(
                          {
                            message: new AssistantMessage(
                              'Your tutor will be here soon to help you through these.',
                              [{ text: 'Okay' }],
                              '',
                              true)
                          }));
                      }
                      else if (value.text === 'Try again') {
                        this.#store.dispatch(fromAssistant.clearMessagesAction());
                      }
                    });
                    this.#store.dispatch(fromAssistant.changeMontyAction({ monty: Monty.Head }));
                    this.#store.dispatch(fromAssistant.addMessageAction({ message: message }));
                  }
                })
              ));
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  chat$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.chatAction),
      switchMap(action => this.#messagingService.chat(action.lessonGuid, action.message, action.chatGroup, action.warning).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  aiChat$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromAiChat.chatAction),
      switchMap(action => this.#messagingService.aiChat(action.message, action.chatGroup, action.lessonGuid, action.lessonId, action.tutorName,
        action.lessonActivityPlanId, action.pdf, action.adHoc, action.question, action.restrictAiSubject, action.aiWithholdAnswer).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  screenSharePublishAllowed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.screenSharePublishAllowedAction),
      switchMap(action => this.#messagingService.screenSharePublishAllowed(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenSharePublishDenied$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.screenSharePublishDeniedAction),
      switchMap(action => this.#messagingService.screenSharePublishDenied(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  publishAllowed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.publishAllowedAction),
      switchMap(action => this.#messagingService.publishAllowed(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  publishDenied$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.publishDeniedAction),
      switchMap(action => this.#messagingService.publishDenied(action.lessonGuid).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  subscribeFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.subscribeFailureAction),
      switchMap(action => this.#messagingService.subscribeFailure(action.lessonGuid, action.name, action.message).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenShareStreamDestroyed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.screenShareStreamDestroyedAction),
      switchMap(action => this.#messagingService.screenShareStreamDestroyed(action.lessonGuid, action.reason).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  screenSharePublishFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.screenSharePublishFailureAction),
      switchMap(action => this.#messagingService.screenSharePublishFailure(action.lessonGuid, action.name, action.message).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  streamDestroyed$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.streamDestroyedAction),
      switchMap(action => this.#messagingService.streamDestroyed(action.lessonGuid, action.reason).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  publishFailure$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.publishFailureAction),
      switchMap(action => this.#messagingService.publishFailure(action.lessonGuid, action.name, action.message).pipe(
        switchMap(() => EMPTY)
      ))
    );
  }, { dispatch: false });

  whiteboardSetGridType$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.sendGridTypeAction),
      switchMap(action => this.#messagingService.whiteboardGridType(action.lessonGuid, action.whiteboardGuidKey.activityGuid,
        action.whiteboardGuidKey.pageGuid, action.gridType, action.broadcastLessonGuid).pipe(
          switchMap(() => EMPTY)
        ))
    );
  }, { dispatch: false });

  whiteboardOpen$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.openAction),
      concatLatestFrom(() => this.#store.select(fromLesson.selectSelectedLesson)),
      switchMap(([action, lesson]) => {
        if (lesson) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, action.lessonGuid);

          this.#messagingService.whiteboardOpen(lesson.lessonGuid, action.whiteboardGuidKey.activityGuid, action.whiteboardGuidKey.pageGuid, previousLessonGuid).pipe(
            switchMap(() =>
              EMPTY)
          );
        }
        return EMPTY;
      })
    );
  }, { dispatch: false });

  whiteboardClose$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.closeAction),
      concatLatestFrom(() => this.#store.select(fromLesson.selectSelectedLesson)),
      switchMap(([action, lesson]) => {
        if (lesson) {
          const previousLessonGuid = this.getLastWeeksHomeworkPreviousLessonGuidFromAction(lesson.previousLessonGuid, action.lessonGuid);

          this.#messagingService.whiteboardClose(lesson.lessonGuid, action.whiteboardGuidKey.activityGuid, action.whiteboardGuidKey.pageGuid, previousLessonGuid).pipe(
            switchMap(() =>
              EMPTY)
          );
        }

        return EMPTY;
      })
    );
  }, { dispatch: false });

  addDIY$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromLesson.addDIYAction),
      switchMap(action => this.#messagingService.addDIY(action.lessonId, action.isHomework).pipe(
        switchMap(() =>
          EMPTY)
      ))
    );
  }, { dispatch: false });

  whiteboardSend$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromWhiteboard.sendAction),
      switchMap(action =>
        this.#messagingService.whiteboardEvent(action.lessonGuid, action.whiteboardGuidKey.activityGuid, action.whiteboardGuidKey.pageGuid,
          action.event, action.broadcastLessonGuid).pipe(
            switchMap(() =>
              action.event.type === WhiteboardEventType.PartiallyAdded
                || action.event.type === WhiteboardEventType.PartiallyUpdated ? [] : [
                fromActivity.whiteboardDrawnAction({ activityGuid: action.whiteboardGuidKey.activityGuid }),
                fromQuestion.whiteboardDrawnAction({ questionGuid: action.whiteboardGuidKey.pageGuid })
              ]
            )
          )
      )
    );
  });

  getLastWeeksHomeworkPreviousLessonGuidFromAction(previousLessonGuid: string | undefined, lessonGuid: string) {
    if (lessonGuid === previousLessonGuid) {
      return lessonGuid;
    }
    return undefined;
  }
}
