import { createReducer, on } from '@ngrx/store';
import { Monty } from 'ui-common-lib';

import { AssistantMessage } from '../../models';
import * as AssistantActions from './assistant.actions';

export interface AssistantState {
  readonly monty: Monty;
  readonly messages: readonly AssistantMessage[];
  readonly isLoading: boolean;
}

const initialState: AssistantState = {
  monty: Monty.Head,
  messages: [],
  isLoading: false
};

export const assistantReducer = createReducer(initialState,
  on(AssistantActions.clearMessagesAction, (state): AssistantState => {
    return { monty: state.monty, messages: [], isLoading: state.isLoading };
  }),
  on(AssistantActions.addMessageAction, (state, action) => {
    const messages = [...state.messages];
    messages.unshift(action.message);
    return { monty: state.monty, messages: messages, isLoading: state.isLoading };
  }),
  on(AssistantActions.changeMontyAction, (state, action): AssistantState => {
    return { monty: action.monty, messages: state.messages, isLoading: state.isLoading };
  }),
  on(AssistantActions.showLoadingAction, (state): AssistantState => {
    return { monty: state.monty, messages: state.messages, isLoading: true };
  }),
  on(AssistantActions.hideLoadingAction, (state): AssistantState => {
    return { monty: state.monty, messages: state.messages, isLoading: false };
  }),
  on(AssistantActions.selectOptionAction, (state, action) => {
    action.message.setOption(action.option);
    const existingMessages = [...state.messages];
    existingMessages.splice(existingMessages.indexOf(action.message), 1);
    return { monty: state.monty, messages: existingMessages, isLoading: false };
  }),
  on(AssistantActions.resetAssistantAction, (state): AssistantState => {
    return { monty: state.monty, messages: [], isLoading: false };
  })
);
