import { createAction, props } from '@ngrx/store';
import { AnswerType } from 'questions-lib';

import { LessonQuestion } from '../../../shared';

export interface QuestionContext {
  readonly lessonGuid: string;
  readonly question: LessonQuestion;
}

enum QuestionActionTypes {
  AiChat = 'Tutor > Question > AI Chat',
  LoadMany = 'Tutor > Question > Load Many',
  Update = 'Tutor > Question > Update',
  Open = 'Tutor > Question > Open',
  Skip = 'Tutor > Question > Skip',
  Close = 'Tutor > Question > Close',
  Answer = 'Tutor > Question > Answer',
  Introduction = 'Tutor > Question > Introduction',
  WorkedSolution = 'Tutor > Question > Worked Solution',
  StartTimer = 'Tutor > Question > Timer > Start',
  EndTimer = 'Tutor > Question > Timer > End',
  WhiteboardDrawn = 'Tutor > Question > Whiteboard',
  AnswerUpdated = 'Tutor > Question > Answer Updated'
}

export const loadManyAction = createAction(
  QuestionActionTypes.LoadMany,
  props<{
    readonly contexts: readonly QuestionContext[];
  }>()
);

export const updateAction = createAction(
  QuestionActionTypes.Update,
  props<{
    readonly lessonGuid: string;
    readonly questions: readonly LessonQuestion[];
  }>()
);

export const introductionAction = createAction(
  QuestionActionTypes.Introduction,
  props<{
    readonly lessonGuid: string;
    readonly questionGuid: string;
  }>()
);

export const workedSolutionAction = createAction(
  QuestionActionTypes.WorkedSolution,
  props<{
    readonly lessonGuid: string;
    readonly questionGuid: string;
  }>()
);

export const openAction = createAction(
  QuestionActionTypes.Open,
  props<{
    readonly questionGuid: string;
  }>()
);

export const skipAction = createAction(
  QuestionActionTypes.Skip,
  props<{
    readonly questionGuid: string;
    readonly lessonId: number;
    readonly activityGuid: string;
    readonly skillBuilderAdded: boolean;
  }>()
);

export const closeAction = createAction(
  QuestionActionTypes.Close,
  props<{
    readonly questionGuid: string;
  }>()
);

export const whiteboardDrawnAction = createAction(
  QuestionActionTypes.WhiteboardDrawn,
  props<{
    readonly questionGuid: string;
  }>()
);

export const answerAction = createAction(
  QuestionActionTypes.Answer,
  props<{
    readonly questionGuid: string;
    readonly answers: readonly AnswerType[];
    readonly correct: boolean;
    readonly score: number;
  }>()
);

export const answerUpdatedAction = createAction(
  QuestionActionTypes.AnswerUpdated,
  props<{
    readonly questionGuid: string;
    readonly answers: readonly AnswerType[];
    readonly correct: boolean;
  }>()
);

export const startTimerAction = createAction(
  QuestionActionTypes.StartTimer,
  props<{
    readonly questionGuid: string;
  }>()
);

export const endTimerAction = createAction(
  QuestionActionTypes.EndTimer,
  props<{
    readonly questionGuid: string;
  }>()
);

export const aiChat = createAction(
  QuestionActionTypes.AiChat,
  props<{
    readonly questionGuid: string;
  }>()
);