import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'service-lib';

import { DropInSessionSummaryList } from '../models';

@Injectable({ providedIn: 'root' })
export class DropInService extends HttpService {

  getDropInSessions(): Observable<DropInSessionSummaryList> {
    return this.get<DropInSessionSummaryList>('student/drop-in');
  }
}
