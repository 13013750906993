import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-new-talking-three',
  templateUrl: './monty-new-talking-three.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})

export class MontyNewTalkingThreeComponent implements MontyGraphic {

}
