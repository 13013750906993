import { createReducer, on } from '@ngrx/store';
import { LessonEnrolment } from 'enrolment-lib';
import { OpenTokErrorCodes, PublishState } from 'open-tok-lib';
import { CompletionGraphic, getCompletionGraphic } from 'pipes-directives-lib';
import { AnimationActions, AnimationActionTime } from 'ready-player-me-lib';
import { VideoEffectType } from 'ui-common-lib';

import { AssessmentStatus, ConnectionState, DIYUpdateResult, IdleStatus, LessonFocus, LessonHelp, LessonHelpStatus, StreamState } from '../../../shared';
import { LessonEntity, LessonEntityState } from '../models';
import * as LessonActions from './lesson.actions';

export interface LessonState {
  readonly lobby: boolean;
  readonly idle: IdleStatus;
  readonly lookingTab: boolean;
  readonly connected: ConnectionState;
  readonly streamState: StreamState;
  readonly videoEffectType: VideoEffectType;
  readonly disableScreenShare: boolean;
  readonly selectedId: number | undefined;
  readonly entityIds: readonly number[];
  readonly isLoadingLesson: boolean;
  readonly entities: {
    readonly [lessonId: number]: LessonEntity;
  };
  readonly entityState: {
    readonly [lessonGuid: string]: LessonEntityState;
  };
  readonly sendUpdates: boolean;
  readonly connectionId: string;
  readonly classInProgress: boolean;
  readonly animationAction: AnimationActionTime;
}

const initialState: LessonState = {
  lobby: false,
  idle: IdleStatus.Active,
  lookingTab: true,
  connected: ConnectionState.Unknown,
  streamState: StreamState.Unknown,
  videoEffectType: VideoEffectType.None,
  disableScreenShare: false,
  selectedId: undefined,
  entityIds: [],
  entities: {},
  entityState: {},
  sendUpdates: false,
  isLoadingLesson: false,
  connectionId: '',
  classInProgress: false,
  animationAction: { action: AnimationActions.Hide }
};

function updateLessonEntityState(lessonGuid: string, state: LessonState,
  update: (entityState: LessonEntityState) => Partial<LessonEntityState>,
  sendUpdates?: boolean): LessonState {

  let lessonState = state.entityState[lessonGuid];

  if (lessonState) {
    const updateState = update(lessonState);
    lessonState = Object.assign({}, lessonState, updateState);

    return {
      lobby: state.lobby,
      idle: state.idle,
      lookingTab: state.lookingTab,
      connected: state.connected,
      streamState: state.streamState,
      videoEffectType: state.videoEffectType,
      disableScreenShare: state.disableScreenShare,
      selectedId: state.selectedId,
      entityIds: state.entityIds,
      entities: state.entities,
      isLoadingLesson: state.isLoadingLesson,
      entityState: { ...state.entityState, [lessonGuid]: lessonState },
      sendUpdates: sendUpdates === undefined ? state.sendUpdates : sendUpdates,
      connectionId: state.connectionId,
      classInProgress: state.classInProgress,
      animationAction: state.animationAction
    };
  }

  return state;
}

function updateLessonEnrolmentState(lessonGuid: string, state: LessonState,
  update: (entityState: LessonEntityState) => Partial<LessonEnrolment>): LessonState {
  const lessonState = state.entityState[lessonGuid];
  return updateLessonEntityState(lessonGuid, state, () => ({
    enrolment: Object.assign({}, lessonState.enrolment, update(lessonState))
  }));
}

function updateLessonState(state: LessonState,
  update: (entityState: LessonState) => Partial<LessonState>): LessonState {
  return Object.assign({}, state, update(state));
}

export const lessonReducer = createReducer(initialState,
  on(LessonActions.loadAction, (state, action) => {
    let entityIds = state.entityIds;
    let entities = state.entities;

    // If the entity doesn't exist, add it
    if (!state.entityIds.includes(action.lesson.lessonId)) {
      const entity: LessonEntity = {
        lessonId: action.lesson.lessonId,
        lessonGuid: action.lesson.lessonGuid,
        sessionId: action.lesson.sessionId,
        regionId: action.lesson.regionId,
        soundRegionId: action.lesson.soundRegionId,
        studentId: action.lesson.studentId,
        tutorId: action.lesson.tutorId,
        centreId: action.lesson.centreId,
        centreName: action.lesson.centreName,
        allowBrainBooster: action.lesson.allowBrainBooster ?? false,
        subjectName: action.lesson.subjectName,
        adjustableHeaderId: action.lesson.adjustableHeaderId,
        aiStudentHelpEnabled: action.lesson.aiStudentHelpEnabled ?? false,
        aiStudentHelpSpeechEnabled: action.lesson.aiStudentHelpSpeechEnabled ?? false,
        aiTutorEnabled: action.lesson.aiTutorEnabled ?? false,
        lessonType: action.lesson.lessonType,
        student: action.lesson.student,
        newAvatar: action.lesson.newAvatar ?? false,
        tutor: action.lesson.tutor,
        tutorIsAI: action.lesson.tutorIsAI ?? false,
        aiAssessmentTargetPercentage: action.lesson.aiAssessmentTargetPercentage,
        dateTime: action.lesson.dateTime,
        duration: action.lesson.duration,
        allowStudentStartLessonNoteRanking: action.lesson.allowStudentStartLessonNoteRanking ?? false,
        allowStudentFinishLessonNoteRanking: action.lesson.allowStudentFinishLessonNoteRanking ?? false,
        allowStudentFinishLessonNoteComment: action.lesson.allowStudentFinishLessonNoteComment ?? false,
        allowStudentStartLessonNoteComment: action.lesson.allowStudentStartLessonNoteComment ?? false,
        useEmojisForStudentRanking: action.lesson.useEmojisForStudentRanking ?? false,
        studentStartLessonTitle: action.lesson.studentStartLessonTitle ?? '',
        studentFinishLessonText: action.lesson.studentFinishLessonText ?? '',
        studentFinishLessonTitle: action.lesson.studentFinishLessonTitle ?? '',
        studentStartLessonText: action.lesson.studentStartLessonText ?? '',
        isAssessment: action.lesson.isAssessment ?? false,
        isDIYCompatible: action.lesson.isDIYCompatible ?? false,
        activityGuids: (action.lesson.activities ?? []).map(activity => activity.activityGuid),
        homeworkGuids: (action.lesson.homework ?? []).map(activity => activity.activityGuid),
        previousLessonGuid: action.lesson.previousLessonGuid,
        previousHomeworkGuids: (action.lesson.previousHomework ?? []).map(activity => activity.activityGuid)
      };

      entityIds = [...entityIds, action.lesson.lessonId];
      entities = { ...entities, [action.lesson.lessonId]: entity };
    }

    // Build the lesson state
    const lessonState: LessonEntityState = {
      tutorOnline: action.lesson.tutorOnline ?? false,
      publishVideo: action.lesson.publishVideo ?? false,
      publishAudio: action.lesson.publishAudio ?? false,
      publishState: PublishState.Unknown,
      screenSharePublishState: PublishState.Unknown,
      tutorPhotoUrl: '',
      studentPhotoUrl: '',
      tutorPhotoDefault: true,
      studentPhotoDefault: true,
      subscribeAudio: false,
      subscribeScreenShare: false,
      isOnline: action.lesson.isOnline,
      startLessonNote: action.lesson.startLessonNote ?? '',
      startLessonNoteRanking: action.lesson.startLessonNoteRanking ?? null,
      finishLessonNote: action.lesson.finishLessonNote ?? '',
      finishLessonNoteRanking: action.lesson.finishLessonNoteRanking ?? null,
      isHomeworkOnly: action.lesson.isHomeworkOnly ?? false,
      isDropInLessonCompleted: action.lesson.isDropInLessonCompleted,
      focus: action.lesson.focus,
      observe: action.lesson.observe,
      help: action.lesson.help,
      enrolment: undefined,
      assessmentStatus: action.lesson.assessmentStatus ?? null,
      assessmentResultType: undefined,
      rendered: new Date(),
      lessonType: action.lesson.lessonType,
      awardData: { awards: 0, points: 0, certificates: 0 },
      installApp: false,
      message: '',
      bundleSelected: {},
      treeSummaryIndex: 0,
      treeSummaries: action.lesson.treeSummaries ?? [],
      aiAssessmentFinished: false,
      aiNote: ''
    };

    return {
      lobby: state.lobby,
      idle: IdleStatus.Active,
      lookingTab: true,
      connected: ConnectionState.Unknown,
      streamState: StreamState.Unknown,
      videoEffectType: action.lesson.tutorVideoEffect,
      disableScreenShare: action.lesson.disableScreenShare ?? false,
      selectedId: state.selectedId,
      isLoadingLesson: state.isLoadingLesson,
      entityIds: entityIds,
      entities: entities,
      entityState: { ...state.entityState, [action.lesson.lessonGuid]: lessonState },
      sendUpdates: lessonState.observe.observed,
      connectionId: state.connectionId,
      classInProgress: state.classInProgress,
      animationAction: state.animationAction
    };
  }),
  on(LessonActions.startedLookingTabAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      lookingTab: true,
      selectedId: action.lessonId
    }));
  }),
  on(LessonActions.joinLessonCompletedAction, (state, action): LessonState => {
    console.log(`SignalR ConnectionId:${action.connectionId}`);
    return updateLessonState(state, () => ({
      lookingTab: true,
      connectionId: action.connectionId
    }));
  }),
  on(LessonActions.stoppedLookingTabAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      lookingTab: false,
      selectedId: action.lessonId
    }));
  }),
  on(LessonActions.updateIdleStatusAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      idle: action.idleStatus,
      selectedId: action.lessonId
    }));
  }),
  on(LessonActions.updateAINoteAction, (state, action): LessonState => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      aiNote: action.note
    }));
  }),
  on(LessonActions.openLobbyAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      lobby: true,
      selectedId: action.lessonId
    }));
  }),
  on(LessonActions.closeLobbyAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      lobby: false,
      selectedId: action.lessonId
    }));
  }),
  on(LessonActions.openAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      selectedId: action.lessonId
    }));
  }),
  on(LessonActions.closeAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      lobby: false,
      selectedId: undefined
    }));
  }),
  on(LessonActions.avatarShowAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      animationAction: { action: AnimationActions.Show, time: new Date() }
    }));
  }),
  on(LessonActions.avatarHideAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      animationAction: { action: AnimationActions.Hide, time: new Date() }
    }));
  }),
  on(LessonActions.updateAnimationStateQuestionAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      animationAction: { action: action.correct ? AnimationActions.Correct : AnimationActions.Incorrect, time: new Date() }
    }));
  }),
  on(LessonActions.updateAnimationStateCompleteAction, (state, action): LessonState => {

    let animationAction = AnimationActions.Complete;
    switch (getCompletionGraphic(action.percentage, action.isAssessment)) {
      case CompletionGraphic.Complete:
        animationAction = AnimationActions.Complete;
        break;
      case CompletionGraphic.WellDone:
        animationAction = AnimationActions.WellDone;
        break;
      case CompletionGraphic.GreatJob:
        animationAction = AnimationActions.GreatJob;
        break;
      case CompletionGraphic.PerfectScore:
        animationAction = AnimationActions.PerfectScore;
        break;
    }

    return updateLessonState(state, () => ({
      animationAction: { action: animationAction, time: new Date() }
    }));
  }),
  on(LessonActions.updateAction, (state, action): LessonState => {

    // updated lesson details read in entity.
    let entity = state.entities[action.lesson.lessonId];
    entity = Object.assign({}, entity, {
      activityGuids: (action.lesson.activities ?? []).map(activity => activity.activityGuid),
      homeworkGuids: (action.lesson.homework ?? []).map(activity => activity.activityGuid)
    });

    return updateLessonState(state, () => ({
      entities: { ...state.entities, [entity.lessonId]: entity }
    }));
  }),
  on(LessonActions.updateFinishLessonNoteAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      finishLessonNote: action.note,
      finishLessonNoteRanking: action.noteRanking
    }));
  }),
  on(LessonActions.updateTreeSummaryAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      treeSummaryIndex: action.treeSummaryIndex,
      treeSummaries: action.treesSummaries
    }));
  }),
  on(LessonActions.updateStartLessonNoteAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      startLessonNote: action.note,
      startLessonNoteRanking: action.noteRanking
    }));
  }),
  on(LessonActions.updateAwardDataAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      awardData: action.awardData
    }));
  }),
  on(LessonActions.toggleOnlineAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, entityState => ({
      publishAudio: action.isOnline,
      publishVideo: action.isOnline,
      subscribeAudio: action.isOnline ? entityState.subscribeAudio : false,
      isOnline: action.isOnline,
      help: action.isOnline ? entityState.help : undefined
    }));
  }),
  on(LessonActions.aiAssessmentFinished, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      aiAssessmentFinished: true
    }));
  }),
  on(LessonActions.loadEnrolmentAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      enrolment: action.lessonEnrolment
    }));
  }),
  on(LessonActions.updateEnrolmentProgressAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      stage: action.enrolmentStage
    }));
  }),
  on(LessonActions.updateEnrolmentSubjectsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      subjects: action.subjects
    }));
  }),
  on(LessonActions.updateEnrolmentSessionsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      sessionSchedules: action.sessions
    }));
  }),
  on(LessonActions.updateEnrolmentBundleCurrencyAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      bundleCurrency: action.bundleCurrency
    }));
  }),
  on(LessonActions.updateEnrolmentBundleTypeAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      bundleOfferTypeId: action.bundleOfferTypeId
    }));
  }),
  on(LessonActions.updateEnrolmentBundleAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      billingInterval: action.billingInterval,
      bundleOfferId: action.bundleOfferId,
      customerRepresentsOrganisation: action.customerRepresentsOrganisation
    }));
  }),
  on(LessonActions.updateEnrolmentPaymentOptionsAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      startDate: action.startDate,
      debitDay: action.debitDay,
      upfrontPaymentOption: action.upfrontPaymentOption
    }));
  }),
  on(LessonActions.updateEnrolmentPaymentEntryAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      assessorEnteringPayment: action.assessorEnteringPayment
    }));
  }),
  on(LessonActions.updateEnrolmentAccountCreatedAction, (state, action) => {
    return updateLessonEnrolmentState(action.lessonGuid, state, () => ({
      parentPortalCreated: true
    }));
  }),
  on(LessonActions.clearMessageAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      message: ''
    }));
  }),
  on(LessonActions.updateLessonDoItYourselfAction, (state, action) => {
    /* eslint-disable sonarjs/no-duplicate-string */
    switch (action.updateResult) {
      case DIYUpdateResult.SuccessToScheduled:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          isOnline: action.isOnline,
          lessonType: action.lessonType,
          lessonStatus: action.lessonStatus,
          message: 'Tutor has changed your lesson. Press Ok to reload.'
        }));
      case DIYUpdateResult.SuccessToHomeMakeupCreated:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          isOnline: action.isOnline,
          lessonType: action.lessonType,
          lessonStatus: action.lessonStatus,
          message: 'Tutor has changed your lesson. Press Ok to reload.'
        }));
      case DIYUpdateResult.SuccessToHomeMakeupNotCreated:
        return updateLessonEntityState(action.lessonGuid, state, () => ({
          isOnline: action.isOnline,
          lessonType: action.lessonType,
          lessonStatus: action.lessonStatus,
          message: 'Tutor has changed your lesson. Press Ok to reload.'
        }));
      default:
        return state;
    }
    /* eslint-enable sonarjs/no-duplicate-string */
  }),
  on(LessonActions.openAssessmentResultsAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      assessmentStatus: AssessmentStatus.Reviewing,
      bundleSelected: action.bundleSelected
    }));
  }),
  on(LessonActions.openAssessmentResultTypeAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      assessmentResultType: action.resultType
    }));
  }),
  on(LessonActions.renderedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      rendered: action.rendered
    }));
  }),
  on(LessonActions.openAssessmentHomeAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      assessmentStatus: AssessmentStatus.Assessing
    }));
  }),
  on(LessonActions.updateAssessmentStatusAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      assessmentStatus: action.assessmentStatus
    }));
  }),
  on(LessonActions.tutorPhotoUpdateAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      tutorPhotoUrl: action.tutorPhotoUrl,
      tutorPhotoDefault: action.tutorPhotoDefault
    }));
  }),
  on(LessonActions.studentPhotoUpdateAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      studentPhotoUrl: action.studentPhotoUrl,
      studentPhotoDefault: action.studentPhotoDefault
    }));
  }),
  on(LessonActions.toggleVideoAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      publishVideo: action.publish
    }));
  }),
  on(LessonActions.toggleAudioAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      publishAudio: action.publish
    }));
  }),
  on(LessonActions.screenSharePublishAllowedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      screenSharePublishState: PublishState.Allowed
    }));
  }),
  on(LessonActions.screenSharePublishDeniedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      screenSharePublishState: PublishState.Denied
    }));
  }),
  on(LessonActions.publishAllowedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      publishState: PublishState.Allowed
    }));
  }),
  on(LessonActions.publishDeniedAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      publishState: PublishState.Denied
    }));
  }),
  on(LessonActions.studentDetailsUpdatedAction, (state, action): LessonState => {
    const entities: { [lessonId: number]: LessonEntity } = {};

    for (const entityId of state.entityIds) {
      const existingEntity = state.entities[entityId];
      if (existingEntity) {
        entities[entityId] = Object.assign({}, existingEntity, {
          student: { givenName: action.givenName, familyName: action.familyName },
          regionId: action.regionId ?? existingEntity.regionId
        });
      }
    }

    return updateLessonState(state, () => ({
      entities: entities
    }));
  }),
  on(LessonActions.dropInLessonCompletedAction, (state, action): LessonState => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      isDropInLessonCompleted: true
    }));
  }),
  on(LessonActions.dropInLessonRequestAcceptedAction, (state, action): LessonState => {
    const lesson = state.entities[action.lessonId];

    if (lesson) {
      return updateLessonEntityState(lesson.lessonGuid, state, () => ({
        isDropInLessonCompleted: false
      }));
    }
    return state;
  }),

  on(LessonActions.screenSharePublishFailureAction, (state, action) => {
    // Denied is already covered by denied message

    if (action.name !== OpenTokErrorCodes.AccessDenied) {
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        screenSharePublishState: PublishState.Unable
      }));
    }
    return state;
  }),
  on(LessonActions.publishFailureAction, (state, action) => {

    // Denied is already covered by denied message

    if (action.name !== OpenTokErrorCodes.AccessDenied) {
      return updateLessonEntityState(action.lessonGuid, state, () => ({
        publishState: PublishState.Unable
      }));
    }
    return state;
  }),
  on(LessonActions.tutorPublishAllowedAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      streamState: StreamState.Streaming
    }));
  }),
  on(LessonActions.tutorPublishDeniedAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      streamState: StreamState.Denied
    }));
  }),
  on(LessonActions.tutorPublishFailureAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      streamState: StreamState.Failure
    }));
  }),
  on(LessonActions.startTeachingAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, lessonState => {
      const focus: LessonFocus = {
        focused: true,
        duration: lessonState.focus ? lessonState.focus.duration : 0,
        sinceLast: undefined
      };

      // Also clear the help as any requests are being addressed via the focus
      return {
        tutorOnline: true,
        publishAudio: true,
        subscribeAudio: true,
        subscribeScreenShare: true,
        focus: focus,
        help: undefined
      };
    }, true);
  }),
  on(LessonActions.stopTeachingAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, lessonState => {
      const focus: LessonFocus = {
        focused: false,
        duration: lessonState.focus ? lessonState.focus.duration : 0,
        sinceLast: 0
      };

      return {
        publishAudio: false,
        subscribeAudio: false,
        subscribeScreenShare: false,
        focus: focus,
        observed: false
      };
    }, false);
  }),
  on(LessonActions.startTeachingClassAction, (state, action) => {
    const updatedState = updateLessonState(state, () => ({
      classInProgress: true
    }));

    return updateLessonEntityState(action.lessonGuid, updatedState, lessonState => {
      const focus: LessonFocus = {
        focused: false,
        duration: lessonState.focus ? lessonState.focus.duration : 0,
        sinceLast: 0
      };

      return {
        tutorOnline: true,
        publishAudio: false,
        subscribeAudio: true,
        subscribeScreenShare: true,
        focus: focus
      };
    }, true);
  }),
  on(LessonActions.stopTeachingClassAction, (state, action) => {
    const updatedState = updateLessonState(state, () => ({
      classInProgress: false
    }));

    return updateLessonEntityState(action.lessonGuid, updatedState, lessonState => {
      const focus: LessonFocus = {
        focused: false,
        duration: lessonState.focus ? lessonState.focus.duration : 0,
        sinceLast: 0
      };

      return {
        publishAudio: false,
        subscribeAudio: false,
        subscribeScreenShare: false,
        focus: focus,
        observed: false
      };
    }, false);
  }),
  on(LessonActions.requestHelpAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, lessonState => {
      const help: LessonHelp = {
        duration: lessonState.help ? lessonState.help.duration : 0,
        status: LessonHelpStatus.Requested
      };

      return {
        help: help
      };
    });
  }),
  on(LessonActions.acknowledgeHelpAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, lessonState => {
      const help: LessonHelp = {
        duration: lessonState.help ? lessonState.help.duration : 0,
        status: LessonHelpStatus.Acknowledged
      };

      return {
        help: help
      };
    });
  }),
  on(LessonActions.clearHelpAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => {
      return {
        help: undefined
      };
    });
  }),
  on(LessonActions.openSessionAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      tutorOnline: true
    }));
  }),
  on(LessonActions.updateSignalRConnectionStateAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      connected: action.connected ? ConnectionState.Connected : ConnectionState.Disconnected
    }));
  }),
  on(LessonActions.installAppAction, (state, action): LessonState => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      installApp: true
    }));
  }),
  on(LessonActions.cancelInstallAppAction, (state, action): LessonState => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      installApp: false
    }));
  }),
  on(LessonActions.startObservingAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      sendUpdates: true
    }));
  }),
  on(LessonActions.stopObservingAction, (state): LessonState => {
    return updateLessonState(state, () => ({
      sendUpdates: false
    }));
  }),
  on(LessonActions.closeSessionAction, LessonActions.disconnectSessionAction, (state, action) => {
    return updateLessonEntityState(action.lessonGuid, state, () => ({
      tutorOnline: false
    }), false);
  }),
  on(LessonActions.videoEffectRequestedAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      videoEffectType: action.videoEffectType
    }));
  }),
  on(LessonActions.screenShareSettingUpdatedAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      disableScreenShare: action.disableScreenShare
    }));
  }),
  on(LessonActions.getLoadingAction, (state, action): LessonState => {
    return updateLessonState(state, () => ({
      isLoadingLesson: action.isLoading
    }));
  })
);
