<div class="w-100 h-100 bg-light d-flex justify-content-center align-items-center">
  <div class="modal-body kip-student p-4">
    <div class="d-flex align-items-center flex-column text-center">
      <kip-monty-construction class="pe-3" />
      <div class="ps-3">
        <h1 class="h1 mt-5 mb-3 fw-normal text-primary">We are performing scheduled maintenance</h1>
        <p class="fs-3">
          @if (downTime && downTime.start && downTime.finish)
          {
            <div class="row">
              <div class="col-12 col-md-auto">
                <fa-icon class="me-1" [icon]="icons.whiteboardToolbar.clock" />
                Start: {{ downTime.start | dateFromOffsetToTimezone : downTime.userIanaTimeZoneName }}
              </div>
              <div class="col-12 col-md-auto">
                  <fa-icon class="me-1" [icon]="icons.whiteboardToolbar.clock" />
                Finish: {{ downTime.finish | dateFromOffsetToTimezone : downTime.userIanaTimeZoneName }}
              </div>
            </div>
         }
        </p>
        <p class="fs-2 my-4">
          Please check back soon!
        </p>
        <p class="fs-3">
          <button class="btn btn-primary" type="button" (click)="logout()">Logout</button>
        </p>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-3">
    </div>
  </div>
</div>