import { Observable, Subscriber } from 'rxjs';

import { AssistantMessageOption } from './assistant-message-option';

export class AssistantMessage {
  #subscriber: Subscriber<AssistantMessageOption> | undefined;

  readonly result: Observable<AssistantMessageOption>;
  constructor(readonly text: string,
    readonly options: readonly AssistantMessageOption[],
    readonly sound: string,
    readonly required: boolean) {
    this.result = new Observable<AssistantMessageOption>(subscriber => { this.#subscriber = subscriber; });
  }

  setOption(option: AssistantMessageOption) {
    this.#subscriber?.next(option);
    this.#subscriber?.complete();
  }
}
