/* eslint-disable @typescript-eslint/naming-convention  */

import {
    currencySwapCodes,
    DrillType,
    Question, QuestionAnalogueClock, QuestionDigitalClock, QuestionDrills, QuestionFillInTheBlank,
    QuestionGeneric, QuestionLayoutType, QuestionMatching, QuestionMultipleAnswerMultipleChoice, QuestionSelection,
    QuestionSingleAnswerMultipleChoice, QuestionSorting, QuestionSpelling, QuestionWordMatch, QuestionWordPicker, RegionId
} from '../models';
import { regions } from './regions';
import { currencySwapFunction } from './regions/currency-swap-function';

interface GenericOptions {
    genericInput: string;
    genericChoice: string;
    genericAnalogueClock: string;
    genericDigitalClock: string;
    render: (input: string | null | undefined) => string;
    highlightCorrect: (input: string) => string;
    writeCorrect: boolean;
    split: string;
}

const replaceMatch = '&nbsp';

function convertAnalogueClock(question: QuestionAnalogueClock, genericOptions: GenericOptions) {
    const time = `${question.answers[0].values[0]} hrs ${question.answers[1].values[0]} mins`;
    return `${question.text} ${genericOptions.genericAnalogueClock} ${genericOptions.genericInput.replace(replaceMatch, time)}`;
}

function convertDigitalClock(question: QuestionDigitalClock, genericOptions: GenericOptions) {
    const time = `${question.answers[0].values[0]} hrs ${question.answers[1].values[0]} mins`;
    return `${question.text} ${genericOptions.genericDigitalClock} ${genericOptions.genericInput.replace(replaceMatch, time)}`;
}

function swapCurrencyValue(money: boolean, value: string, currencySymbol: string, decimalSymbol: string) {
    if (money) {
        return `${currencySymbol}${value.replace('.', decimalSymbol)}`;
    }

    return value;
}

function convertDrill(question: QuestionDrills, genericOptions: GenericOptions, currencySymbol: string, decimalSymbol: string) {
    let text = swapCurrencyValue(question.parameters.money, question.parameters.values[0].toString(), currencySymbol, decimalSymbol);

    for (let index = 1, indexMax = question.parameters.values.length; index < indexMax; index++) {
        let sign = '';
        switch (question.parameters.type) {
            case DrillType.Addition:
                sign = '+';
                break;
            case DrillType.Subtraction:
                sign = '-';
                break;
            case DrillType.Multiplication:
                sign = 'x';
                break;
            case DrillType.Division:
                sign = '÷';
                break;
        }
        text += ` ${sign} ${swapCurrencyValue(question.parameters.money, question.parameters.values[index].toString(), currencySymbol, decimalSymbol)}`;
    }
    return `${text} = ${genericOptions.genericInput.replace(replaceMatch, swapCurrencyValue(question.parameters.money, question.answers[0].values[0].toString(), currencySymbol, decimalSymbol))}`;
}

function convertSorting(question: QuestionSorting, genericOptions: GenericOptions) {
    let correct = '';
    for (const answer of question.answers) {
        correct += ` ${genericOptions.render(answer.values[0].toString())} - `;
    }
    let random = '';
    for (const parameter of question.parameters.options) {
        random += ` ${genericOptions.render((parameter.value ?? '').toString())} - ${genericOptions.render((parameter.text ?? '').toString())}`;
    }
    return `${question.text} ${genericOptions.genericInput.replace(replaceMatch, random)}>${genericOptions.genericInput.replace(replaceMatch, correct)}`;
}

function convertSelection(question: QuestionSelection, genericOptions: GenericOptions) {
    const values: (number | string | undefined)[] = [];
    for (const answer of question.answers) {
        values.push(answer.values[0]);
    }
    let selection = '';
    for (const parameter of question.parameters) {
        selection += values.includes(parameter.value) ? ` ${genericOptions.highlightCorrect(genericOptions.render(parameter.text))}\n\n` : ` ${genericOptions.render(parameter.text)}\n\n`;
    }
    return `${question.text}\n\n${genericOptions.genericInput.replace(replaceMatch, selection)}`;

}

function convertMatching(question: QuestionMatching, genericOptions: GenericOptions) {
    let result = '';
    let index = 0;
    for (const source of question.parameters.source) {
        const targetValue = question.parameters.target.find(s => s.value === question.answers[index].values[0])?.text;
        const replaceText = `${genericOptions.render(source.text)} > ${genericOptions.render(targetValue)}`;
        result += `${genericOptions.genericInput.replace(replaceMatch, replaceText)}\n`;
        index++;
    }

    return `${question.text}\n\n${result}\n`;
}

function convertFillInTheBlank(question: QuestionFillInTheBlank, genericOptions: GenericOptions) {
    const controlRegex = new RegExp(/{answer}/g);

    let i = 0;
    const text = question.parameters.target.replace(controlRegex, (_substring: string) => {
        const value = genericOptions.genericInput.replace(replaceMatch, question.answers[i].values[0].toString());
        i++;
        return value;
    });

    return `${question.text} : ${text}`;
}

function convertMultipleAnswerMultipleChoice(question: QuestionMultipleAnswerMultipleChoice, genericOptions: GenericOptions) {
    const answer = genericOptions.genericInput.replace(replaceMatch, genericOptions.render(question.answers[0].values[0].toString()));

    let optionsText = genericOptions.writeCorrect ? 'Possible Answers Are : ' : '';
    let counter = 0;
    for (const option of question.parameters.options) {
        if (counter > 0) {
            optionsText += ` ${genericOptions.split} `;
        }
        optionsText += option.value === question.answers[0].values[0] ? genericOptions.highlightCorrect(genericOptions.render(option.text)) : genericOptions.render(option.text);
        counter++;
    }
    optionsText = genericOptions.genericChoice.replace(replaceMatch, optionsText.slice(2));

    if (question.text.includes('___')) {
        return question.text.replace('___', answer) + optionsText;
    }

    const answerText = genericOptions.writeCorrect ? `Correct Answer is ${answer}` : answer;
    return `${question.text}\n\n${optionsText}\n\n${answerText}`;
}

function convertSingleAnswerMultipleChoice(question: QuestionSingleAnswerMultipleChoice, genericOptions: GenericOptions) {
    let answer = question.answers[0].values[0].toString();

    let optionsText = genericOptions.writeCorrect ? 'Possible Answers Are : ' : '';
    let counter = 0;
    for (const option of question.parameters.options) {
        if (option.value === question.answers[0].values[0]) {
            answer = option.text ?? option.value.toString();
        }
        if (counter > 0) {
            optionsText += ` ${genericOptions.split} `;
        }
        optionsText += option.value === question.answers[0].values[0] ? genericOptions.highlightCorrect(genericOptions.render(option.text)) : genericOptions.render(option.text);
        counter++;
    }
    optionsText = genericOptions.genericChoice.replace(replaceMatch, optionsText);

    answer = genericOptions.genericInput.replace(replaceMatch, genericOptions.render(answer));

    if (question.text.includes('___')) {
        return question.text.replace('___', answer) + optionsText;
    }
    const answerText = genericOptions.writeCorrect ? `Correct Answer is ${answer}` : answer;

    return `${question.text}\n\n${optionsText}\n\n${answerText}`;
}

function convertGeneric(question: QuestionGeneric, genericOptions: GenericOptions) {

    const questionText = question.text.trim();
    const controlRegex = new RegExp(/[{*]control [^}^\\^*]+[}*]/g);

    const propertyNames = Object.getOwnPropertyNames(question.parameters);

    const matches = questionText.match(controlRegex) || [];
    const hasOneMatchAtEnd = matches.length === 1 && questionText.endsWith(matches[0]);

    return questionText.replace(controlRegex, (substring: string) => {
        const propertyName = substring.replace(/[{*]control /, '').replace(/[}*]/, '');
        const index = propertyNames.indexOf(propertyName);
        let value = '??';
        if (index !== -1) {
            const answer = question.answers[index];
            if (answer) {
                value = genericOptions.writeCorrect ? answer.values[0].toString() : genericOptions.genericInput.replace(replaceMatch, answer.values[0].toString());
            }
        }

        return hasOneMatchAtEnd && genericOptions.writeCorrect ? `\n\nCorrect answer is ${value}` : value;
    });
}

function convertSpelling(question: QuestionSpelling, genericOptions: GenericOptions) {
    return `${question.text} ${genericOptions.genericInput.replace(replaceMatch, question.answers[0].values[0].toString())}`;
}

function convertWordPicker(question: QuestionWordPicker, genericOptions: GenericOptions) {
    let text = '';
    for (let index = 0, indexMax = question.parameters.length; index < indexMax; index++) {

        let match = false;
        for (const answer of question.answers) {
            if (answer.values[0] === index) {
                match = true;
                break;
            }
        }
        text += match ? ` ${genericOptions.highlightCorrect(question.parameters[index])}` : ` ${question.parameters[index]}`;
    }
    return `${question.text} ${genericOptions.genericInput.replace(replaceMatch, text)}`;

}

function convertWordMatch(question: QuestionWordMatch, genericOptions: GenericOptions) {
    let result = '';
    let index = 0;
    for (const source of question.parameters.source) {
        result += genericOptions.genericInput.replace(replaceMatch, `${genericOptions.render(source.text)} > ${question.answers[index].values[0]}`);
        index++;
    }

    return question.text + result;
}

export function convertQuestion(question: Question, genericOptions: GenericOptions, regionId: number = RegionId.Australia) {
    let questionEnglish = '';

    const region = regions.find(s => s.id === regionId);
    const currencySymbol = region?.currencySwap.primaryCurrencySymbol ?? '';
    const decimalSymbol = region?.currencySwap.decimalSymbol ?? '';
    switch (question.type) {
        case QuestionLayoutType.AnalogueClock:
            questionEnglish = convertAnalogueClock(question, genericOptions);
            break;
        case QuestionLayoutType.DigitalClock:
            questionEnglish = convertDigitalClock(question, genericOptions);
            break;
        case QuestionLayoutType.Drill:
            questionEnglish = convertDrill(question, genericOptions, currencySymbol, decimalSymbol);
            break;
        case QuestionLayoutType.Sorting:
            questionEnglish = convertSorting(question, genericOptions);
            break;
        case QuestionLayoutType.Selection:
            questionEnglish = convertSelection(question, genericOptions);
            break;
        case QuestionLayoutType.Matching:
            questionEnglish = convertMatching(question, genericOptions);
            break;
        case QuestionLayoutType.FillInTheBlank:
            questionEnglish = convertFillInTheBlank(question, genericOptions);
            break;
        case QuestionLayoutType.MultipleAnswerMultipleChoice:
            questionEnglish = convertMultipleAnswerMultipleChoice(question, genericOptions);
            break;
        case QuestionLayoutType.SingleAnswerMultipleChoice:
            questionEnglish = convertSingleAnswerMultipleChoice(question, genericOptions);
            break;
        case QuestionLayoutType.Generic:
            questionEnglish = convertGeneric(question, genericOptions);
            break;
        case QuestionLayoutType.Spelling:
            questionEnglish = convertSpelling(question, genericOptions);
            break;
        case QuestionLayoutType.WordPicker:
            questionEnglish = convertWordPicker(question, genericOptions);
            break;
        case QuestionLayoutType.WordMatch:
            questionEnglish = convertWordMatch(question, genericOptions);
            break;
    }

    return currencySwapFunction(questionEnglish, regionId);
}

function escapeRegExp(str: string): string {
    return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function determineMacros() {
    const macros: { [key: string]: string } = {
        '\\control': '\\text{*control #1*}',
        '\\currency': '\\text{*currency#1}',
        '\\number': '\\text{*number#1}',
        '\\year': '\\text{*year#1}',
        '\\time': '\\text{*time#1}'
    };
    for (const currencySwapCode in currencySwapCodes) {
        const keyValue = `\\${currencySwapCode}`;
        macros[keyValue] = `\\text{*${currencySwapCode}}`;
    }

    return macros;
}

function fixQuestion(question: Question) {
    let newQuestion = JSON.stringify(question);

    const macros = determineMacros();

    for (const macro in macros) {
        const swapPattern = `${escapeRegExp(macro)}\\{(.*?)\\}`;
        const expression = new RegExp(swapPattern, 'g');
        const replaceValue = macros[macro].replace('#', '$');
        newQuestion = newQuestion.replace(expression, replaceValue);
    }

    return JSON.parse(newQuestion) as Question;
}

export function convertQuestionEnglish(question: Question, regionId: number = RegionId.Australia) {

    return convertQuestion(fixQuestion(question), {
        genericInput: `[${replaceMatch}]`,
        genericAnalogueClock: 'Analogue Clock',
        genericDigitalClock: 'Digital Clock',
        genericChoice: `[${replaceMatch}]`,
        render: (input: string) => input,
        highlightCorrect: (input: string) => input,
        writeCorrect: true,
        split: 'or'
    },
        regionId).replace(/<:\[\.image-height-\d+\]:>/g, '').replace(/<:\[\.image-width-\d+\]:>/g, '');
}

export function convertQuestionHtml(question: Question, regionId: number = RegionId.Australia) {
    return convertQuestion(question, {
        genericInput: `<div class="kip-generic-input">${replaceMatch}</div>`,
        genericAnalogueClock: 'div class="kip-generic-clock">AC</div>',
        genericDigitalClock: '<div class="kip-generic-clock">DC</div>',
        genericChoice: `<div class="kip-generic-choice">${replaceMatch}</div>`,
        writeCorrect: false,
        split: '|',
        highlightCorrect: (input: string) => `<b>${input}</b>`,
        render: (input: string) => {
            if (input === null || input === undefined) {
                return '';
            }

            let output = input;
            output = output.replace(/\u00A0/g, ' ');

            // remove paragraph markers and line breaks - not required

            let regex = new RegExp(/<p>/g);
            output = output.replace(regex, '&nbsp;');

            regex = new RegExp(/<\/p>/g);
            output = output.replace(regex, '&nbsp;');

            regex = new RegExp(/<br>/g);
            output = output.replace(regex, '&nbsp;');

            // remove mathml - not required

            regex = new RegExp(/<span class="katex-mathml"><math.+?<\/math><\/span>/g);
            output = output.replace(regex, '');

            return output;
        }
    },
        regionId);
}

