import { Pipe, PipeTransform } from '@angular/core';
import { DateTimeOffsetIanaTimeZoneName } from 'moment-extensions-lib';

import { dateFromOffsetToTimezone } from '../helpers';

@Pipe({
  name: 'dateFromOffsetToTimezone',
  standalone: false
})
export class DateFromOffsetToTimezonePipe implements PipeTransform {

  transform(value: DateTimeOffsetIanaTimeZoneName, userTimeZoneName: string, format = 'D MMM YYYY[,] h:mm a'): string {
    return dateFromOffsetToTimezone(value, userTimeZoneName, format);
  }
}
