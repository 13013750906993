@if (activity && activity.isManual) {
<div class="d-flex flex-column flex-fill h-100 mh-100">
  <kip-pdf class="h-100 mh-100" [activityDescription]="activity.description | currencySwap : regionId"
    [activityHtml]="activity.html" [activityName]="activity.name | currencySwap : regionId" [allowAI]="true"
    [allowJira]="allowJira && !activity.isJiraReportingBlocked" [allowToggleAnswers]="allowToggleAnswers"
    [pdfFile]="pdfToView" [soundFile]="activity.soundFile" (displayAISolution)="questionNumber = $event; ai.open()"
    (displayJira)="jira.open()" (toggleAnswers)="toggleAnswers()" />
  <kip-explore-manual-ai #ai [activityName]="activity.name | currencySwap : regionId" [pdf]="pdfToView"
    [questionNumber]="questionNumber" />
  <kip-jira-modal #jira requestText="Report Activity Content" style="display:none"
    [jiraStyleType]="jiraStyleTypeContent" [metaData]="jiraMetaData" [requestType]="jiraRequestType" [uId]="jiraUId" />
</div>
}