import { createSelector } from '@ngrx/store';
import { unknownPdf } from 'pdf-lib';

import { LessonActivityType, SessionActivityType, TimedComputerActivity } from '../../../shared';
import { LessonActivity, SelectedActivity } from '../../models';
import { ActivityEntity, ActivityEntityState } from '../models';
import {
  selectActivityEntities,
  selectActivityEntityState,
  selectIsHomeworkActivityEntity,
  selectRequestedActivityEntityId,
  selectRequestedActivityQuestionIndex,
  selectRequestedQuestionEntityGuid,
  selectSelectedActivityEntityId,
  selectSelectedLessonActivityGuids,
  selectSelectedLessonHomeworkGuids,
  selectSelectedLessonPreviousHomeworkGuids
} from '../state';

export const selectSelectedLessonActivityPlanId = createSelector(selectSelectedActivityEntityId, id => id);
export const selectRequestedLessonActivityPlanId = createSelector(selectRequestedActivityEntityId, id => id);
export const selectRequestedQuestionGuid = createSelector(selectRequestedQuestionEntityGuid, id => id);
export const selectRequestedQuestionIndex = createSelector(selectRequestedActivityQuestionIndex, id => id);
const selectIsHomeworkActivity = createSelector(selectIsHomeworkActivityEntity, isHomework => isHomework);

function mapActivities(lessonGuid: string, isHomework: boolean, activityGuids: readonly string[], entities: { [lessonActivityPlanId: number]: ActivityEntity },
  entityState: { [activityGuid: string]: ActivityEntityState }): LessonActivity[] {
  const activities = activityGuids.map(guid => {
    const state: ActivityEntityState = entityState[guid];

    let lessonActivity: LessonActivity | undefined;
    if (state) {
      const entity: ActivityEntity = entities[state.lessonActivityPlanId];
      if (entity) {
        let timedOut = false;

        if (!isHomework) {
          if (entity.computer?.maxAllowedTimeMinutes) {
            let totalSeconds = 0;
            for (const question of entity.computer.questions) {
              totalSeconds += question.duration ?? 0;
            }
            timedOut = totalSeconds >= entity.computer.maxAllowedTimeMinutes * 60;
          }
          if (entity.manual?.maxAllowedTimeMinutes) {
            timedOut = state.completionTime >= entity.manual.maxAllowedTimeMinutes * 60;
          }
        }

        const timedComputerStatic = entity.timedComputer;
        let timedComputer: TimedComputerActivity | null = null;
        if (timedComputerStatic) {
          const newScore = Math.max(state.firstAttemptResult ?? 0, state.secondAttemptResult ?? 0);
          const isNewBestScore = newScore > timedComputerStatic.bestResultEver;
          timedComputer = {
            activityId: timedComputerStatic.activityId,
            bestResultEver: timedComputerStatic.bestResultEver,
            questions: timedComputerStatic.questions,
            isFastQuestionTransition: timedComputerStatic.isFastQuestionTransition,
            isNavigationEnabled: timedComputerStatic.isNavigationEnabled,
            firstAttemptResult: state.firstAttemptResult ?? 0,
            firstAttemptStartTime: state.firstAttemptStartTime,
            secondAttemptResult: state.secondAttemptResult ?? 0,
            secondAttemptStartTime: state.secondAttemptStartTime,
            firstAttemptActive: state.firstAttemptActive ?? false,
            secondAttemptActive: state.secondAttemptActive ?? false,
            timeLimitElapsed: state.timeLimitElapsed ?? false,
            newScore: newScore,
            isNewBestScore: isNewBestScore,
            activitySeconds: state.activitySeconds,
            percentageTime: state.percentageTime,
            remainingColor: state.remainingColor,
            remainingSeconds: state.remainingSeconds,
            timesTablesModalState: state.timesTableModalState
          };
        }

        lessonActivity = {
          activityId: entity.activityId,
          lessonActivityPlanId: entity.lessonActivityPlanId,
          activityGuid: guid,
          lessonGuid: lessonGuid,
          name: entity.name,
          treeTopicId: entity.treeTopicId,
          html: entity.html,
          counterId: entity.counterId,
          isHiddenStudent: entity.isHiddenStudent,
          description: entity.description,
          unit: entity.unit,
          unitId: entity.unitId,
          computer: entity.computer,
          manual: entity.manual,
          timedComputer: timedComputer,
          adHoc: entity.adHoc,
          lessonActivityType: entity.lessonActivityType,
          continuedFromPrevious: entity.continuedFromPrevious,
          percentage: state.percentage,
          completionTime: state.completionTime,
          completed: state.completed,
          hasWhiteboard: state.hasWhiteboard,
          allowCalculator: entity.lessonActivityType === LessonActivityType.Computer && entity.computer !== null && (entity.computer.isCalculatorAvailable ?? false) ||
            entity.lessonActivityType === LessonActivityType.Manual && entity.manual !== null && (entity.manual.isCalculatorAvailable ?? false),
          score: state.score,
          startedOn: state.startedOn,
          completedOn: state.completedOn,
          kipPointsScaleFactor: entity.kipPointsScaleFactor,
          assessmentFormName: entity.assessmentFormName,
          files: state.files,
          timedOut: timedOut,
          assessmentResultTypeId: entity.assessmentResultTypeId,
          isAssessmentActivity: entity.isAssessmentActivity,
          maxAllowedTimeMinutes: entity.maxTimeAllowedMinutes,
          questionsLoaded: state.questionsLoaded
        };
      }
    }
    return lessonActivity;
  });

  return activities.filter((activity): activity is LessonActivity => activity !== undefined);
}

export const selectLessonActivities = createSelector(
  selectActivityEntities, selectActivityEntityState, selectSelectedLessonActivityGuids,
  (entities, entityState, context) =>
    context ? mapActivities(context.lessonGuid, false, context.activityGuids, entities, entityState) : undefined);

export const selectLessonHomework = createSelector(
  selectActivityEntities, selectActivityEntityState, selectSelectedLessonHomeworkGuids,
  (entities, entityState, context) =>
    context ? mapActivities(context.lessonGuid, true, context.homeworkGuids, entities, entityState) : undefined);

const selectLessonPreviousHomework = createSelector(
  selectActivityEntities, selectActivityEntityState, selectSelectedLessonPreviousHomeworkGuids,
  (entities, entityState, context) =>
    context ? mapActivities(context.previousLessonGuid, true, context.homeworkGuids, entities, entityState) : undefined);

export const selectRequestedActivity = createSelector(
  selectLessonActivities, selectRequestedActivityEntityId,
  (activities, requestedActivityId) => {

    if (activities) {
      return activities?.find(current => current.lessonActivityPlanId === requestedActivityId);
    }

    return undefined;
  });

export const selectSelectedActivity = createSelector(selectSelectedLessonActivityPlanId, selectIsHomeworkActivity,
  selectLessonActivities, selectLessonHomework, selectLessonPreviousHomework, (lessonActivityPlanId, isHomework, activities, homework, previousHomework) => {
    let activity: SelectedActivity | undefined;
    if (activities && !isHomework) {
      // Find the activity in the lesson and map it
      const lessonActivity = activities.find(current => current.lessonActivityPlanId === lessonActivityPlanId);

      if (lessonActivity) {
        activity = {
          lessonActivityPlanId: lessonActivity.lessonActivityPlanId,
          activityGuid: lessonActivity.activityGuid,
          lessonGuid: lessonActivity.lessonGuid,
          name: lessonActivity.name,
          html: lessonActivity.html,
          unit: lessonActivity.unit,
          unitId: lessonActivity.unitId,
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          pdfFile: lessonActivity.manual?.pdfFile || lessonActivity.adHoc?.file || unknownPdf,
          soundFile: lessonActivity.manual?.soundFile ?? '',
          description: lessonActivity.description,
          computer: lessonActivity.computer,
          manual: lessonActivity.manual,
          timedComputer: lessonActivity.timedComputer,
          adHoc: lessonActivity.adHoc,
          lessonActivityType: lessonActivity.lessonActivityType,
          percentage: lessonActivity.percentage,
          score: lessonActivity.score,
          completionTime: lessonActivity.completionTime,
          completed: lessonActivity.completed,
          startedOn: lessonActivity.startedOn,
          completedOn: lessonActivity.completedOn,
          allowCalculator: lessonActivity.allowCalculator,
          sessionActivityType: SessionActivityType.Activity,
          files: lessonActivity.files,
          kipPointsScaleFactor: lessonActivity.kipPointsScaleFactor,
          assessmentFormName: lessonActivity.assessmentFormName,
          isAssessmentActivity: lessonActivity.isAssessmentActivity,
          isHomework: false,
          maxTimeAllowedMinutes: lessonActivity.maxAllowedTimeMinutes,
          treeTopicId: lessonActivity.treeTopicId,
          questionsLoaded: lessonActivity.questionsLoaded
        };
      }
    }

    if (homework) {
      const homeworkActivity = homework.find(current => current.lessonActivityPlanId === lessonActivityPlanId);
      if (homeworkActivity) {
        activity = {
          lessonActivityPlanId: homeworkActivity.lessonActivityPlanId,
          activityGuid: homeworkActivity.activityGuid,
          lessonGuid: homeworkActivity.lessonGuid,
          name: homeworkActivity.name,
          html: homeworkActivity.html,
          unit: homeworkActivity.unit,
          unitId: homeworkActivity.unitId,
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          pdfFile: homeworkActivity.manual?.pdfFile || homeworkActivity.adHoc?.file || unknownPdf,
          soundFile: homeworkActivity.manual?.soundFile ?? '',
          description: homeworkActivity.description,
          computer: homeworkActivity.computer,
          manual: homeworkActivity.manual,
          timedComputer: homeworkActivity.timedComputer,
          adHoc: homeworkActivity.adHoc,
          lessonActivityType: homeworkActivity.lessonActivityType,
          percentage: homeworkActivity.percentage,
          score: homeworkActivity.score,
          completionTime: homeworkActivity.completionTime,
          completed: homeworkActivity.completed,
          startedOn: homeworkActivity.startedOn,
          completedOn: homeworkActivity.completedOn,
          allowCalculator: homeworkActivity.allowCalculator,
          sessionActivityType: SessionActivityType.Homework,
          files: homeworkActivity.files,
          kipPointsScaleFactor: homeworkActivity.kipPointsScaleFactor,
          assessmentFormName: homeworkActivity.assessmentFormName,
          isAssessmentActivity: homeworkActivity.isAssessmentActivity,
          isHomework: true,
          maxTimeAllowedMinutes: homeworkActivity.maxAllowedTimeMinutes,
          treeTopicId: homeworkActivity.treeTopicId,
          questionsLoaded: homeworkActivity.questionsLoaded
        };
      }
    }

    if (previousHomework) {
      const homeworkActivity = previousHomework.find(current => current.lessonActivityPlanId === lessonActivityPlanId);
      if (homeworkActivity) {
        activity = {
          lessonActivityPlanId: homeworkActivity.lessonActivityPlanId,
          activityGuid: homeworkActivity.activityGuid,
          lessonGuid: homeworkActivity.lessonGuid,
          name: homeworkActivity.name,
          html: homeworkActivity.html,
          unit: homeworkActivity.unit,
          unitId: homeworkActivity.unitId,
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
          pdfFile: homeworkActivity.manual?.pdfFile || homeworkActivity.adHoc?.file || unknownPdf,
          soundFile: homeworkActivity.manual?.soundFile ?? '',
          description: homeworkActivity.description,
          computer: homeworkActivity.computer,
          manual: homeworkActivity.manual,
          timedComputer: homeworkActivity.timedComputer,
          adHoc: homeworkActivity.adHoc,
          lessonActivityType: homeworkActivity.lessonActivityType,
          percentage: homeworkActivity.percentage,
          score: homeworkActivity.score,
          completionTime: homeworkActivity.completionTime,
          completed: homeworkActivity.completed,
          startedOn: homeworkActivity.startedOn,
          completedOn: homeworkActivity.completedOn,
          allowCalculator: homeworkActivity.allowCalculator,
          sessionActivityType: SessionActivityType.PreviousHomework,
          files: homeworkActivity.files,
          kipPointsScaleFactor: homeworkActivity.kipPointsScaleFactor,
          assessmentFormName: homeworkActivity.assessmentFormName,
          isAssessmentActivity: homeworkActivity.isAssessmentActivity,
          isHomework: true,
          maxTimeAllowedMinutes: homeworkActivity.maxAllowedTimeMinutes,
          treeTopicId: homeworkActivity.treeTopicId,
          questionsLoaded: homeworkActivity.questionsLoaded
        };
      }
    }
    return activity;
  });
