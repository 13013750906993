export enum AnimationActions {
  None = 0,
  Show = 1,
  Hide = 2,
  Correct = 3,
  Incorrect = 4,
  Complete = 5,
  GreatJob = 6,
  WellDone = 7,
  PerfectScore = 8,
  Idle = 9
}
