import { ChangeDetectionStrategy, Component, inject, OnDestroy, OnInit, signal, viewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MontyAiMessagesComponent } from 'message-lib';
import { convertQuestionEnglish, RegionId } from 'questions-lib';
import { filter, first, map, Subscription } from 'rxjs';
import { ProfileService } from 'ui-common-lib';
import * as uuid from 'uuid';

import { SelectedActivity, SelectedLesson, SelectedQuestion } from '../../student/models';
import { MessagingService } from '../../student/services';
import { fromActivity, fromAiChat, fromLesson, fromQuestion, StudentState } from '../../student/store';
import { AiChatQuestion } from '../../student/store/ai-chat';

@Component({
  selector: 'kip-ai-container',
  templateUrl: './ai-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class AiContainerComponent implements OnInit, OnDestroy {
  readonly #router = inject(Router);
  readonly #store = inject(Store<StudentState>);
  readonly #messagingService = inject(MessagingService);
  readonly #profileService = inject(ProfileService);
  readonly #aiChatGroupDefault = uuid.v1();
  #subscriptions: Subscription[] = [];
  #selectedQuestion: SelectedQuestion | undefined;
  #selectedActivity: SelectedActivity | undefined;
  #selectedLesson: SelectedLesson | undefined;
  #aiTutorEnabled = false;
  #restrictAiSubject = false;
  #aiWithholdAnswer = false;
  #unattemptedMessages: string[] = [];
  #lessonAINote = '';
  readonly #visible = signal(false);

  readonly chatMessages = signal<string[]>([]);
  readonly chatGroupId = signal<string>(this.#aiChatGroupDefault);
  readonly visible = this.#visible.asReadonly();
  readonly aiTutorNoAnswerText = signal('Hey, I do not see an answer yet! Give it a go first, even a guess is better than nothing. You learn the most by trying!');
  readonly ai = viewChild<MontyAiMessagesComponent>('ai');

  ngOnInit(): void {
    this.#subscriptions.push(
      this.#store
        .select(fromAiChat.selectAiMessages)
        .subscribe(messages => {
          this.chatMessages.set([...this.#unattemptedMessages, ...messages]);
        }),
      this.#profileService.aiTutorEnabled().subscribe(aiTutorEnabled => {
        this.#aiTutorEnabled = aiTutorEnabled;
        this.#updateMontyVisibility();
      }),
      this.#store
        .select(fromActivity.selectSelectedActivity)
        .subscribe(activity => {
          this.#selectedActivity = activity;
          this.#updateMontyVisibility();
          this.#updateChatGroup();
        }),
      this.#store
        .select(fromLesson.selectSelectedLesson)
        .subscribe(lesson => {
          this.#selectedLesson = lesson;
          // on receiving an aiNote, act as if the student said it
          if (lesson && this.#lessonAINote !== lesson.aiNote) {
            this.#lessonAINote = lesson.aiNote;
            const ai = this.ai();
            if (this.#lessonAINote && ai) {
              // want to ensure it as if it occurs at the lesson level otherwise it will forget the chat
              this.#selectedActivity = undefined;
              this.#selectedQuestion = undefined;
              this.#updateChatGroup();
              ai.onSendMessage(this.#lessonAINote);
            }
          }
          this.#updateMontyVisibility();
          this.#updateChatGroup();
        }),
      this.#router.events.pipe(
        filter(event => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url)
      ).subscribe({
        next: url => {
          if (url === '/student' || url.startsWith('/student/lesson') || url.startsWith('/student/homework')) {
            this.#updateMontyVisibility();
          } else {
            this.#visible.set(false);
          }
        }
      }),
      this.#store.select(fromQuestion.selectSelectedQuestion).subscribe(question => {
        this.#selectedQuestion = question;
        this.#updateChatGroup();
      }),
      this.#profileService.restrictAiSubject().subscribe(restrictAiSubject => this.#restrictAiSubject = restrictAiSubject),
      this.#profileService.aiWithholdAnswer().subscribe(aiWithholdAnswer => this.#aiWithholdAnswer = aiWithholdAnswer)
    );
  }

  ngOnDestroy(): void {
    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];
  }

  onSendMessage(message: string) {
    let question: AiChatQuestion | undefined = undefined;

    const lessonActivityPlanId = this.#selectedActivity?.lessonActivityPlanId ?? null;
    const lessonId = this.#selectedLesson?.lessonId ?? null;
    const regionId = this.#selectedLesson?.regionId ?? RegionId.Australia;
    const lessonGuid = this.#selectedLesson?.lessonGuid ?? '';
    let tutorName: string | null = null;
    // if tutor is known human, lesson is not homework 
    if (this.#selectedLesson?.tutor && !this.#selectedLesson?.tutorIsAI && !this.#selectedLesson.isHomeworkOnly) {
      tutorName = `${this.#selectedLesson.tutor.givenName} ${this.#selectedLesson.tutor.familyName}`;
    }

    if (this.#selectedQuestion) {
      if ((this.#selectedQuestion.attempts ?? 0) === 0) {
        this.chatMessages.set([this.aiTutorNoAnswerText()]);
        this.#unattemptedMessages.push('unattempted');
        return;
      }

      question = {
        questionGuid: this.#selectedQuestion.questionGuid,
        questionId: this.#selectedQuestion.questionId,
        questionText: convertQuestionEnglish(this.#selectedQuestion.questionKatex, regionId)
      };
    }

    this.#subscriptions.push(
      this.#messagingService.connect().pipe(first()).subscribe(() => {
        this.#store.dispatch(
          fromAiChat.chatAction(
            {
              message: message,
              chatGroup: this.chatGroupId(),
              question: question,
              pdf: this.#selectedActivity?.pdfFile ?? null,
              adHoc: this.#selectedActivity?.adHoc?.file ?? null,
              tutorName: tutorName,
              lessonActivityPlanId: lessonActivityPlanId,
              lessonId: lessonId,
              lessonGuid: lessonGuid,
              restrictAiSubject: this.#restrictAiSubject,
              aiWithholdAnswer: this.#aiWithholdAnswer
            }
          )
        );
      })
    );
  }

  #updateMontyVisibility() {
    if (!this.#aiTutorEnabled) {
      this.#visible.set(false);
    } else {
      // hide for assessments
      const isAssessment = (this.#selectedLesson?.isAssessment ?? false) || (this.#selectedActivity?.isAssessmentActivity ?? false);
      this.#visible.set(!isAssessment);
    }
  }

  #updateChatGroup() {
    // define chat group based on lesson, activity or question - ensure it defaults to a consistent uuid for my day
    const currentChatGroup = this.chatGroupId();
    let newChatGroup = this.#aiChatGroupDefault;
    if (this.#selectedLesson) {
      newChatGroup = this.#selectedLesson.lessonGuid;
    }
    if (this.#selectedActivity) {
      newChatGroup = this.#selectedActivity.activityGuid;
    }
    if (this.#selectedQuestion) {
      newChatGroup = this.#selectedQuestion.questionGuid;
    }

    if (currentChatGroup !== newChatGroup) {
      this.#unattemptedMessages = [];
      this.chatGroupId.set(newChatGroup);
    }
  }
}
