<kip-form-control [class]="class()" [control]="control()" [errorMessage]="errorMessage()" [label]="label()">
  <label [class]="labelClass()??''" [for]="id()">{{ label() }}
    <fa-icon kipPreventDefault [icon]="icons.audioTest" (click)="playAudio()"  />
  </label>
  <select class="form-control" [formControl]="control()" [id]="id()">
    @for (pollyValue of pollyValues(); track pollyValue) {
    <option [ngValue]="pollyValue.id">
      {{pollyValue.text }}
    </option>
    }
  </select>
 
</kip-form-control>