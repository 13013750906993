import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MontyGraphic } from '../monty-graphic';

@Component({
  selector: 'kip-monty-new-talking-two',
  templateUrl: './monty-new-talking-two.component.svg',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})

export class MontyNewTalkingTwoComponent implements MontyGraphic {

}
