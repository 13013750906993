import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef,
  EventEmitter, Inject, Input, OnDestroy, OnInit, Output, ViewChild
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { install } from 'chart-js-fabric';
import { fabric } from 'fabric';
import { Icons } from 'icon-lib';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';

import {
  FabricObjectExtended,
  MEMENTO_MANAGER_FACTORY, ToolbarAction,
  WhiteboardAddMemento, WhiteboardClearMemento, WhiteboardEvent, WhiteboardEventType, WhiteboardGridTool,
  WhiteboardGridType, WhiteboardGuidKey, WhiteboardMementoManager, WhiteboardMoveMemento, WhiteboardRemoveMemento,
  WhiteboardRotateMemento, WhiteboardScaleMemento, WhiteboardTextMemento, WhiteboardToolbarSelection
} from '../models';
import { PrintElement, WhiteboardService } from '../whiteboard.service';
@Component({
    selector: 'kip-whiteboard',
    templateUrl: './whiteboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: false
})
export class WhiteboardComponent implements OnInit, OnDestroy {

  readonly #handlers: { [prop: string]: (event: WhiteboardEvent) => void } = {
    'added': event => this.#remoteAdd(event),
    'updated': event => this.#remoteUpdate(event),
    'removed': event => this.#remoteRemove(event),
    'cleared': () => this.#remoteClear()
  };

  #whiteboardVisibleInDom = false;
  #resizeTimer: NodeJS.Timeout | undefined;
  #canvas: fabric.Canvas | undefined;
  readonly #mementoManager: WhiteboardMementoManager;
  #toolbarSelection: WhiteboardToolbarSelection | undefined;
  #backgroundImage = '';
  #backgroundImageWidth = 0;
  #safeBackgroundImage: SafeUrl | undefined;
  #hasImageLoaded = false;
  #isClearing = false;
  #isRemoteObject = false;
  #toolUpdating = false;
  #lastWidth = 0;
  #eventsArchivedOnUtc: string | null | undefined;
  #lastWidthDifference = 0;
  #pageTitle = '';
  #subscriptions: Subscription[] = [];

  readonly icons = Icons;
  disablePan = false;

  get readOnlyOrArchived() {
    return this.readonly || this.#eventsArchivedOnUtc;
  }

  get allowClose() {
    return this.close.observed;
  }

  get safeBackgroundImage() {
    return this.#safeBackgroundImage;
  }

  get backgroundImageWidth() {
    return this.#backgroundImageWidth;
  }

  get hasImageLoaded() {
    return this.#hasImageLoaded;
  }

  @Input() color = 'black';
  @Input() tool = 'pen';
  // eslint-disable-next-line kip/no-unused-public-members
  @Input({ required: true }) whiteboardGuidKey: WhiteboardGuidKey | undefined;
  @Input() readonly = false;
  @Input() printAllEnabled = false;
  @Input() isManual = false;
  @Input() isScrolling = false;
  @Input() showViewToggle = false;
  @Input() othersViewing = false;
  @Input() toolbarActions: readonly ToolbarAction[] = [];
  @Input() backgroundColor = '';

  @Input({ required: true }) set backgroundImage(value: string) {
    if (this.#backgroundImage !== value) {
      this.#backgroundImage = value;
      this.#safeBackgroundImage = value ? this.sanitizer.bypassSecurityTrustUrl(this.#backgroundImage) : undefined;
      this.#resizeCanvas();
      const imageData = new Image();
      imageData.src = this.#backgroundImage;
      this.#backgroundImageWidth = imageData.width;
    }
  }

  get backgroundImage() {
    return this.#backgroundImage;
  }

  @ViewChild('canvas', { static: true }) canvas: ElementRef<HTMLCanvasElement> | undefined;
  @ViewChild('image', { static: true }) image: ElementRef<HTMLImageElement> | undefined;
  @ViewChild('container', { static: true }) container: ElementRef<HTMLDivElement> | undefined;

  @Output() readonly events = new EventEmitter<WhiteboardEvent>();
  @Output() readonly close = new EventEmitter<void>();
  @Output() readonly disableScrolling = new EventEmitter<void>();
  @Output() readonly enableScrolling = new EventEmitter<void>();
  @Output() readonly othersViewingChange = new EventEmitter<void>();
  @Output() readonly printAll = new EventEmitter<void>();
  @Output() readonly gridTypeChange = new EventEmitter<WhiteboardGridType>();

  constructor(
    @Inject(MEMENTO_MANAGER_FACTORY) factory: () => WhiteboardMementoManager,
    private readonly sanitizer: DomSanitizer,
    private readonly whiteboardService: WhiteboardService,
    private readonly changeDetectorRef: ChangeDetectorRef) {
    install(fabric);
    this.#mementoManager = factory();

    // Listen for clear events
    this.#subscriptions.push(
      whiteboardService.clear$.subscribe(message => {
        if (message.activityGuid === this.whiteboardGuidKey?.activityGuid && message.pageGuid === this.whiteboardGuidKey?.pageGuid) {
          this.#clearCanvas();

          if (this.#mementoManager) {
            this.#mementoManager.clear();
          }
        }
        this.changeDetectorRef.markForCheck();
      }),

      whiteboardService.load$.subscribe(message => {
        this.#eventsArchivedOnUtc = message.eventsArchivedOnUtc;
        this.#pageTitle = message.pageTitle;
        if (message.whiteboardGuidKey.activityGuid === this.whiteboardGuidKey?.activityGuid && message.whiteboardGuidKey.pageGuid === this.whiteboardGuidKey?.pageGuid && this.#canvas) {
          this.#clearCanvas();

          this.#resizeCanvas(() => {
            this.#load(message.events || []);
            if (this.#canvas) {
              WhiteboardGridTool.drawGrid(message.gridType, this.#canvas);
            }
            whiteboardService.markLoadComplete();
            setTimeout(() => {
              if (whiteboardService.printing) {
                if (this.whiteboardService.pagesLeftToPrint === 0) {
                  const printElements = this.whiteboardService.printElements;
                  this.whiteboardService.finishPrinting();
                  this.#printElements(printElements);
                } else {
                  if (this.image && this.canvas && this.whiteboardGuidKey) {
                    const originalImage = this.image?.nativeElement;
                    const originalCanvas = this.canvas?.nativeElement;
                    const printImage = originalImage.cloneNode() as HTMLImageElement;
                    const printCanvas = document.createElement('img');
                    printImage.style.width = '90%';
                    printImage.style.margin = '0 5%';
                    printCanvas.src = originalCanvas.toDataURL('image/png');
                    this.whiteboardService.addPrintElement({ image: printImage, canvas: printCanvas, activityGuid: this.whiteboardGuidKey.activityGuid });
                  }
                }
              }
            }, 200);
          });

        } else {
          console.log('Incorrect whiteboard - message ignored');
        }
        this.changeDetectorRef.markForCheck();
      }),
      whiteboardService.unload$.subscribe(() => {
        if (this.#canvas) {
          const activeObject = this.#canvas.getActiveObject();
          if (activeObject && activeObject.type === 'i-text') {
            // eslint-disable-next-line sonarjs/no-duplicate-string
            activeObject.fire('text:editing:exited');
            this.#canvas.discardActiveObject();
          }
        }
      }),
      whiteboardService.gridType$.subscribe(message => {
        if (message.whiteboardGuidKey.activityGuid === this.whiteboardGuidKey?.activityGuid && message.whiteboardGuidKey.pageGuid === this.whiteboardGuidKey?.pageGuid && this.#canvas) {
          WhiteboardGridTool.drawGrid(message.gridType, this.#canvas);
        }
        this.changeDetectorRef.markForCheck();
      }),
      whiteboardService.messages$.subscribe(message => {
        if (message.whiteboardGuidKey.activityGuid === this.whiteboardGuidKey?.activityGuid && message.whiteboardGuidKey.pageGuid === this.whiteboardGuidKey?.pageGuid) {
          const handler = this.#handlers[message.event.type];
          if (handler) {
            handler(message.event);
          }
        }
        this.changeDetectorRef.markForCheck();
      }));
  }

  ngOnInit() {

    // Initialize the canvas
    if (this.canvas) {
      this.#canvas = new fabric.Canvas(this.canvas.nativeElement, {
        selection: false,
        isDrawingMode: false
      });
    }

    this.#resizeCanvas();

    // When page is resized, resize the canvas, but only when actually visible

    if (this.container && ResizeObserver) {
      const observer = new ResizeObserver(() => {
        if (this.#whiteboardVisibleInDom) {
          this.#resizeCanvas();
        }
      });
      observer.observe(this.container.nativeElement);
    }

    // Determine if the whiteboard is visible in the dom
    if (this.container && IntersectionObserver) {
      const intersectionObserver = new IntersectionObserver(entries => {
        this.#whiteboardVisibleInDom = entries.some(s => s.isIntersecting);
        if (!this.#whiteboardVisibleInDom) {
          this.#resetCanvasSizing();
        }
      });

      intersectionObserver.observe(this.container.nativeElement);
    }

    // HACK: Set the canvas to 100% via css then get the px dimensions to scale the canvas correctly
    // this._canvas.setHeight(this.canvas.nativeElement.clientHeight);
    // this._canvas.setWidth(this.canvas.nativeElement.clientWidth);

    if (this.#canvas) {

      // Bind the required events for collaborative processing
      this.#canvas.on('object:added', (event: fabric.IEvent) => {
        const target = event.target as FabricObjectExtended;
        if (target && // Only path objects are process when added
          // All others are processed in the modified event once they have been 'drawn'
          (target.type === 'path' || this.#isShape(target) || target.data?.memento || this.#isChart(target))) {
          this.#processAdd(target);
        }
        this.changeDetectorRef.markForCheck();
      });

      this.#canvas.on('text:changed', (event: fabric.IEvent) => {
        const target = event.target as fabric.Text;
        if (target) {
          this.#processPartial(target);
        }
      });

      this.#canvas.on('text:editing:exited', (event: fabric.IEvent) => {
        const target = event.target as fabric.Text;
        if (target && !this.#toolUpdating) {

          // Ensure an empty string isn't processed
          let text = target.text ?? '';
          text = text.trim();

          // Check if the text is being added or it is a change to the value
          if (text || text === '') {
            if (this.#isNew(target)) {
              this.#processAdd(target);
            } else {
              this.#processUpdate(target, () => {
                if (this.#canvas) {
                  this.#mementoManager.add(
                    new WhiteboardTextMemento(this.#canvas, target)
                  );
                }
              });
            }
          }
        }
        this.changeDetectorRef.markForCheck();
      });

      this.#canvas.on('object:moved', (event: fabric.IEvent) => {
        const target = event.target;
        if (target) {
          this.#processUpdate(target, () => {
            if (this.#canvas) {
              this.#mementoManager.add(
                new WhiteboardMoveMemento(this.#canvas, target, event.transform as any)
              );
            }
          });
        }
        this.changeDetectorRef.markForCheck();
      });

      this.#canvas.on('object:scaled', (event: fabric.IEvent) => {
        const target = event.target;
        if (target) {
          this.#processUpdate(target, () => {
            if (this.#canvas) {
              this.#mementoManager.add(
                new WhiteboardScaleMemento(this.#canvas, target, event.transform as any)
              );
            }
          });
        }
        this.changeDetectorRef.markForCheck();
      });

      this.#canvas.on('object:rotated', (event: fabric.IEvent) => {
        const target = event.target;
        if (target) {
          this.#processUpdate(target, () => {
            if (this.#canvas) {
              this.#mementoManager.add(
                new WhiteboardRotateMemento(this.#canvas, target, event.transform as any)
              );
            }
          });
        }
        this.changeDetectorRef.markForCheck();
      });

      this.#canvas.on('object:removed', (event: fabric.IEvent) => {
        const target = event.target;
        if (target && !this.#isClearing) {
          this.#processRemove(target);
        }
        this.changeDetectorRef.markForCheck();
      });
    }
  }

  ngOnDestroy() {
    this.whiteboardService.onCloseToolbar();
    this.whiteboardService.hideKeyboard();

    this.#resetCanvasSizing();

    for (const subscription of this.#subscriptions) {
      subscription.unsubscribe();
    }
    this.#subscriptions = [];

    if (this.#canvas) {

      // TODO - latest version of fabric crashes app of dispose
      try {
        this.#canvas.dispose();
      }
      catch {
        // ignore
      }
    }

    this.whiteboardService.destroy();
  }

  onClose() {
    this.#resetCanvasSizing();
    this.close.emit();
  }

  onUndo() {
    this.#mementoManager.undo();
  }

  onRedo() {
    this.#mementoManager.redo();
  }

  onClear() {

    // Process the clear request
    const objects = this.#handleClear();

    // Add the clear to the memento stack for undo/redo
    if (objects.length > 0 && this.#canvas) {
      this.#mementoManager.add(
        new WhiteboardClearMemento(this.#canvas, objects)
      );
    }
  }

  onPrint() {
    this.#printElements();
  }

  enablePanEnableScroll() {
    this.disablePan = false;
    this.enableScrolling.emit();
  }

  disablePanDisableScroll() {
    this.disablePan = true;
    this.disableScrolling.emit();
  }

  onToolbarSelection(selection: WhiteboardToolbarSelection) {
    this.whiteboardService.onSelectTool(selection);

    if (this.#canvas) {
      const activeObject = this.#canvas.getActiveObject();
      if (activeObject && activeObject.type === 'i-text') {
        activeObject.fire('text:editing:exited');
        this.#canvas.discardActiveObject();
      }

      this.#toolUpdating = true;

      // Teardown the previous toolbar selection
      if (this.#toolbarSelection) {
        this.#toolbarSelection.teardown(this.#canvas);
      }

      // Then setup the new selection
      this.#toolbarSelection = selection;
      this.#toolbarSelection.setup(this.#canvas);

      // And ensure the canvas is correctly rendered after the changes
      this.#canvas.renderAll();
      this.#toolUpdating = false;
    }

    this.disableScrolling.emit();
  }

  displayToolbar() {
    this.#hasImageLoaded = true;
  }

  #printElements(elements: PrintElement[] = []) {
    if (this.image && this.canvas && this.whiteboardGuidKey) {
      const body = document.body;
      const originalImage = this.image?.nativeElement;
      const originalCanvas = this.canvas?.nativeElement;
      const printImage = originalImage.cloneNode() as HTMLImageElement;
      const printCanvas = document.createElement('img');
      const printContainer = document.createElement('div');
      const printStyle = document.createElement('style');
      const orientation = originalCanvas.width < originalCanvas.height || elements.length > 0 ? 'portrait' : 'landscape';

      printStyle.innerHTML = `@page { size: A4 ${orientation}; max-height:100%; max-width:100%; }`;
      printContainer.append(printStyle);

      printImage.style.width = '90%';
      printImage.style.margin = '0 5%';

      elements.push({ image: printImage, canvas: printCanvas, activityGuid: this.whiteboardGuidKey.activityGuid });

      let index = 0;
      for (const element of elements) {

        const wrappingContainer = document.createElement('div');

        wrappingContainer.className = 'kip-whiteboard-printing__page-wrapper';
        // eslint-disable-next-line @typescript-eslint/no-deprecated, etc/no-deprecated
        wrappingContainer.style.pageBreakAfter = index !== elements.length - 1 ? 'always' : '';
        element.image.removeAttribute('style');
        element.image.className = 'kip-whiteboard-printing__pdf';
        element.canvas.className = 'kip-whiteboard-printing__canvas';

        if (element.image.src.startsWith('data:image') || element.image.src.startsWith('blob')) {
          element.image.style.width = '90%';
          element.image.style.margin = '0 5%';
          wrappingContainer.append(element.image);
        }
        wrappingContainer.append(element.canvas);

        const titleContainer = document.createElement('div');
        titleContainer.innerText = `${this.#pageTitle} - Page ${index + 1} of ${elements.length}`;
        titleContainer.className = 'kip-whiteboard-printing__title';

        wrappingContainer.append(titleContainer);

        printContainer.append(wrappingContainer);
        index++;
      }

      printContainer.className = 'window-printing-container overflow-visible';
      printCanvas.src = originalCanvas.toDataURL('image/png');
      printCanvas.onload = () => {
        body.className += ' kip-whiteboard-printing';
        body.append(printContainer);

        window.print();

        body.removeChild(printContainer);
        body.className = body.className.replace(/ kip-whiteboard-printing/g, '');
      };
    }
  }

  #clearCanvas() {
    if (this.#canvas) {
      this.#isClearing = true;
      this.#canvas.clear();
      this.#isClearing = false;
    }
  }

  #invertSign(number: number) {
    return number - number * 2;
  }

  #resizeCanvasTimer(elementRef: ElementRef<HTMLElement> | undefined, action?: () => void) {
    this.#resizeTimer = setTimeout(() => {
      if (this.#canvas && this.container && elementRef) {
        try {
          const width = elementRef.nativeElement.clientWidth;
          let height = elementRef.nativeElement.clientHeight;
          const currentWidthDifference = width - this.#lastWidth;

          // setting it to zero is useless, wait and retry
          if (height === 0 && action) {
            this.#resizeCanvasTimer(elementRef, action);
            return;
          }

          console.log(`Resize canvas to ${width}x${height}`);

          // Check if scrollbar is causing resizer to flicker between two sizes before setting dimensions
          if (currentWidthDifference !== this.#invertSign(this.#lastWidthDifference) || currentWidthDifference === 0 && this.#lastWidthDifference === 0) {
            if (height < this.container.nativeElement.clientHeight) {
              height = this.container.nativeElement.clientHeight;
            }

            this.#canvas.setDimensions({ height: height, width: width });
          }

          if (this.#lastWidth > 0) {
            const objects = this.#canvas.getObjects();
            for (const obj of objects) {
              this.#scale(obj, this.#lastWidth, width);
            }

            // Set lastWidthDifference if not 0
            if (width - this.#lastWidth !== 0) {
              this.#lastWidthDifference = width - this.#lastWidth;
            }
          }
          this.#lastWidth = width;

          this.#canvas.renderAll();
        } catch {
          // ignore errors

          console.log('An error occurred while attempting to resize the whiteboard canvas');
        }
        if (action) {
          action();
        }
      }
      this.changeDetectorRef.markForCheck();
    }, 200);
  }

  #resetCanvasSizing() {
    this.#lastWidth = 0;
    this.#lastWidthDifference = 0;
    if (this.#resizeTimer) {
      clearTimeout(this.#resizeTimer);
    }
  }

  #resizeCanvas(action?: () => void) {
    if (this.#canvas) {
      if (this.#backgroundImage) {
        this.#resizeCanvasTimer(this.image, action);
      } else {
        this.#hasImageLoaded = true;
        this.#resizeCanvasTimer(this.container, action);
      }
    }
  }

  #isNew(obj: FabricObjectExtended): boolean {
    return !obj.data || !obj.data.id;
  }

  #isShape(obj: FabricObjectExtended): boolean {
    return obj.data?.shape ?? false;
  }

  #isChart(obj: FabricObjectExtended): boolean {
    return obj.data?.chart ?? false;
  }

  #isRemote(obj: FabricObjectExtended): boolean {

    // Objects coming in from another user are consider remote and processed differently
    return this.#isRemoteObject || (obj.data?.remote ?? false);
  }

  #isAsset(obj: FabricObjectExtended): boolean {

    // A concept is in place to ensure only user created objects are processed
    // The non-user objects are called 'assets' of the whiteboard and are tagged as such
    return obj.data?.asset ?? false;
  }

  #isLoading(obj: FabricObjectExtended): boolean {
    return obj.data?.loading ?? false;
  }

  #findObject(id: string | undefined) {
    let match: fabric.Object | undefined;

    if (this.#canvas && id) {
      this.#canvas.forEachObject(obj => {
        const objDataDefined = obj as FabricObjectExtended;
        if (objDataDefined.data && objDataDefined.data?.id === id) {
          match = obj;
        }
      });
    }

    return match;
  }

  #selectable(obj: fabric.Object): boolean {
    return this.#toolbarSelection?.selectable(obj) ?? false;
  }

  #load(events: readonly WhiteboardEvent[]) {

    if (!this.#canvas) {
      console.log('No canvas set up yet - aborting');
      return;
    }

    const add = (obj: fabric.Object, canvas: fabric.Canvas, event: WhiteboardEvent) => {
      // Set the object config
      obj.selectable = this.#selectable(obj);
      this.#setLoading(obj);

      this.#clearMemento(obj);

      if (event.width) {
        const width = canvas.getWidth();
        this.#scale(obj, event.width, width);
      }

      obj.setCoords();

      // Add it to the canvas
      canvas.add(obj);

      // Remove the loading flag for subsequent processing
      this.#clearLoading(obj);
    };

    for (const event of events) {
      if (event.data?.chart) {
        const chart = new fabric.Chart(event.data);
        add(chart, this.#canvas, event);
      } else {
        fabric.util.enlivenObjects([event.data], (objects: fabric.Object[]) => {
          for (const enlivened of objects) {
            if (this.#canvas) {
              add(enlivened, this.#canvas, event);
            }
          }
        }, '');
      }

      this.#lastWidth = this.#canvas.getWidth();
      this.#canvas.renderAll();
    }
    this.changeDetectorRef.markForCheck();
  }

  #scale(obj: fabric.Object, sourceWidth: number, targetWidth: number) {
    const ratio: number = targetWidth / sourceWidth;

    if (obj.top) {
      obj.top = obj.top * ratio;
    }
    if (obj.left) {
      obj.left = obj.left * ratio;
    }

    // When scaling some consideration is needed around text and adjusting the font size
    // At the moment this is disregarded, but it may need to be smarter

    if (obj.scaleX) {
      obj.scaleX = ratio * obj.scaleX;
    }

    if (obj.scaleY) {
      obj.scaleY = ratio * obj.scaleY;
    }

    obj.setCoords();
  }

  #processAdd(obj: FabricObjectExtended) {
    // Check if the object is an asset or loading and stop if so
    if (this.#isAsset(obj) || this.#isLoading(obj)) {
      return;
    }

    // Force selectable false for added objects if not explicitly set
    // And update the coords for canvas selection
    if (obj.selectable === undefined) {
      obj.selectable = false;
    }

    obj.setCoords();

    // Locally added objects won't have an id so assign one for tracking
    if (!this.#isRemote(obj) && this.#canvas) {
      if (this.#isNew(obj)) {
        this.#setObjectId(obj);
      }

      // Emit the locally added object for other whiteboard to display
      this.events.emit({
        id: obj.data?.id,
        type: WhiteboardEventType.Added,
        height: this.#canvas.getHeight(),
        width: this.#canvas.getWidth(),
        data: obj.toDatalessObject(['data'])
      });

      // Add both locally and remotely added objects to the undo/redo manager
      if (!obj.data?.memento) {
        this.#mementoManager.add(
          new WhiteboardAddMemento(this.#canvas, obj)
        );
      }
    }
  }

  #processUpdate(obj: FabricObjectExtended, memento: () => void) {
    // Check if the object is new, an asset or loading and stop if so
    if (this.#isNew(obj) || this.#isAsset(obj) || this.#isLoading(obj)) {
      return;
    }

    // Update the coords for canvas selection
    obj.setCoords();

    // Emit the update event
    if (!this.#isRemote(obj) && this.#canvas) {
      this.events.emit({
        id: obj.data?.id,
        type: WhiteboardEventType.Updated,
        height: this.#canvas.getHeight(),
        width: this.#canvas.getWidth(),
        data: obj.toDatalessObject(['data'])
      });

      // Allow the update event to add to the undo/redo manager
      if (!obj.data?.memento) {
        memento();
      }
    }
  }

  #processRemove(obj: FabricObjectExtended) {
    // Check if the object is new, an asset or loading and stop if so
    if (this.#isNew(obj) || this.#isAsset(obj) || this.#isLoading(obj)) {
      return;
    }

    // Emit the event but only if it is removed locally
    if (!this.#isRemote(obj) && this.#canvas) {
      this.events.emit({
        id: obj.data?.id,
        type: WhiteboardEventType.Removed,
        height: this.#canvas.getHeight(),
        width: this.#canvas.getWidth(),
        data: obj.toDatalessObject(['data'])
      });

      // Track the remove in the undo/redo manager
      if (!obj.data?.memento) {
        this.#mementoManager.add(
          new WhiteboardRemoveMemento(this.#canvas, obj)
        );
      }
    }
  }

  #processPartial(obj: FabricObjectExtended) {
    if (!this.#isRemote(obj) && this.#canvas) {
      const isNew = this.#isNew(obj);
      if (isNew) {
        this.#setPartialId(obj);
        this.#setObjectId(obj);
      }
      this.events.emit({
        id: obj.data?.id,
        type: isNew ? WhiteboardEventType.PartiallyAdded : WhiteboardEventType.PartiallyUpdated,
        height: this.#canvas.getHeight(),
        width: this.#canvas.getWidth(),
        data: obj.toDatalessObject(['data'])
      });
      if (isNew) {
        this.#clearObjectId(obj);
      }
    }
  }

  #remoteAdd(event: WhiteboardEvent) {

    if (!this.#canvas) {
      return;
    }

    // Add a remote flag to avoid local processing
    const canvasElement = this.#findObject(event.id);

    const add = (obj: fabric.Object, canvas: fabric.Canvas) => {
      if (canvas) {
        // Delete any memento reference
        this.#clearMemento(obj);

        // Set the object config
        obj.selectable = this.#selectable(obj);
        this.#setRemote(obj);

        if (event.width) {
          const canvasWidth = canvas.getWidth();
          this.#scale(obj, event.width, canvasWidth);
        }

        obj.setCoords();

        // Add it to the canvas
        canvas.add(obj);

        // Ensure the remote flag is deleted to ensure subsequent inclusions
        this.#clearRemote(obj);
      }
    };

    if (!canvasElement) {
      if (event.data?.chart) {

        if (this.#canvas) {
          const chart = new fabric.Chart(event.data);
          add(chart, this.#canvas);
        }
      } else {
        fabric.util.enlivenObjects([event.data], (objects: fabric.Object[]) => {
          for (const enlivened of objects) {
            if (this.#canvas) {
              add(enlivened, this.#canvas);
            }
          }
        }, '');
      }
      this.#lastWidth = this.#canvas.getWidth();
      this.#canvas.renderAll();
    }
    this.changeDetectorRef.markForCheck();
  }

  #remoteUpdate(event: WhiteboardEvent) {

    // Add a remote flag to avoid local processing
    const obj = this.#findObject(event.id);

    if (obj && this.#canvas) {

      // Delete any memento reference
      this.#clearMemento(obj);

      // Configure the object before update
      obj.selectable = this.#selectable(obj);
      this.#setRemote(obj);

      const canvasWidth = this.#canvas.getWidth();

      obj.set(event.data);
      if (event.width) {
        this.#scale(obj, event.width, canvasWidth);
      }
      obj.setCoords();

      this.#lastWidth = canvasWidth;
      this.#canvas.renderAll();

      // Ensure the remote flag is deleted to ensure subsequent inclusions
      this.#clearRemote(obj);
    }
  }

  #remoteRemove(event: WhiteboardEvent) {

    // Add a remote flag to avoid local processing
    const obj = this.#findObject(event.id);

    if (obj && this.#canvas) {

      // Delete any memento reference
      this.#clearMemento(obj);

      // Configure the remote flag
      this.#setRemote(obj);

      this.#canvas.remove(obj);
      this.#canvas.renderAll();

      // Ensure the remote flag is deleted to ensure subsequent inclusions
      this.#clearRemote(obj);
    }
  }

  #remoteClear() {
    this.#handleClear(obj => {
      if (obj?.data) {
        this.#setRemote(obj);
      }
    }, true);
  }

  #handleClear(adjust?: (obj: FabricObjectExtended) => void, isRemote = false): fabric.Object[] {
    // Get each of the canvas items, remove them and add them to the undo/redo manager
    const objects: FabricObjectExtended[] = [];

    if (this.#canvas) {
      // Ensure any partial object are deselected to guarantee their removal
      this.#canvas.discardActiveObject();

      this.#canvas.forEachObject(obj => {

        // Check that only user objects are removed
        if (!this.#isAsset(obj)) {
          if (adjust) {
            adjust(obj);
          }

          objects.push(obj);
        }
      });

      if (!isRemote) {
        this.events.emit({
          id: undefined,
          type: WhiteboardEventType.Cleared,
          height: this.#canvas.getHeight(),
          width: this.#canvas.getWidth(),
          data: null
        });
      }

      if (objects.length > 0) {
        for (const obj of objects) {
          this.#canvas.remove(obj);
        }
      }
    }

    return objects;
  }

  #setObjectId(obj: FabricObjectExtended) {
    obj.data = obj.data ? Object.assign({}, obj.data, { id: obj.data?.partialId ?? uuid.v1() }) : {
      id: uuid.v1()
    };
  }

  #setPartialId(obj: FabricObjectExtended) {
    if (obj.data) {
      if (!obj.data.partialId) {
        obj.data = Object.assign({}, obj.data, { partialId: uuid.v1() });
      }
    }
    else {
      obj.data = {
        partialId: uuid.v1()
      };
    }
  }

  #clearObjectId(obj: FabricObjectExtended) {
    if (obj.data?.id) {
      obj.data = Object.assign({}, obj.data, { id: undefined });
    }
  }

  #setLoading(obj: FabricObjectExtended) {
    try {
      obj.data = Object.assign({}, obj.data, { loading: true });
    } catch {
      // do nothing
    }
  }

  #clearLoading(obj: FabricObjectExtended) {
    try {
      delete obj.data?.loading;
    } catch {
      obj.data = Object.assign({}, obj.data, { loading: false });
    }
  }

  #setRemote(obj: FabricObjectExtended) {
    try {
      obj.data = Object.assign({}, obj.data, { remote: true });
    } catch {
      // do nothing
    }
    this.#isRemoteObject = true;
  }

  #clearRemote(obj: FabricObjectExtended) {
    try {
      delete obj.data?.remote;
    } catch {
      obj.data = Object.assign({}, obj.data, { remote: false });
    }
    this.#isRemoteObject = false;
  }

  #clearMemento(obj: FabricObjectExtended) {
    if (obj.data) {
      try {
        delete obj.data?.memento;
      } catch {
        obj.data = Object.assign({}, obj.data, { memento: undefined });
      }
    }
  }
}
