import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as moment from 'moment';
import { EMPTY, Observable } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';

import { LessonService } from '../../services';
import { fromQuestion } from '..';
import * as fromActivity from '../activity';
import * as fromLesson from '../lesson';
import { StudentState } from '../state';

@Injectable()
export class ActivityEffects {

  readonly #actions$ = inject(Actions);
  readonly #store = inject(Store<StudentState>);
  readonly #lessonService = inject(LessonService);

  loadQuestions$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.loadQuestionsAction),
      switchMap(action => this.#lessonService
        .getQuestions(action.lessonActivityPlanId, action.regionId)
        .pipe(
          switchMap(activityQuestions => {
            if (activityQuestions) {
              const adjustedQuestions = activityQuestions.questions.map(q => {
                return {
                  questionGuid: q.questionGuid,
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  question: Object.assign({
                    questionId: q.question.questionId,
                    contentGuid: q.question.contentGuid,
                    hasIntroduction: q.question.hasIntroduction,
                    hasWorkedSolution: q.question.hasWorkedSolution,
                    isJiraReportingBlocked: q.question.isJiraReportingBlocked
                  },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    (q.question as any).data),
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  questionKatex: Object.assign({
                    questionId: q.questionKatex.questionId,
                    contentGuid: q.questionKatex.contentGuid,
                    hasIntroduction: q.questionKatex.hasIntroduction,
                    hasWorkedSolution: q.questionKatex.hasWorkedSolution,
                    isJiraReportingBlocked: q.questionKatex.isJiraReportingBlocked
                  },
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    (q.questionKatex as any).data)
                };
              });

              const actions: Action[] = [
                fromActivity.finishLoadQuestionsAction({ activityGuid: activityQuestions.activityGuid }),
                fromQuestion.loadQuestionsAction({ questions: adjustedQuestions })];

              return actions;
            } else {
              return EMPTY;
            }
          })
        )
      )
    );
  });

  open$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.openAction),
      withLatestFrom(
        this.#store.select(fromActivity.selectSelectedActivity),
        this.#store.select(fromLesson.selectSelectedLesson)
      ),
      switchMap(([action, activity, lesson]) => {
        // Get the current date & time & use it to populate startedOn in selected activity
        const now = moment.utc().toDate();

        const actions: Action[] = [];

        if (activity) {
          actions.push(
            fromActivity.startAction({ activityGuid: activity.activityGuid, startedOn: now }));

          // load computer questions on the fly if required
          if (lesson && (activity.computer || activity.timedComputer) && !activity.questionsLoaded) {
            actions.push(
              fromActivity.loadQuestionsAction({ lessonActivityPlanId: action.lessonActivityPlanId, regionId: lesson.regionId })
            );
          }
        }

        return actions;
      })
    );
  });

  activityScored$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromActivity.scoredAction),

      switchMap(action => {
        // Build the actions to load the lesson data
        const actions: Action[] = [
          fromLesson.refreshAwardDataAction({ lessonGuid: action.lessonGuid, lessonId: action.lessonId })
        ];

        return actions;
      })
    );
  });
}
