/* eslint-disable @typescript-eslint/ban-ts-comment */

// @ts-nocheck
// this was a hack as it was getting confused about typescript versions and
// complaining about the Itnl library

import { AnswerType } from '../../models';
import { createCurrencyParser } from './create-currency-parser';
import { createCurrencySymbols, CurrencySymbols } from './create-currency-symbols';

enum CurrencyMatchOption {
  Exclude = 0,
  Mandatory = 1,
  Optional = 2,
  Unknown = 3
}

interface CurrencyMatchOptions {
  fractions: CurrencyMatchOption;
  negativeSignage: CurrencyMatchOption;
  prefix: CurrencyMatchOption;
}

export class CurrencyValidationHelper {

  #matches: string[] = [];

  readonly parts: Intl.NumberFormatPart[];
  readonly symbols: CurrencySymbols;
  readonly valueOf: number;

  constructor(
    readonly answer: AnswerType,
    readonly formatter: Intl.NumberFormat,
    readonly suffixes: readonly string[]
  ) {
    const valueString = typeof answer === 'number' ? answer.toString() : answer;
    const currencyParser = createCurrencyParser(formatter);

    this.valueOf = currencyParser(valueString);
    this.symbols = createCurrencySymbols(formatter);
    this.parts = formatter
      .formatToParts(this.valueOf)
      .filter(o => this.#filterPart(o));
  }

  createMatches(options?: CurrencyMatchOptions) {

    const matchOptions = options ?? this.createMatchOptions();

    const matches: string[] = [];

    const removeCurrency = matchOptions.prefix === CurrencyMatchOption.Exclude;
    const removeFractions = matchOptions.fractions === CurrencyMatchOption.Exclude;
    const removeNegativeSignage = matchOptions.negativeSignage === CurrencyMatchOption.Exclude;
    const template = this.#createMatchTemplate(removeCurrency, removeFractions, removeNegativeSignage);
    const hasSignificantFraction = this.has('decimal') && this.hasSignificantFraction();

    if (!removeCurrency) {
      matches.push(template);

      if (!hasSignificantFraction) {
        /* eslint-disable no-param-reassign */
        const value = this.parts.filter(o =>
          ['minusSign', 'currency', 'integer', 'group'].includes(o.type)).reduce((prev, current) => prev += current.value, '');
        /* eslint-enable no-param-reassign */
        matches.push(value);
      }

      if (matchOptions.prefix === CurrencyMatchOption.Optional) {
        const items = this.suffixes
          .map(suffix => this.#createMatchForSuffix(suffix, hasSignificantFraction));

        matches.push(...items);
      }
    }

    if (!removeNegativeSignage && matchOptions.negativeSignage === CurrencyMatchOption.Optional) {
      matches.push(
        ...matches.map(o => o.replace(this.symbols.negative, ''))
      );
    }

    if (this.has('group')) {
      const pattern = new RegExp(`[\\${this.symbols.grouping}]`, 'g');
      matches.push(
        ...matches.map(o => o.replace(pattern, ''))
      );
    }

    // creating a set remove duplicates
    return this.#matches = [...new Set(
      matches.map(o => o.replace(/\s/g, ''))
    )];
  }

  createMatchOptions(): CurrencyMatchOptions {
    const value = this.valueOf;

    const options: CurrencyMatchOptions = {
      fractions: CurrencyMatchOption.Optional,
      negativeSignage: CurrencyMatchOption.Exclude,
      prefix: CurrencyMatchOption.Optional
    };

    const hasSignificantFraction = this.has('decimal') && this.hasSignificantFraction();
    const bounded = value > 0 && value < 1 || value < 0 && value > -1;

    if (hasSignificantFraction && !bounded) {
      options.fractions = CurrencyMatchOption.Mandatory;
      options.prefix = CurrencyMatchOption.Mandatory;
    }

    if (!hasSignificantFraction && (value < -1 || value > 1)) {
      options.prefix = CurrencyMatchOption.Mandatory;
    }

    if (value === 0) {
      options.negativeSignage = CurrencyMatchOption.Optional;
    } else if (value < 0) {
      options.negativeSignage = CurrencyMatchOption.Mandatory;
    }

    return options;
  }

  has(type: Intl.NumberFormatPartTypes): boolean {
    return this.parts.some(o => o.type === type);
  }

  hasSignificantFraction(): boolean {
    const fraction = this.parts.find(o => o.type === 'fraction')?.value;

    if (fraction) {
      return fraction.replace(/0/g, '').length > 0;
    }

    return false;
  }

  get matches() {
    return this.#matches;
  }

  validate(studentAnswer: AnswerType) {
    const match = typeof studentAnswer === 'number' ? studentAnswer.toString() : studentAnswer;

    // want to match c or C, r or R even though it is not technically correct
    const find = match.replace(/\s/g, '').toLowerCase();

    return this.#matches.some(
      m => m.toLowerCase() === find
    );
  }

  #createMatchForSuffix(suffix: string, hasSignificantFraction: boolean): string {
    let returnedValue = '';

    /* eslint-disable no-param-reassign */
    if (hasSignificantFraction) {
      returnedValue = this.parts
        .filter(o => ['minusSign', 'fraction'].includes(o.type))
        .map(({ type, value }) => type === 'fraction' ? Number.parseInt(value, 10).toString() : value)
        .reduce((prev, current) => prev += current, '');
    } else {
      returnedValue = this.parts
        .filter(o => ['minusSign', 'integer', 'group'].includes(o.type))
        .reduce((prev, current) => prev += current.value, '');

      const asInt = Number.parseInt(returnedValue, 10);

      if (!Number.isNaN(asInt) && Math.abs(asInt) === 1) {
        returnedValue = returnedValue.replace('1', '100');
      }
    }
    /* eslint-enable no-param-reassign */

    return `${returnedValue}${suffix}`;
  }

  #createMatchTemplate(removeCurrency: boolean, removeFractions: boolean, removeNegativeSignage: boolean) {
    return this.parts
      .filter(({ type }) => !(removeCurrency && type === 'currency' ||
        removeFractions && (type === 'fraction' || type === 'decimal') ||
        removeNegativeSignage && type === 'minusSign')
      )
      .map(({ value }) => value)
      .reduce((current, next) => current + next, '');
  }

  #filterPart(part: Intl.NumberFormatPart) {
    return !(part.type === 'literal' && part.value.trim() === '');
  }
}
