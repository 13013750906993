import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SharedService } from 'ui-common-lib';

import * as fromComprehensionTest from '../comprehension-test';

@Injectable()
export class ComprehensionTestEffects {

  readonly #actions$ = inject(Actions);
  readonly #sharedService = inject(SharedService);

  load$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(fromComprehensionTest.loadComprehensionTestsAction),
      switchMap(() => this.#sharedService.getComprehensionTests()
        .pipe(
          switchMap(response =>
            [fromComprehensionTest.loadComprehensionTestsManyAction({ comprehensionTest: response })]
          )
        )
      )
    );
  });
}
