import { createFeatureSelector, createSelector } from '@ngrx/store';

import { CustomActivityQuestionIndexFormatter } from '../../shared';
import { ActivityState } from './activity/activity.reducer';
import { AiChatState } from './ai-chat/ai-chat.reducer';
import { AssistantState } from './assistant/assistant.reducer';
import { CentreState } from './centre/centre.reducer';
import { ChatState } from './chat/chat.reducer';
import { ComprehensionState } from './comprehension-test/comprehension-test.reducer';
import { DeviceState } from './device/device.reducer';
import { DropInState } from './drop-in/drop-in.reducer';
import { LessonState } from './lesson/lesson.reducer';
import { LogState } from './log/log.reducer';
import { ActivityEntityState } from './models';
import { QuestionState } from './question/question.reducer';
import { SoundOptionState } from './sound-option/sound-option.reducer';

interface StudentData {
  readonly lessons: LessonState;
  readonly activities: ActivityState;
  readonly questions: QuestionState;
  readonly logs: LogState;
  readonly chat: ChatState;
  readonly aiChat: AiChatState;
  readonly assistant: AssistantState;
  readonly devices: DeviceState;
  readonly assessment: ComprehensionState;
  readonly dropIn: DropInState;
  readonly centres: CentreState;
  readonly soundOption: SoundOptionState;
}

export interface StudentState {
  readonly student: StudentData;
}

export const studentFeatureName = 'student';

const selectStudentState = createFeatureSelector<StudentData>(studentFeatureName);

export const selectLessonState = createSelector(selectStudentState, (state: StudentData) => state.lessons);
const selectLessonEntities = createSelector(selectLessonState, (state: LessonState) => state.entities);
export const selectLessonEntityState = createSelector(selectLessonState, (state: LessonState) => state.entityState);
const selectSelectedLessonEntityId = createSelector(selectLessonState, (state: LessonState) => state.selectedId);
export const selectSelectedLessonEntity = createSelector(selectSelectedLessonEntityId, selectLessonEntities, (id, entities) =>
  id !== undefined ? entities[id] : undefined);

export const selectSoundOptionGroups = createSelector(selectStudentState, (state: StudentData) => state.soundOption.soundOptionGroups);

export const selectDropInState = createSelector(selectStudentState, (state: StudentData) => state.dropIn);
export const selectAssistantState = createSelector(selectStudentState, (state: StudentData) => state.assistant);

const selectActivityState = createSelector(selectStudentState, (state: StudentData) => state.activities);
export const selectActivityEntities = createSelector(selectActivityState, (state: ActivityState) => state.entities);
export const selectActivityEntityState = createSelector(selectActivityState, (state: ActivityState) => state.entityState);
export const selectSelectedActivityEntityId = createSelector(selectActivityState, (state: ActivityState) => state.selectedLessonActivityPlanId);
export const selectRequestedActivityEntityId = createSelector(selectActivityState, (state: ActivityState) => state.requestedLessonActivityPlanId);
export const selectRequestedQuestionEntityGuid = createSelector(selectActivityState, (state: ActivityState) => state.requestedQuestionGuid);
const selectRequestedQuestionEntityPageGuid = createSelector(selectActivityState, (state: ActivityState) => state.requestedPageGuid);
export const selectIsHomeworkActivityEntity = createSelector(selectActivityState, (state: ActivityState) => state.isHomework);
const selectSelectedActivityEntity = createSelector(selectSelectedActivityEntityId, selectActivityEntities, (id, entities) =>
  id !== undefined ? entities[id] : undefined);

const selectQuestionState = createSelector(selectStudentState, (state: StudentData) => state.questions);
export const selectQuestionEntityState = createSelector(selectQuestionState, (state: QuestionState) => state.entityState);
export const selectSelectedQuestionEntityIndex = createSelector(selectQuestionState, (state: QuestionState) => state.selectedIndex);

const selectLogState = createSelector(selectStudentState, (state: StudentData) => state.logs);
export const selectLogEntityIds = createSelector(selectLogState, (state: LogState) => state.entityIds);
export const selectLogEntities = createSelector(selectLogState, (state: LogState) => state.entities);

export const selectAiChatState = createSelector(selectStudentState, (state: StudentData) => state.aiChat);
export const selectAiChatMessages = createSelector(selectAiChatState, (state: AiChatState) => state.messages);

export const selectChatState = createSelector(selectStudentState, (state: StudentData) => state.chat);
export const selectChatEntityIds = createSelector(selectChatState, (state: ChatState) => state.entityIds);
export const selectChatEntities = createSelector(selectChatState, (state: ChatState) => state.entities);

export const selectDeviceState = createSelector(selectStudentState, (state: StudentData) => state.devices);

export const selectCentreState = createSelector(selectStudentState, (state: StudentData) => state.centres);

export const selectComprehensionTests = createSelector(selectStudentState, (state: StudentData) => state.assessment.comprehensionTests);

export const selectSelectedLessonActivityGuids = createSelector(selectSelectedLessonEntity, entity => {
  if (entity) {
    return {
      lessonGuid: entity.lessonGuid,
      activityGuids: entity.activityGuids
    };
  }

  return undefined;
});

export const selectSelectedLessonHomeworkGuids = createSelector(selectSelectedLessonEntity, entity => {
  if (entity) {
    return {
      lessonGuid: entity.lessonGuid,
      homeworkGuids: entity.homeworkGuids
    };
  }

  return undefined;
});

export const selectSelectedLessonPreviousHomeworkGuids = createSelector(selectSelectedLessonEntity, entity => {
  if (entity) {
    return {
      previousLessonGuid: entity.previousLessonGuid,
      homeworkGuids: entity.previousHomeworkGuids
    };
  }

  return undefined;
});

function getQuestionIndex(entityStates: { [p: string]: ActivityEntityState }, activityGuid: string, questionGuid?: string, pageGuid?: string): number | undefined {

  if (!questionGuid && !pageGuid) {
    return undefined;
  }

  let questionIndex = -1;

  if (entityStates && activityGuid) {
    if (questionGuid) {
      questionIndex = entityStates[activityGuid].questionGuids.indexOf(questionGuid);
    }

    if (pageGuid) {
      let pageGuidQuestionIndex = CustomActivityQuestionIndexFormatter.getQuestionIndexFromPageGuid(pageGuid);

      // default to index 1
      if (!pageGuidQuestionIndex || pageGuidQuestionIndex < 1) {
        pageGuidQuestionIndex = 1;
      }

      // the index on the page guid is not a 0 based index
      // negating 1 to match the existing 0 based indexing
      // for the question indexes
      questionIndex = pageGuidQuestionIndex - 1;
    }

  }

  return questionIndex;
}

export const selectRequestedActivityQuestionIndex = createSelector(
  selectRequestedActivityEntityId, selectRequestedQuestionEntityGuid, selectRequestedQuestionEntityPageGuid, selectActivityEntityState,
  selectSelectedLessonActivityGuids, selectSelectedLessonHomeworkGuids, selectSelectedLessonPreviousHomeworkGuids,
  (lessonActivityPlanId, questionGuid, pageGuid, entityStates, lessonGuids, homeworkGuids, previousHomeworkGuids) => {
    let questionIndex: number | undefined;

    if (lessonActivityPlanId !== undefined && (questionGuid !== undefined || pageGuid !== undefined) && lessonGuids) {
      let activityGuid = lessonGuids.activityGuids.find(s => entityStates[s].lessonActivityPlanId === lessonActivityPlanId);
      if (activityGuid) {
        questionIndex = getQuestionIndex(entityStates, activityGuid, questionGuid, pageGuid);
      }
      if (!questionIndex && homeworkGuids) {
        activityGuid = homeworkGuids.homeworkGuids.find(s => entityStates[s].lessonActivityPlanId === lessonActivityPlanId);
        if (activityGuid) {
          questionIndex = getQuestionIndex(entityStates, activityGuid, questionGuid, pageGuid);
        }
      }
      if (!questionIndex && previousHomeworkGuids) {
        activityGuid = previousHomeworkGuids.homeworkGuids.find(s => entityStates[s].lessonActivityPlanId === lessonActivityPlanId);
        if (activityGuid) {
          questionIndex = getQuestionIndex(entityStates, activityGuid, questionGuid, pageGuid);
        }
      }
    }
    if (questionIndex === undefined || questionIndex === -1) {
      return undefined;
    }
    return questionIndex + 1;
  });

export const selectSelectedActivityQuestionGuids = createSelector(
  selectSelectedActivityEntity, selectIsHomeworkActivityEntity, selectActivityEntityState, selectSelectedLessonActivityGuids,
  selectSelectedLessonHomeworkGuids, selectSelectedLessonPreviousHomeworkGuids, (entity, isHomework, entityState, context, homeworkContext, previousHomeworkContext) => {

    if (entity && (context || homeworkContext)) {
      // Map the lesson activities to their state objects
      let states: ActivityEntityState[] = [];
      let lessonGuid = '';
      if (!isHomework && context) {
        lessonGuid = context.lessonGuid;
        states = context.activityGuids
          .map(guid => entityState[guid])
          .filter(state => !!state);
        if (homeworkContext) {
          states = [...states, ...homeworkContext.homeworkGuids
            .map(guid => entityState[guid])
            .filter(state => !!state)];
        }
        if (previousHomeworkContext) {
          states = [...states, ...previousHomeworkContext.homeworkGuids
            .map(guid => entityState[guid])
            .filter(state => !!state)];
        }
      } else if (homeworkContext) {
        lessonGuid = homeworkContext.lessonGuid;
        states = homeworkContext.homeworkGuids
          .map(guid => entityState[guid])
          .filter(state => !!state);
      }

      // Then find the matching id
      const match = states.find(state => state.lessonActivityPlanId === entity.lessonActivityPlanId);

      if (match) {
        return {
          lessonGuid: lessonGuid,
          questionGuids: match.questionGuids
        };
      }
    }

    return undefined;
  });
