/* eslint-disable rxjs/no-ignored-subscription */

import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

import { DropInMessagingService } from '../../services';
import * as dropInActions from '../drop-in';
import * as fromLesson from '../lesson';

@Injectable()
export class DropInEffects {

  readonly #actions$ = inject(Actions);
  readonly #dropInMessagingService = inject(DropInMessagingService);

  dropInLessonRequested$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(dropInActions.dropInSessionJoinRequestAction),
      tap(action => {
        this.#dropInMessagingService.joinRequested(action.sessionId, action.tutorId, action.studentUserId, action.studentName).subscribe();
      })
    );
  },
    { dispatch: false });

  dropInLessonRequestAccepted$: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(dropInActions.dropInLessonRequestAcceptedAction),
      switchMap(action => {
        return [fromLesson.dropInLessonRequestAcceptedAction({ lessonId: action.lessonId })];
      })
    );
  });

  dropInLessonRequestCancelled: Observable<Action> = createEffect(() => {
    return this.#actions$.pipe(
      ofType(dropInActions.dropInLessonRequestCancelledAction),
      tap(action => {
        this.#dropInMessagingService.dropInLessonRequestCancelled(action.sessionId, action.tutorId, action.studentUserId, action.studentName).subscribe();
      })
    );
  },
    { dispatch: false }
  );
}
