import { ChangeDetectionStrategy, Component, computed, inject, input, resource, TemplateRef, viewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Icons } from 'icon-lib';
import { firstValueFrom } from 'rxjs';

import { ExploreService } from '../../services';

@Component({
  selector: 'kip-explore-manual-ai',
  templateUrl: './manual-ai.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false
})
export class ExploreManualAIComponent {

  readonly #exploreService = inject(ExploreService);
  readonly #modalService = inject(NgbModal);

  readonly icons = Icons;

  readonly pdf = input.required<string>();
  readonly activityName = input.required<string>();
  readonly questionNumber = input.required<string>();

  readonly solutionResource = resource({
    request: () => ({ questionNumber: this.questionNumber(), pdf: this.pdf() }),
    loader: async param => {
      if (!param.request.pdf || !param.request.questionNumber) {
        return undefined;
      }
      return await firstValueFrom(this.#exploreService.getActivityAISolution(param.request.pdf, param.request.questionNumber));
    }
  });
  readonly solution = computed(() => this.solutionResource.value());

  readonly modalContent = viewChild<TemplateRef<any>>('content');

  /* eslint-disable kip/no-unused-public-members */

  open() {
    this.#modalService.open(this.modalContent(), { size: 'xl', centered: true });
  }

  /* eslint-enable kip/no-unused-public-members */

  close() {
    this.#modalService.dismissAll();
  }
}