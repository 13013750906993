import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { LessonType } from 'ui-common-lib';

import { IdleService, IdleStatus } from '../../shared';
import { SelectedLesson } from '../models';
import { fromLesson, StudentState } from '../store';
import { MessagingService } from './messaging.service';

@Injectable({ providedIn: 'root' })
export class StudentIdleService extends IdleService<number, string> {

  readonly #store = inject(Store<StudentState>);
  readonly #messagingService = inject(MessagingService);

  performLogout(showMessage: boolean, logoutMessage: string) {
    this.#store.dispatch(fromLesson.disconnectLessonAction({
      logoutUser: true,
      showLogoutMessage: showMessage,
      logoutMessage: logoutMessage
    }));
  }

  updateIdleStatus(id: number, idleStatus: IdleStatus) {
    this.#store.dispatch(
      fromLesson.updateIdleStatusAction({
        lessonId: id,
        idleStatus: idleStatus
      })
    );
  }

  updateMessage(id: string) {
    this.#messagingService.autoLoggedOutOnIdle(id);
  }

  updateLesson(lesson: SelectedLesson | undefined) {
    let checkIdle = false;
    // reset idle time if lesson changes
    if (lesson) {
      this.dateTimeOffsetIanaTimeZoneName = lesson.dateTime;
      this.duration = lesson.duration;

      if (this.id !== lesson.lessonId) {
        this.id = lesson.lessonId;
        this.messageId = lesson.lessonGuid;
        this.resetIdle(lesson.isHomeworkOnly
          || lesson.lessonType === LessonType.HomeMakeUp
          || lesson.lessonType === LessonType.DropIn
          || lesson.lessonType === LessonType.Assessment);
        checkIdle = true;
      }
    }

    if (checkIdle) {
      this.checkIdleTime();
    }
  }

}
